// eslint-disable-next-line
const buttonStyle = (theme, props) => ({
  button: {
    fontWeight: 600,
    marginTop: "20px",
    marginRight: "12px",
    color: "white",
    textTransform: "none",
    padding: "8px 24px",
    height: "45px",
  },
  spinner: {
    color: "white",
    position: "absolute",
  },
});

export default buttonStyle;
