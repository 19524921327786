import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteFeature,
  getFeatures,
  saveFeature,
  toggleFeatureProperty,
  toggleFeatureRoleProperty,
} from "services/features";
import { useAddNotification } from "./useAddNotification";
import { FeatureI } from "types/Feature";
import { ErrorResponseI } from "types/ErrorResponse";
import { QUERYKEY_FEATURES } from "./queryKeys";

const mapFeatures = (data) => {
  return {
    approveEnabled: data.some(
      (feature) =>
        feature.featureIdentifier === "approve" && feature.featureEnabled
    ),
    data,
  };
};

export function useFeatures(id) {
  return useQuery({
    queryKey: [QUERYKEY_FEATURES, id],
    queryFn: () => getFeatures(id),
    select: mapFeatures,
    enabled: Boolean(id),
  });
}

export function useDeleteFeature(companyID) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: deleteFeature,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERYKEY_FEATURES, companyID],
      }),

    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error deleting the feature.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}

export function useSaveFeature(id) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ params, selectedFeature, template }: any) =>
      saveFeature(params, selectedFeature, template),
    onSuccess: () => {
      return queryClient.invalidateQueries([QUERYKEY_FEATURES, id]);
    },
  });
}

export function useToggleFeatureProperty(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: toggleFeatureProperty,
    onSuccess: () => {
      return queryClient.invalidateQueries([QUERYKEY_FEATURES, id]);
    },
    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error toggling the feature.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}

export function useToggleFeatureRoleProperty(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: toggleFeatureRoleProperty,
    // React Query Optimistic Update
    onMutate: async ({ roleIndex, enabled, featureIndex }) => {
      await queryClient.cancelQueries([QUERYKEY_FEATURES, id]);
      const previousFeatures = queryClient.getQueryData([
        QUERYKEY_FEATURES,
        id,
      ]);
      queryClient.setQueryData(
        [QUERYKEY_FEATURES, id],
        (old: FeatureI[]): FeatureI[] => {
          const newFeatures = [...old];
          const newFeature = { ...newFeatures[featureIndex] };
          newFeature.roles[roleIndex].enabled = !enabled;
          newFeature.roles[roleIndex].loading = true;
          newFeatures[featureIndex] = newFeature;
          return newFeatures;
        }
      );
      return { previousFeatures };
    },
    onError: (er: ErrorResponseI, newTodo, context) => {
      queryClient.setQueryData(
        [QUERYKEY_FEATURES, id],
        context.previousFeatures
      );
      addNotification({
        text: `There was an error toggling the feature role property.`,
        errors: er.customErrors,
        type: "error",
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [QUERYKEY_FEATURES, id],
      });
    },
  });
}
