import { useSafeSetState } from "hooks/useState";
import { withStyles } from "tss-react/mui";
import TablePagination from "@mui/material/TablePagination";
import Switch from "@mui/material/Switch";
import { ArrowDownward } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import featuresTableStyle from "components/FeaturesTable/featuresTableStyle";
import { smartSort } from "common/helpers";
import { FeatureI } from "types/Feature";
import { useToggleFeatureProperty } from "hooks/features";
import { ExtendedInputProps } from "types/MUIExtensions";

const initialState = {
  order: "desc",
  orderBy: "featureName",
  rowsPerPage: 10,
  page: 0,
  toolTipIndex: null,
};

interface FeaturesTableI {
  features: FeatureI[];
  selectFeature: (feature: FeatureI) => void;
  selectedCompanyID: string;
  setFeatureDelete: (feature: FeatureI) => void;
  search: string;
  classes?: Partial<
    Record<
      | "innerRow"
      | "tableRow"
      | "tableBody"
      | "tableCell"
      | "largeCell"
      | "largeCellWithLeftPadding"
      | "mediumCell"
      | "roleCell"
      | "lastCell"
      | "cellItem"
      | "sortIcon"
      | "iconButton"
      | "caption"
      | "paginationRoot"
      | "paginationCaption"
      | "paginationIcon"
      | "ellipsisOverflow",
      string
    >
  >;
}

const FeaturesTable = (props: FeaturesTableI) => {
  const {
    features,
    selectFeature,
    selectedCompanyID,
    setFeatureDelete,
    search,
  } = props;
  const classes = withStyles.getClasses(props);

  const toggleFeatureProperty = useToggleFeatureProperty(selectedCompanyID);

  const [{ order, orderBy, rowsPerPage, page, toolTipIndex }, safeSetState] =
    useSafeSetState(initialState);

  const handleChangePage = (e, page) => {
    safeSetState({ page });
  };

  const handleChangeRowsPerPage = (e) => {
    safeSetState({ rowsPerPage: e.target.value });
  };

  const handleRequestSort = (newOrderBy) => {
    let order = "desc";
    if (orderBy === newOrderBy && order === "desc") order = "asc";
    safeSetState({ orderBy: newOrderBy, order });
  };

  const sortData = (a, b) => {
    const evaluated = smartSort(a[orderBy], b[orderBy]);
    if (order === "desc") return evaluated;
    else return -evaluated;
  };

  const parseFeatures = () => {
    let renderedFeatures;
    if (!search.length) renderedFeatures = features;
    else
      renderedFeatures = features.filter(
        (f) =>
          (typeof f.featureName === "string" &&
            f.featureName.toLowerCase().includes(search)) ||
          (typeof f.featureNameOverride === "string" &&
            f.featureNameOverride.toLowerCase().includes(search))
      );

    if (orderBy === "featureEnabled")
      renderedFeatures.sort((a, b) => (a.featureName < b.featureName ? -1 : 1));
    renderedFeatures.sort(sortData);
    renderedFeatures = renderedFeatures.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    return renderedFeatures;
  };

  const renderTableBody = () => {
    return parseFeatures().map((feature, i) => (
      <div
        className={classes.innerRow}
        onClick={() => selectFeature(feature)}
        key={feature.companyFeatureID}
        data-aut={`Features|Row|${
          feature.featureNameOverride || feature.featureName
        }`}
      >
        <span className={classes.largeCell}>
          <span
            className={classes.ellipsisOverflow}
            aria-label="Feature Name"
            data-aut={`Feature Name: ${feature.featureName}`}
          >
            {feature.featureName}
          </span>
        </span>
        <span className={classes.largeCell}>
          <span
            className={classes.ellipsisOverflow}
            aria-label="Custom Name"
            data-aut={`Custom Name: ${feature.featureNameOverride || ""}`}
          >
            {feature.featureNameOverride || ""}
          </span>
        </span>
        <span className={classes.largeCell}>
          <Switch
            checked={feature.featureEnabled}
            onClick={(e) => {
              e.stopPropagation();
              toggleFeatureProperty.mutate({
                enabled: !feature.featureEnabled,
                companyFeatureID: feature.companyFeatureID,
              });
            }}
            value={feature.featureName}
            color="primary"
            inputProps={
              {
                "aria-label": `Active Toggle: ${
                  feature.featureNameOverride || feature.featureName
                }`,
                "data-aut": `Active Toggle: ${
                  feature.featureNameOverride || feature.featureName
                }`,
              } as ExtendedInputProps
            }
          />
        </span>
        <span className={classes.lastCell}>
          <Tooltip
            title="Delete Feature"
            enterDelay={0}
            open={toolTipIndex === i}
          >
            <IconButton
              className={classes.iconButton}
              data-aut={`Delete Feature: ${
                feature.featureNameOverride || feature.featureName
              }`}
              aria-label={`Delete Feature: ${
                feature.featureNameOverride || feature.featureName
              }`}
              onMouseEnter={() => safeSetState({ toolTipIndex: i })}
              onMouseLeave={() => safeSetState({ toolTipIndex: null })}
              onClick={(e) => {
                e.stopPropagation();
                setFeatureDelete(feature);
              }}
              size="large"
            >
              <Clear />
            </IconButton>
          </Tooltip>
        </span>
      </div>
    ));
  };

  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className={classes.tableRow}>
          <span
            className={classes.largeCell}
            onClick={() => handleRequestSort("featureName")}
          >
            Feature{" "}
            {orderBy === "featureName" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={order === "asc" ? { transform: "rotate(-180deg)" } : {}}
              />
            )}
          </span>
          <span
            className={classes.largeCell}
            onClick={() => handleRequestSort("featureNameOverride")}
          >
            Custom Name{" "}
            {orderBy === "featureNameOverride" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={order === "asc" ? { transform: "rotate(-180deg)" } : {}}
              />
            )}
          </span>
          <span
            className={classes.largeCellWithLeftPadding}
            onClick={() => handleRequestSort("featureEnabled")}
          >
            Active{" "}
            {orderBy === "featureEnabled" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={order === "asc" ? { transform: "rotate(-180deg)" } : {}}
              />
            )}
          </span>
          <span className={classes.lastCell}></span>
        </div>
        <div className={classes.tableBody}>{renderTableBody()}</div>
      </div>
      <TablePagination
        classes={{
          root: classes.paginationRoot,
          selectIcon: classes.paginationIcon,
          // caption: classes.paginationCaption,
        }}
        component="div"
        count={features.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
          color: "primary",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
          color: "primary",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default withStyles(FeaturesTable, featuresTableStyle);
