import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";
import { withStyles } from "tss-react/mui";
import style from "assets/jss/components/authenticationStyle";
import {
  setUserStateFailed,
  setUserStateSuccess,
} from "redux/actions/userState";

import { useAuth0 } from "@auth0/auth0-react";
import {
  AUTH_CONFIG,
  ACCESS_TOKEN,
  EXPIRES_AT,
  ID_TOKEN,
  SCOPES,
} from "services/authConstants";

const Callback = (props) => {
  const { history, setUserStateFailed, setUserStateSuccess } = props;
  const classes = withStyles.getClasses(props);
  const { error, user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const handleSuccessfulLogin = useCallback(
    (user) => {
      try {
        const roles = user["https://www.ctmsmart.com/roles"];
        const givenName = user["https://www.ctmsmart.com/name"].split(" ")[0];
        const userState = {
          roles,
          givenName,
        };
        setUserStateSuccess(userState);
      } catch (er) {
        setUserStateFailed(er);
      } finally {
        history.push("/");
      }
    },
    [history, setUserStateFailed, setUserStateSuccess]
  );
  const setTokenSession = useCallback(
    (authData) => {
      const { access_token, expires_in, id_token, scope } = authData;
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(
        expires_in * 1000 + new Date().getTime()
      );
      // If there is a value on the `scope` param from the authResult,
      // use it to set scopes in the session for the user. Otherwise
      // use the scopes as requested. If no scopes were requested,
      // set it to nothing
      const scopes = scope || AUTH_CONFIG.scope || "";

      localStorage.setItem(ACCESS_TOKEN, access_token);
      localStorage.setItem(ID_TOKEN, id_token);
      localStorage.setItem(EXPIRES_AT, expiresAt);
      localStorage.setItem(SCOPES, JSON.stringify(scopes));

      handleSuccessfulLogin(user);
    },
    [handleSuccessfulLogin, user]
  );

  useEffect(() => {
    const handleTokenData = async () => {
      const data = await getAccessTokenSilently({ detailedResponse: true });
      setTokenSession(data);
    };
    if (isAuthenticated) {
      handleTokenData();
    }
  }, [isAuthenticated, setTokenSession, getAccessTokenSilently]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        history.push("/logout");
      }, 5000);
    }
  }, [error, history]);

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item className={classes.gridItem}>
        {error ? (
          <>
            <p>There was an error with your user.</p>
            <p>Redirecting to login page.</p>
          </>
        ) : (
          <>
            <CircularProgress />
            <p>Please Wait...</p>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUserStateSuccess: (data) => dispatch(setUserStateSuccess(data)),
  setUserStateFailed: (er) => dispatch(setUserStateFailed(er)),
});

export default connect(null, mapDispatchToProps)(withStyles(Callback, style));
