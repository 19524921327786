import { withStyles } from "tss-react/mui";
import logo from "assets/img/ctm-logo-landscape.png";
import footerStyle from "components/Footer/footerStyle";

const curYear = new Date().getFullYear();

interface FooterI {
  classes?: Partial<
    Record<
      | "footer"
      | "logo"
      | "container"
      | "contact"
      | "footerText"
      | "footerLink"
      | "footerCopy",
      string
    >
  >;
}

const Footer = (props: FooterI) => {
  const classes = withStyles.getClasses(props);

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.contact}>
          <img src={logo} className={classes.logo} alt="CTM Logo" />
          <span className={classes.footerText}>CONTACT US</span>
          <a href="tel:3033336161" className={classes.footerLink}>
            (303) 333-6161
          </a>
          <a href="https://us.travelctm.com/" className={classes.footerLink}>
            us.travelctm.com
          </a>
        </div>
        <p className={classes.footerCopy}>
          Copyright {curYear}. Corporate Travel Management is a leading global
          travel management company, with presence currently throughout
          Australia & New Zealand, the USA, Asia and Europe.
        </p>
      </div>
    </footer>
  );
};

export default withStyles(Footer, footerStyle);
