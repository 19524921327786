import { get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { EMAIL_TEMPLATES } from "common/constants";
import { handleErrors } from "common/helpers";

const url = getConfig(EMAIL_TEMPLATES).url;

export function fetchEmailTemplates() {
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${EMAIL_TEMPLATES} url`));
  }
}

export function saveEmail({
  defaultFrom,
  defaultSubject,
  defaultContent,
  template,
}) {
  const params = {
    defaultFrom,
    defaultSubject,
    defaultContent: defaultContent.replace(/[\n\r]/g, ""),
    code: template.code,
    name: template.name,
    active: template.active,
    id: template.id,
  };
  if (url) {
    return put(url, params).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${EMAIL_TEMPLATES} url`));
  }
}

export function sendTestEmail({ defaultFrom, defaultSubject, defaultContent }) {
  const params = {
    defaultFrom,
    defaultSubject,
    defaultContent,
  };

  if (url) {
    const finalURL = `${url}/Test`;
    return post(finalURL, params).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${EMAIL_TEMPLATES} url`));
  }
}
