import { get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { PCCS } from "common/constants";
import { handleErrors } from "common/helpers";

export const fetchPCCs = () => {
  const url = getConfig(PCCS).url;
  if (url) {
    const finalUrl = `${url}/GetAll`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${PCCS} url`));
  }
};

export const createPCC = (code) => {
  const url = getConfig(PCCS).url;
  if (url) {
    const finalUrl = `${url}/Create`;
    return post(finalUrl, { code, active: true })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${PCCS} url`));
  }
};

export const updatePCC = (updatedPCC) => {
  const url = getConfig(PCCS).url;
  if (url) {
    const finalUrl = `${url}/Update`;
    return put(finalUrl, updatedPCC)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${PCCS} url`));
  }
};
