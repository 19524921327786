import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { searchUsers, createUser, editUser, deleteUser } from "services/users";
import { UserSearchStateI } from "types/User";
import { QUERYKEY_USERS } from "./queryKeys";

export function useSearchUsers(shouldFetchUsers) {
  const reduxState = useSelector(
    ({ userSearch }: { userSearch: UserSearchStateI }) => ({ userSearch })
  );
  return useQuery({
    queryKey: [QUERYKEY_USERS, reduxState.userSearch],
    queryFn: () => {
      return searchUsers(reduxState.userSearch);
    },
    enabled: shouldFetchUsers,
  });
}

export function useCreateUser() {
  return useMutation({
    mutationFn: createUser,
  });
}

export function useEditUser() {
  return useMutation({
    mutationFn: editUser,
  });
}

export function useDeleteUser() {
  return useMutation({
    mutationFn: deleteUser,
  });
}
