import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addDebtorToCompany,
  createDebtor,
  fetchCompanyDebtors,
  fetchDebtors,
  fetchUnusedDebtors,
  makeDebtorDefault,
  removeDebtorFromCompany,
  saveDebtor,
  fetchDebtorsWithApprove,
} from "services/debtors";
import { useAddNotification } from "./useAddNotification";
import { ErrorResponseI } from "types/ErrorResponse";
import {
  QUERYKEY_COMPANY_DEBTORS,
  QUERYKEY_DATE_FORMATS,
  QUERYKEY_DEBTORS,
  QUERYKEY_DEBTORS_COMPACT,
  QUERYKEY_SELECTED_COMPANY,
  QUERYKEY_UNUSED_DEBTORS,
} from "./queryKeys";
import { fetchDateFormats } from "services/dateFormats";
import { CompanyI } from "types/Company";

const mapApproveDebtors = (
  debtors = [],
  companies: CompanyI[],
  selectedDebtorID: string,
  selectedCompanyID: string
) => {
  const companiesObject = {};
  companies.forEach((comp) => {
    companiesObject[comp.id] = comp;
  });
  return debtors.reduce((acc, curr) => {
    if (!curr?.companies?.length) {
      return acc;
    } else if (curr.companies.length > 1) {
      curr.companies.forEach((company) => {
        const comp = companiesObject[company.id];
        if (!(curr.id === selectedDebtorID && comp.id === selectedCompanyID)) {
          acc.push({
            ...curr,
            label: `${curr.label} - ${company.name}`,
            companyID: company.id,
          });
        }
      });
    } else {
      if (curr.id !== selectedDebtorID) {
        acc.push({
          ...curr,
          label: curr.name || "",
          companyID: curr.companies[0].id,
        });
      }
    }
    return acc;
  }, []);
};

const mapDebtors = (debtors = []) => {
  return debtors.map((data) => ({ ...data, label: data.name || "" }));
};

export function useDebtorsWithApprove({
  companies,
  selectedDebtorID,
  selectedCompanyID,
}: {
  companies: CompanyI[];
  selectedDebtorID: string;
  selectedCompanyID: string;
}) {
  return useQuery({
    queryKey: [QUERYKEY_DEBTORS_COMPACT],
    queryFn: fetchDebtorsWithApprove,
    select: (deb) =>
      mapApproveDebtors(deb, companies, selectedDebtorID, selectedCompanyID),
  });
}

export function useDebtors() {
  return useQuery({
    queryKey: [QUERYKEY_DEBTORS],
    queryFn: fetchDebtors,
    select: mapDebtors,
  });
}

export function useCreateDebtor(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: createDebtor,
    onSuccess: () => {
      addNotification({
        text: `Debtor saved.`,
        type: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERYKEY_DEBTORS] }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_COMPANY_DEBTORS, id],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_SELECTED_COMPANY, id],
        }),
      ]);
    },
    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error saving the debtor.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}

export function useEditDebtor(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: saveDebtor,
    onSuccess: () => {
      addNotification({
        text: `Debtor saved.`,
        type: "success",
      });

      return Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERYKEY_DEBTORS] }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_COMPANY_DEBTORS, id],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_SELECTED_COMPANY, id],
        }),
      ]);
    },
    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error saving the debtor.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}

export function useCompanyDebtors(id) {
  return useQuery({
    queryKey: [QUERYKEY_COMPANY_DEBTORS, id],
    queryFn: () => fetchCompanyDebtors(id),
    enabled: Boolean(id),
  });
}

export function useUnusedDebtors() {
  return useQuery({
    queryKey: [QUERYKEY_UNUSED_DEBTORS],
    queryFn: fetchUnusedDebtors,
  });
}

export function useAddDebtorToCompany(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: addDebtorToCompany,
    onSuccess: () => {
      addNotification({
        text: `Debtor saved to company.`,
        type: "success",
      });

      return Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERYKEY_UNUSED_DEBTORS] }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_COMPANY_DEBTORS, id],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_SELECTED_COMPANY, id],
        }),
      ]);
    },
    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error adding the debtor to the company.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}

export function useRemoveDebtorFromCompany(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: removeDebtorFromCompany,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERYKEY_UNUSED_DEBTORS] }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_COMPANY_DEBTORS, id],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_SELECTED_COMPANY, id],
        }),
      ]);
    },
    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error removing the debtor.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}
export function useMakeDebtorDefault(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: makeDebtorDefault,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_COMPANY_DEBTORS, id],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_SELECTED_COMPANY, id],
        }),
      ]);
    },
    onError: (er: ErrorResponseI) => {
      addNotification({
        text: `There was an error setting this debtor as primary.`,
        errors: er.customErrors,
        type: "error",
      });
    },
  });
}

export function useDateFormats() {
  return useQuery({
    queryKey: [QUERYKEY_DATE_FORMATS],
    queryFn: () => fetchDateFormats(),
    initialData: [],
  });
}
