import { Fragment } from "react";
import { withStyles } from "tss-react/mui";
import loadingStyles from "components/Loading/loadingStyle";
/**
 * Loader used on components doing async requests. It can show a animated loading icon, dim the background
 * and switch over to an error state if the component fails to load its resource.
 */

interface LoadingPropsI {
  active: boolean;
  addContainer?: boolean;
  dimmer?: boolean;
  error?: ErrorObject | boolean | string | Error;
  loadingText?: string;
  paddingX?: number;
  paddingY?: number;
  classes?: Partial<
    Record<
      | "loadingContainer"
      | "loadingCenteredDiv"
      | "loadingImage"
      | "loadingErrorHeader"
      | "loadingErrorText",
      string
    >
  >;
}

type ErrorObject = {
  code: number;
};

const Loading = (props: LoadingPropsI) => {
  const {
    active,
    addContainer,
    dimmer,
    error,
    loadingText,
    paddingX,
    paddingY,
  } = props;
  const classes = withStyles.getClasses(props);
  // If the loader is not actively loading or in an error state, it should not render anything
  if (!active && !error) return null;

  const renderContent = () => {
    if (!error) {
      // Default loader
      return (
        <Fragment>
          <img
            className={classes.loadingImage}
            src="/images/ctm-logo-landscape.png"
            alt="Loading"
          />
          {loadingText && <p>{loadingText}</p>}
        </Fragment>
      );
    }
    // If an error has a code, let's use that code for our checking
    if (
      typeof error !== "string" &&
      typeof error !== "boolean" &&
      !(error instanceof Error) &&
      error.code
    ) {
      switch (error.code) {
        case 404:
        case 204:
        case 500:
        case 503:
        case 504:
        default:
          return (
            <Fragment>
              <img
                className={classes.loadingImage}
                src="/images/whoops-icon.svg"
                alt="Whoops"
              />
              <h4 className={classes.loadingErrorHeader}>WHOOPS</h4>
              <span className={classes.loadingErrorText}>
                We can't load anything right now.
              </span>
              <span className={classes.loadingErrorText}>
                Please try again in a few minutes.
              </span>
            </Fragment>
          );
      }
    }

    switch (error) {
      case "No Content":
        return (
          <Fragment>
            <img
              className={classes.loadingImage}
              src="/images/no-data-to-show-icon.svg"
              alt="No data to show."
            />
            <h4 className={classes.loadingErrorHeader}>NO DATA TO SHOW</h4>
            <span className={classes.loadingErrorText}>
              There is no data available right now.
            </span>
          </Fragment>
        );
      case "serverError":
        return (
          <Fragment>
            <img
              className={classes.loadingImage}
              src="/images/oh-snap-unhappy-cloud-face-icon.svg"
              alt="Oh Snap!"
            />
            <h4 className={classes.loadingErrorHeader}>
              OH SNAP, SOMETHING WENT WRONG
            </h4>
            <span className={classes.loadingErrorText}>
              Looks like we were not able to connect to the server.
            </span>
            <span className={classes.loadingErrorText}>
              Please try again in a few minutes.
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <img
              className={classes.loadingImage}
              src="/images/whoops-icon.svg"
              alt="Whoops"
            />
            <h4 className={classes.loadingErrorHeader}>WHOOPS</h4>
            <span className={classes.loadingErrorText}>
              We can't load anything right now.
            </span>
            <span className={classes.loadingErrorText}>
              Please try again in a few minutes.
            </span>
          </Fragment>
        );
    }
  };

  // Set dynamic/prop based styles if we are using the dimmer
  const style: any = {};
  if (dimmer) {
    style.backgroundColor = "rgba(0,0,0,0.6)";
    if (paddingX) {
      style.left = `${paddingX}px`;
      style.right = `${paddingX}px`;
    }
    if (paddingY) {
      style.top = `${paddingY}px`;
      style.bottom = `${paddingY}px`;
    }
    if (!error && active) style.zIndex = "999999";
  }

  return (
    <div
      style={
        addContainer
          ? {
              width: "100%",
              height: "100%",
              minHeight: "500px",
              position: "relative",
            }
          : {}
      }
    >
      <div className={classes.loadingContainer} style={style}>
        <div className={classes.loadingCenteredDiv}>{renderContent()}</div>
      </div>
    </div>
  );
};

const LoadingStyled = withStyles(Loading, loadingStyles);

export default LoadingStyled;
