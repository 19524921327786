import { useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { useSafeSetState } from "hooks/useState";
import {
  AutoComplete,
  Button,
  Dropdown,
  Input,
  Loading,
  Modal,
  MultiToggle,
} from "components";
import CheckboxSpinner from "components/CheckboxSpinner/CheckboxSpinner";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";

import { fetchDebtor } from "services/debtors";
import { label } from "assets/jss/globalStyle";
import { NotificationObjI } from "types";
import { Country } from "types/Locations";

import { DateTimeFormatI, DebtorI } from "types/Company";
import { usePCCs } from "hooks/pccs";
import {
  useCreateDebtor,
  useDateFormats,
  useEditDebtor,
  useMakeDebtorDefault,
  useRemoveDebtorFromCompany,
  useCompanyDebtors,
} from "hooks/debtors";
import { useCountries } from "hooks/countries";
import { useCurrencySymbols } from "hooks/currencySymbols";
import { CurrencySymbolI } from "types/CurrencySymbol";
import { Timezone, useTimezones } from "hooks/timezones";

const validateRegex = /^[A-Z0-9]+$/;

// eslint-disable-next-line
const styles = (theme, props) => ({
  inputContainer: {
    width: "80%",
    minHeight: "100px",
    "&:first-of-type": {
      marginRight: "16px",
    },
  },
  label,
  checkboxLabelContainer: {
    width: "100%",
  },
  checkboxLabel: {
    fontFamily: '"Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif',
    color: "black",
    fontSize: "14px",
    fontWeight: 600,
  },
  checkbox: {
    color: "red",
  },
  row: {
    width: "80%",
    display: "flex",
  },
});

interface CompanyDebtorModalStateI {
  country: Country;
  currencyCode?: CurrencySymbolI;
  error: null | Error;
  fetchedDebtor: null | DebtorI;
  isActive: boolean;
  isDefault: boolean;
  loading: boolean;
  name: string;
  pcc: string;
  removing: boolean;
  sabreError: boolean;
  sabreIdentifier: string;
  settingDefault: boolean;
  dateTimeFormat: DateTimeFormatI;
  timezone?: Timezone;
  tramadaAgencyID?: string;
  tramadaAuthID?: string;
  tramadaAgencyIDError: boolean;
  tramadaAuthIDError: boolean;
}

const initialState: CompanyDebtorModalStateI = {
  country: null,
  error: null,
  currencyCode: null,
  fetchedDebtor: null,
  isActive: true,
  isDefault: false,
  loading: true,
  name: "",
  pcc: "",
  removing: false,
  sabreError: false,
  sabreIdentifier: "",
  settingDefault: false,
  dateTimeFormat: null,
  timezone: null,
  tramadaAgencyID: null,
  tramadaAuthID: null,
  tramadaAgencyIDError: false,
  tramadaAuthIDError: false,
};

interface CompanyDebtorModalPropsI {
  addNotification: (not: NotificationObjI) => void;
  companyID: string;
  debtor: DebtorI;
  defaultDebtorID: string;
  handleClose: (forceRefetch?: boolean) => void;
  isCreating: boolean;
  isOpen: boolean;
  selectedCompanyID: string;
  classes?: Partial<
    Record<
      | "inputContainer"
      | "label"
      | "checkboxLabelContainer"
      | "checkboxLabel"
      | "checkbox"
      | "row",
      string
    >
  >;
}

function CompanyDebtorModal(props: CompanyDebtorModalPropsI) {
  const {
    companyID,
    debtor,
    defaultDebtorID,
    handleClose,
    isCreating,
    isOpen,
    selectedCompanyID,
  } = props;
  const classes = withStyles.getClasses(props);

  const [
    {
      country,
      currencyCode,
      error,
      fetchedDebtor,
      isActive,
      isDefault,
      loading,
      name,
      pcc,
      removing,
      sabreError,
      sabreIdentifier,
      settingDefault,
      dateTimeFormat,
      timezone,
      tramadaAgencyID,
      tramadaAuthID,
      tramadaAgencyIDError,
      tramadaAuthIDError,
    },
    safeSetState,
  ] = useSafeSetState(initialState);

  const currencySymbols = useCurrencySymbols();
  const pccs = usePCCs();
  const debtors = useCompanyDebtors(selectedCompanyID);
  const createDebtor = useCreateDebtor(selectedCompanyID);
  const editDebtor = useEditDebtor(selectedCompanyID);
  const removeDebtorFromCompany = useRemoveDebtorFromCompany(selectedCompanyID);
  const makeDebtorDefault = useMakeDebtorDefault(selectedCompanyID);
  const countries = useCountries();
  const dateFormats = useDateFormats();
  const timezones = useTimezones();

  useEffect(() => {
    function getCountry(debtorCode) {
      return countries.data && debtorCode
        ? countries.data.find((c) => c.countryCode === debtorCode)
        : null;
    }

    function getCurrency(debtorCode) {
      return currencySymbols.data && debtorCode
        ? currencySymbols.data.find((c) => c.value === debtorCode)
        : null;
    }

    function getDateFormat(dateFormat) {
      return dateFormats.data && dateFormat
        ? dateFormats.data.find((d) => d.code === dateFormat)
        : null;
    }

    function getTimeZone(timezone) {
      return timezones.data && timezone
        ? timezones.data.find((t) => t.code === timezone)
        : null;
    }
    if (debtor) {
      fetchDebtor(debtor.id)
        .then((debtor) => {
          const newState = {
            fetchedDebtor: debtor,
            currencyCode: getCurrency(debtor.defaultCurrencyCode),
            country: getCountry(debtor.defaultCountryCode),
            dateTimeFormat: getDateFormat(debtor.dateTimeFormat),
            loading: false,
            isActive: debtor.active,
            name: debtor.name,
            pcc: debtor.pcc,
            sabreIdentifier: debtor.identifier,
            isDefault: debtor.id === defaultDebtorID,
            sabreError: !validateInput(debtor.identifier),
            timezone: getTimeZone(debtor.timeZone),
            tramadaAuthID: debtor?.externalPublication?.tramada?.authID,
            tramadaAgencyID: debtor?.externalPublication?.tramada?.agencyID,
          };
          safeSetState(newState);
        })
        .catch((error) => {
          safeSetState({ loading: false, error });
        });
    } else {
      safeSetState(initialState);
    }
  }, [
    countries.data,
    debtor,
    defaultDebtorID,
    safeSetState,
    currencySymbols.data,
    dateFormats.data,
    timezones.data,
  ]);

  const isManagedInFaces = fetchedDebtor ? fetchedDebtor.managedInFaces : false;

  function close() {
    handleClose();
    safeSetState(initialState);
  }

  function handleRemove() {
    removeDebtorFromCompany
      .mutateAsync({
        debtorId: fetchedDebtor.id,
        companyId: companyID,
      })
      .then(close);
  }

  function handleSabreBlur(e) {
    // Skip mouse out check if the input is not the current one in focus
    if (
      e &&
      e.type === "mouseout" &&
      document.activeElement.id !== "Sabre Identifier *"
    )
      return;
    const isValid = validateInput(sabreIdentifier);
    if (!isValid && !sabreError) safeSetState({ sabreError: true });
    else if (isValid && sabreError) safeSetState({ sabreError: false });
  }

  function handleTramadaAgencyIDBlur(e) {
    if (
      e &&
      e.type === "mouseout" &&
      document.activeElement.id !== "Tramada Agency ID"
    )
      return;

    if (tramadaAgencyID?.length) {
      if (tramadaAgencyIDError) safeSetState({ tramadaAgencyIDError: false });
      if (!tramadaAuthID?.length) safeSetState({ tramadaAuthIDError: true });
    } else {
      if (tramadaAuthID?.length)
        safeSetState({
          tramadaAgencyIDError: true,
          tramadaAuthIDError: false,
        });
      else if (tramadaAgencyIDError || tramadaAuthIDError) {
        safeSetState({
          tramadaAgencyIDError: false,
          tramadaAuthIDError: false,
        });
      }
    }
  }

  function handleTramadaAuthIDBlur(e) {
    if (
      e &&
      e.type === "mouseout" &&
      document.activeElement.id !== "Tramada Auth ID"
    )
      return;

    if (tramadaAuthID?.length) {
      if (tramadaAuthIDError) safeSetState({ tramadaAuthIDError: false });
      if (!tramadaAgencyID?.length)
        safeSetState({ tramadaAgencyIDError: true });
    } else {
      if (tramadaAgencyID?.length)
        safeSetState({ tramadaAuthIDError: true, tramadaAgencyIDError: false });
      else if (tramadaAgencyIDError || tramadaAuthIDError) {
        safeSetState({
          tramadaAgencyIDError: false,
          tramadaAuthIDError: false,
        });
      }
    }
  }

  function validateInput(str, minLength = 5, maxLength = 10) {
    return (
      typeof str === "string" &&
      str.length >= minLength &&
      str.length <= maxLength &&
      validateRegex.test(str)
    );
  }

  function handleSave() {
    const newDebtorData = {
      defaultCountryCode: country ? country.countryCode : null,
      defaultCurrencyCode: currencyCode ? currencyCode.code : null,
      identifier: sabreIdentifier,
      name,
      pcc,
      active: isActive,
      dateTimeFormat: dateTimeFormat ? dateTimeFormat.code : null,
      timeZone: timezone ? timezone.code : null,
      externalPublication:
        tramadaAgencyID?.length && tramadaAuthID?.length
          ? {
              tramada: {
                authID: tramadaAuthID,
                agencyID: tramadaAgencyID,
              },
            }
          : null,
    };

    if (isCreating) {
      createDebtor
        .mutateAsync({ debtor: newDebtorData, companyID })
        .then(close);
    } else {
      const newDebtor = {
        ...fetchedDebtor,
        ...newDebtorData,
      };
      editDebtor.mutateAsync(newDebtor).then(close);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleSave();
  }

  function setActive() {
    safeSetState({ isActive: !isActive });
  }

  function setCountry(country) {
    safeSetState({ country });
  }

  function setCurrency(currencyCode) {
    safeSetState({ currencyCode });
  }

  function setDefaultDebtor() {
    makeDebtorDefault.mutate({
      debtorId: fetchedDebtor.id,
      companyId: companyID,
    });
  }

  function setIdentifier(val) {
    if (sabreError && validateInput(val)) {
      safeSetState({ sabreIdentifier: val, sabreError: null });
    } else {
      safeSetState({ sabreIdentifier: val });
    }
  }

  function setName(name) {
    safeSetState({ name });
  }

  function setTimezone(timezone) {
    safeSetState({ timezone });
  }

  function setPCC(pcc) {
    safeSetState({ pcc: pcc && pcc.code ? pcc.code : null });
  }

  function setRemoveTooltip() {
    if (!debtors.data || !debtor) return;
    if (debtors.data.length === 1)
      return "Cannot remove company's only debtor.";
    if (isDefault) return "Cannot remove company's primary debtor.";
    else return undefined;
  }

  function setTramadaAgencyID(tramadaAgencyID) {
    safeSetState({ tramadaAgencyID });
  }

  function setTramadaAuthID(tramadaAuthID) {
    safeSetState({ tramadaAuthID });
  }

  function handleDateTimeChange(dateTimeFormat) {
    safeSetState({ dateTimeFormat });
  }

  function renderBody() {
    const tooltip = setRemoveTooltip();
    const activeProperties = [
      { name: "Active", value: isActive },
      { name: "Inactive", value: !isActive },
    ];

    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <Input
            label="Name *"
            value={name}
            onChange={setName}
            disabled={isManagedInFaces}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputContainer}>
            <AutoComplete
              label="Home Country"
              errorMessage={
                countries.error ? "Error Fetching Countries" : undefined
              }
              isLoading={countries.isLoading}
              handleDelete={() => setCountry(null)}
              placeholder={countries.isLoading ? "Loading Countries" : ""}
              onChange={setCountry}
              selectedItem={country || null}
              suggestions={countries.data}
              disabled={isManagedInFaces}
            />
          </div>
          <div className={classes.inputContainer}>
            <AutoComplete
              label="Preferred Currency"
              errorMessage={
                currencySymbols.error ? "Error Fetching Currencies" : undefined
              }
              isLoading={currencySymbols.isLoading}
              handleDelete={() => setCurrency(null)}
              placeholder={
                currencySymbols.isLoading ? "Loading Currencies" : ""
              }
              onChange={setCurrency}
              selectedItem={currencyCode || null}
              suggestions={currencySymbols.data}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputContainer}>
            <AutoComplete
              label="PCC"
              errorMessage={
                pccs.isError || pccs.isLoadingError
                  ? "Error Fetching PCCs"
                  : undefined
              }
              isLoading={pccs.isLoading}
              handleDelete={() => setPCC(null)}
              placeholder={pccs.isLoading ? "Loading PCCs" : ""}
              onChange={setPCC}
              selectedItem={pcc ? { label: pcc } : null}
              suggestions={pccs.data?.activePCCs}
              autoRenderSuggestions
            />
          </div>
          <div className={classes.inputContainer}>
            <Input
              label="Sabre Identifier *"
              error={
                sabreError &&
                "Sabre Identifier must be 5-10 chars, uppercase letters and numbers only."
              }
              value={sabreIdentifier}
              onChange={setIdentifier}
              onBlur={handleSabreBlur}
              onMouseOut={handleSabreBlur}
              disabled={isManagedInFaces}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <Dropdown
            onChange={handleDateTimeChange}
            displayEmpty
            disabled={dateFormats?.isError || dateFormats?.isLoading}
            loading={dateFormats?.isLoading}
            placeholder={
              dateFormats?.isError
                ? "Error loading date formats"
                : "Select A Date/Time Format"
            }
            selectedItems={dateTimeFormat}
            items={dateFormats?.data || []}
            label="Date/time format"
          />
        </div>
        <div className={classes.inputContainer}>
          <AutoComplete
            label="Timezone *"
            errorMessage={
              timezones.isError ? "Error Fetching Timezones" : undefined
            }
            isLoading={timezones.isLoading}
            handleDelete={() => setTimezone(null)}
            placeholder={timezones.isLoading ? "Loading Timezones" : ""}
            onChange={setTimezone}
            selectedItem={timezone || null}
            suggestions={timezones.data}
            disabled={Boolean(timezones.isError || timezones.isLoading)}
          />
        </div>

        <div className={classes.row}>
          <div className={classes.inputContainer}>
            <Input
              label="Tramada Auth ID"
              error={
                tramadaAuthIDError &&
                "Auth ID is required if Agency ID is configured."
              }
              value={tramadaAuthID || ""}
              onChange={setTramadaAuthID}
              onBlur={handleTramadaAuthIDBlur}
              onMouseOut={handleTramadaAuthIDBlur}
            />
          </div>
          <div className={classes.inputContainer}>
            <Input
              label="Tramada Agency ID"
              error={
                tramadaAgencyIDError &&
                "Agency ID is required if AuthID is configured."
              }
              value={tramadaAgencyID || ""}
              onChange={setTramadaAgencyID}
              onBlur={handleTramadaAgencyIDBlur}
              onMouseOut={handleTramadaAgencyIDBlur}
            />
          </div>
        </div>

        <div className={classes.inputContainer}>
          <span className={classes.label}>Debtor Status</span>
          <MultiToggle
            value={isActive ? 0 : 1}
            properties={activeProperties}
            handleClick={setActive}
            label="Active"
            disabled={isManagedInFaces || (isDefault && isActive)}
            tooltip={
              isDefault && isActive
                ? "Primary debtors cannot be deactivated."
                : null
            }
          />
        </div>
        <div className={classes.checkboxLabelContainer}>
          {renderDefaultDebtor()}
        </div>
        <Button
          onClick={handleSave}
          label="Save"
          disabled={
            !validateInput(sabreIdentifier) ||
            !name ||
            !name.length ||
            !timezone ||
            tramadaAgencyIDError ||
            tramadaAuthIDError
          }
          loading={editDebtor.isLoading || createDebtor.isLoading}
          data-aut="DebtorSave"
        />

        <Button
          label="Remove"
          secondary
          loading={removing}
          onClick={handleRemove}
          disabled={Boolean(tooltip)}
          data-aut="DebtorRemove"
          tooltip={tooltip}
        />
      </form>
    );
  }

  function renderDefaultDebtor() {
    if (!debtor) return;
    let tooltip = null;
    if (isDefault && !settingDefault) {
      tooltip = "Save a different debtor as primary to disable this one.";
    } else if (!isActive) {
      if (fetchedDebtor && fetchedDebtor.active)
        tooltip = "Save debtor to activate it before toggling it as primary.";
      else tooltip = "Only active debtors can be set as primary.";
    } else if (sabreError) {
      tooltip = "Provide a valid saber identifier.";
    } else if (!validateInput(sabreIdentifier)) {
      tooltip = "Save to give debtor valid identifier.";
    }

    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement={"top"} enterDelay={150}>
          {renderDefaultDebtorCheckbox(tooltip)}
        </Tooltip>
      );
    } else {
      return renderDefaultDebtorCheckbox();
    }
  }

  function renderDefaultDebtorCheckbox(tooltip?: string) {
    return (
      <FormControlLabel
        classes={{ label: classes.checkboxLabel }}
        control={
          <CheckboxSpinner
            enabled={isDefault}
            disabled={isDefault || settingDefault || Boolean(tooltip)}
            loading={settingDefault}
            onChange={setDefaultDebtor}
            checkboxClass={"checkboxBlue"}
            value={"DefaultDebtor"}
            fixedWidth="40px"
          />
        }
        label="Primary Debtor For Company"
      />
    );
  }

  return (
    <Modal
      close={close}
      isOpen={isOpen}
      header={isCreating ? "Create New Debtor" : " "}
    >
      {!isCreating && (loading || error) ? (
        <Loading
          active
          loadingText="Loading Debtor"
          error={error}
          addContainer
        />
      ) : (
        renderBody()
      )}
    </Modal>
  );
}

export default withStyles(CompanyDebtorModal, styles);
