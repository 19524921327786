import { withStyles } from "tss-react/mui";
import { Button, HtmlEditor, Input } from "components";
import { useSetState } from "hooks/useState";
import { saveEmail, sendTestEmail } from "services/emailTemplates";
import { NotificationObjI } from "types";
import { EmailTemplateI } from "types/EmailTemplate";

// eslint-disable-next-line
const styles = (theme, props) => ({
  form: {
    display: "flex",
    width: "100%",
  },
  emailSettingsHeader: {
    marginTop: 0,
    marginBottom: "10px",
  },
  inputColumn: {
    minWidth: "400px",
    flexGrow: 0,
  },
  templateColumn: {
    flexGrow: 1,
  },
  inputRow: {
    paddingRight: "40px",
  },
});

interface EmailSettingsI {
  addNotification: (not: NotificationObjI) => void;
  template: EmailTemplateI;
  classes?: Partial<
    Record<
      | "form"
      | "emailSettingsHeader"
      | "inputColumn"
      | "templateColumn"
      | "inputRow",
      string
    >
  >;
}

function EmailSettings(props: EmailSettingsI) {
  const { addNotification, template } = props;
  const classes = withStyles.getClasses(props);
  const initialState = {
    name: template.name,
    defaultFrom: template.defaultFrom,
    defaultSubject: template.defaultSubject,
    defaultContent: template.defaultContent,
    saving: false,
  };

  const [{ defaultFrom, defaultSubject, defaultContent, saving }, setState] =
    useSetState(initialState);

  function handleSendTestEmail(params) {
    sendTestEmail(params)
      .then(() => {
        addNotification({
          text: `Email template sent.`,
          type: "success",
        });
      })
      .catch((er) => {
        addNotification({
          text: `There was an error sending the template.`,
          errors: er.customErrors,
          type: "error",
        });
      });
  }

  function handleSaveEmail(params) {
    setState({ saving: true });
    saveEmail(params)
      .then(() => {
        addNotification({
          text: `Email template saved.`,
          type: "success",
        });
      })
      .catch((er) => {
        addNotification({
          text: `There was an error saving the email template.`,
          errors: er.customErrors,
          type: "error",
        });
      })
      .finally(() => {
        setState({ saving: false });
      });
  }

  function setContent(evt) {
    const newValue = evt.editor.getData();
    if (typeof newValue === "string") {
      setState({ defaultContent: newValue });
    }
  }

  const params = { template, defaultFrom, defaultSubject, defaultContent };

  return (
    <form className={classes.form}>
      <div className={classes.inputColumn}>
        <h5 className={classes.emailSettingsHeader}>Email Settings</h5>
        <div className={classes.inputRow}>
          <Input
            label="From Email Address"
            value={defaultFrom}
            onChange={(val) => setState({ defaultFrom: val })}
          />
        </div>
        <div className={classes.inputRow}>
          <Input
            label="Email Subject"
            value={defaultSubject}
            onChange={(val) => setState({ defaultSubject: val })}
          />
        </div>
        <div className={classes.inputRow}>
          <Button
            onClick={() => handleSaveEmail(params)}
            label="Save"
            disabled={saving}
            loading={saving}
          />
          <Button
            onClick={() => handleSendTestEmail(params)}
            label="Send Test Email"
            isTextButton
          />
        </div>
      </div>
      <div className={classes.templateColumn}>
        <HtmlEditor content={defaultContent} handleChange={setContent} />
      </div>
    </form>
  );
}

export default withStyles(EmailSettings, styles);
