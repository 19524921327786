import moment from "moment";
import { get } from "services/apiClient";

// Used in order to trigger a logout
import { redirectToLogout } from "redux/actions/application";
import { store } from "App";
import { BASE_URL } from "services/apiConfig";

/**
 * Wrapper for handling fetch errors since fetch won't handle it natively
 */
export const handleErrors = async (response) => {
  // If the status is a 401, our token in no longer valid and the user needs to log back in.
  if (response.status === 401 && store) store.dispatch(redirectToLogout());
  if (!response.ok || response.status < 200 || response.status >= 300) {
    const error = new Error(response.statusText) as any;
    error.code = response.status;
    const parsedResponse = await response.json();
    error.customErrors = parsedResponse.errors;
    throw error;
  }
  return response;
};

// Detects whether or not a browser is IE (which we don't support)
export function getIEVersion() {
  const sAgent = window.navigator.userAgent;
  const Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)), 10);
  // If IE 11 then look for Updated user agent string.
  else if (navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
}

export function copyToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

export function isValidSabreRemark(str) {
  return /^[a-z0-9<>/\-*\s.,☨‡¥¤§ç]+$/i.test(str);
}

export function isCustomRemark(logic) {
  return logic.source === 77;
}

export function isNumericString(str) {
  if (typeof str !== "string") return false;
  return (
    // ensure strings of whitespace fail
    !isNaN(parseFloat(str)) &&
    // ensure numbers with leading or trailing space fail
    str.length === str.trim().length
  );
}

export function isUserNotSelectingText() {
  return window.getSelection().toString().length === 0;
}

export function toQueryString(data) {
  function parseDataType(data) {
    // Re-enable if we need to start handling moment in params
    // if (moment.isMoment(data)) return data;
    if (typeof data === "object" && data !== null) {
      return JSON.stringify(data);
    }
    return data;
  }
  return Object.keys(data)
    .map((k) => {
      return (
        encodeURIComponent(k) + "=" + encodeURIComponent(parseDataType(data[k]))
      );
    })
    .join("&");
}

// Sorts data based on data type
export function smartSort(a, b) {
  // Date Sorting
  if (moment.isMoment(a)) {
    if (moment(a).isSame(moment(b))) return 0;
    if (moment(a).isBefore(moment(b))) return -1;
    return 1;
    // String Sorting
  } else if (typeof a === "string" || typeof b === "string") {
    const newA = typeof a === "string" ? a : "";
    const newB = typeof b === "string" ? b : "";
    if (newA.toLowerCase() === newB.toLowerCase()) return 0;
    else if (newA.toLowerCase() < newB.toLowerCase()) return -1;
    else return 1;
  }
  // Default Sorting
  return a - b;
}

// Maps Tower Responses into more traditional JSON responses
export function mapResponse(response) {
  // Restructure each feature for quicker lookup moving forward
  response.forEach((item) => {
    item.options = item.values.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
    delete item.values;
  });
  return response;
}

export function formatDate(date) {
  if (!date) return "n/a";
  try {
    return moment(date).format("MM/DD/YYYY");
  } catch (er) {
    return "n/a";
  }
}

// Formats a traveler object with first name and last name keys into a name
// string that is lowercase with the first character of each name uppercased
export function formatTravelerName(traveler) {
  if (!traveler) return "";
  let firstName = traveler.firstName ? traveler.firstName.toLowerCase() : "";
  if (firstName.length) firstName = capitalizeFirstLetter(firstName);
  let lastName = traveler.lastName ? traveler.lastName.toLowerCase() : "";
  if (lastName.length) lastName = capitalizeFirstLetter(lastName);
  return `${firstName} ${lastName}`;
}

export function capitalizeFirstLetter(str) {
  if (str && typeof str === "string" && str.length)
    return str.charAt(0).toUpperCase() + str.slice(1);
  else return "";
}

export function validateEmail(email) {
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export function validateURL(url) {
  // eslint-disable-next-line
  return /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
    url
  );
}

export async function convertUrlToFileUrl(url) {
  const res = await fetch(url);
  const resBlob = await res.blob();
  const newFile = new File([resBlob], "test.jpg", { type: resBlob.type });
  const fileUrl = URL.createObjectURL(newFile);
  return fileUrl;
}

// Code used to fake a click to download csv
export function saveFile(data, fileName) {
  const a = document.createElement("a") as any;
  document.body.appendChild(a);
  a.style = "display: none";
  const blob = new Blob([data], { type: "octet/stream" }),
    url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

const dataToCsvURI = (data) => {
  return (
    "data:text/csv;charset=utf-8," +
    encodeURIComponent(data.map((row) => row.join(",")).join(`\n`))
  );
};

export const mapDataForCSV = <T>(
  data: T[],
  configRowMap: {
    label: string;
    render: (T) => string;
  }[]
) => {
  const legend = configRowMap.map((row) => row.label);
  const mappedData = [];
  data.forEach((config) => {
    mappedData.push(
      configRowMap.map((row) => wrapCSVRowWithQuotes(row.render(config)))
    );
  });
  return [legend, ...mappedData];
};

export const convertDataToCSVDownload = (
  rows = [],
  filename = "example.csv"
) => {
  const csvEl = document.createElement("a");
  const url = dataToCsvURI(rows);
  csvEl.href = url;
  csvEl.setAttribute("download", filename);
  csvEl.click();
  csvEl.remove();
};

export const wrapCSVRowWithQuotes = (row) => {
  if (!row?.length) return "";
  if (row[0] === '"' || row[row.length - 1] === '"') return row;

  return `"` + row + `"`;
};

export function preventDefault(e) {
  e.preventDefault();
}

// Function that memoizes GET requests
export function memoFetch() {
  // Create our caches to store our values in a closure
  // We have one cache for urls with no params, and one for those with params for simplicity
  const cache = {};
  const cacheWithParams = {};
  // Return a memoized function that uses the closure cache to remember previous calls
  return (url, params) => {
    // Stringify params so that if we get an array/object we do a simple flat check
    // This won't work if the order of the array is changed but the values are the same, but it works well enough for our purposes here
    // since our code generally pushes out params in the same order.
    const stringifiedParams = JSON.stringify(params);
    // Check to see if we've called this url already
    if (cache[url] || cacheWithParams[url]) {
      // Check to see if we have params, if not, we can just return the previous successful result of the call to the url
      if (!params) return new Promise((resolve) => resolve(cache[url]));
      else {
        // If we have a params, check to see if we've cached the result under the key of the stringified params
        if (cacheWithParams[url][stringifiedParams]) {
          return new Promise((resolve) =>
            resolve(cache[url][stringifiedParams])
          );
        }
      }
    } else {
      // We have no cached value to now it's time to fetch
      if (params) {
        get(url, params)
          .then(handleErrors)
          .then((res) => res.json())
          .then((res) => {
            // Check to see if we've cached a response on this url with different params
            if (cacheWithParams[url]) {
              // Store our result on a new key of the stringified params
              cacheWithParams[url][stringifiedParams] = res;
              return new Promise((resolve) => resolve(res));
            } else {
              // Otherwise we need to create an object for the url
              cacheWithParams[url] = {};
              // and then create a key with the stringified params and cache the result on it prior to returning it as a promise
              cacheWithParams[url][stringifiedParams] = res;
              return new Promise((resolve) => resolve(res));
            }
          });
      } else
        return get(url)
          .then(handleErrors)
          .then((res) => res.json())
          .then((res) => {
            // Cache the result and return it as a promise
            cache[url] = res;
            return new Promise((resolve) => resolve(res));
          });
    }
  };
}

export const shouldCancelSort = (e) => {
  if (e.srcElement) {
    // If the user clicks on the remove item svg icon, or the path that is rendered by the svg, we want to cancel the sort
    if (
      e.srcElement.ariaLabel &&
      e.srcElement.ariaLabel.indexOf("Remove Item") === 0
    )
      return true;
    if (
      e.srcElement.parentElement &&
      e.srcElement.parentElement.ariaLabel &&
      e.srcElement.parentElement.ariaLabel.indexOf("Remove Item") === 0
    )
      return true;

    // Firefox checks
    if (e.srcElement.attributes && e.srcElement.attributes["aria-label"]) {
      const label = e.srcElement.getAttribute("aria-label");
      if (label && label.indexOf("Remove Item") === 0) return true;
    }

    if (
      e.srcElement.parentElement &&
      e.srcElement.parentElement.attributes &&
      e.srcElement.parentElement.attributes["aria-label"]
    ) {
      const label = e.srcElement.parentElement.getAttribute("aria-label");
      if (label && label.indexOf("Remove Item") === 0) return true;
    }
  }
  return false;
};

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function buildFormData(formData, data, parentKey?: string) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    // don't append null & undefined values, but do append false values
    if (data != null && parentKey) {
      formData.append(parentKey, data);
    }
  }
}

export function isProdEnv() {
  return BASE_URL === "https://elysium-towers-api.ctmna-apps.net/api/";
}
