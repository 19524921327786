import { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "tss-react/mui";
import { ArrowDropDown } from "@mui/icons-material";
import logo from "assets/img/ctm-logo-landscape.png";
import {
  AppBar,
  Toolbar,
  MenuItem,
  MenuList,
  IconButton,
  Popover,
  Paper,
} from "@mui/material";
import { setCompany } from "redux/actions/selectedCompanyID";
import headerStyle from "components/Header/headerStyle";
import { CompanyI } from "types/Company";
import { UserStateStateI } from "types/User";

interface HeaderI {
  companySelected?: string;
  setCompany: (company: CompanyI) => void;
  userState: UserStateStateI;
  classes?: Partial<
    Record<
      | "appBar"
      | "container"
      | "img"
      | "imgContainer"
      | "greeting"
      | "dropdown"
      | "dropdownItem"
      | "profilePic",
      string
    >
  >;
}

const Header = (props: HeaderI) => {
  const { companySelected, setCompany, userState } = props;
  const classes = withStyles.getClasses(props);
  const [profileMenu, setProfileMenu] = useState(null);

  const handleProfileClick = (event) => {
    setProfileMenu(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileMenu(null);
  };

  const handleHomeNavClick = () => {
    if (companySelected) setCompany(null);
  };

  const greeting = () => {
    let userName;
    if (!userState.payload || !userState.payload.givenName) userName = "";
    else userName = `, ${userState.payload.givenName}`;
    const today = new Date();
    const hr = today.getHours();
    if (hr < 12) {
      return `Good Morning${userName}`;
    } else if (hr < 18) {
      return `Good Afternoon${userName}`;
    } else {
      return `Good Evening${userName}`;
    }
  };

  const profileMenuOpen = Boolean(profileMenu);
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.imgContainer}>
          <NavLink to="/" onClick={handleHomeNavClick}>
            <img src={logo} className={classes.img} alt="CTM" />
          </NavLink>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className={classes.greeting}>{greeting()}</span>
          <IconButton
            color="inherit"
            aria-label="Open Profile Menu"
            aria-owns={profileMenuOpen ? "simple-popper" : null}
            aria-haspopup="true"
            onClick={handleProfileClick}
            data-aut="profile-menu-button"
            size="large"
          >
            <ArrowDropDown color="primary" />
          </IconButton>
          <Popover
            id="simple-popper"
            open={profileMenuOpen}
            anchorEl={profileMenu}
            onClose={handleProfileClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.dropdown}>
              <MenuList role="menu">
                <NavLink to="/logout" data-aut="ProfilePopup|Logout">
                  <MenuItem className={classes.dropdownItem}>Log Out</MenuItem>
                </NavLink>
              </MenuList>
            </Paper>
          </Popover>
          <img
            src="/images/default-avatar.png"
            className={classes.profilePic}
            alt="User Avatar"
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  companySelected: state.selectedCompanyID,
  userState: state.userState,
});

const mapDispatchToProps = (dispatch) => ({
  setCompany: (data) => dispatch(setCompany(data)),
});

const HeaderStyled = withStyles(Header, headerStyle);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyled);
