import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { MESSAGE_LOG_TEMPLATE_CODES } from "common/constants";
import { handleErrors } from "common/helpers";

const url = getConfig(MESSAGE_LOG_TEMPLATE_CODES).url;

export const fetchMessageLogTemplateCodes = () => {
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(
      new Error(`Missing ${MESSAGE_LOG_TEMPLATE_CODES} url`)
    );
  }
};
