import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { VENDORS } from "common/constants";
import { handleErrors } from "common/helpers";

export const searchVendors = (query) => {
  const url = getConfig(VENDORS).url;
  if (url) {
    const params = { SearchTerm: query };
    return get(url, params)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${VENDORS} url`));
  }
};
