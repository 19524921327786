import moment from "moment";
import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { MESSAGE_LOGS } from "common/constants";
import { handleErrors } from "common/helpers";
import { CompanyI, DebtorI } from "types/Company";
import { MessageLogTemplateCodeI } from "types/MessageLogTemplateCode";

const url = getConfig(MESSAGE_LOGS).url;

export interface MessageLogParams {
  company?: CompanyI | { id: string };
  debtor?: DebtorI;
  messageLogTemplate?: MessageLogTemplateCodeI;
  startDate: moment.Moment;
  endDate: moment.Moment;
  query: string;
}

interface ConstructedParams {
  CompanyId?: string;
  DebtorId?: string;
  TemplateCode?: string;
  StartDate?: string;
  EndDate?: string;
  SearchTerm?: string;
}

function generateParams(params: MessageLogParams) {
  // Only add params if we have valid values there, don't pass in any null refs
  const constructedParams: ConstructedParams = {};

  if (params.company && params.company.id)
    constructedParams.CompanyId = params.company.id;

  if (params.debtor && params.debtor.id)
    constructedParams.DebtorId = params.debtor.id;

  if (params.messageLogTemplate?.code)
    constructedParams.TemplateCode = params.messageLogTemplate.code;

  if (params.startDate)
    constructedParams.StartDate = moment(params.startDate)
      .startOf("date")
      .format();

  if (params.endDate)
    constructedParams.EndDate = moment(params.endDate).endOf("date").format();

  if (params.query && params.query.length)
    constructedParams.SearchTerm = params.query;

  return constructedParams;
}

export function fetchMessageLogs(params: MessageLogParams) {
  if (url) {
    const constructedParams = generateParams(params);
    return get(url, constructedParams)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${MESSAGE_LOGS} url`));
  }
}

export function fetchMessageLog(id) {
  if (url) {
    const finalUrl = `${url}/${id}`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${MESSAGE_LOGS} url`));
  }
}

export function fetchMessageLogsCSV(params) {
  if (url) {
    const constructedParams = generateParams(params);
    const finalUrl = `${url}/csv`;
    return get(finalUrl, constructedParams)
      .then(handleErrors)
      .then((res) => res.text());
  } else {
    return Promise.reject(new Error(`Missing ${MESSAGE_LOGS} url`));
  }
}
