import { useSafeSetState } from "hooks/useState";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AutoComplete } from "components";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Button from "@mui/material/Button";
import { withStyles } from "tss-react/mui";

import {
  blueColor,
  greenColor,
  label,
  unstyledButton,
} from "assets/jss/globalStyle";
import { DebtorI, DebtorSelectionI } from "types/Company";
import { useCompanyDebtors, useUnusedDebtors } from "hooks/debtors";

// eslint-disable-next-line
const styles = (theme, props) => ({
  container: {
    width: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    ...label,
    display: "block",
    lineHeight: "2em",
  },
  primaryIcon: {
    fontSize: "1.25em",
    color: greenColor,
  },
  primaryText: {
    fontSize: "14px",
    marginRight: "16px",
  },
  debtorList: {
    listStyle: "none",
    paddingLeft: 0,
    maxHeight: "250px",
    overflowY: "auto",
    margin: "0 16px 0 0",
  },
  debtor: {
    ...unstyledButton,
    fontWeight: 600,
    marginRight: "4px",
    color: blueColor,
  },
  checkbox: {
    padding: "9px 9px 9px 12px",
    "& svg": {
      fontSize: "18px",
    },
  },
  checkboxLabel: {
    fontSize: "14px",
  },
  debtorCheckbox: {
    color: "black",
  },
  inputContainer: {
    width: "80%",
  },
});

const initialState: {
  addingNewDebtor: boolean;
  newDebtor?: DebtorSelectionI;
} = {
  addingNewDebtor: false,
  newDebtor: null,
};

interface CompanyDebtorConfigPropsI {
  addDebtorToCompany: (id: string) => void;
  createDebtor: () => void;
  defaultDebtorID: string;
  selectDebtor: (selection: DebtorI) => void;
  selectedCompanyID: string;
  classes?: Partial<
    Record<
      | "container"
      | "flexCenter"
      | "label"
      | "primaryIcon"
      | "primaryText"
      | "debtorList"
      | "debtor"
      | "checkbox"
      | "checkboxLabel"
      | "debtorCheckbox"
      | "inputContainer",
      string
    >
  >;
}

function CompanyDebtorConfig(props: CompanyDebtorConfigPropsI) {
  const {
    addDebtorToCompany,
    createDebtor,
    defaultDebtorID,
    selectDebtor,
    selectedCompanyID,
  } = props;
  const classes = withStyles.getClasses(props);

  const companyDebtors = useCompanyDebtors(selectedCompanyID);
  const unusedDebtors = useUnusedDebtors();
  const [{ addingNewDebtor, newDebtor }, safeSetState] =
    useSafeSetState(initialState);

  function toggleDebtorCheckbox() {
    safeSetState({ addingNewDebtor: !addingNewDebtor, newDebtor: null });
  }

  function getPlaceholder() {
    if (unusedDebtors.isLoading) return "Fetching Unused Debtors";
    else if (unusedDebtors.isError) return "Error Fetching Debtors";
    else return "Type a Debtor Name";
  }

  function handleAddDebtor() {
    addDebtorToCompany(newDebtor.id);
    toggleDebtorCheckbox();
  }

  function selectNewDebtor(newDebtor) {
    safeSetState({ newDebtor });
  }

  if (companyDebtors.isLoading || companyDebtors.isError || !companyDebtors)
    return null;

  // Store current companyDebtors as a set for easy lookup
  const debtorIDs = new Set();
  companyDebtors.data?.forEach((d) => {
    debtorIDs.add(d.id);
  });

  // Filter out currently used debtors from unusedDebtors list
  const filteredDebtors = unusedDebtors.data?.filter(
    (d) => !debtorIDs.has(d.id)
  );
  return (
    <div className={classes.container}>
      <span className={classes.label}>Company Debtors</span>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={createDebtor}
      >
        Create New Debtor
      </Button>
      <div className={classes.flexCenter} style={{ flexWrap: "wrap" }}>
        <FormControlLabel
          className={classes.debtorCheckbox}
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              classes={{ root: classes.checkbox }}
              checked={addingNewDebtor}
              color="primary"
              onChange={toggleDebtorCheckbox}
              value="Email"
              data-aut="AddExistingDebtorCheckbox"
              inputProps={{ "aria-label": "Add Existing Debtor" }}
            />
          }
          label="Add Existing Debtor"
        />
        <span className={classes.flexCenter}>
          <DoneOutlineIcon className={classes.primaryIcon} />
          <span className={classes.primaryText}>= Primary</span>
        </span>
        {newDebtor && (
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleAddDebtor}
            data-aut="SaveDebtorToCompany"
          >
            Save To Company
          </Button>
        )}
      </div>
      {addingNewDebtor && (
        <div className={classes.inputContainer} style={{ margin: "8px 0" }}>
          <AutoComplete
            errorMessage={
              unusedDebtors.isError ? "Error Fetching Debtors" : undefined
            }
            disabled={unusedDebtors.isLoading || unusedDebtors.isError}
            placeholder={getPlaceholder()}
            isLoading={unusedDebtors.isLoading}
            handleDelete={() => selectNewDebtor(null)}
            onChange={selectNewDebtor}
            selectedItem={newDebtor}
            suggestions={filteredDebtors || []}
            dataAut="DebtorNameInput"
          />
        </div>
      )}
      <ul className={classes.debtorList}>
        {companyDebtors?.data?.map((d) => (
          <li
            key={d.id}
            className={classes.flexCenter}
            style={{ marginBottom: "4px" }}
          >
            <button
              className={classes.debtor}
              onClick={() => selectDebtor(d)}
              data-aut={`${d.name}-debtor`}
            >
              {d.name}
            </button>
            {d.id === defaultDebtorID ? (
              <DoneOutlineIcon className={classes.primaryIcon} />
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withStyles(CompanyDebtorConfig, styles);
