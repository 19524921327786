import { deleteRequest, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { SLATES } from "common/constants";
import { handleErrors } from "common/helpers";

export const createEditSlate = (params, companyId) => {
  let url = getConfig(SLATES).url;
  const isEdit = Boolean(params.slateId);
  const action = isEdit ? put : post;
  const formData = new FormData();
  const contentType = "multipart/form-data";

  Object.keys(params).forEach((paramKey) => {
    const value = params[paramKey];
    switch (paramKey) {
      case "file":
        if (value instanceof File) formData.append("file", value);
        break;
      case "links":
        if (value.length) {
          value.forEach((link, idx) => {
            Object.keys(link).forEach((linkKey) => {
              formData.append(`links[][${idx}][${linkKey}]`, link[linkKey]);
            });
          });
        } else formData.append(paramKey, value);
        break;
      case "description":
        if (value && value.length) formData.append(paramKey, value);
        break;
      case "title":
      case "imageURL":
        formData.append(paramKey, value);
        break;
      default:
      // don't append to form by default
    }
  });

  if (url && companyId) {
    url = isEdit
      ? `${url}/${companyId}/${params.slateId}`
      : `${url}/${companyId}`;
    return action(url, formData, null, contentType)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${SLATES} url`));
  }
};

export const deleteSlate = (slateId) => {
  let url = getConfig(SLATES).url;
  if (url && slateId) {
    url = `${url}/DeleteSlate/${slateId}`;
    return deleteRequest(url).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${SLATES} url`));
  }
};
