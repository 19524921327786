import React, { Suspense } from "react";
import { Loading } from "components";

const Roles = React.lazy(() => import("./Roles"));

function RolesView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Roles" />}>
      <Roles {...props} />
    </Suspense>
  );
}

export default RolesView;
