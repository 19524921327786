import { blueColor, whiteColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 20px 10px 20px",
  },
  button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    color: blueColor,
    boxSizing: "border-box",
    cursor: "pointer",
    background: "none",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: blueColor,
    borderImage: "initial",
    padding: "4px 12px",
    overflow: "visible",
    fontSize: "14px",
    textTransform: "none",
    "&:hover": {
      color: whiteColor,
      background: blueColor,
    },
  },
});

export default styles;
