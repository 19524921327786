import React, { Suspense } from "react";
import { Loading } from "components";

const Onboarding = React.lazy(() => import("./Onboarding"));

function OnboardingView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Onboarding" />}>
      <Onboarding {...props} />
    </Suspense>
  );
}

export default OnboardingView;
