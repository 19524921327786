import { CKEditor } from "ckeditor4-react";

interface HtmlEditorPropsI {
  content: string;
  handleChange: (e: {
    editor?: {
      getData: (v: string) => void;
    };
  }) => void;
}

function HtmlEditor({ content, handleChange }: HtmlEditorPropsI) {
  return (
    <CKEditor
      initData={content}
      config={{
        startupShowBorders: false,
        allowedContent: true,
        height: 200,
        contentsCss: "/ckeditor.css",
        versionCheck: false,
      }}
      // This removes an error regarding multiple CKEDITOR instances on a page
      onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
      onChange={handleChange}
    />
  );
}

export default HtmlEditor;
