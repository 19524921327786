import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "tss-react/mui";
import cx from "classnames";
import createDashboardRoutes from "routes/dashboard";
import {
  closeNotification,
  processNotificationQueue,
} from "redux/actions/notifications.js";
import {
  SnackbarMessage,
  ErrorBoundary,
  Header,
  Footer,
  RoleAuthentication,
  Sidebar,
  Tabs,
} from "components";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import appStyle from "layouts/Dashboard/appStyle";
import { setCompany } from "redux/actions/selectedCompanyID";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useFeatures } from "hooks/features";

function switchRoutes(dashboardRoutes) {
  return (
    <Switch>
      {dashboardRoutes.map((prop, key) => {
        const { path, redirect, RouteComponent, to } = prop;
        if (redirect) return <Redirect from={path} to={to} key={key} />;
        return (
          <Route
            path={path}
            render={(props) => (
              <ErrorBoundary>
                <RouteComponent {...props} />
              </ErrorBoundary>
            )}
            key={key}
          />
        );
      })}
    </Switch>
  );
}

const App = ({
  classes,
  closeNotification,
  history,
  location,
  notifications,
  processNotificationQueue,
  redirectToLogout,
  selectedCompanyID,
  setCompany,
  userState,
  ...rest
}) => {
  const mainPanelRef = useRef<HTMLDivElement>(null);
  const features = useFeatures(selectedCompanyID);
  const selectedCompany = useSelectedCompany(selectedCompanyID);
  const [dashboardRoutes, setRoutes] = useState(
    createDashboardRoutes(userState.payload, features.data?.approveEnabled)
  );

  useEffect(() => {
    const params = new window.URLSearchParams(location.search);
    const companyId = params.get("company");
    if (companyId) setCompany(companyId);
    // eslint-disable-next-line
  }, []);

  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     // eslint-disable-next-line
  //     const ps = new PerfectScrollbar(this.refs.mainPanel);
  //   }
  //   // Fetch Companies Immediately to speed up load
  // }

  useEffect(() => {
    setRoutes(
      createDashboardRoutes(userState.payload, features.data?.approveEnabled)
    );
  }, [userState.payload, features.data?.approveEnabled]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  // componentDidUpdate(prevProps) {
  //   if (prevProps.companySelected !== this.props.companySelected)
  //     this.setState({
  //       dashboardRoutes: createDashboardRoutes(
  //         this.props.userState.payload,
  //         this.props.companySelected
  //       ),
  //     });

  //   if (this.refs && this.refs.mainPanel && this.refs.mainPanel.scrollTop)
  //     this.refs.mainPanel.scrollTop = 0;
  // }

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      closeNotification();
    }
  };

  const handleTopNavClick = (loc) => {
    if (selectedCompanyID) setCompany(null);
    if (history.location.pathname !== loc) history.push(loc);
  };

  if (redirectToLogout) return <Redirect to="/login" />;

  const isError = notifications?.message?.type === "error";

  const snackbarClass = cx({
    [" " + classes.snackbarContent]: true,
    [" " + classes.success]: notifications?.message?.type === "success",
    [" " + classes.error]: isError,
  });

  const renderTabs = userState?.payload?.accessLevel > 30;

  const renderSidebar = Boolean(selectedCompany.data);
  return (
    <RoleAuthentication userState={userState}>
      <div className={classes.wrapper} ref={mainPanelRef}>
        <div className={classes.mainPanel}>
          <Header {...rest} />
          {renderTabs && (
            <Tabs
              history={history}
              onClick={handleTopNavClick}
              userState={userState.payload}
            />
          )}
          <div
            className={classes.content}
            style={!renderTabs ? { marginTop: "70px" } : {}}
          >
            {renderSidebar && (
              <Sidebar
                routes={dashboardRoutes}
                pathname={location.pathname}
                search={location.search}
                companySelected={selectedCompany.data}
                userState={userState.payload}
              />
            )}
            <div className={classes.container}>
              <ErrorBoundary>
                {selectedCompany.data ? (
                  <div
                    className={classes.selectedCompanyContainer}
                    style={!renderSidebar ? { margin: "auto" } : {}}
                  >
                    {switchRoutes(dashboardRoutes)}
                  </div>
                ) : (
                  <>{switchRoutes(dashboardRoutes)}</>
                )}
              </ErrorBoundary>
            </div>
          </div>
          <Footer />
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={notifications.open}
          autoHideDuration={5000}
          onClose={handleClose}
          TransitionProps={{ onExited: processNotificationQueue }}
        >
          <SnackbarContent
            className={snackbarClass}
            aria-describedby="message-id"
            message={
              <span
                id="message-id"
                className={snackbarClass}
                data-aut={`Snackbar|${isError ? "Error" : "Success"}`}
              >
                <SnackbarMessage
                  message={notifications?.message?.text}
                  errors={notifications?.message?.errors}
                />
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                data-aut={`Snackbar|${
                  isError ? "Error" : "Success"
                }|CloseButton`}
                onClick={closeNotification}
                size="large"
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    </RoleAuthentication>
  );
};
const mapStateToProps = (state) => ({
  selectedCompanyID: state.selectedCompanyID,
  notifications: state.notifications,
  userState: state.userState,
  redirectToLogout: state.application.redirectToLogout,
});

const mapDispatchToProps = (dispatch) => ({
  closeNotification: () => dispatch(closeNotification()),
  processNotificationQueue: () => dispatch(processNotificationQueue()),
  setCompany: (data) => dispatch(setCompany(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(App, appStyle));
