import {
  USER_SEARCH_CHANGE_ROLES,
  USER_SEARCH_CHANGE_QUERY,
  USER_SEARCH_REMOVE_ROLE,
  USER_SEARCH_ORDER_BY,
  USER_SEARCH_PAGE,
  USER_SEARCH_ROWS_PER_PAGE,
  USER_SEARCH_SHOW_DELETED_USERS,
  USER_SEARCH_SELECT_COMPANY,
  USER_SEARCH_SET_FORM_PARAMS,
} from "redux/actions/userSearch";

import moment from "moment";
import { UserSearchStateI } from "types/User";
import { SET_SELECTED_COMPANY } from "redux/actions/selectedCompanyID";

export const initialState: UserSearchStateI = {
  query: "",
  selectedCompanies: [],
  selectedRoles: [],
  users: [],
  usersOrder: "asc",
  usersOrderBy: null,
  usersPage: 0,
  usersRowsPerPage: 10,
  totalCount: 0,
  showDeletedUsers: false,
  includeCount: true,
};

export const userMapper = (u) => ({
  ...u,
  created: moment(u.createdOn),
  lastLogin: u.lastLogin && moment(u.lastLogin),
});

const userSearch = (
  state: UserSearchStateI = initialState,
  action
): UserSearchStateI => {
  switch (action.type) {
    case USER_SEARCH_CHANGE_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case USER_SEARCH_SELECT_COMPANY:
      return {
        ...state,
        selectedCompanies: action.payload,
        selectedRoles: [],
      };
    case USER_SEARCH_CHANGE_ROLES:
      return {
        ...state,
        selectedRoles: action.payload,
      };
    case USER_SEARCH_REMOVE_ROLE:
      return {
        ...state,
        selectedRoles: state.selectedRoles.filter(
          (role) => role !== action.payload
        ),
      };
    case USER_SEARCH_SET_FORM_PARAMS:
      return {
        ...state,
        ...action.payload,
      };

    case USER_SEARCH_ORDER_BY:
      return {
        ...state,
        usersOrder: action.payload.usersOrder,
        usersOrderBy: action.payload.usersOrderBy,
        // We want to reset to page 0 whenever we change ordering
        usersPage: 0,
      };
    case USER_SEARCH_PAGE:
      return {
        ...state,
        usersPage: action.payload,
      };
    case USER_SEARCH_SHOW_DELETED_USERS:
      return {
        ...state,
        showDeletedUsers: action.payload,
      };
    case USER_SEARCH_ROWS_PER_PAGE:
      return {
        ...state,
        usersRowsPerPage: action.payload,
      };
    case SET_SELECTED_COMPANY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userSearch;
