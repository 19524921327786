import { useQuery } from "@tanstack/react-query";
import { fetchApps } from "services/apps";
import { QUERYKEY_APPS } from "./queryKeys";
import { AppI } from "types/Apps";

function mapApps(data): AppI[] {
  return data.map((app) => ({
    label: app.name,
    value: app.id,
    id: app.id,
  }));
}

export function useApps() {
  return useQuery({
    queryKey: [QUERYKEY_APPS],
    queryFn: fetchApps,
    select: mapApps,
  });
}
