import { useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CompanyRoleFeature from "./CompanyRoleFeature";
import companyRoleFeaturesStyle from "./companyRoleFeaturesStyle";
import { FeatureI } from "types/Feature";
import { RoleI } from "types/Role";
import { useToggleFeatureRoleProperty } from "hooks/features";

function getRoles(features: FeatureI[]) {
  if (
    !features ||
    !features.length ||
    !features[0].roles ||
    !features[0].roles.length
  )
    return [];
  return features[0].roles;
}

interface CompanyRoleFeaturesI {
  features: FeatureI[];
  roles: RoleI[];
  selectFeature: (feature: FeatureI) => void;
  selectedCompanyID: string;
  classes?: Partial<
    Record<
      | "root"
      | "header"
      | "propertyHeader"
      | "paperWrapper"
      | "table"
      | "textField"
      | "propertyCell"
      | "roleCell"
      | "roleCellApprove",
      string
    >
  >;
}

const CompanyRoleFeatures = (props: CompanyRoleFeaturesI) => {
  const { features, roles, selectFeature, selectedCompanyID } = props;
  const classes = withStyles.getClasses(props);
  const [featureFilter, setFilter] = useState("");
  const lowerFilter = featureFilter.toLowerCase();
  const toggleFeatureRoleProperty =
    useToggleFeatureRoleProperty(selectedCompanyID);
  const filteredFeatures = lowerFilter
    ? features.reduce((filtered, feature, featureIndex) => {
        if (feature.featureName.toLowerCase().includes(lowerFilter)) {
          filtered.push({ ...feature, featureIndex });
        }
        return filtered;
      }, [])
    : features.map((f, i) => ({ ...f, featureIndex: i }));

  const featureRoles = getRoles(filteredFeatures);
  const hasApproveRole = featureRoles.some((f) => f.name === "Approve Support");
  const minWidth = `${470 + roles.length * 150}px`;
  const handleRoleToggle = (feature, roleIndex) => {
    const featureID = feature.companyFeatureID;
    const featureIndex = feature.featureIndex;
    const roleID = feature.roles[roleIndex].id;
    const enabled = !feature.roles[roleIndex].enabled;
    toggleFeatureRoleProperty.mutate({
      roleIndex,
      enabled,
      featureIndex,
      roleID,
      featureID,
    });
  };
  return (
    <div style={{ width: "100%", minWidth, position: "relative" }}>
      <TextField
        InputProps={{
          inputProps: {
            "aria-label": "Filter Features",
            autoComplete: "off",
          },
        }}
        label="Filter Features"
        placeholder=""
        className={classes.textField}
        margin="none"
        value={featureFilter}
        onChange={(e) => setFilter(e.target.value)}
        variant="standard"
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.propertyCell}>
              Property Name
            </TableCell>
            <TableCell className={classes.propertyCell} style={{ left: 240 }}>
              Custom Name
            </TableCell>
            {featureRoles.map((r) => (
              <TableCell
                key={r.id}
                className={
                  hasApproveRole ? classes.roleCellApprove : classes.roleCell
                }
              >
                {r.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredFeatures.map(
            (feature) =>
              feature.featureEnabled && (
                <CompanyRoleFeature
                  feature={feature}
                  featureIndex={feature.featureIndex}
                  roles={roles}
                  toggle={handleRoleToggle}
                  key={`${feature.featureIndex}${feature.companyFeatureID}`}
                  hasApproveRole={hasApproveRole}
                  selectFeature={selectFeature}
                />
              )
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(CompanyRoleFeatures, companyRoleFeaturesStyle);
