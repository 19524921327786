import {
  errorMessage,
  grayColor,
  redColor,
  label,
  silverColor,
} from "assets/jss/globalStyle";
import { ThemeI } from "types/Theme";

const input = {
  height: "45px",
  paddingTop: 0,
  paddingBottom: 0,
  width: "auto",
  flex: "100 1 auto",
  minWidth: "50px",
};

// eslint-disable-next-line
const inputStyle = (theme: ThemeI, props) => ({
  wrapper: {
    position: "relative",
  },
  errorLabel: {
    ...label,
    color: redColor,
    fontSize: "12px",
    lineHeight: "16px",
    margin: "13px 0",
  },
  flex: {
    display: "flex",
  },
  errorMessage,
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  label,
  input,
  inputMultiline: {
    ...input,
    height: "100% !important",
  },
  textField: {
    height: "85px",
    padding: "16px",
  },
  root: {
    flexWrap: "wrap",
  },
  inputAdornment: {
    color: grayColor,
  },
  disabled: {
    backgroundColor: silverColor,
  },
  checkbox: {
    padding: 0,
    "& svg": {
      fontSize: "18px",
    },
  },
});

export default inputStyle;
