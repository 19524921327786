import { withStyles } from "tss-react/mui";
import { convertDataToCSVDownload, wrapCSVRowWithQuotes } from "common/helpers";

import { Button } from "components";
import { useState } from "react";

import { exportApproveConfig } from "services/approveConfig";
import { NotificationObjI } from "types";

type ExportedTravelClass = {
  name: string;
  travelClassUsers: {
    name: string;
    email: string;
  }[];
};

type ExportedCompanyApproveData = {
  approvalGroupNames: string;
  companyName: string;
  debtorConfig: ExportedDebtorApproveData[];
  travelClass: ExportedTravelClass[];
  travelPolicyNames: string;
};

type ExportedDebtorApproveData = {
  approveConfigs: ExportedDebtorApproveConfigs;
  approveEmailConfigurations: ExportedDebtorApproveEmailConfigurations;
};

type ApproveConfigCode = {
  code: string;
  label: string;
  isCustom: boolean;
};

type ApproveRemarkCode = {
  key: string;
  udid: string;
  label: string;
};

type ExportedDebtorApproveConfigs = {
  queue: {
    approveCode?: string;
    approvePCC?: string;
    declineCode?: string;
    declinePCC?: string;
  };
  id: string;
  companyID: string;
  debtor: {
    id: string;
    identifier: string;
    name: string;
  };
  processQueue?: string;
  sentQueue?: string;
  rejectedQueue?: string;
  declinedQueue?: string;
  noApproveQueue?: string;
  offlineApprovedQueue?: string;
  onlineApprovedQueue?: string;
  noApproveRemark?: string;
  approveRemark?: string;
  declinedRemark?: string;
  approvedEmailSubject?: string;
  approvedEmailText?: string;
  declinedEmailSubject?: string;
  declinedEmailText?: string;
  reminderTimeLimit?: string;
  reminderEmailAddress?: string;
  timeToLiveFailureEmail?: string;
  agencyEmailReceiptEmail?: string;
  reasonCodes: ApproveConfigCode[];
  tripPurposeCodes: ApproveConfigCode[];
  remarkCodes: {
    [key: string]: ApproveRemarkCode;
  };
  customRemarkCodes: {
    [key: string]: ApproveRemarkCode;
  };
  udidDataSource: string;
  resultOfExchangeQueuePolicy: string;
  instantPurchaseQueuePolicy: string;
  autoQueuePolicy: string;
  noApprovePolicy: string;
  gdsCodes: string[];
  timerDuration?: number;
  taw: string;
  tawTrigger?: string;
  twoFactorApproval: boolean;
  onlineHoldEnabled: boolean;
  noApproveTrigger: string;
  instantPurchaseQueue: {
    instantPurchaseQueuePolicy: string;
    instantPurchaseQueueTravelGroupIds?: string[];
  };
  resultOfExchangeQueue: {
    resultOfExchangeQueuePolicy: string;
    resultOfExchangeQueueTravelGroupIds?: string[];
  };
  autoQueue: {
    autoQueuePolicy: string;
    autoQueueTravelGroupIds?: string[];
  };
  noApprove: {
    noApprovePolicy: string;
    noApproveTravelGroupIds?: string[];
  };
};

type ExportedEmailTemplate = {
  customInbox: string[];
  customInboxBCC: string[];
  customSubject?: string;
  customBody?: string;
  showDeclinedItinerary: boolean;
  showLlf: boolean;
  selectedEmailCustomUdids: {
    uniqueIdentifier: string;
    active: boolean;
  }[];

  showHeaderMessage: boolean;
  showApproveByTimestamp: boolean;
  showCustomBody: boolean;
  showApprovalStatus: boolean;
  showReasonBooked: boolean;
  showTripPurpose: boolean;
  showPolicyViolationOverview: boolean;
  showSendEmailOnEveryUpdate: boolean;
};

type ExportedDebtorApproveEmailConfigurations = {
  id: string;
  companyID: string;
  debtor: {
    id: string;
    identifier: string;
    name: string;
  };
  customInboxCC?: string[];
  customInboxBCC?: string[];
  showHyperlinkButton: boolean;
  customUdids: {
    uniqueIdentifier: string;
    udid: string;
    label: string;
    exclusion?: string;
  }[];

  approveButtonText?: string;
  approveButtonColor?: string;
  declineButtonText?: string;
  declineButtonColor?: string;
  mainHeadingColor?: string;
  approveLogoImageUrl?: string;
  headingBackgroundColor?: string;
  manageButtonText?: string;
  manageButtonColor?: string;
  approvalNeededTemplate: ExportedEmailTemplate;
  approvalRequestedTemplate: ExportedEmailTemplate;
  approvalAuthorityTransferTemplate: ExportedEmailTemplate;
  timeoutReminderTemplate: ExportedEmailTemplate;
  approvalTimeoutTemplate: ExportedEmailTemplate;
  approvalGrantedTemplate: ExportedEmailTemplate;
  approvalDeclinedTemplate: ExportedEmailTemplate;
  managerNotificationTemplate: ExportedEmailTemplate;
};

// eslint-disable-next-line
const styles = (theme, props) => ({
  exportButton: {
    margin: "0 0 22px 22px",
  },
});

const mapEmailTemplateValues = (template: ExportedEmailTemplate) => {
  return [
    // `Email Design - ${emailGroup} - Custom Subject`,
    template?.customSubject || "",
    // `Email Design - ${emailGroup} - Custom Body`,
    template?.customBody || "",
    // `Email Design - ${emailGroup} - Custom Inbox`,
    wrapCSVRowWithQuotes(template?.customInbox?.join(", ")),
    // `Email Design - ${emailGroup} - Custom Inbox BCC`,
    wrapCSVRowWithQuotes(template?.customInboxBCC?.join(", ")),
    // `Email Design - ${emailGroup} - Include in Emails - Header Message`,
    Boolean(template?.showHeaderMessage).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Approve by timestamp`,
    Boolean(template?.showApproveByTimestamp).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Custom body`,
    Boolean(template?.showCustomBody).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Approval status`,
    Boolean(template?.showApprovalStatus).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Reason booked`,
    Boolean(template?.showReasonBooked).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Trip purpose`,
    Boolean(template?.showTripPurpose).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Policy Violation Overview`,
    Boolean(template?.showPolicyViolationOverview).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Lowest logical fare`,
    Boolean(template?.showLlf).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Declined itinerary`,
    Boolean(template?.showDeclinedItinerary).toString() || "",
    // `Email Design - ${emailGroup} - Include in Emails - Send Email on every update`,
    Boolean(template?.showSendEmailOnEveryUpdate).toString() || "",
    // `Email Design - ${emailGroup} - Custom UDID`,
    wrapCSVRowWithQuotes(
      template?.selectedEmailCustomUdids
        ?.map((ud) => ud.uniqueIdentifier)
        .join(", ")
    ),
  ];
};

export const mapEmailDesignFields = (emailGroup) => {
  return [
    `Email Design - ${emailGroup} - Custom Subject`,
    `Email Design - ${emailGroup} - Custom Body`,
    `Email Design - ${emailGroup} - Custom Inbox CC`,
    `Email Design - ${emailGroup} - Custom Inbox BCC`,
    `Email Design - ${emailGroup} - Include in Emails - Header Message`,
    `Email Design - ${emailGroup} - Include in Emails - Approve by timestamp`,
    `Email Design - ${emailGroup} - Include in Emails - Custom body`,
    `Email Design - ${emailGroup} - Include in Emails - Approval status`,
    `Email Design - ${emailGroup} - Include in Emails - Reason booked`,
    `Email Design - ${emailGroup} - Include in Emails - Trip purpose`,
    `Email Design - ${emailGroup} - Include in Emails - Policy Violation Overview`,
    `Email Design - ${emailGroup} - Include in Emails - Lowest logical fare`,
    `Email Design - ${emailGroup} - Include in Emails - Declined itinerary`,
    `Email Design - ${emailGroup} - Include in Emails - Send Email on every update`,
    `Email Design - ${emailGroup} - Custom UDID`,
  ];
};

const downloadApproveConfigCSV = (config: ExportedCompanyApproveData[]) => {
  const legend = [
    "Company",
    "Debtor Name",
    "Approve Queue",
    "Approve PCC",
    "Decline Queue",
    "Decline PCC",
    "Approve Remark",
    "Declined Remark",
    "No Approve Remark",
    "Sabre UDIDs",
    "Reason Codes",
    "Trip Purpose Codes",
    "Auto-Queue Setting",
    "No Approve Setting",
    "Exchange Setting",
    "Instant Purchase Setting",
    "Approvers Found In",
    "2-Factor Approval",
    "Online Hold Enabled",
    "Approval Hold",
    "Approval Trigger",
    "Timer Duration",
    "GDS Source",
    "No Approve Trigger",
    "Travel Policy",
    "Approval Groups",
    "Custom Inbox CC",
    "Custom Inbox BCC",
    "Email Header Image URL",
    "Header Background Color (hex)",
    "Main Headings Color (hex)",
    "Approve Button Text",
    "Approve Button Color (hex)",
    "Decline Button Text",
    "Decline Button Color (hex)",
    ...mapEmailDesignFields("Requested"),
    ...mapEmailDesignFields("Reminder"),
    ...mapEmailDesignFields("Timeout"),
    ...mapEmailDesignFields("Needed"),
    ...mapEmailDesignFields("Granted"),
    ...mapEmailDesignFields("Declined"),
    ...mapEmailDesignFields("Manager Notification"),
    "Travel Class Name",
    "Travel Class - Policies",
    "Travel Class Users",
  ];
  const mappedData = [];
  config.forEach((comp) => {
    if (!comp.debtorConfig?.length) return;
    comp.debtorConfig.forEach((debtor) => {
      const sabreUdidData = {
        ...(debtor?.approveConfigs?.remarkCodes || {}),
        ...(debtor?.approveConfigs?.customRemarkCodes || {}),
      };
      const newLine = [
        // "Company",
        comp.companyName || "",
        // "Debtor Name",
        debtor?.approveConfigs?.debtor?.name || "",
        // "Approve Queue",
        debtor?.approveConfigs?.queue?.approveCode || "",
        // "Approve PCC",
        debtor?.approveConfigs?.queue?.approvePCC || "",
        // "Decline Queue",
        debtor?.approveConfigs?.queue?.declineCode || "",
        // "Decline PCC",
        debtor?.approveConfigs?.queue?.declinePCC || "",
        // "Approve Remark",
        debtor?.approveConfigs?.approveRemark || "",
        // "Declined Remark",
        debtor?.approveConfigs?.declinedRemark || "",
        // "No Approve Remark",
        debtor?.approveConfigs?.noApproveRemark || "",
        // "Sabre UDIDs",
        wrapCSVRowWithQuotes(
          Object.values(sabreUdidData)
            .map(
              (rc) =>
                `Label: ${rc.label}, Udid: ${rc.udid}${
                  rc.key?.length ? `, Key: ${rc.key}` : ""
                }`
            )
            .join("| ")
        ),
        // "Reason Codes",
        debtor?.approveConfigs?.reasonCodes
          ? wrapCSVRowWithQuotes(
              Object.values(debtor?.approveConfigs?.reasonCodes)
                .map((rc) => `Code: ${rc.code}, Label: ${rc.label}`)
                .join("| ")
            )
          : "",
        // "Trip Purpose Codes",
        debtor?.approveConfigs?.tripPurposeCodes?.length
          ? wrapCSVRowWithQuotes(
              debtor?.approveConfigs?.tripPurposeCodes
                ?.map((tpc) => `Label: ${tpc.label}, Code: ${tpc.code}`)
                .join("| ")
            )
          : "",
        // "Auto-Queue Setting",
        debtor?.approveConfigs?.autoQueue?.autoQueuePolicy || "",
        // "No Approve Setting",
        debtor?.approveConfigs?.noApprove?.noApprovePolicy || "",
        // "Exchange Setting",
        debtor?.approveConfigs?.resultOfExchangeQueue
          ?.resultOfExchangeQueuePolicy || "",
        // "Instant Purchase Setting",
        debtor?.approveConfigs?.instantPurchaseQueue
          ?.instantPurchaseQueuePolicy || "",
        // "Approvers Found In",
        debtor?.approveConfigs?.udidDataSource || "",
        // "2-Factor Approval",
        Boolean(debtor?.approveConfigs?.udidDataSource).toString() || "",
        // "Online Hold Enabled",
        Boolean(debtor?.approveConfigs?.onlineHoldEnabled).toString() || "",
        // "Approval Hold",
        debtor?.approveConfigs?.taw || "",
        // "Approval Trigger",
        debtor?.approveConfigs?.tawTrigger || "",
        // "Timer Duration",
        debtor?.approveConfigs?.timerDuration || "",
        // "GDS Source",
        wrapCSVRowWithQuotes(debtor?.approveConfigs?.gdsCodes?.join(", ")),
        // "No Approve Trigger",
        debtor?.approveConfigs?.noApproveTrigger || "",
        // "Travel Policy",
        wrapCSVRowWithQuotes(comp?.travelPolicyNames.split(",").join(", ")),
        // "Approval Groups",
        wrapCSVRowWithQuotes(comp?.approvalGroupNames.split(",").join(", ")),
        // "Custom Inbox CC",
        wrapCSVRowWithQuotes(
          debtor?.approveEmailConfigurations?.customInboxCC?.join(", ")
        ),
        // "Custom Inbox BCC",
        wrapCSVRowWithQuotes(
          debtor?.approveEmailConfigurations?.customInboxBCC?.join(", ")
        ),
        // "Email Header Image URL",
        debtor?.approveEmailConfigurations?.approveLogoImageUrl || "",
        // "Header Background Color (hex)",
        debtor?.approveEmailConfigurations?.headingBackgroundColor || "",
        // "Main Headings Color (hex)",
        debtor?.approveEmailConfigurations?.mainHeadingColor || "",
        // "Approve Button Text",
        debtor?.approveEmailConfigurations?.approveButtonText || "",
        // "Approve Button Color (hex)",
        debtor?.approveEmailConfigurations?.approveButtonColor || "",
        // "Decline Button Text",
        debtor?.approveEmailConfigurations?.declineButtonText || "",
        // "Decline Button Color (hex)",
        debtor?.approveEmailConfigurations?.declineButtonColor || "",
        // ...mapEmailDesignFields("Requested"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.approvalRequestedTemplate
        ),
        // ...mapEmailDesignFields("Reminder"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.timeoutReminderTemplate
        ),

        // ...mapEmailDesignFields("Timeout"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.approvalTimeoutTemplate
        ),

        // ...mapEmailDesignFields("Needed"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.approvalNeededTemplate
        ),

        // ...mapEmailDesignFields("Granted"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.approvalGrantedTemplate
        ),

        // ...mapEmailDesignFields("Declined"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.approvalDeclinedTemplate
        ),

        // ...mapEmailDesignFields("Manager Notification"),
        ...mapEmailTemplateValues(
          debtor?.approveEmailConfigurations?.managerNotificationTemplate
        ),
        // "Travel Class Name",
        wrapCSVRowWithQuotes(comp.travelClass.map((tc) => tc.name).join(", ")),
        // "Travel Class - Policies",
        wrapCSVRowWithQuotes(comp.travelPolicyNames.split(",").join(", ")),
        // "Travel Class Users",
        wrapCSVRowWithQuotes(
          comp.travelClass
            .map((tc) => {
              return `${tc.name}: ${
                tc.travelClassUsers?.length
                  ? tc.travelClassUsers
                      .map((user) => {
                        return `${user.name} (${user.email})`;
                      })
                      .join(", ")
                  : "none"
              }`;
            })
            .join(" | ")
        ),
      ].map((item) => {
        if (typeof item === "string") return item.replace(/[\n\r]/g, "");
        else return item;
      });

      mappedData.push(newLine);
    });
  });
  convertDataToCSVDownload([legend, ...mappedData], "approve-config.csv");
};

interface ApproveConfigPropsI {
  addNotification: (notification: NotificationObjI) => void;
  classes?: Partial<Record<"exportButton", string>>;
}

const ApproveConfig = (props: ApproveConfigPropsI) => {
  const { addNotification } = props;
  const classes = withStyles.getClasses(props);

  const [isFetching, setFetching] = useState(false);
  return (
    <div className={classes.exportButton}>
      <Button
        disabled={isFetching}
        loading={isFetching}
        onClick={() => {
          setFetching(true);
          exportApproveConfig()
            .then((r) => {
              downloadApproveConfigCSV(r);
              addNotification({
                text: `Approve Data Downloaded`,
                type: "success",
              });
            })
            .catch(() => {
              addNotification({
                text: "Error Downloading Approve Data",
                type: "error",
              });
            })
            .finally(() => {
              setFetching(false);
            });
        }}
        label="Export"
        data-aut="GlobalSearch|ApproveConfigExport|Button"
      />
    </div>
  );
};

export default withStyles(ApproveConfig, styles);
