import { CompanyDetails, Modal } from "components";
import { CompanyDetailsStateI } from "components/CompanyDetails/CompanyDetails";
import { UserStateI } from "types/User";

interface CompanyModalI {
  close: () => void;
  isOpen: boolean;
  onSave: (company: CompanyDetailsStateI) => void;
  saving: boolean;
  userState: UserStateI;
}
const CompanyModal = ({
  close,
  isOpen,
  onSave,
  saving,
  userState,
}: CompanyModalI) => (
  <Modal
    close={close}
    isOpen={isOpen}
    hasRequiredFields
    header="Add a new account"
    overflowY={false}
  >
    <CompanyDetails
      newCompany
      close={close}
      onSave={onSave}
      saving={saving}
      userState={userState}
    />
  </Modal>
);

export default CompanyModal;
