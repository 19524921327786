import { deleteRequest, get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import {
  USERS,
  USER_SEARCH,
  USER_PASSWORD_RESET_EMAIL_TRIGGER,
  USER_WELCOME_EMAIL_TRIGGER,
} from "common/constants";
import { handleErrors } from "common/helpers";

let url = getConfig(USERS).url;

export const createUser = (user) => {
  if (!user) return Promise.reject(new Error(`Missing user`));
  if (!url) return Promise.reject(new Error(`Missing ${USERS} url`));
  return post(url, user).then(handleErrors);
};

export const deleteUser = (id) => {
  if (!id) return Promise.reject(new Error(`Missing user id`));
  if (!url) return Promise.reject(new Error(`Missing ${USERS} url`));
  const finalURL = `${url}/${id}`;
  return deleteRequest(finalURL).then(handleErrors);
};

export const editUser = (user) => {
  if (!user) return Promise.reject(new Error(`Missing user`));
  if (!url) return Promise.reject(new Error(`Missing ${USERS} url`));
  return put(url, user).then(handleErrors);
};

export const getUser = (id) => {
  if (!id) return Promise.reject(new Error(`Missing user id`));

  if (!url) return Promise.reject(new Error(`Missing ${USERS} url`));
  const finalURL = `${url}/${id}`;
  return get(finalURL)
    .then(handleErrors)
    .then((res) => res.json());
};

const generateParams = (userSearch) => {
  const {
    includeCount,
    query,
    selectedCompanies,
    selectedRoles,
    usersPage,
    usersRowsPerPage,
    usersOrder,
    usersOrderBy,
    showDeletedUsers,
  } = userSearch;
  const params = {
    searchTerm: query,
    maxResults: usersRowsPerPage,
    pageIndex: usersPage,
    sortDescending: usersOrder === "desc",
    includeCount: includeCount ? true : false,
    showDeletedUsers,
  };

  if (usersOrderBy) {
    params.sortBy = usersOrderBy;
  }

  if (selectedCompanies) {
    params.CompanyIds = selectedCompanies.map((c) => c.id);
  }
  if (selectedRoles && selectedRoles.length) {
    params.Roles = selectedRoles.map((r) => r.value);
  }

  return params;
};

export const searchUsers = (userSearch) => {
  const url = getConfig(USER_SEARCH).url;
  const params = generateParams(userSearch);

  if (url) {
    return get(url, params)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${USER_SEARCH} url`));
  }
};

export const userReinstate = (id) => {
  if (!id) return Promise.reject(new Error(`Missing user id`));
  const reinstateUrl = url + `/${id}/Reinstate`;
  return post(reinstateUrl).then(handleErrors);
};

export const userWelcomeEmailTrigger = (id) => {
  if (!id) return Promise.reject(new Error(`Missing user id`));
  let url = getConfig(USER_WELCOME_EMAIL_TRIGGER).url;
  if (!url)
    return Promise.reject(
      new Error(`Missing ${USER_WELCOME_EMAIL_TRIGGER} url`)
    );
  url += id;
  return post(url).then(handleErrors);
};

export const userPasswordResetEmailTrigger = (id) => {
  if (!id) return Promise.reject(new Error(`Missing user id`));
  let baseurl = getConfig(USER_PASSWORD_RESET_EMAIL_TRIGGER).url;
  if (!baseurl)
    return Promise.reject(
      new Error(`Missing ${USER_PASSWORD_RESET_EMAIL_TRIGGER} url`)
    );
  const url = `${baseurl}${id}/PasswordReset`;
  return post(url).then(handleErrors);
};

export function fetchUsersCSV(userSearch) {
  if (url) {
    const constructedParams = generateParams(userSearch);
    const finalUrl = `${url}/Csv`;
    return get(finalUrl, constructedParams)
      .then(handleErrors)
      .then((res) => res.text());
  } else {
    return Promise.reject(new Error(`Missing ${USER_SEARCH} url`));
  }
}
