/*!

 =========================================================
 * Material Dashboard React - v1.2.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const contentWidth = "1300px";

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
};

const flexVertAligned = {
  display: "flex",
  alignItems: "center",
};

const flexRight = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const flexSpaceBetween = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
};

const spacing = {
  unit: 15,
};

const headerHeight = 80;
const footerHeight = 130;

const layout = {
  header: {
    height: `${headerHeight}px`,
  },
  footer: {
    height: `${footerHeight}px`,
  },
  dashboard: {
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
  },
};

const defaultFont = {
  fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,sans-serif",
  fontWeight: 300,
  lineHeight: "1.5em",
};

const paddingSize = "22px";

const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "rgba(0, 0, 0, 0.54)";
const textColor = "#333333";

// Official CTM Palette
const blueColor = "#007AFF";
const greenColor = "#51CE6C";
const neutralColor = "#2C3E50";
const silverColor = "#F3F6FC";
const whiteColor = "#FFFFFF";
const pinkColor = "#FF2D55";
const redColor = "#FF0035";
const purpleColor = "#6554C0";
const orangeColor = "#FF9500";
const primaryColor = "#007AFF";
const lightBlueColor = "#02c2eb";

const backgroundColor = "#E9EBF1";
const hoverBackground = "rgba(0, 0, 0, 0.07)";
const blueBackgroundColor = "#283C4F";

const lightGrayColor = "rgba(0, 0, 0, 0.23)";

const primaryBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",
};
const infoBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
};
const successBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
};
const warningBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
};
const dangerBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
};

const styledLink = {
  display: "flex",
  alignItems: "center",
  color: "gray",
  cursor: "pointer",
  "&:hover": {
    color: "gray",
  },
  "& > svg": {
    cursor: "pointer",
  },
};

const cardHeader = {
  fontSize: "18px",
  fontWeight: 600,
};

const tableHeadFont = {
  fontSize: "14px",
  fontWeight: 600,
  color: grayColor,
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
};

const pageHeader = {
  color: greenColor,
  fontSize: "36px",
  margin: 0,
};

const cardTitle = {
  width: "100%",
  margin: 0,
  padding: "16px",
  fontSize: "18px",
  fontWeight: 600,
};

const requiredFields = {
  marginLeft: "16px",
  color: grayColor,
  fontSize: "14px",
};

const label = {
  paddingLeft: "8px",
  fontSize: "14px",
  fontWeight: 600,
  color: "black",
  lineHeight: "3em",
};

const ellipsisOverflow = {
  whiteSpace: "nowrap" as "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const errorMessage = {
  margin: "0 5px",
  color: "red",
  fontSize: "12px",
  position: "absolute" as "absolute",
  width: "100%",
  ...ellipsisOverflow,
};

const unstyledButton = {
  background: "none",
  color: "inherit",
  border: "none",
  padding: 0,
  font: "inherit",
  cursor: "pointer",
  "&:focus": {
    color: blueColor,
  },
};

export {
  //variables
  ellipsisOverflow,
  errorMessage,
  unstyledButton,
  paddingSize,
  backgroundColor,
  hoverBackground,
  blueBackgroundColor,
  contentWidth,
  label,
  layout,
  drawerWidth,
  cardHeader,
  requiredFields,
  tableHeadFont,
  transition,
  container,
  styledLink,
  flexRight,
  flexSpaceBetween,
  flexVertAligned,
  boxShadow,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  defaultBoxShadow,
  textColor,
  spacing,
  pageHeader,
  cardTitle,
  // CTM Palette
  blueColor,
  greenColor,
  neutralColor,
  silverColor,
  whiteColor,
  pinkColor,
  redColor,
  purpleColor,
  orangeColor,
  lightBlueColor,
  lightGrayColor,
};
