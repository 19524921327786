import {
  NOTIFICATIONS_ADD_MESSAGE,
  NOTIFICATIONS_CLOSE_MESSAGE,
  NOTIFICATIONS_PROCESS_QUEUE,
} from "redux/actions/notifications.js";

type Notification = {
  key: number;
  text: string;
  type: "success" | "error";
};

export interface NotificationsStateI {
  open: boolean;
  message?: Notification;
  queue: Notification[];
}

export const initialState: NotificationsStateI = {
  open: false,
  message: null,
  queue: [],
};

const notifications = (state = initialState, action): NotificationsStateI => {
  switch (action.type) {
    case NOTIFICATIONS_ADD_MESSAGE: {
      const newMessage = { ...action.payload, key: new Date().getTime() };
      // If we already have a message, just add the new one to the message queue
      if (state.message && state.message.text) {
        const newQueue = [...state.queue];
        newQueue.push(newMessage);
        return {
          ...state,
          queue: newQueue,
        };
      }
      // Otherwise, the payload is the message and we will also open our notifications
      return {
        ...state,
        message: newMessage,
        open: true,
      };
    }
    case NOTIFICATIONS_CLOSE_MESSAGE:
      return {
        ...state,
        open: false,
      };
    case NOTIFICATIONS_PROCESS_QUEUE:
      if (state.queue.length > 0) {
        const newQueue = [...state.queue];
        const newMessage = newQueue.shift();
        return {
          open: true,
          message: newMessage,
          queue: newQueue,
        };
      } else {
        return {
          open: false,
          message: null,
          queue: [],
        };
      }
    default:
      return state;
  }
};

export default notifications;
