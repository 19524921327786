import { useQuery } from "@tanstack/react-query";
import { fetchCompany } from "services/selectedCompany";
import { QUERYKEY_SELECTED_COMPANY } from "./queryKeys";
import { CompanySelectionI } from "types/Company";

function mapCompanyPayload(payload): CompanySelectionI {
  return {
    ...payload,
    label: payload.title || "",
    debtors: payload.debtors.map((d) => ({ ...d, value: d.id, label: d.name })),
  };
}

export function useSelectedCompany(id) {
  return useQuery({
    queryKey: [QUERYKEY_SELECTED_COMPANY, id],
    queryFn: () => fetchCompany(id),
    select: mapCompanyPayload,
    enabled: Boolean(id),
    // Required for image upload to work properly
    refetchOnWindowFocus: false,
  });
}
