import Dashboard from "layouts/Dashboard/Dashboard";
import { Login, Logout, Callback } from "views/Authentication";
import ProtectedRoute from "components/Common/ProtectedRoute";

const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/callback", component: Callback },
  { path: "/", component: ProtectedRoute(Dashboard) },
];

export default indexRoutes;
