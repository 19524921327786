import {
  cardHeader,
  label,
  flexVertAligned,
  paddingSize,
  requiredFields,
} from "assets/jss/globalStyle";

// eslint-disable-next-line
const companyDetailsStyles = (theme, props) => ({
  container: {
    width: "100%",
    display: "flex",
  },
  checkbox: {
    "& svg": {
      fontSize: "32px",
    },
  },
  header: {
    ...cardHeader,
    margin: 0,
  },
  headerWrapper: {
    ...flexVertAligned,
    paddingBottom: paddingSize,
  },
  requiredFields,
  formControlLabel: {
    fontFamily: '"Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif',
    fontWeight: 600,
    fontSize: "0.875rem",
    color: "black",
  },
  formControl: {
    width: "100%",
    marginTop: "8px",
  },
  label,
  column: {
    width: "50%",
  },
  inputContainer: {
    width: "80%",
    height: "100px",
  },
  doubleContainer: {
    width: "100%",
    minHeight: "100px",
    display: "flex",
    // we want to use a custom value here to handle a specific wrapping and not be beholden to material's breakpoints
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      flexWrap: "wrap",
      "& div:nth-of-type(2)": {
        marginTop: "12px",
      },
    },
  },
  status: {
    marginRight: "40px",
  },
  imageUpload: {
    height: "100px",
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "7px",
  },
  modified: {
    fontWeight: 600,
    fontSize: "14px",
    color: "gray",
    "& > span": {
      fontWeight: 900,
    },
    margin: "8px 0",
  },
  authenticationMethods: {
    marginTop: "0px",
  },
  newCompanyAuthenticationMethods: {
    marginTop: "10px",
  },
  authMethodsLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: "black",
    display: "block",
    margin: 0,
    marginTop: "4px",
  },
  authMethodsFooter: {
    fontSize: "10px",
    margin: "0",
  },
  authenticationCheckbox: {
    marginRight: "16px",
  },
});

export default companyDetailsStyles;
