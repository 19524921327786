import {
  USER_STATE_SET_SUCCESS,
  USER_STATE_SET_FAILED,
} from "redux/actions/userState";
import { UserStateStateI } from "types/User";

const initialState: UserStateStateI = { payload: null };

const userState = (state = initialState, action): UserStateStateI => {
  switch (action.type) {
    case USER_STATE_SET_SUCCESS:
      return {
        ...state,
        payload: action.payload,
      };
    case USER_STATE_SET_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userState;
