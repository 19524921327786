import {
  ellipsisOverflow,
  label,
  flexRight,
  flexVertAligned,
  requiredFields,
} from "assets/jss/globalStyle";

// eslint-disable-next-line
const userModalStyle = (props, theme) => ({
  container: {
    padding: "24px",
  },
  checkbox: {
    "& svg": {
      fontSize: "32px",
    },
  },
  ssoLabel: {
    fontFamily: '"Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif',
    fontWeight: 600,
  },
  label,
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "64px",
    "& h2": {
      fontSize: "18px",
      maxWidth: "700px",
      ...ellipsisOverflow,
    },
  },
  loadingContainer: {
    width: "960px",
    height: "645px",
    padding: "16px",
  },
  errorButton: {
    zIndex: 2,
  },
  main: {
    display: "flex",
  },
  isSSOUser: {
    width: "100%",
    marginTop: "16px",
    marginBottom: "16px",
  },
  inputs: {
    flexGrow: 1,
    marginRight: "48px",
  },
  toggles: {
    width: "35%",
    marginRight: "48px",
  },
  rightColumn: {
    width: "20%",
    marginRight: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  roles: {
    maxHeight: "501px",
    overflowX: "auto",
  },
  roleMessage: {
    fontSize: "12px",
    margin: 0,
  },
  rolesLoading: {
    marginTop: "16px",
    height: "40px",
    textAlign: "center",
  },
  flexRight,
  userActionIcon: {
    fontSize: "42px",
  },
  input: {
    width: "100%",
    height: "100px",
  },
  date: {
    fontWeight: 600,
    fontSize: "14px",
    color: "gray",
    "& > span": {
      fontWeight: 900,
    },
    "&:first-of-type": {
      marginTop: "16px",
    },
  },
  flexVertAligned,
  requiredFields,
});

export default userModalStyle;
