import {
  UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { fetchRoles } from "services/roles";
import { createRole, deleteRole, editRole } from "services/roles";
import { QUERYKEY_FEATURES, QUERYKEY_ROLES } from "./queryKeys";
import { RoleI } from "types/Role";

function mapRoles(roles) {
  return roles.map((r) => ({ ...r, label: r.name, value: r.id }));
}

export function useRoles(id: string): UseQueryResult<RoleI[]> {
  return useQuery({
    queryKey: [QUERYKEY_ROLES, id],
    queryFn: () => fetchRoles(id),
    enabled: Boolean(id),
    select: mapRoles,
  });
}

export function useMultiCompanyRoles(companyIds: string[]): UseQueryResult[] {
  const queries = companyIds.map((companyId) => {
    return {
      queryKey: [QUERYKEY_ROLES, companyId],
      queryFn: () => fetchRoles(companyId),
      enabled: companyIds.length > 0,
      select: mapRoles,
    };
  });
  return useQueries({ queries });
}

export function useCreateRole(selectedCompanyID) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createRole,
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_ROLES, selectedCompanyID],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_FEATURES, selectedCompanyID],
        }),
      ]),
  });
}

export function useEditRole(selectedCompanyID) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editRole,
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_ROLES, selectedCompanyID],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_FEATURES, selectedCompanyID],
        }),
      ]),
  });
}

export function useDeleteRole(selectedCompanyID) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteRole,
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_ROLES, selectedCompanyID],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_FEATURES, selectedCompanyID],
        }),
      ]),
  });
}
