import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";

import { tableHeadFont } from "assets/jss/globalStyle";
import { SortOrder } from "types";
import { TableColumnI } from "types/Table";
import { withStyles } from "tss-react/mui";

// eslint-disable-next-line
const styles = (theme, props) => ({
  cell: {
    ...tableHeadFont,
  },
});

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

interface SortableTableHeadI {
  onRequestSort: (
    event: React.ChangeEvent<HTMLInputElement>,
    property: SortOrder
  ) => void;
  orderBy: string;
  order: SortOrder;
  columns: TableColumnI[];
  name?: string;
  classes?: Partial<Record<"cell", string>>;
}

const SortableTableHead = (props: SortableTableHeadI) => {
  const { onRequestSort, order, orderBy, columns, name } = props;
  const classes = withStyles.getClasses(props);
  return (
    <TableHead>
      <TableRow>
        {columns.map(({ id, align, width, label, sortDisabled }) => {
          return (
            <TableCell
              key={id}
              align={align}
              padding="normal"
              sortDirection={orderBy === id ? order : false}
              className={classes.cell}
              style={{ width: width }}
            >
              {sortDisabled ? (
                <TableSortLabel data-aut={`${name}|TableHead|${label}`}>
                  {label}
                </TableSortLabel>
              ) : (
                <Tooltip
                  title="Sort"
                  placement={align === "right" ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === id}
                    direction={order}
                    onClick={createSortHandler(id, onRequestSort)}
                    data-aut={`${name}|TableHead|${label}`}
                  >
                    {label}
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
};

const SortableTableHeadStyled = withStyles(SortableTableHead, styles);

export default SortableTableHeadStyled;
