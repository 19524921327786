import React, { useState, useRef } from "react";
import Downshift from "downshift";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getInternalInputProps,
  itemToString,
  renderInput,
  getSuggestions,
  renderSuggestions,
  AutoCompleteSharedI,
} from "./AutoComplete";

export interface AutoCompleteSingleI extends AutoCompleteSharedI {
  selectedItem?: { label: string };
}

const AutoCompleteSingle = (props: AutoCompleteSingleI) => {
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef(null);

  const {
    autoRenderSuggestions,
    classes,
    disabled,
    errorMessage,
    fixedPositionWidth,
    handleDelete,
    handleQuery,
    isAsync,
    isLoading,
    label,
    dataAut,
    maxResults = 100,
    minChar = 3,
    placeholder = "",
    onChange,
    selectedItem,
    suggestions = [],
  } = props;

  const handleSingleInputChange = (val, handleDelete) => {
    if (handleDelete) handleDelete();
    if (isAsync) {
      const minChars = minChar || 0;
      if (val.length >= minChars) {
        handleQuery(val);
      }
    }
  };

  const handleSingleInputSelect = (selection) => {
    // If the user selects something on a single input, we want the input to lose focus
    inputRef.current.blur();
    onChange(selection);
  };

  return (
    <div
      className={classes.wrapper}
      style={!label ? { minHeight: "45px", height: "auto" } : {}}
    >
      {label && (
        <label htmlFor={label} className={classes.label}>
          {label}
        </label>
      )}
      <div className={classes.root}>
        <Downshift
          defaultHighlightedIndex={0}
          onChange={(selection) => {
            setOpen(false);
            handleSingleInputSelect(selection);
          }}
          selectedItem={selectedItem}
          itemToString={itemToString}
          isOpen={isOpen}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => (
            <div className={classes.container}>
              {renderInput({
                useInputAutoWidth: true,
                fullWidth: true,
                classes,
                disabled,
                ref: inputRef,
                InputProps: getInputProps({
                  inputProps: getInternalInputProps(label, dataAut),
                  onFocus: () => setOpen(true),
                  onBlur: () => {
                    setOpen(false);
                  },
                  id: label,
                  endAdornment: isLoading ? (
                    <CircularProgress size={20} style={{ marginLeft: "8px" }} />
                  ) : undefined,
                  placeholder: placeholder || "",
                  onChange: (e) =>
                    handleSingleInputChange(e.target.value, handleDelete),
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen && (!isAsync || inputValue.length >= minChar) ? (
                  <div
                    style={
                      fixedPositionWidth
                        ? {
                            position: "fixed",
                            width: fixedPositionWidth,
                            zIndex: 2,
                          }
                        : {}
                    }
                  >
                    <Paper
                      className={classes.paper}
                      square
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {renderSuggestions(
                        getSuggestions(
                          inputValue,
                          suggestions,
                          minChar,
                          autoRenderSuggestions,
                          maxResults,
                          selectedItem,
                          false
                        ),
                        selectedItem,
                        getItemProps,
                        highlightedIndex,
                        inputValue,
                        classes,
                        isLoading,
                        minChar
                      )}
                    </Paper>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default AutoCompleteSingle;
