import { backgroundColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const itineraryCardStyle = (theme, props) => ({
  cardWrapper: {
    width: "100%",
    backgroundColor,
    padding: "20px 25px",
    marginBottom: "10px",
  },
  topRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  middleRow: {
    marginTop: "20px",
    width: "100%",
    fontSize: 14,
    "& p": {
      margin: 0,
      lineHeight: "20px",
    },
    "& > :first-child": {
      fontWeight: 600,
    },
  },
  bottomRow: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    fontSize: 14,
  },
  bottomItem: {
    width: "180px",
    "& > :first-child": {
      fontWeight: 600,
    },
    "& p": {
      margin: 0,
      lineHeight: "20px",
    },
  },
});

export default itineraryCardStyle;
