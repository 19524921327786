//@flow

import { PREFIX } from "services/authConstants";
import { handleErrors, toQueryString } from "common/helpers";

const getToken = () => {
  return localStorage.getItem(`${PREFIX}_access_token`);
};

export const request = async (method, url, params, opts, contentType) => {
  const token = getToken();

  if (!token) {
    try {
      handleErrors({ status: 401 });
    } catch (er) {
      // In some cases, our handleErrors can throw an error here instead of redirecting (styleguidist)
      // So we need to handle the error gracefully
      const error = new Error("No Token");
      error.code = 401;
      return Promise.reject(error);
    }
  }

  const merged = {
    method: method || "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...opts,
  };
  if (params != null) {
    merged.body =
      contentType === "multipart/form-data" ? params : JSON.stringify(params);
    merged.headers["Accept"] =
      contentType === "multipart/form-data" ? "*/*" : "application/json";
    if (contentType !== "multipart/form-data")
      merged.headers["Content-Type"] = "application/json";
  }

  return fetch(url, merged);
};

export const get = async (url, params, opts) => {
  if (params) {
    const qs = toQueryString(params);
    if (url.indexOf("?") > 0) url += qs;
    else url += `?${qs}`;
  }

  return request("get", url, undefined, opts);
};

export const deleteRequest = async (url, params, opts, contentType) => {
  return request("delete", url, params, opts, contentType);
};

export const put = async (url, params, opts, contentType) => {
  return request("put", url, params, opts, contentType);
};

export const post = async (url, params, opts, contentType) => {
  return request("post", url, params, opts, contentType);
};
