import { Theme, createStyles } from "@mui/material";
import { errorMessage, label, silverColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const autoCompleteStyles = (theme: Theme, props) =>
  createStyles({
    wrapper: {
      minHeight: "100px",
      position: "relative",
    },
    root: {
      flexGrow: 1,
    },
    container: {
      flexGrow: 1,
      zIndex: 2,
    },
    label,
    errorMessage,
    paper: {
      position: "absolute",
      zIndex: 2,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      maxHeight: "250px",
      overflowY: "scroll",
    },
    chip: {
      margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    },
    inputRoot: {
      flexWrap: "wrap",
      minHeight: "45px",
    },
    inputRootDisabled: {
      flexWrap: "wrap",
      minHeight: "45px",
      backgroundColor: silverColor,
    },
    inputAutoWidth: {
      width: "auto",
      flexGrow: 1,
      padding: "8px 14px",
    },
    inputMinWidth: {
      width: "10px",
      flexGrow: 0,
      padding: "8px 14px",
    },
    divider: {
      height: theme.spacing(2),
    },
    noResults: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

export default autoCompleteStyles;
