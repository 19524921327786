import { createTheme, adaptV4Theme } from "@mui/material/styles";
import {
  flexVertAligned,
  grayColor,
  greenColor,
  hoverBackground,
  layout,
  paddingSize,
  primaryColor,
  redColor,
  silverColor,
} from "./globalStyle";
import { ExtendedTheme } from "types/MUIExtensions";

function themeCreator() {
  return createTheme(
    adaptV4Theme({
      palette: {
        primary: {
          main: primaryColor,
        },
        grayColor,
        greenColor,
        hoverBackground,
        primaryColor,
        redColor,
        silverColor,
      },
      layout: {
        ...layout,
        paddingSize,
        flexVertAligned,
      },
    } as ExtendedTheme)
  );
}

export default themeCreator;
