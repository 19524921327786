import { Fragment, useState } from "react";
import { Button, Modal } from "components";
import Popover from "@mui/material/Popover";
import { validateEmail } from "common/helpers";
import Papa from "papaparse";
import { withStyles } from "tss-react/mui";
import { NotificationObjI } from "types";

// eslint-disable-next-line
const styles = (theme, props) => ({
  container: {
    marginTop: "22px",
    textAlign: "center",
  },
  popover: {
    textAlign: "center",
    padding: "22px",
    maxWidth: "750px",
  },
  requiredText: {
    fontSize: "14px",
  },
  popupText: {
    marginBottom: 0,
  },
});

interface BuildUploadModalI {
  addNotification: (notification: NotificationObjI) => void;
  close: (val?: boolean) => void;
  file?: File;
  isOpen: boolean;
  isTravelGroupUpload?: boolean;
  service: (file: File) => Promise<null>;
  classes?: Partial<
    Record<"container" | "popover" | "requiredText" | "popupText", string>
  >;
}

function BulkUploadModal(props: BuildUploadModalI) {
  const { addNotification, close, file, isOpen, isTravelGroupUpload, service } =
    props;
  const classes = withStyles.getClasses(props);
  const [parsedResults, setParsedResults] = useState<null | {
    validResults: number;
    inValidResultsIndexes: any[];
  }>(null);
  const [popoverAnchor, setPopover] = useState<null | HTMLElement>(null);
  const [saving, setSaving] = useState(false);

  if (!parsedResults && file) {
    Papa.parse(file, {
      complete: isTravelGroupUpload
        ? handleTravelGroupUploadParse
        : handleFileUploadParse,
    });
  }

  function handleClose() {
    setParsedResults(null);
    close();
  }

  function handleFileUploadParse(results: { data: any[] }) {
    let validResults = 0;
    const inValidResultsIndexes = [];
    const rows = results.data;
    for (let i = 1; i < rows.length; i++) {
      if (!Array.isArray(rows[i]) || !rows[i].join("").length) break;
      let isValid = true;
      for (let c = 0; c < 5; c++) {
        if (!rows[i][c] || !rows[i][c].length) {
          isValid = false;
          break;
        } else if (c === 2) {
          const isValidEmail = validateEmail(rows[i][c]);
          if (!isValidEmail) {
            isValid = false;
            break;
          }
        }
      }
      isValid ? validResults++ : inValidResultsIndexes.push(i + 1);
    }
    setParsedResults({ validResults, inValidResultsIndexes });
  }

  function handleTravelGroupUploadParse(results: { data: any[] }) {
    let validResults = 0;
    const inValidResultsIndexes = [];
    const rows = results.data;
    for (let i = 1; i < rows.length; i++) {
      // Excel likes to make an empty row at the end of a csv when it saves, so we ignore validation on that line if that's the case
      if (i === rows.length - 1 && !rows[i].toString()) {
        continue;
      }
      const id = rows[i][0];
      // Check to make sure we have an id and it's not a duplicate
      if (
        !id ||
        !id.length ||
        !id.trim().length ||
        rows.findIndex((r) => r[0] === id) !== i
      ) {
        inValidResultsIndexes.push(i + 1);
      } else {
        validResults++;
      }
    }
    setParsedResults({ validResults, inValidResultsIndexes });
  }

  function openPopover(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setPopover(e.currentTarget);
  }

  function closePopover() {
    setPopover(null);
  }

  function handleSave() {
    setSaving(true);
    service(file)
      .then(() => {
        addNotification({
          text: `Bulk user upload successful.`,
          type: "success",
        });
      })
      .catch((er) => {
        addNotification({
          text: `Bulk user upload failed.`,
          errors: er.customErrors,
          type: "error",
        });
      })
      .finally(() => {
        setParsedResults(null);
        setSaving(false);
        close(true);
      });
  }

  const open = Boolean(popoverAnchor);

  function renderInvalidButton() {
    return (
      <div>
        <Button
          onClick={openPopover}
          label="Show Invalid User Rows"
          isBlue
          data-aut="BulkUpload|ShowInvalidUsers"
        />
        <Popover
          id="bulk-upload-popover"
          open={open}
          anchorEl={popoverAnchor}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popover}>
            {!isTravelGroupUpload && (
              <p className={classes.requiredText}>
                Required fields are Name, UserName, Email Address, DK, and
                Role(s).
              </p>
            )}
            {isTravelGroupUpload ? (
              <p>
                The following rows are missing an id or have a duplicate id.
              </p>
            ) : (
              <p>
                The following rows are missing a required field or are
                improperly formatted.
              </p>
            )}
            <hr />
            <p>{parsedResults.inValidResultsIndexes.join(",")}</p>
          </div>
        </Popover>
      </div>
    );
  }

  function renderUploadButton() {
    return (
      <Button
        onClick={handleSave}
        label="Upload"
        loading={saving}
        disabled={saving}
        data-aut="BulkUpload|UploadButton"
      />
    );
  }

  return (
    <Modal close={handleClose} isOpen={isOpen} header="User Bulk Upload">
      <div className={classes.container}>
        {!parsedResults ? (
          <p>Parsing CSV...</p>
        ) : (
          <Fragment>
            <p className={classes.popupText}>
              {parsedResults.validResults} valid user entries found.
            </p>
            {parsedResults.inValidResultsIndexes.length ? (
              <p className={classes.popupText}>
                Found {parsedResults.inValidResultsIndexes.length}{" "}
                {parsedResults.inValidResultsIndexes.length === 1
                  ? "row"
                  : "rows"}{" "}
                missing required fields.
              </p>
            ) : null}
            <div>
              {parsedResults.inValidResultsIndexes.length
                ? renderInvalidButton()
                : renderUploadButton()}
            </div>
          </Fragment>
        )}
      </div>
    </Modal>
  );
}

const BulkUploadModalStyled = withStyles(BulkUploadModal, styles);

export default BulkUploadModalStyled;
