import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import { AutoComplete, Dropdown, HtmlEditor, Input } from "components";
import { Button, IconButton, Checkbox, Tooltip, Chip } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import timezones from "common/data/timezones.js";
import { copyToClipboard } from "common/helpers";
import uuidv4 from "uuid/v4";
import styles from "components/Sidebar/smartInputStyle";
import UploadInput from "../UploadInput/UploadInput";
import ColorPicker from "../ColorPicker/ColorPicker";

/*
  Helper Functions
*/

function handleCopy(text, addNotification) {
  copyToClipboard(text)
    .then(() => {
      addNotification({
        text: `Value saved to clipboard.`,
        type: "success",
      });
    })
    .catch(() => {
      addNotification({
        text: `Error saving to clipboard.`,
        type: "error",
      });
    });
}

function multiAutoAdd(setValue, selectedValue, value) {
  const newValue = [...selectedValue];
  newValue.push(value);
  setValue(newValue);
}

function getPrimaryLabel({ templateParameter }) {
  return `${templateParameter.label}${templateParameter.required ? " *" : ""}`;
}

function renderSecondaryLabel({ label, classes }) {
  if (!label) return;
  return <p className={classes.secondaryText}>{label}</p>;
}

/*
  Default Value Render Functions
*/

function renderTextDefault(
  defaultValue,
  classes,
  addNotification,
  addTopMargin
) {
  if (!defaultValue) return;
  const isLong = defaultValue.length > 70;
  const style = {};
  if (isLong) style.marginLeft = 0;
  if (addTopMargin) style.marginTop = "-5px";
  return (
    <p className={classes.secondaryText} style={style}>
      {isLong ? (
        <Fragment>
          <Tooltip title="Copy To Clipboard" enterDelay={300}>
            <IconButton
              className={classes.icon}
              onClick={() => handleCopy(defaultValue, addNotification)}
              aria-label="Copy text"
              size="small"
            >
              <GetApp fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.toolTip }}
            title={defaultValue}
            enterDelay={300}
            placement="bottom-start"
          >
            <span>Default Value: {defaultValue}</span>
          </Tooltip>
        </Fragment>
      ) : (
        <span>Default Value: {defaultValue}</span>
      )}
    </p>
  );
}

function renderMultiDefault({ defaultValues, classes, addTopMargin }) {
  if (!defaultValues || !defaultValues.length) return;
  return (
    <div
      className={classes.defaultAuto}
      style={addTopMargin ? { marginTop: "5px" } : {}}
    >
      Default Value:{" "}
      <span>
        {defaultValues.map((item) => (
          <Chip
            key={item.label || item}
            tabIndex={-1}
            label={item.label || item}
            className={classes.chip}
            onDelete={() => {}}
          />
        ))}
      </span>
    </div>
  );
}

/*
  Text Inputs
*/

function IntegratedInput(props) {
  if (props.templateParameter.allowMultiples) return MultiInput(props);
  else return SingleInput(props);
}

function MultiInput({
  classes,
  templateParameter,
  paramValues,
  secondaryLabel,
  setValue,
}) {
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  else selectedValue = [];
  const [inputValue, setInputValue] = useState("");
  const defaultNode = renderMultiDefault({
    defaultValues: templateParameter.defaultValue,
    classes,
  });

  return (
    <div
      className={classes.fullWidth}
      style={defaultNode || secondaryLabel ? { marginBottom: "24px" } : {}}
    >
      <Input
        label={getPrimaryLabel({ templateParameter })}
        value={inputValue}
        onChange={setInputValue}
        selectedItems={selectedValue}
        setValues={setValue}
        isMultiple
      />
      {defaultNode}
      {secondaryLabel}
    </div>
  );
}

function ColorInput({ classes, templateParameter, paramValues, setValue }) {
  const selectedValue = paramValues[templateParameter.key];
  return (
    <div className={classes.partialWidth}>
      <div style={{ display: "flex" }}>
        <ColorPicker
          value={selectedValue}
          onChange={setValue}
          defaultValue={templateParameter.defaultValue}
          inputLabel={getPrimaryLabel({ templateParameter })}
        />
      </div>
    </div>
  );
}

function SingleInput({
  addNotification,
  classes,
  templateParameter,
  paramValues,
  secondaryLabel,
  setValue,
}) {
  const isGUID =
    templateParameter.valueGenerated &&
    templateParameter.valueGeneratorTypeName === "guid";
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  else selectedValue = "";
  return (
    <div className={classes.partialWidth}>
      <Input
        label={getPrimaryLabel({ templateParameter })}
        value={selectedValue}
        onChange={setValue}
        disabled={isGUID || templateParameter.typeName === "Readonly"}
      />
      {isGUID ? (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setValue(uuidv4())}
        >
          Regenerate
        </Button>
      ) : (
        renderTextDefault(
          templateParameter.defaultValue,
          classes,
          addNotification,
          true
        )
      )}
      {secondaryLabel}
    </div>
  );
}

/*
  Dropdown selects
*/

function IntegratedDropdown(props) {
  if (props.templateParameter.allowMultiples) return MultiDropdown(props);
  else return SingleDropdown(props);
}

function SingleDropdown({
  addNotification,
  classes,
  options,
  paramValues,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  const selectedValue = paramValues[templateParameter.key];

  let defaultOption;
  if (templateParameter.defaultValue) {
    defaultOption = options.find(
      (o) => o.value.toString() === templateParameter.defaultValue.toString()
    );
  }

  return (
    <div className={classes.partialWidth}>
      <Dropdown
        displayEmpty
        id={templateParameter.label}
        handleDelete={(val) => setValue(selectedValue.filter((v) => v !== val))}
        label={getPrimaryLabel({ templateParameter })}
        onChange={setValue}
        selectedItems={selectedValue || ""}
        items={options}
      />
      {defaultOption &&
        defaultOption.label &&
        renderTextDefault(defaultOption.label, classes, addNotification)}
      {secondaryLabel}
    </div>
  );
}

function MultiDropdown({
  classes,
  options,
  paramValues,
  removeValue,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  let selectedValue = [];
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  let defaultNode;
  if (Array.isArray(templateParameter.defaultValue)) {
    const defaultValues = [];
    templateParameter.defaultValue.forEach((v) => {
      // eslint-disable-next-line
      const option = options.find((o) => o.value == v);
      if (option) defaultValues.push(option);
    });
    defaultNode = renderMultiDefault({
      defaultValues,
      classes,
      addTopMargin: true,
    });
  }
  const isDisabled = Boolean(!options || !options.length);
  return (
    <div
      className={classes.fullWidth}
      style={defaultNode ? { marginBottom: "24px" } : {}}
    >
      <Dropdown
        multiple
        id={templateParameter.label}
        handleDelete={removeValue}
        label={getPrimaryLabel({ templateParameter })}
        onChange={setValue}
        selectedItems={selectedValue}
        items={options}
        disabled={isDisabled}
        displayEmpty={isDisabled}
        placeholder={"No Options Provided"}
      />
      {defaultNode}
      {secondaryLabel}
    </div>
  );
}

/*
  Autocomplete search selects
*/

function IntegratedAutoComplete(props) {
  if (props.templateParameter.allowMultiples) return MultiAutoComplete(props);
  else return SingleAutoComplete(props);
}

function SingleAutoComplete({
  addNotification,
  classes,
  options,
  paramValues,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  const selectedValue = paramValues[templateParameter.key];
  let defaultNode;
  if (templateParameter.defaultValue) {
    const defaultValue = options.find(
      // eslint-disable-next-line
      (o) => o.value == templateParameter.defaultValue
    );
    defaultNode = renderTextDefault(
      defaultValue.label,
      classes,
      addNotification
    );
  }

  return (
    <div
      className={classes.partialWidth}
      style={defaultNode ? { marginBottom: "24px" } : {}}
    >
      <AutoComplete
        label={getPrimaryLabel({ templateParameter })}
        handleDelete={() => setValue(null)}
        onChange={setValue}
        selectedItem={selectedValue}
        suggestions={options}
        fixedPositionWidth="650px"
        autoRenderSuggestions
      />
      {defaultNode}
      {secondaryLabel}
    </div>
  );
}

function MultiAutoComplete({
  classes,
  options,
  paramValues,
  removeValue,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  let selectedValue = [];
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  let defaultNode;
  if (Array.isArray(templateParameter.defaultValue)) {
    const defaultValues = [];
    templateParameter.defaultValue.forEach((v) => {
      // eslint-disable-next-line
      const option = options.find((o) => o.value == v);
      if (option) defaultValues.push(option);
    });
    defaultNode = renderMultiDefault({
      defaultValues,
      classes,
      addTopMargin: true,
    });
  }
  return (
    <div
      className={classes.fullWidth}
      style={defaultNode ? { marginBottom: "24px" } : {}}
    >
      <AutoComplete
        label={getPrimaryLabel({ templateParameter })}
        autoRenderSuggestions
        isMulti
        handleDelete={removeValue}
        onChange={(val) => multiAutoAdd(setValue, selectedValue, val)}
        selectedItem={selectedValue}
        suggestions={options}
        maxResults={100}
        fixedPositionWidth="800px"
      />
      {defaultNode}
      {secondaryLabel}
    </div>
  );
}

// Leaving radio/checkbox group UI options to revisit later

// function IntegratedRadioGroup({
//   classes,
//   options,
//   paramValues,
//   templateParameter,
//   setValue,
// }) {
//   const handleChange = (e) => {
//     const optionValue = e.target.value;
//     const optionObj = options.find((o) => o.value === optionValue);
//     setValue(optionObj);
//   };
//   const radioValue =
//     paramValues[templateParameter.key] &&
//     paramValues[templateParameter.key].value;

//   useEffect(() => {
//     if (radioValue) return;
//     if (templateParameter.required && templateParameter.defaultValue != null) {
//       const val = templateParameter.options.find(
//         (option) => option.value == templateParameter.defaultValue
//       );
//       if (val) {
//         setValue(val);
//       }
//     }
//   }, []);

//   const getRadioValue = () => {
//     return radioValue || "";
//   };

//   return (
//     <div className={classes.fullWidth}>
//       <FormControl>
//         <FormLabel className={classes.label}>
//           {getPrimaryLabel({ templateParameter })}
//         </FormLabel>
//         <RadioGroup
//           aria-label={`select ${templateParameter.label} radio group`}
//           name={templateParameter.label}
//           value={getRadioValue()}
//           row
//           onChange={handleChange}
//         >
//           {options.map((option) => (
//             <FormControlLabel
//               key={option.value}
//               value={option.value}
//               control={<Radio color="primary" />}
//               label={option.label}
//               classes={{
//                 label: classes.blackText,
//               }}
//             />
//           ))}
//         </RadioGroup>
//       </FormControl>
//     </div>
//   );
// }

// function IntegratedCheckboxGroup({
//   classes,
//   options,
//   paramValues,
//   templateParameter,
//   setValue,
// }) {
//   const templateKey = templateParameter.key;
//   const params = paramValues[templateKey] ? [...paramValues[templateKey]] : [];

//   const isChecked = (option) => {
//     return Boolean(params.find((item) => item.value === option.value));
//   };
//   const handleChecked = (e) => {
//     const val = e.target.value;
//     const option = options.find((o) => o.value === val);
//     const itemIdx = params.findIndex((param) => param.value === val);
//     const isUnselecting = isChecked(option);

//     isUnselecting ? params.splice(itemIdx, 1) : params.push(option);
//     setValue(params);
//   };

//   return (
//     <div className={classes.fullWidth}>
//       <FormControl>
//         <FormLabel className={classes.label}>
//           {getPrimaryLabel({ templateParameter })}
//         </FormLabel>
//         {options.map((option) => (
//           <FormControlLabel
//             key={option.value}
//             value={option.value}
//             label={option.label}
//             control={
//               <Checkbox
//                 type="checkbox"
//                 color="primary"
//                 name={option.label}
//                 value={option.value}
//                 checked={isChecked(option)}
//                 onChange={handleChecked}
//               />
//             }
//             classes={{
//               label: classes.blackText,
//             }}
//           />
//         ))}
//       </FormControl>
//     </div>
//   );
// }

function IntegratedUpload({
  classes,
  templateParameter,
  paramValues,
  secondaryLabel,
  setValue,
}) {
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  return (
    <div className={classes.fullWidth}>
      <UploadInput
        label={getPrimaryLabel({ templateParameter })}
        value={selectedValue}
        onChange={setValue}
      />
      {secondaryLabel}
    </div>
  );
}

//

/*
  Boolean Toggles
*/
function CheckboxToggle({ classes, paramValues, setValue, templateParameter }) {
  const active =
    paramValues[templateParameter.key] == null
      ? Boolean(templateParameter.defaultValue)
      : paramValues[templateParameter.key];
  return (
    <div>
      <Checkbox
        classes={{ root: classes.checkbox }}
        checked={active}
        color="primary"
        onChange={() => setValue(!active)}
        value={templateParameter.label}
        inputProps={{ "aria-label": templateParameter.label }}
      />
      <label className={classes.label}>{templateParameter.label}</label>
    </div>
  );
}

// Leaving this here in case we ever want it again
// const BooleanToggle = (props) => {
//   const { classes, paramValues, templateParameter } = props;
//   let active = false;
//   let statusProperties = [
//     { name: 'True', value: false }, { name: 'False', value: false }
//   ];
//   if (paramValues[templateParameter.key] || templateParameter.defaultValue) active = true;
//   if (active) {
//     statusProperties[0].value = true;
//   } else {
//     statusProperties[1].value = true;
//   }
//   return (
//     <div className={classes.partialWidth}>
//       <label className={classes.label}>{templateParameter.label}</label>
//       <MultiToggle
//         value={active ? 0 : 1}
//         properties={statusProperties}
//         handleClick={this.statusClick}
//         label={templateParameter.label}
//       />
//     </div>
//   )
// };

function HtmlInput({
  addNotification,
  classes,
  paramValues,
  setValue,
  templateParameter,
}) {
  function handleTemplateUpdate(evt) {
    const newValue = evt.editor.getData();
    if (typeof newValue === "string") {
      // Due to the fact that we are not escaping events, ckeditor is nice enough to allow enter keys to show up in the template. We remove them here.
      const sanitizedValue = newValue.replace(/↵/g, "");
      setValue(sanitizedValue);
    }
  }
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];

  return (
    <div
      className={classes.fullWidth}
      style={{ marginBottom: "36px", minHeight: "340px" }}
    >
      <label className={classes.label}>{templateParameter.label}</label>
      <HtmlEditor content={selectedValue} handleChange={handleTemplateUpdate} />
      {templateParameter.defaultValue
        ? renderTextDefault(
            templateParameter.defaultValue,
            classes,
            addNotification
          )
        : null}
    </div>
  );
}

function SmartInput(props) {
  const { templateParameter } = props;
  const classes = withStyles.getClasses(props);
  const copiedProps = { ...props };
  copiedProps.secondaryLabel = renderSecondaryLabel({
    label: templateParameter.secondaryLabel,
    classes,
  });
  if (templateParameter.typeName === "Color") return ColorInput(copiedProps);
  if (templateParameter.typeName === "Html") return HtmlInput(copiedProps);
  if (templateParameter.typeName === "File")
    return IntegratedUpload(copiedProps);
  if (templateParameter.typeName === "Boolean")
    return CheckboxToggle(copiedProps);
  let options;
  if (templateParameter.options) options = templateParameter.options;
  else if (templateParameter.typeName === "Timezone") options = timezones;
  if (!options) return IntegratedInput(copiedProps);
  // else if (options.length < 4)
  //   return templateParameter.allowMultiples
  //     ? IntegratedCheckboxGroup({ ...copiedProps, options })
  //     : IntegratedRadioGroup({ ...copiedProps, options });
  else if (options.length < 15)
    return IntegratedDropdown({ ...copiedProps, options });
  else return IntegratedAutoComplete({ ...copiedProps, options });
}

SmartInput.propTypes = {
  addNotification: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  paramValues: PropTypes.object.isRequired,
  removeValue: PropTypes.func,
  templateParameter: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
};

const SmartInputStyled = withStyles(SmartInput, styles);

export default SmartInputStyled;
