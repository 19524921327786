import React, { Suspense } from "react";
import { Loading } from "components";

const Features = React.lazy(() => import("./Features"));

function FeaturesView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Features" />}>
      <Features {...props} />
    </Suspense>
  );
}

export default FeaturesView;
