import Dialog from "@mui/material/Dialog";
import cx from "classnames";
import { flexVertAligned, requiredFields } from "assets/jss/globalStyle";
import { withStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import { Clear } from "@mui/icons-material";

// eslint-disable-next-line
const styles = (theme, props) => ({
  container: {
    padding: "24px",
  },
  noPaddingContainer: {
    padding: "24px 0",
  },
  overflowY: {
    overflowY: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "64px",
    "& h2": {
      fontSize: "18px",
    },
  },
  flexVertAligned,
  requiredFields,
});

interface ModalI {
  children: React.ReactNode;
  close?: React.MouseEventHandler<HTMLButtonElement>;
  isOpen?: boolean;
  header?: React.ReactNode;
  hasRequiredFields?: boolean;
  disableEnforceFocus?: boolean;
  overflowY?: boolean;
  noPadding?: boolean;
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl";
  classes?: Partial<
    Record<
      | "container"
      | "noPaddingContainer"
      | "overflowY"
      | "header"
      | "flexVertAligned"
      | "requiredFields",
      string
    >
  >;
}

const Modal = (props: ModalI) => {
  const {
    children,
    close,
    isOpen,
    header,
    hasRequiredFields,
    disableEnforceFocus = false,
    overflowY = true,
    noPadding,
    maxWidth = "md",
  } = props;
  const classes = withStyles.getClasses(props);
  const containerClass = cx(
    noPadding ? classes.noPaddingContainer : classes.container,
    overflowY ? classes.overflowY : ""
  );
  return (
    <Dialog
      disableEnforceFocus={disableEnforceFocus}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      PaperProps={{ style: { overflowY: "visible" } }}
    >
      <div className={containerClass}>
        {(close || header) && (
          <div
            className={classes.header}
            style={noPadding ? { padding: "0 24px" } : {}}
          >
            {header && (
              <div className={classes.flexVertAligned}>
                <h2>{header}</h2>
                {hasRequiredFields && (
                  <span className={classes.requiredFields}>
                    * Required Fields
                  </span>
                )}
              </div>
            )}
            {close && (
              <IconButton onClick={close} data-aut="Modal|Close" size="large">
                <Clear fontSize="large" />
              </IconButton>
            )}
          </div>
        )}
        {children}
      </div>
    </Dialog>
  );
};

export default withStyles(Modal, styles);
