// ##############################
// // // Footer styles
// #############################

import {
  contentWidth,
  defaultFont,
  layout,
  container,
  primaryColor,
} from "assets/jss/globalStyle";

const footerText = {
  fontSize: "16px",
  fontWeight: 700,
  marginRight: "16px",
};

// eslint-disable-next-line
const footerStyle = (theme, props) => ({
  footer: {
    height: layout.footer.height,
    width: "100%",
    ...defaultFont,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "50px",
    marginRight: "50px",
  },
  container: {
    ...container,
    width: "100%",
    maxWidth: contentWidth,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  contact: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  footerText,
  footerLink: {
    ...footerText,
    color: primaryColor,
  },
  footerCopy: {
    fontSize: "12px",
  },
});
export default footerStyle;
