export const AIRPORT_CODES = "AIRPORT_CODES";
export const APPS = "APPS";
export const APPLICATION_MESSAGES = "APPLICATION_MESSAGES";
export const APPROVAL_CONFIG = "APPROVAL_CONFIG";
export const APPROVAL_EMAIL = "APPROVAL_EMAIL";
export const APPROVAL_GROUPS = "APPROVAL_GROUPS";
export const CITIES = "CITIES";
export const CITYSEARCH = "CITYSEARCH";
export const COUNTRIES = "COUNTRIES";
export const COMPANY = "COMPANY";
export const COMPANY_DEBTORS = "COMPANY_DEBTORS";
export const COMPANIES = "COMPANIES";
export const COMPANY_FEATURE_TEMPLATES = "COMPANY_FEATURE_TEMPLATES";
export const COMPANY_ROLE_FEATURE = "COMPANY_ROLE_FEATURE";
export const COMPANY_SEARCH = "COMPANY_SEARCH";
export const COMPANY_SEND_WELCOME_EMAILS = "COMPANY_SEND_WELCOME_EMAILS";
export const COMPANY_TRAVELERS = "COMPANY_TRAVELERS";
export const CURRENCY_SYMBOLS = "CURRENCY_SYMBOLS";
export const DATE_FORMATS = "DATE_FORMATS";
export const DEBTOR_TRAVELERS = "DEBTOR_TRAVELERS";
export const DEBTORS = "DEBTORS";
export const EMAIL_TEMPLATES = "EMAIL_TEMPLATES";
export const FEATURES = "FEATURES";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const HOTEL_CITIES = "HOTEL_CITIES";
export const IMPERSONATION_LOGS = "IMPERSONATION_LOGS";
export const ITINERARIES = "ITINERARIES";
export const MESSAGE_LOG_TEMPLATE_CODES = "MESSAGE_LOG_TEMPLATE_CODES";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const PCCS = "PCCS";
export const PNRS = "PNRS";
export const MESSAGE_LOGS = "MESSAGE_LOGS";
export const ROLES = "ROLES";
export const ROLE_TYPES = "ROLE_TYPES";
export const ROLES_ADMIN = "ROLES_ADMIN";
export const SELECTED_COMPANY = "SELECTED_COMPANY";
export const TIMEZONES = "TIMEZONES";
export const TRAVEL_GROUPS = "TRAVEL_GROUPS";
export const TRAVEL_POLICIES = "TRAVEL_POLICIES";
export const USERS = "USERS";
export const USER_PASSWORD_RESET_EMAIL_TRIGGER =
  "USER_PASSWORD_RESET_EMAIL_TRIGGER";
export const USER_SEARCH = "USER_SEARCH";
export const USER_WELCOME_EMAIL_TRIGGER = "USER_WELCOME_EMAIL_TRIGGER";
export const USER_REINSTATE = "USER_REINSTATE";
export const VENDORS = "VENDORS";
export const SLATES = "SLATES";
