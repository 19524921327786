import { withStyles } from "tss-react/mui";
import { ExpandableCard, Loading } from "components";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Clear } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import {
  cardTitle,
  hoverBackground,
  tableHeadFont,
} from "assets/jss/globalStyle";
import { RoleI } from "types/Role";
import { useRoleTypes } from "hooks/roleTypes";
import { useApps } from "hooks/apps";

// eslint-disable-next-line
const styles = (theme, props) => ({
  cardTitle: {
    ...cardTitle,
  },
  iconButton: {
    padding: "6px",
    color: "gray",
  },
  buttonCell: {
    width: "75px",
  },
  row: {
    "&:hover": {
      backgroundColor: hoverBackground,
    },
    cursor: "pointer",
  },
  tableHeadFont: {
    ...tableHeadFont,
    color: "black",
  },
  noRoles: {
    paddingLeft: "16px",
    fontSize: "13px",
  },
  centerAlignCell: {
    textAlign: "center",
  },
  applicationCell: {
    textAlign: "right",
    width: "200px",
  },
});

interface CompanyRoleConfigurationI {
  editRole: (role: RoleI) => void;
  enableRole: (role: RoleI, active: boolean) => void;
  handleRolesExpand: () => void;
  loading?: boolean;
  roles: RoleI[];
  rolesExpanded: boolean;
  setRoleToDelete: (role: RoleI) => void;
  classes?: Partial<
    Record<
      | "cardTitle"
      | "iconButton"
      | "buttonCell"
      | "row"
      | "tableHeadFont"
      | "noRoles"
      | "centerAlignCell"
      | "applicationCell",
      string
    >
  >;
}

const CompanyRoleConfiguration = (props: CompanyRoleConfigurationI) => {
  const {
    editRole,
    enableRole,
    handleRolesExpand,
    loading,
    roles,
    rolesExpanded,
    setRoleToDelete,
  } = props;
  const classes = withStyles.getClasses(props);

  const roleTypes = useRoleTypes();
  const apps = useApps();
  const cardLabel = "CUSTOM ROLE CONFIGURATION";

  function renderApp(role) {
    if (role.applicationID) {
      if (apps && apps.data && apps.data.length) {
        const app = apps.data.find((a) => a.id === role.applicationID);
        if (app) return app.label;
      }
    }
  }

  function renderRoleType(role) {
    if (roleTypes.data?.length) {
      const foundType = roleTypes.data.find((rt) => rt.id === role.roleTypeID);
      if (foundType) return foundType.name;
    }
  }

  function renderBody() {
    if (loading) return renderLoading();
    if (!roles || !roles.length) return renderNoRoles();
    else return renderTable();
  }

  function renderLoading() {
    return <Loading active loadingText="Loading Roles." addContainer />;
  }

  function renderNoRoles() {
    return <p className={classes.noRoles}>This company has no custom roles.</p>;
  }

  function renderTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadFont}>Name</TableCell>
            <TableCell className={classes.applicationCell}>Role Type</TableCell>
            <TableCell className={classes.applicationCell}>
              Application
            </TableCell>
            <TableCell className={classes.centerAlignCell}>Active</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles
            .filter((r) => !r.isGlobal && r.code !== "CS")
            .map((r) => (
              <TableRow
                key={r.id}
                className={classes.row}
                onClick={() => editRole(r)}
              >
                <TableCell>{r.name}</TableCell>
                <TableCell className={classes.applicationCell}>
                  {renderRoleType(r)}
                </TableCell>
                <TableCell className={classes.applicationCell}>
                  {renderApp(r)}
                </TableCell>
                <TableCell className={classes.buttonCell}>
                  <Tooltip
                    title={r.active ? "Disable Role" : "Enable Role"}
                    placement={"top"}
                    enterDelay={150}
                  >
                    <Switch
                      checked={r.active}
                      onClick={(e) => {
                        e.stopPropagation();
                        enableRole(r, !r.active);
                      }}
                      value={r.active}
                      color="primary"
                      inputProps={{ "aria-label": `${r.name} Active Toggle` }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.buttonCell}>
                  <Tooltip
                    title="Delete role"
                    placement={"top"}
                    enterDelay={150}
                  >
                    <IconButton
                      className={classes.iconButton}
                      aria-label="Delete role"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRoleToDelete(r);
                      }}
                      size="large"
                    >
                      <Clear />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <ExpandableCard
      expanded={rolesExpanded}
      header={cardLabel}
      handleClick={handleRolesExpand}
      ariaLabel={`Expandable travel policy: ${cardLabel}`}
      dataAut={cardLabel}
      noPadding
    >
      {renderBody()}
    </ExpandableCard>
  );
};

export default withStyles(CompanyRoleConfiguration, styles);
