import React, { Suspense } from "react";
import { Loading } from "components";

const Approve = React.lazy(() => import("./Approve"));

function ApproveView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Approve" />}>
      <Approve {...props} />
    </Suspense>
  );
}

export default ApproveView;
