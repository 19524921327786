import { Fragment, useCallback, useEffect, useRef } from "react";
import { withStyles } from "tss-react/mui";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  AutoComplete,
  Button,
  ImageUpload,
  Input,
  Loading,
  Modal,
  MultiToggle,
} from "components";

import CompanyDebtorConfig from "./CompanyDebtorConfig";
import CompanyDebtorModal from "./CompanyDebtorModal";
import { formatDate, validateURL } from "common/helpers";
import { addNotification } from "redux/actions/notifications";
import styles from "components/CompanyDetails/companyDetailsStyle";
import { useSafeSetState } from "hooks/useState";
import ImageCropper from "components/ImageCropper/ImageCropper";
import { NotificationObjI } from "types";
import { CompanySelectionI, DebtorI } from "types/Company";
import { UserStateI } from "types/User";
import { useAddDebtorToCompany } from "hooks/debtors";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useCompanies } from "hooks/companies";

interface CompanyDetailsPropsI {
  addNotification: (notification: NotificationObjI) => void;
  close?: () => void;
  onSave: (company: CompanyDetailsStateI) => void;
  newCompany?: boolean;
  saving?: boolean;
  selectedCompanyID: string;
  userState: UserStateI;
  classes?: Partial<
    Record<
      | "container"
      | "checkbox"
      | "header"
      | "headerWrapper"
      | "requiredFields"
      | "formControlLabel"
      | "formControl"
      | "label"
      | "column"
      | "inputContainer"
      | "doubleContainer"
      | "status"
      | "imageUpload"
      | "modified"
      | "authenticationMethods"
      | "newCompanyAuthenticationMethods"
      | "authMethodsLabel"
      | "authMethodsFooter"
      | "authenticationCheckbox",
      string
    >
  >;
}

export interface CompanyDetailsStateI {
  active: boolean;
  biDebtorCode?: string;
  clientSsoCode?: string;
  creatingDebtor: boolean;
  croppingImage: boolean;
  defaultDebtorID?: string;
  externalAuth0EnterpriseConnection?: string;
  id?: string;
  identifier?: string;
  isCloning: boolean;
  selectedCompanyClone?: CompanySelectionI;
  modifiedBy?: string;
  modifiedOn?: string;
  title: string;
  logoFile?: File;
  logoFileObjectURL?: string;
  logoUrl?: string;
  isSso: boolean;
  selectedDebtor?: DebtorI;
  sso_HardLogout: boolean;
  sso_AuthenticateByEmail: boolean;
  sso_AuthenticateByEmployeeID: boolean;
  sso_LogoutUrl?: string;
  sso_LogoutUrlError: boolean;
  welcomeEmailAutotriggered?: boolean;
  canImpersonate: boolean;
}

const CompanyDetails = (props: CompanyDetailsPropsI) => {
  const {
    addNotification,
    close,
    onSave,
    newCompany,
    saving,
    selectedCompanyID,
    userState,
  } = props;
  const classes = withStyles.getClasses(props);

  const selectedCompany = useSelectedCompany(selectedCompanyID);
  const initialState: CompanyDetailsStateI = {
    active: checkIsActive(selectedCompany?.data),
    clientSsoCode: "",
    creatingDebtor: false,
    croppingImage: false,
    externalAuth0EnterpriseConnection: "",
    id: "",
    identifier: "",
    isCloning: false,
    selectedCompanyClone: null,
    modifiedBy: null,
    modifiedOn: null,
    title: "",
    logoFile: null,
    logoFileObjectURL: null,
    logoUrl: null,
    isSso: false,
    selectedDebtor: null,
    sso_HardLogout: hasHardLogout(selectedCompany?.data),
    sso_AuthenticateByEmail: canAuthenticateByEmail(selectedCompany?.data),
    sso_AuthenticateByEmployeeID: canAuthenticateByEmployeeID(
      selectedCompany?.data
    ),
    sso_LogoutUrl: "",
    sso_LogoutUrlError: false,
    canImpersonate: true,
  };
  const [state, safeSetState] = useSafeSetState(initialState);

  const {
    active,
    clientSsoCode,
    creatingDebtor,
    croppingImage,
    externalAuth0EnterpriseConnection,
    id,
    isCloning,
    modifiedBy,
    modifiedOn,
    title,
    logoFile,
    logoFileObjectURL,
    logoUrl,
    isSso,
    selectedCompanyClone,
    selectedDebtor,
    sso_HardLogout,
    sso_AuthenticateByEmail,
    sso_AuthenticateByEmployeeID,
    sso_LogoutUrl,
    sso_LogoutUrlError,
    canImpersonate,
  } = state;

  const addDebtorToCompany = useAddDebtorToCompany(selectedCompanyID);
  const companies = useCompanies();

  const curCompany = useRef(null);

  const statusProperties = [
    { name: "Active", value: active },
    { name: "Inactive", value: !active },
  ];

  const handleSetCompany = useCallback(() => {
    curCompany.current = selectedCompany.data;
    safeSetState({
      ...selectedCompany.data,
    });
  }, [selectedCompany.data, safeSetState]);

  useEffect(() => {
    if (selectedCompany.data !== curCompany.current) {
      handleSetCompany();
    }
  }, [selectedCompany, handleSetCompany]);

  function canAuthenticateByEmail(selectedCompany) {
    return selectedCompany?.data?.sso_AuthenticateByEmail === false
      ? false
      : true;
  }

  function canAuthenticateByEmployeeID(selectedCompany) {
    return selectedCompany?.data?.sso_AuthenticateByEmployeeID === true
      ? true
      : false;
  }

  function closeModal() {
    safeSetState({ selectedDebtor: null, creatingDebtor: false });
  }

  function handleAddDebtorToCompany(debtorId) {
    addDebtorToCompany.mutate({ debtorId, companyId: id });
  }

  function handleSSOLogutUrlInputBlur() {
    if (!validateURL(sso_LogoutUrl)) {
      safeSetState({ sso_LogoutUrlError: true });
    }
  }

  function hasHardLogout(selectedCompany) {
    return Boolean(selectedCompany?.payload?.sso_HardLogout);
  }

  function checkIsActive(selectedCompany) {
    return selectedCompany?.payload?.active === false ? false : true;
  }

  function canSave() {
    if (isCloning && !selectedCompanyClone) return false;
    if (!title || !title.length) return false;
    if (isSso) {
      if (
        sso_LogoutUrlError ||
        (sso_LogoutUrl?.length && !validateURL(sso_LogoutUrl))
      )
        return false;
      if (!clientSsoCode || !clientSsoCode.length) return false;
      if (
        !externalAuth0EnterpriseConnection ||
        !externalAuth0EnterpriseConnection.length
      )
        return false;
      if (!sso_AuthenticateByEmail && !sso_AuthenticateByEmployeeID)
        return false;
    }
    return true;
  }

  function setAuth0(externalAuth0EnterpriseConnection) {
    safeSetState({ externalAuth0EnterpriseConnection });
  }

  function toggleImageCrop() {
    safeSetState({ croppingImage: !croppingImage });
  }

  function selectDebtor(selectedDebtor) {
    safeSetState({ selectedDebtor });
  }

  function setCreatingDebtor() {
    safeSetState({ creatingDebtor: true });
  }

  function setHardLogout() {
    safeSetState({ sso_HardLogout: !sso_HardLogout });
  }

  function setLogoFile(logoFile) {
    if (logoFile)
      safeSetState({
        croppingImage: false,
        logoFile,
        logoFileObjectURL: URL.createObjectURL(logoFile),
      });
    else
      safeSetState({
        croppingImage: false,
        logoFile: null,
        logoFileObjectURL: null,
        logoUrl: null,
      });
  }

  function setIsCloning() {
    safeSetState({ isCloning: !isCloning });
  }

  function setCompanyToClone(selectedCompanyClone) {
    safeSetState({ selectedCompanyClone });
  }

  function setIsSso() {
    safeSetState({ isSso: !isSso });
  }

  function setSsoAuthenticationEmail() {
    safeSetState({
      sso_AuthenticateByEmail: !sso_AuthenticateByEmail,
    });
  }

  function setSsoAuthenticationEmployeeID() {
    safeSetState({
      sso_AuthenticateByEmployeeID: !sso_AuthenticateByEmployeeID,
    });
  }

  function setClientSsoCode(clientSsoCode) {
    safeSetState({ clientSsoCode });
  }

  function setSsoLogoutUrl(sso_LogoutUrl) {
    if (sso_LogoutUrlError && validateURL(sso_LogoutUrl)) {
      safeSetState({ sso_LogoutUrl, sso_LogoutUrlError: false });
    } else {
      safeSetState({ sso_LogoutUrl });
    }
  }

  function setTitle(title) {
    safeSetState({ title });
  }

  function statusClick(i) {
    if (i === 0 && !active) {
      safeSetState({ active: true });
    } else if (i === 1 && active) {
      safeSetState({ active: false });
    }
  }

  function setImpersonation() {
    safeSetState({ canImpersonate: !canImpersonate });
  }

  const defaultDebtorID = selectedCompany?.data?.defaultDebtorID;

  const isClientLogin = userState?.accessLevel < 40;
  const hasLimitedAccess = userState?.accessLevel < 60;
  return (
    <div>
      {croppingImage ? (
        <Modal isOpen={true}>
          <ImageCropper
            close={toggleImageCrop}
            name={logoFile?.name}
            src={logoFileObjectURL || logoUrl}
            updateImage={(url, file) => {
              safeSetState({
                logoFileObjectURL: url,
                logoFile: file,
                croppingImage: false,
              });
            }}
            type={logoFile ? "file" : "url"}
          />
        </Modal>
      ) : selectedCompany.isFetching ? (
        <Loading active={true} loadingText="Loading Company" addContainer />
      ) : (
        <Fragment>
          {!newCompany && (
            <div className={classes.headerWrapper}>
              <h2 className={classes.header}>ACCOUNT DETAILS</h2>
              <span className={classes.requiredFields}>* Required Fields</span>
            </div>
          )}
          <div className={classes.container}>
            <div className={classes.column}>
              <div className={classes.inputContainer}>
                <Input
                  label="Account Name *"
                  value={title}
                  onChange={setTitle}
                  disabled={hasLimitedAccess}
                  data-aut="AccountNameInput"
                />
              </div>
              {!isClientLogin && newCompany && (
                <FormControlLabel
                  className={classes.formControl}
                  classes={{ label: classes.formControlLabel }}
                  control={
                    <Checkbox
                      classes={{ root: classes.checkbox }}
                      checked={isCloning}
                      color="primary"
                      onChange={setIsCloning}
                      value="isCloning"
                      inputProps={{ "aria-label": "Clone Existing Company" }}
                      disabled={hasLimitedAccess}
                    />
                  }
                  label="Clone Existing Company"
                />
              )}
              {isCloning && (
                <AutoComplete
                  label="Company"
                  errorMessage={
                    companies.isError ? "Error Fetching Companies" : ""
                  }
                  isLoading={companies.isLoading}
                  handleDelete={() => setCompanyToClone(null)}
                  onChange={setCompanyToClone}
                  selectedItem={selectedCompanyClone}
                  suggestions={companies.data}
                />
              )}
              {!isClientLogin && !isCloning && (
                <Fragment>
                  {!hasLimitedAccess && !newCompany && (
                    <CompanyDebtorConfig
                      addDebtorToCompany={handleAddDebtorToCompany}
                      createDebtor={setCreatingDebtor}
                      defaultDebtorID={defaultDebtorID}
                      selectDebtor={selectDebtor}
                      selectedCompanyID={selectedCompanyID}
                    />
                  )}
                  <FormControlLabel
                    className={classes.formControl}
                    classes={{ label: classes.formControlLabel }}
                    control={
                      <Checkbox
                        classes={{ root: classes.checkbox }}
                        checked={isSso}
                        color="primary"
                        onChange={setIsSso}
                        value="ssoDisabled"
                        inputProps={{ "aria-label": "Enable SSO" }}
                        disabled={hasLimitedAccess}
                      />
                    }
                    label="Enable SSO"
                    data-aut="EnableSSOCheckbox"
                  />
                </Fragment>
              )}
              {!hasLimitedAccess && (
                <Button
                  onClick={() => onSave(state)}
                  label="Save"
                  disabled={saving || !canSave()}
                  loading={saving}
                  data-aut="CompanySave"
                />
              )}
              {newCompany && (
                <Button
                  onClick={close}
                  label="Cancel"
                  isTextButton
                  data-aut="CompanyCancel"
                />
              )}
            </div>
            <div className={classes.column}>
              <div className={classes.doubleContainer}>
                {!newCompany && !isClientLogin && (
                  <div className={classes.status}>
                    <label className={classes.label}>Status</label>
                    <MultiToggle
                      value={active ? 0 : 1}
                      properties={statusProperties}
                      handleClick={statusClick}
                      label="Status"
                      disabled={hasLimitedAccess}
                    />
                  </div>
                )}
                <div className={classes.imageUpload}>
                  <ImageUpload
                    addNotification={addNotification}
                    handleOpenImageCrop={toggleImageCrop}
                    imageFile={logoFile}
                    imageFileObjectURL={logoFileObjectURL}
                    imageUrl={logoUrl}
                    buttonLabel="Add Company Logo"
                    formLabel="Logo"
                    alt="Company Logo"
                    onChange={setLogoFile}
                    disabled={hasLimitedAccess && !isClientLogin}
                    validation={{
                      maxSize: 2,
                      minHeight: 70,
                    }}
                  />
                </div>
              </div>
              {!isClientLogin && (
                <Fragment>
                  {!newCompany && (
                    <p className={classes.modified}>
                      <span>Modified On: </span>
                      {formatDate(modifiedOn)}
                    </p>
                  )}
                  {!newCompany && (
                    <p className={classes.modified}>
                      <span>Modified By: </span>
                      {modifiedBy}
                    </p>
                  )}
                  <FormControlLabel
                    className={classes.formControl}
                    classes={{ label: classes.formControlLabel }}
                    control={
                      <Checkbox
                        classes={{ root: classes.checkbox }}
                        checked={canImpersonate}
                        color="primary"
                        onChange={setImpersonation}
                        value="canImpersonate"
                        inputProps={{ "aria-label": "Enable Impersonation" }}
                        disabled={hasLimitedAccess}
                      />
                    }
                    label="Enable Impersonation"
                  />
                  {isSso && (
                    <div
                      className={
                        newCompany
                          ? classes.newCompanyAuthenticationMethods
                          : classes.authenticationMethods
                      }
                    >
                      <FormControlLabel
                        className={classes.authenticationCheckbox}
                        classes={{ label: classes.formControlLabel }}
                        control={
                          <Checkbox
                            classes={{ root: classes.checkbox }}
                            checked={sso_HardLogout}
                            color="primary"
                            onChange={setHardLogout}
                            value="Email"
                            inputProps={{ "aria-label": "Hard Logout" }}
                            disabled={hasLimitedAccess}
                          />
                        }
                        label="Hard Logout"
                        data-aut="HardLogoutCheckbox"
                      />
                      <label className={classes.authMethodsLabel}>
                        Authentication Method *
                      </label>
                      <p className={classes.authMethodsFooter}>
                        (Email and/or Employee Id required)
                      </p>
                      <FormControlLabel
                        className={classes.authenticationCheckbox}
                        classes={{ label: classes.formControlLabel }}
                        control={
                          <Checkbox
                            classes={{ root: classes.checkbox }}
                            checked={sso_AuthenticateByEmail}
                            color="primary"
                            onChange={setSsoAuthenticationEmail}
                            value="Email"
                            inputProps={{ "aria-label": "Email" }}
                            disabled={hasLimitedAccess}
                          />
                        }
                        label="Email"
                      />
                      <FormControlLabel
                        className={classes.authenticationCheckbox}
                        classes={{ label: classes.formControlLabel }}
                        control={
                          <Checkbox
                            classes={{ root: classes.checkbox }}
                            checked={sso_AuthenticateByEmployeeID}
                            color="primary"
                            onChange={setSsoAuthenticationEmployeeID}
                            value="Employee Id"
                            inputProps={{ "aria-label": "Employee Id" }}
                            disabled={hasLimitedAccess}
                          />
                        }
                        label="Employee Id"
                      />
                      <div className={classes.inputContainer}>
                        <Input
                          label="Auth0 Connection *"
                          value={externalAuth0EnterpriseConnection}
                          onChange={setAuth0}
                          disabled={hasLimitedAccess}
                          data-aut="Auth0ConnectionInput"
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <Input
                          label="SSO Config *"
                          value={isSso ? clientSsoCode : ""}
                          onChange={setClientSsoCode}
                          disabled={hasLimitedAccess}
                          data-aut="SSOConfigInput"
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <Input
                          label="SSO Logout URL"
                          error={
                            sso_LogoutUrlError &&
                            "Valid URL required for logout."
                          }
                          value={sso_LogoutUrl}
                          onBlur={handleSSOLogutUrlInputBlur}
                          onChange={setSsoLogoutUrl}
                          disabled={hasLimitedAccess}
                          data-aut="SSOLogoutUrlInput"
                        />
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
      <CompanyDebtorModal
        addNotification={addNotification}
        companyID={id}
        handleClose={closeModal}
        isCreating={creatingDebtor}
        isOpen={Boolean(selectedDebtor || creatingDebtor)}
        debtor={selectedDebtor}
        defaultDebtorID={defaultDebtorID}
        selectedCompanyID={selectedCompanyID}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCompanyID: state.selectedCompanyID,
});

const mapDispatchToProps = (dispatch) => ({
  addNotification: (notification) => dispatch(addNotification(notification)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(CompanyDetails, styles));
