import React, { Suspense } from "react";
import { Loading } from "components";

const Companies = React.lazy(() => import("./Companies"));

function CompaniesView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Companies" />}>
      <Companies {...props} />
    </Suspense>
  );
}

export default CompaniesView;
