import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { withStyles } from "tss-react/mui";
import customChipStyle from "components/CustomChip/customChipStyle";

function handleClick(e, onDelete) {
  killEvent(e);
  onDelete();
}

function killEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}

interface CustomChipI {
  label: string;
  onDelete: () => void;
  classes?: Partial<Record<"root" | "label" | "icon", string>>;
}

const CustomChip = (props: CustomChipI) => {
  const { label, onDelete } = props;
  const classes = withStyles.getClasses(props);

  return (
    <div className={classes.root} onMouseDown={killEvent}>
      <span className={classes.label}>{label}</span>
      {onDelete && (
        <IconButton
          className={classes.icon}
          aria-label="Remove Item Button"
          onMouseDown={killEvent}
          onClick={(e) => handleClick(e, onDelete)}
          data-aut={`Chip|Remove|${label}`}
          size="large"
        >
          <Cancel aria-label="Remove Item Icon" />
        </IconButton>
      )}
    </div>
  );
};

export default withStyles(CustomChip, customChipStyle);
