import { useQuery } from "@tanstack/react-query";
import {
  fetchCompanyTravelers,
  fetchDebtorTravelers,
} from "services/travelers";
import {
  QUERYKEY_COMPANY_TRAVELERS,
  QUERYKEY_DEBTOR_TRAVELERS,
} from "./queryKeys";
import { Traveler } from "types/ItinerarySearch";
import { formatTravelerName } from "common/helpers";

function mapTravelers(data): Traveler[] {
  return data.map((traveler) => ({
    label: formatTravelerName(traveler),
    secondaryLabel: traveler.email,
    email: traveler.email,
    id: traveler.id,
  }));
}

export function useCompanyTravelers(id) {
  return useQuery({
    queryKey: [QUERYKEY_COMPANY_TRAVELERS, id],
    queryFn: () => fetchCompanyTravelers(id),
    select: mapTravelers,
    enabled: Boolean(id),
  });
}

export function useDebtorTravelers(id) {
  return useQuery({
    queryKey: [QUERYKEY_DEBTOR_TRAVELERS, id],
    queryFn: () => fetchDebtorTravelers(id),
    select: mapTravelers,
    enabled: Boolean(id),
  });
}
