import { ITINERARIES } from "common/constants";

export const ITINERARIES_SET_SEARCH_PARAMS = `${ITINERARIES}_SET_SEARCH_PARAMS`;
export const itinerarySetSearchParams = () => ({
  type: ITINERARIES_SET_SEARCH_PARAMS,
});

// Search Type (names or pnr)
export const ITINERARIES_SET_SEARCH_TYPE = `${ITINERARIES}_SET_SEARCH_TYPE`;
export const itinerarySetSearchType = (type) => ({
  type: ITINERARIES_SET_SEARCH_TYPE,
  payload: type,
});

// Expanded options UI showing or not
export const ITINERARIES_SEARCH_EXPAND = `${ITINERARIES}_SEARCH_EXPAND`;
export const itinerarySearchExpand = () => ({
  type: ITINERARIES_SEARCH_EXPAND,
});

// Adding and removing PNRs
export const ITINERARIES_SET_PNRS = `${ITINERARIES}_SET_PNRS`;
export const itinerarySetPNRs = (pnrs) => ({
  type: ITINERARIES_SET_PNRS,
  payload: pnrs,
});

// Selecting companies for itinerary search (fetch is handled elsewhere)
export const ITINERARIES_SELECT_COMPANY = `${ITINERARIES}_SELECT_COMPANY`;
export const itinerarySelectCompany = (company) => ({
  type: ITINERARIES_SELECT_COMPANY,
  payload: company,
});

// Selecting companies for itinerary search (fetch is handled elsewhere)
export const ITINERARIES_SELECT_DEBTOR = `${ITINERARIES}_SELECT_DEBTOR`;
export const itinerarySelectDebtor = (debtor) => ({
  type: ITINERARIES_SELECT_DEBTOR,
  payload: debtor,
});

// Selecting or removing a traveler
export const ITINERARIES_ADD_TRAVELER = `${ITINERARIES}_ADD_TRAVELER`;
export const ITINERARIES_REMOVE_TRAVELER = `${ITINERARIES}_REMOVE_TRAVELER`;
export const ITINERARIES_RESET_TRAVELERS = `${ITINERARIES}_RESET_TRAVELERS`;
export const itineraryAddTraveler = (traveler) => ({
  type: ITINERARIES_ADD_TRAVELER,
  payload: traveler,
});
export const itineraryRemoveTraveler = (traveler) => ({
  type: ITINERARIES_REMOVE_TRAVELER,
  payload: traveler,
});
export const itineraryResetTravelers = () => ({
  type: ITINERARIES_RESET_TRAVELERS,
});

// Setting Date Range Values
export const ITINERARIES_SET_DATES = `${ITINERARIES}_SET_DATES`;
export const itinerarySetDates = ({ startDate, endDate }) => ({
  type: ITINERARIES_SET_DATES,
  payload: { startDate, endDate },
});

// Updates Travel Type Selection
export const ITINERARIES_SET_TRAVEL_TYPE = `${ITINERARIES}_SET_TRAVEL_TYPE`;
export const itinerarySetTravelType = (travelType) => ({
  type: ITINERARIES_SET_TRAVEL_TYPE,
  payload: travelType,
});

export const ITINERARIES_SET_SUPPLIER_QUERY = `${ITINERARIES}_SET_SUPPLIER_QUERY`;

export const itinerarySetVendorQuery = (query) => ({
  type: ITINERARIES_SET_SUPPLIER_QUERY,
  query,
});

// Adding and removing selected suppliers
export const ITINERARIES_ADD_SUPPLIER = `${ITINERARIES}_ADD_SUPPLIER`;
export const ITINERARIES_REMOVE_SUPPLIER = `${ITINERARIES}_REMOVE_SUPPLIER`;
export const itineraryAddSupplier = (supplier) => ({
  type: ITINERARIES_ADD_SUPPLIER,
  payload: supplier,
});
export const itineraryRemoveSupplier = (supplier) => ({
  type: ITINERARIES_REMOVE_SUPPLIER,
  payload: supplier,
});

// Adding and removing selected pccs
export const ITINERARIES_ADD_PCC = `${ITINERARIES}_ADD_PCC`;
export const ITINERARIES_REMOVE_PCC = `${ITINERARIES}_REMOVE_PCC`;
export const itineraryAddPCC = (payload) => ({
  type: ITINERARIES_ADD_PCC,
  payload,
});
export const itineraryRemovePCC = (payload) => ({
  type: ITINERARIES_REMOVE_PCC,
  payload,
});

// Selecting a country
export const ITINERARIES_SELECT_COUNTRY = `${ITINERARIES}_SELECT_COUNTRY`;
export const itinerarySelectCountry = (country) => ({
  type: ITINERARIES_SELECT_COUNTRY,
  payload: country,
});

export const ITINERARIES_SET_CITY_QUERY = `${ITINERARIES}_SET_CITY_QUERY`;
export const itinerarySetCityQuery = (query) => ({
  type: ITINERARIES_SET_CITY_QUERY,
  query,
});

// Adding and removing cities
export const ITINERARIES_ADD_CITY = `${ITINERARIES}_ADD_CITY`;
export const ITINERARIES_REMOVE_CITY = `${ITINERARIES}_REMOVE_CITY`;
export const itineraryAddCity = (city) => ({
  type: ITINERARIES_ADD_CITY,
  payload: city,
});
export const itineraryRemoveCity = (city) => ({
  type: ITINERARIES_REMOVE_CITY,
  payload: city,
});

export const ITINERARIES_SELECT = `${ITINERARIES}_SELECT`;

export const itinerarySelect = (selectedItinerary) => ({
  type: ITINERARIES_SELECT,
  selectedItinerary,
});

// Pagination
export const ITINERARIES_ORDER = `${ITINERARIES}_ORDER`;
export const ITINERARIES_ORDER_BY = `${ITINERARIES}_ORDER_BY`;
export const ITINERARIES_PAGE = `${ITINERARIES}_PAGE`;
export const ITINERARIES_ROWS_PER_PAGE = `${ITINERARIES}_ROWS_PER_PAGE`;

export const setItinerariesOrder = (payload) => ({
  type: ITINERARIES_ORDER,
  payload,
});
export const setItinerariesOrderBy = (payload) => ({
  type: ITINERARIES_ORDER_BY,
  payload,
});
export const setItinerariesPage = (payload) => ({
  type: ITINERARIES_PAGE,
  payload,
});
export const setItinerariesRowsPerPage = (payload) => ({
  type: ITINERARIES_ROWS_PER_PAGE,
  payload,
});
