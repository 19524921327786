/**
 * Base Url of API server.  Important to append ending slash.
 */
import { AUDIENCE } from "./apiConfig.js";

export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENTID,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK,
  loginUrl: process.env.REACT_APP_AUTH0_LOGIN_URL,
  audience: AUDIENCE,
  responseType: "token id_token",
  scope: "openid email profile",
};

export const PREFIX = "smarttower";
export const ACCESS_TOKEN = `${PREFIX}_access_token`;
export const ID_TOKEN = `${PREFIX}_id_token`;
export const EXPIRES_AT = `${PREFIX}_expires_at`;
export const SCOPES = `${PREFIX}_scopes`;
export const BYPASS = `${PREFIX}_bypass`;
