import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { COMPANY_TRAVELERS, DEBTOR_TRAVELERS } from "common/constants";
import { handleErrors } from "common/helpers";

export const fetchCompanyTravelers = (id) => {
  const url = getConfig(COMPANY_TRAVELERS).url + id;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COMPANY_TRAVELERS} url`));
  }
};

export const fetchDebtorTravelers = (id) => {
  const url = getConfig(DEBTOR_TRAVELERS).url + id;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${DEBTOR_TRAVELERS} url`));
  }
};
