import { Fragment, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withStyles } from "tss-react/mui";
import itinerarySearchStyle from "components/ItinerarySearch/itinerarySearchStyle";
import moment from "moment";
import {
  AutoComplete,
  Button,
  DatePicker,
  Input,
  ItineraryTable,
} from "components";
import {
  itineraryAddTraveler,
  itineraryRemoveTraveler,
  itineraryAddCity,
  itineraryAddPCC,
  itinerarySetPNRs,
  itineraryRemoveCity,
  itineraryAddSupplier,
  itineraryRemovePCC,
  itineraryRemoveSupplier,
  itinerarySetSearchParams,
  itinerarySelectCountry,
  itinerarySelectCompany,
  itinerarySelectDebtor,
  itinerarySetCityQuery,
  itinerarySetVendorQuery,
  itinerarySetDates,
  itinerarySetSearchType,
  itinerarySearchExpand,
  itinerarySetTravelType,
} from "redux/actions/itinerarySearch";
import { addNotification } from "redux/actions/notifications";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Radio from "@mui/material/Radio";
import { parsePNRs, validatePNRs } from "services/pnrs";
import { City, Country } from "types/Locations";
import { NotificationObjI } from "types";
import { CompanyI, DebtorSelectionI } from "types/Company";
import { Pcc } from "types/Pcc";
import {
  ItinerarySearchStateI,
  SearchType,
  Supplier,
  TravelType,
  Traveler,
} from "types/ItinerarySearch";
import { usePCCs } from "hooks/pccs";
import { useCompanies } from "hooks/companies";
import { convertDataToCSVDownload, mapDataForCSV } from "common/helpers";
import { useDebtors } from "hooks/debtors";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useCountries } from "hooks/countries";
import {
  useItinerarySearchCities,
  useItinerarySelectedCountryCities,
} from "hooks/cities";
import { useVendors } from "hooks/vendors";
import { useCompanyTravelers, useDebtorTravelers } from "hooks/travelers";
import { useItineraries } from "hooks/itineraries";
import { ItineraryI } from "types/Intinerary";

const csvDownloadRowMap = [
  {
    label: "PNR",
    render: (i: ItineraryI) => (i.source?.pnr ? i.source.pnr : ""),
  },
  {
    label: "Travelers",
    render: (i: ItineraryI) =>
      i.travelers?.length
        ? i.travelers.map((t) => `${t.firstName} ${t.lastName}`).join(", ")
        : "",
  },
  {
    label: "Traveler Emails",
    render: (i: ItineraryI) =>
      i.travelers?.length
        ? i.travelers.map((t) => t.email || "").join(", ")
        : "",
  },
  {
    label: "Debtor ID",
    render: (i: ItineraryI) => (i.debtorIdentifier ? i.debtorIdentifier : ""),
  },
  { label: "PCC", render: (i: ItineraryI) => i.source?.pcc || "" },
  {
    label: "Travel Start Date",
    render: (i: ItineraryI) =>
      i.startTime ? moment(i.startTime).format("MM/DD/YY") : "",
  },
  {
    label: "Travel End Date",
    render: (i: ItineraryI) =>
      i.endTime ? moment(i.endTime).format("MM/DD/YY") : "",
  },
];

interface ItinerarySearchI {
  addNotification: (not: NotificationObjI) => void;
  selectedCompanyID?: string;
  handleSearch?: () => void;
  fullPage?: boolean;
  itineraryAddCity: (city: string) => void;
  itineraryAddPCC: (pcc: string) => void;
  itinerarySetPNRs: (pnrs: string) => void;
  itineraryAddSupplier: (supplier: string) => void;
  itineraryAddTraveler: (traveler: string) => void;
  itinerarySetSearchParams: () => void;
  itineraryRemoveCity: (city: City) => void;
  itineraryRemovePCC: (pcc: Pcc) => void;
  itineraryRemoveTraveler: (traveler: Traveler) => void;
  itineraryRemoveSupplier: (supplier: Supplier) => void;
  itinerarySetDates: ({
    startDate,
    endDate,
  }: {
    endDate: moment.Moment;
    startDate: moment.Moment;
  }) => string;
  itinerarySearch: ItinerarySearchStateI;
  itinerarySearchExpand: () => void;
  itinerarySelectCountry: (country: Country) => void;
  itinerarySelectCompany: (company: CompanyI) => void;
  itinerarySelectDebtor: (debtor: DebtorSelectionI) => void;
  itinerarySetSearchType: (type: SearchType) => void;
  itinerarySetCityQuery: (query: string) => void;
  itinerarySetTravelType: (type: TravelType) => void;
  itinerarySetVendorQuery: (query: string) => void;
  classes?: Partial<
    Record<
      | "root"
      | "row"
      | "searchButtonRow"
      | "leftHalf"
      | "rightHalf"
      | "leftThird"
      | "middleThird"
      | "rightThird"
      | "checkboxRoot"
      | "checkboxGroup"
      | "checkboxIcon"
      | "label"
      | "legend"
      | "expansionPanel"
      | "panelContent"
      | "panelSummary"
      | "panelDetails"
      | "expandIcon"
      | "expandText"
      | "radioButton"
      | "checked"
      | "searchText"
      | "searchCategory"
      | "travelDates"
      | "travelTypes",
      string
    >
  >;
}

const ItinerarySearch = (props: ItinerarySearchI) => {
  const {
    addNotification,
    selectedCompanyID,
    handleSearch,
    fullPage,
    itineraryAddCity,
    itineraryAddPCC,
    itinerarySetPNRs,
    itineraryAddSupplier,
    itineraryAddTraveler,
    itinerarySetSearchParams,
    itineraryRemoveCity,
    itineraryRemovePCC,
    itineraryRemoveTraveler,
    itineraryRemoveSupplier,
    itinerarySetDates,
    itinerarySearch,
    itinerarySearchExpand,
    itinerarySelectCountry,
    itinerarySelectCompany,
    itinerarySelectDebtor,
    itinerarySetCityQuery,
    itinerarySetSearchType,
    itinerarySetTravelType,
    itinerarySetVendorQuery,
  } = props;
  const {
    cityQuery,
    endDate,
    expanded,
    searchType,
    selectedCities,
    selectedCountry,
    selectedCompany,
    selectedDebtor,
    selectedPCCs,
    selectedPNRs,
    selectedSuppliers,
    selectedTravelers,
    suppliersQuery,
    startDate,
    travelType,
  } = itinerarySearch;
  const classes = withStyles.getClasses(props);

  const itineraries = useItineraries();

  const companySelected = useSelectedCompany(selectedCompanyID)?.data;
  const countryCities = useItinerarySelectedCountryCities(
    selectedCountry?.countryCode
  );
  const cities = useItinerarySearchCities(cityQuery);

  const companies = useCompanies();
  const debtors = useDebtors();
  const countries = useCountries();
  const suppliers = useVendors(suppliersQuery);
  const debtorTravelers = useDebtorTravelers(selectedDebtor?.identifier);
  const companyTravelers = useCompanyTravelers(
    selectedCompanyID || selectedCompany?.id
  );
  const travelers = selectedCompanyID
    ? companyTravelers
    : selectedDebtor?.identifier
    ? debtorTravelers
    : companyTravelers;

  const handleCompanySelect = useCallback(
    (selection) => {
      if (itinerarySearch.searchType !== "Companies") {
        itinerarySetSearchType("Companies");
      }
      itinerarySelectCompany(selection);
    },
    [itinerarySearch.searchType, itinerarySelectCompany, itinerarySetSearchType]
  );

  const hasAutoSelectedCompany = useRef(false);
  const hasDoneInitialFetch = useRef(false);

  useEffect(() => {
    if (companySelected && !hasAutoSelectedCompany.current) {
      hasAutoSelectedCompany.current = true;
      handleCompanySelect(companySelected);
    }
  }, [companySelected, handleCompanySelect]);

  useEffect(() => {
    if (!handleSearch && !hasDoneInitialFetch.current) {
      hasDoneInitialFetch.current = true;
      itinerarySetSearchParams();
    }
  }, [handleSearch, itinerarySetSearchParams]);

  const pccs = usePCCs();

  const handleRadioChange = (event) => {
    itinerarySetSearchType(event.target.value);
  };

  const handleCountrySelect = (selection) => {
    itinerarySelectCountry(selection);
  };

  const handleDebtorSelect = (selection) => {
    itinerarySelectDebtor(selection);
  };

  const onSearch = () => {
    if (searchType === "PNR") {
      const PNRs = parsePNRs(selectedPNRs);
      if (!validatePNRs(PNRs)) {
        addNotification({
          text: `Incorrect format. PNRs need to be 6 chars, comma seperated.`,
          type: "error",
        });
        return;
      }
    }

    if (handleSearch) {
      handleSearch();
    } else {
      itinerarySetSearchParams();
    }
  };

  const downloadItineraryCSV = () => {
    convertDataToCSVDownload(
      mapDataForCSV(itineraries.data, csvDownloadRowMap),
      "itineraries.csv"
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.searchText}>Search by</span>
        <Radio
          checked={searchType === "Companies"}
          onChange={handleRadioChange}
          value="Companies"
          name="radio-button-names"
          inputProps={{
            "aria-label": companySelected ? "Travelers" : "Companies",
          }}
          classes={{ root: classes.radioButton, checked: classes.checked }}
        />
        <label className={classes.searchCategory}>
          {companySelected ? "Travelers" : "Companies"}
        </label>
        {!companySelected ? (
          <Fragment>
            <Radio
              checked={searchType === "Debtors"}
              onChange={handleRadioChange}
              value="Debtors"
              name="radio-button-names"
              inputProps={{ "aria-label": "Debtors" }}
              classes={{
                root: classes.radioButton,
                checked: classes.checked,
              }}
            />
            <label className={classes.searchCategory}>Debtors</label>
          </Fragment>
        ) : null}
        <Radio
          checked={searchType === "PNR"}
          onChange={handleRadioChange}
          value="PNR"
          name="radio-button-pnr"
          inputProps={{ "aria-label": "PNR" }}
          classes={{ root: classes.radioButton, checked: classes.checked }}
        />
        <label className={classes.searchCategory}>PNR</label>
      </div>
      {searchType === "PNR" ? (
        <div className={classes.row}>
          <div className={classes.leftHalf}>
            <Input
              label="PNRs"
              value={selectedPNRs}
              onChange={itinerarySetPNRs}
            />
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={classes.row}>
            <div className={classes.leftHalf}>
              {searchType === "Companies" ? (
                <AutoComplete
                  label="Company Name"
                  errorMessage={
                    companies.isError ? "Error Fetching Companies" : ""
                  }
                  isLoading={companies.isLoading}
                  handleDelete={() => itinerarySelectCompany(null)}
                  onChange={handleCompanySelect}
                  selectedItem={selectedCompany}
                  suggestions={companies.data}
                  disabled={Boolean(companySelected)}
                />
              ) : (
                <AutoComplete
                  label="Debtor Name"
                  errorMessage={debtors.isError ? "Error Fetching Debtors" : ""}
                  isLoading={debtors.isLoading}
                  handleDelete={() => itinerarySelectDebtor(null)}
                  onChange={handleDebtorSelect}
                  selectedItem={selectedDebtor}
                  suggestions={debtors.data}
                />
              )}
            </div>
            <div
              className={companySelected ? classes.leftHalf : classes.rightHalf}
            >
              <AutoComplete
                label="Traveler Names"
                errorMessage={
                  travelers.isError ? "Error Fetching Travelers" : undefined
                }
                handleDelete={itineraryRemoveTraveler}
                isLoading={travelers.isFetching}
                isMulti
                disabled={
                  (searchType === "Companies" && !selectedCompany) ||
                  (searchType === "Debtors" && !selectedDebtor) ||
                  Boolean(travelers.isFetching)
                }
                onChange={itineraryAddTraveler}
                placeholder={travelers.isFetching ? "Loading Travelers" : ""}
                selectedItem={selectedTravelers}
                suggestions={travelers?.data || []}
              />
            </div>
          </div>
          <div>
            <div className={classes.travelDates}>
              <label
                htmlFor="itinerarySearchStartDate"
                className={classes.label}
              >
                Travel Dates
              </label>
              <DatePicker
                endDate={endDate}
                endDateId="itinerarySearchEndDate"
                onDatesChange={({ startDate, endDate }) =>
                  itinerarySetDates({ startDate, endDate })
                }
                startDate={startDate}
                startDateId="itinerarySearchStartDate"
              />
            </div>
            <div className={classes.travelTypes}>
              <fieldset
                className={classes.checkboxGroup}
                id="travel-type-checkboxes"
              >
                <legend className={classes.legend}>Travel Types</legend>
                <Checkbox
                  inputProps={{ "aria-label": "Flight" }}
                  checked={travelType.flight}
                  onChange={() =>
                    itinerarySetTravelType({
                      ...travelType,
                      flight: !travelType.flight,
                    })
                  }
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkboxIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon className={classes.checkboxIcon} />
                  }
                  classes={{ root: classes.checkboxRoot }}
                />
                <label>Flight</label>
                <Checkbox
                  inputProps={{ "aria-label": "Hotel" }}
                  checked={travelType.hotel}
                  onChange={() =>
                    itinerarySetTravelType({
                      ...travelType,
                      hotel: !travelType.hotel,
                    })
                  }
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkboxIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon className={classes.checkboxIcon} />
                  }
                  classes={{ root: classes.checkboxRoot }}
                />
                <label>Hotel</label>
                <Checkbox
                  inputProps={{ "aria-label": "Car" }}
                  checked={travelType.car}
                  onChange={() =>
                    itinerarySetTravelType({
                      ...travelType,
                      car: !travelType.car,
                    })
                  }
                  value="checkedA"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkboxIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon className={classes.checkboxIcon} />
                  }
                  classes={{ root: classes.checkboxRoot }}
                />
                <label>Car</label>
              </fieldset>
            </div>
          </div>
          <Accordion expanded={expanded} className={classes.expansionPanel}>
            <AccordionSummary
              classes={{
                root: classes.panelSummary,
                content: classes.panelContent,
              }}
            >
              <div
                style={{ position: "relative" }}
                onClick={itinerarySearchExpand}
                data-aut="Itineraries|ExpandOptions"
              >
                <i
                  style={expanded ? { transform: "rotate(90deg)" } : {}}
                  className={`icon-minimize-arrows ${classes.expandIcon}`}
                ></i>
                <p className={classes.expandText}>Additional Search Options</p>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>
              <div className={classes.row}>
                <div className={classes.leftHalf}>
                  <AutoComplete
                    label="Supplier Codes"
                    isAsync
                    isMulti
                    errorMessage={
                      suppliers.isError && expanded
                        ? "Error Fetching Suppliers"
                        : undefined
                    }
                    handleDelete={itineraryRemoveSupplier}
                    isLoading={suppliers.isFetching}
                    handleQuery={itinerarySetVendorQuery}
                    onChange={itineraryAddSupplier}
                    selectedItem={selectedSuppliers}
                    suggestions={suppliers?.data || []}
                  />
                </div>
                <div className={classes.rightHalf}>
                  <AutoComplete
                    label="PCCs"
                    autoRenderSuggestions
                    isMulti
                    errorMessage={
                      pccs.isError && expanded
                        ? "Error Fetching PCCs"
                        : undefined
                    }
                    isLoading={pccs.isLoading}
                    handleDelete={itineraryRemovePCC}
                    placeholder={pccs.isLoading ? "Loading PCCs" : ""}
                    onChange={itineraryAddPCC}
                    selectedItem={selectedPCCs}
                    suggestions={pccs.data?.activePCCs}
                  />
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.leftHalf}>
                  <AutoComplete
                    label="Country"
                    errorMessage={
                      countries.isError && expanded
                        ? "Error Fetching Countries"
                        : undefined
                    }
                    isLoading={countries.isLoading}
                    handleDelete={() => itinerarySelectCountry(null)}
                    placeholder={countries.isLoading ? "Loading Countries" : ""}
                    onChange={handleCountrySelect}
                    selectedItem={selectedCountry}
                    suggestions={countries.data}
                  />
                </div>
                <div className={classes.rightHalf}>
                  <AutoComplete
                    label="Cities"
                    errorMessage={
                      (countryCities.isError || cities.isError) && expanded
                        ? "Error Fetching Cities"
                        : ""
                    }
                    handleDelete={itineraryRemoveCity}
                    handleQuery={itinerarySetCityQuery}
                    isLoading={countryCities.isFetching || cities.isFetching}
                    isMulti
                    isAsync={selectedCountry ? false : true}
                    disabled={
                      selectedCountry && countryCities.isFetching ? true : false
                    }
                    onChange={itineraryAddCity}
                    placeholder={
                      selectedCountry && countryCities.isFetching
                        ? "Loading Cities"
                        : ""
                    }
                    selectedItem={selectedCities}
                    suggestions={countryCities?.data || cities?.data || []}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
      <div className={classes.searchButtonRow}>
        <Button
          data-aut="Itineraries|SearchButton"
          disabled={searchType === "PNR" && selectedPNRs.length === 0}
          onClick={onSearch}
          label="Search"
        />
        {itineraries.data.length > 0 && fullPage && (
          <Button
            onClick={downloadItineraryCSV}
            label="Download Itineraries CSV"
            isTextButton
          />
        )}
      </div>
      {fullPage && <ItineraryTable />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  itinerarySearch: state.itinerarySearch,
  selectedCompanyID: state.selectedCompanyID,
});

const mapDispatchToProps = (dispatch) => ({
  addNotification: (notification) => dispatch(addNotification(notification)),
  itineraryAddTraveler: (traveler) => dispatch(itineraryAddTraveler(traveler)),
  itineraryRemoveTraveler: (traveler) =>
    dispatch(itineraryRemoveTraveler(traveler)),
  itineraryAddCity: (city) => dispatch(itineraryAddCity(city)),
  itineraryAddPCC: (pcc) => dispatch(itineraryAddPCC(pcc)),
  itinerarySetPNRs: (pnrs) => dispatch(itinerarySetPNRs(pnrs)),
  itineraryRemoveCity: (city) => dispatch(itineraryRemoveCity(city)),
  itineraryAddSupplier: (supplier) => dispatch(itineraryAddSupplier(supplier)),
  itineraryRemovePCC: (pcc) => dispatch(itineraryRemovePCC(pcc)),
  itineraryRemoveSupplier: (supplier) =>
    dispatch(itineraryRemoveSupplier(supplier)),
  itinerarySelectCountry: (country) =>
    dispatch(itinerarySelectCountry(country)),
  itinerarySelectCompany: (company) =>
    dispatch(itinerarySelectCompany(company)),
  itinerarySetCityQuery: (query) => dispatch(itinerarySetCityQuery(query)),
  itinerarySelectDebtor: (debtor) => dispatch(itinerarySelectDebtor(debtor)),
  itinerarySetDates: (dates) => dispatch(itinerarySetDates(dates)),
  itinerarySetSearchType: (type) => dispatch(itinerarySetSearchType(type)),
  itinerarySetSearchParams: () => dispatch(itinerarySetSearchParams()),
  itinerarySetVendorQuery: (query) => dispatch(itinerarySetVendorQuery(query)),
  itinerarySearchExpand: () => dispatch(itinerarySearchExpand()),
  itinerarySetTravelType: (travelType) =>
    dispatch(itinerarySetTravelType(travelType)),
});

const ItinerarySearchStyled = withStyles(ItinerarySearch, itinerarySearchStyle);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItinerarySearchStyled);
