import React, { Suspense } from "react";
import { Loading } from "components";

const GlobalSearch = React.lazy(() => import("./GlobalSearch"));

function GlobalSearchView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Global Search" />}>
      <GlobalSearch {...props} />
    </Suspense>
  );
}

export default GlobalSearchView;
