import { get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { APPROVAL_CONFIG } from "common/constants";
import { handleErrors } from "common/helpers";

const url = getConfig(APPROVAL_CONFIG).url;

const formStateToDelete = [
  "id",
  "changeHasBeenMade",
  "codesFilter",
  "companyID",
  "creating",
  "debtor",
  "debtorToSwapTo",
  "expanded",
  "fetchingDebtor",
  "fetchingError",
  "requestingCodeResetConfirmation",
  "resetingCodes",
  "saving",
  "selectedDebtor",
  "standardRemarkCodes",
  "standardRemarkCodesError",
  "addingRemarkCode",
];

function conformConfigData(config) {
  const newConfig = { ...config };
  // We have some extra unnecessary data in our form state we want to strip out
  formStateToDelete.forEach((key) => {
    if (newConfig[key] != null) delete newConfig[key];
  });
  // Next let's strip out null/empty values
  const keys = Object.keys(newConfig);
  keys.forEach((k) => {
    if (newConfig[k] == null) delete newConfig[k];
    if (
      (Array.isArray(newConfig[k]) || typeof newConfig[k] === "string") &&
      !newConfig[k].length
    )
      delete newConfig[k];
    if (typeof newConfig[k] === "object" && !Object.keys(newConfig[k]).length)
      delete newConfig[k];
  });
  // Lastly, if we have a reminderTimeLimit key, we want to cast it as a number
  if (newConfig.reminderTimeLimit)
    newConfig.reminderTimeLimit = Number(newConfig.reminderTimeLimit);

  // Remove ids on reasoncodes (added on the f/e for react keying) if they exist
  if (newConfig.reasonCodes && newConfig.reasonCodes.length) {
    newConfig.reasonCodes = newConfig.reasonCodes.map((rc) => ({
      code: rc.code,
      label: rc.label,
      isCustom: rc.isCustom,
    }));
  }

  if (newConfig.remarkCodes && Object.keys(newConfig.remarkCodes).length) {
    const newCodes = { ...newConfig.remarkCodes };
    for (const key in newCodes) {
      newCodes[key] = {
        key: newCodes[key].key,
        udid: newCodes[key].udid,
      };
    }
    newConfig.remarkCodes = newCodes;
  }
  if (
    newConfig.customRemarkCodes &&
    Object.keys(newConfig.customRemarkCodes).length
  ) {
    const newCodes = { ...newConfig.customRemarkCodes };
    for (const key in newCodes) {
      newCodes[key] = {
        label: newCodes[key].label,
        key: newCodes[key].key,
        udid: newCodes[key].udid,
      };
    }
    newConfig.customRemarkCodes = newCodes;
  }
  if (newConfig.customRemarkCodesOriginal)
    delete newConfig.customRemarkCodesOriginal;
  if (newConfig.pendingCustomRemarkCodes)
    delete newConfig.pendingCustomRemarkCodes;
  if (newConfig.customCodesError) delete newConfig.customCodesError;

  const queue = {
    approveCode: null,
    approvePCC: null,
    declineCode: null,
    declinePCC: null,
  };

  if (newConfig.approvePCC) {
    queue.approvePCC = newConfig.approvePCC;
    delete newConfig.approvePCC;
  }

  if (newConfig.declinePCC) {
    queue.declinePCC = newConfig.declinePCC;
    delete newConfig.declinePCC;
  }

  if (newConfig.approveCode) {
    queue.approveCode = newConfig.approveCode;
    delete newConfig.approveCode;
  }

  if (newConfig.declineCode) {
    queue.declineCode = newConfig.declineCode;
    delete newConfig.declineCode;
  }
  newConfig.queue = queue;
  return newConfig;
}

export const createApproveConfig = (companyId, debtorId, config) => {
  if (url && companyId && debtorId) {
    const finalUrl = `${url}/${companyId}/Debtor/${debtorId}`;
    const finalConfig = conformConfigData(config);
    return post(finalUrl, finalConfig)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(
      new Error(`Missing ${APPROVAL_CONFIG} url, debtorId, or companyId`)
    );
  }
};

export const editApproveConfig = (companyId, debtorId, config) => {
  if (url && companyId && debtorId) {
    const finalUrl = `${url}/${companyId}/Debtor/${debtorId}`;
    const finalConfig = conformConfigData(config);
    return put(finalUrl, finalConfig)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(
      new Error(`Missing ${APPROVAL_CONFIG} url, debtorId, or companyId`)
    );
  }
};

export const fetchApproveConfig = (companyId, debtorId) => {
  if (url && companyId && debtorId) {
    const finalUrl = `${url}/${companyId}/Debtor/${debtorId}`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(
      new Error(`Missing ${APPROVAL_CONFIG} url, debtorId, or companyId`)
    );
  }
};

export const fetchReasonCodes = () => {
  if (url) {
    const finalUrl = `${url}/StandardReasonCodes`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVAL_CONFIG} url.`));
  }
};

export const fetchTripPurposeCodes = () => {
  if (url) {
    const finalUrl = `${url}/StandardTripPurposeCodes`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVAL_CONFIG} url`));
  }
};

export const fetchRemarkCodes = (companyId, debtorId) => {
  if (url && companyId && debtorId) {
    const finalUrl = `${url}/RemarkCodes`;
    const params = {
      CompanyID: companyId,
      DebtorID: debtorId,
    };
    return get(finalUrl, params)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(
      new Error(`Missing ${APPROVAL_CONFIG} url, debtorId, or companyId.`)
    );
  }
};

export const fetchStandardQueueValues = () => {
  if (url) {
    const finalUrl = `${url}/StandardQueueValues`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVAL_CONFIG} url.`));
  }
};

export const exportApproveConfig = () => {
  if (url) {
    const finalUrl = `${url}/ExportApproveConfig`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVAL_CONFIG}`));
  }
};

export const cloneApproveGroups = (params: {
  companyID: string;
  cloneCompanyID: string;
}) => {
  const url = getConfig(APPROVAL_CONFIG).url;
  if (url) {
    const finalUrl = `${url}/CloneTravelClassApprovalGroupTravelPolicies`;
    return post(finalUrl, params).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${APPROVAL_CONFIG} url`));
  }
};

export const cloneDebtorApproveSettings = (params: {
  companyID: string;
  debtorID: string;
  cloneDebtorID: string;
  cloneCompanyID: string;
}) => {
  const url = getConfig(APPROVAL_CONFIG).url;
  if (url) {
    const finalUrl = `${url}/CloneApproveSettings`;
    return post(finalUrl, params).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${APPROVAL_CONFIG} url`));
  }
};
