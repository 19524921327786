import { get, post } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { COMPANY, COMPANY_SEND_WELCOME_EMAILS } from "common/constants";
import { handleErrors } from "common/helpers";

export const fetchCompany = (id) => {
  const url = getConfig(COMPANY).url;
  if (url && id) {
    return get(url + id)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COMPANY} url or id`));
  }
};

export const sendCompanyWelcomeEmails = (identifier) => {
  const url = getConfig(COMPANY_SEND_WELCOME_EMAILS).url;
  if (url && identifier) {
    return post(url + identifier).then(handleErrors);
  } else {
    return Promise.reject(
      new Error(`Missing ${COMPANY_SEND_WELCOME_EMAILS} url or identifier`)
    );
  }
};
