// ##############################
// // // Table styles
// #############################

import {
  grayColor,
  hoverBackground,
  primaryColor,
} from "assets/jss/globalStyle";

const cellStyle = {
  width: "9%",
  fontSize: "13px",
  fontWeight: 600,
  display: "flex",
  cursor: "pointer",
  position: "relative" as "relative",
};

export const innerRow = {
  width: "100%",
  display: "flex",
  padding: "10px 20px !important",
  alignItems: "center",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: hoverBackground,
  },
};

export const tableRow = {
  width: "100%",
  display: "flex",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  padding: "10px 20px",
  alignItems: "center",
};

export const tableBody = {
  position: "relative" as "relative",
  minHeight: "40px",
};

export const tableCell = cellStyle;

export const largeCell = {
  ...cellStyle,
  width: "22%",
};

export const mediumCell = {
  ...cellStyle,
  width: "19%",
};

export const noPointerCell = {
  ...cellStyle,
  cursor: "initial",
};

export const lastCell = {
  ...cellStyle,
  width: "10%",
  justifyContent: "flex-end",
  alignItems: "center",
};

export const cellItem = {
  margin: "5px 0",
};

export const pagination = {
  paginationRoot: {
    color: grayColor,
    fontSize: ".8rem",
    paddingLeft: "0px",
  },
  paginationCaption: {
    fontSize: ".8rem",
  },
  paginationIcon: {
    color: primaryColor,
  },
};
