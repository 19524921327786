import {
  innerRow,
  tableRow,
  tableBody,
  tableCell,
  largeCell,
  mediumCell,
  noPointerCell,
  lastCell,
  cellItem,
  pagination,
} from "assets/jss/components/tableStyle";

import { ellipsisOverflow } from "assets/jss/globalStyle";

// eslint-disable-next-line
const featuresTableStyle = (theme, props) => ({
  innerRow: {
    ...innerRow,
    paddingBottom: "4px !important",
    paddingTop: "4px !important",
  },
  tableRow,
  tableBody,
  tableCell,
  largeCell: { ...largeCell, width: "30%" },
  largeCellWithLeftPadding: { ...largeCell, width: "30%", paddingLeft: "15px" },
  mediumCell,
  roleCell: noPointerCell,
  lastCell: { ...lastCell, width: "auto", flexGrow: 1 },
  cellItem,
  sortIcon: {
    marginLeft: "2px",
    transition: "all 0.25s ease",
    color: "rgba(0,0,0, .7)",
  },
  iconButton: {
    padding: "6px",
    color: "gray",
  },
  caption: {
    fontSize: ".8rem",
  },
  ...pagination,
  ellipsisOverflow,
});

export default featuresTableStyle;
