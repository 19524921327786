import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { withStyles } from "tss-react/mui";
import sidebarStyle from "components/Sidebar/sidebarStyle";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Sidebar = (props) => {
  const { pathname, routes, search, companySelected, userState } = props;
  const classes = withStyles.getClasses(props);
  const [subNavState, setSubNavState] = useState({});

  function filterRoute(route) {
    if (!route.sidebarName) return false;
    else return true;
  }

  return (
    <aside
      className={classes.root}
      style={userState?.accessLevel < 40 ? { marginTop: 0 } : {}}
    >
      <List
        component="nav"
        aria-labelledby="Towers Pages"
        subheader={
          <ListSubheader component="div" className={classes.routeListHeader}>
            {companySelected.title}
          </ListSubheader>
        }
      >
        {routes.filter(filterRoute).map((route) => {
          if (route.subRoutes) {
            const isOpen = Boolean(subNavState[route.path]);
            return (
              <Fragment key={route.path}>
                <ListItem
                  className={classes.routeListItem}
                  button
                  onClick={() => {
                    const newState = { ...subNavState };
                    newState[route.path] = !isOpen;
                    setSubNavState(newState);
                  }}
                >
                  <NavLink
                    to={{ pathname: route.path, search }}
                    className={cx(
                      classes.routeLink,
                      route.path === pathname ? classes.routeLinkActive : ""
                    )}
                    data-aut={`Sidebar|${route.dataAut}`}
                  >
                    <route.icon />
                    <ListItemText
                      primary={route.sidebarName}
                      className={classes.routeText}
                    />
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                  </NavLink>
                </ListItem>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {route.subRoutes.map((r) => (
                      <ListItem
                        className={classes.routeListItem}
                        button
                        key={r.path}
                      >
                        <NavLink
                          to={{ pathname: r.path, search }}
                          className={cx(
                            classes.subRouteLink,
                            r.path === pathname ? classes.routeLinkActive : ""
                          )}
                          data-aut={`Sidebar|${r.dataAut}`}
                        >
                          <span className={classes.subRouteText}>
                            {r.sidebarName}
                          </span>
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            );
          } else {
            return (
              <ListItem
                className={classes.routeListItem}
                button
                key={route.path}
              >
                <NavLink
                  to={{ pathname: route.path, search }}
                  className={cx(
                    classes.routeLink,
                    route.path === pathname ? classes.routeLinkActive : ""
                  )}
                  data-aut={`Sidebar|${route.dataAut}`}
                >
                  <route.icon />
                  <ListItemText
                    primary={route.sidebarName}
                    className={classes.routeText}
                  />
                </NavLink>
              </ListItem>
            );
          }
        })}
      </List>
    </aside>
  );
};

export default withStyles(Sidebar, sidebarStyle);
