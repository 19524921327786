import { useCallback, useEffect } from "react";
import { useSafeSetState } from "hooks/useState";
import { withStyles } from "tss-react/mui";
import { Button, Confirmation, Dropdown, Input, Modal } from "components";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DeleteForever } from "@mui/icons-material";
import { RoleI } from "types/Role";
import { RoleTypeI } from "types/Role";
import { useRoleTypes } from "hooks/roleTypes";
import { AppI } from "types/Apps";
import { useApps } from "hooks/apps";

// eslint-disable-next-line
const styles = (theme, props) => ({
  container: {
    marginTop: "22px",
    textAlign: "center",
  },
  dropdownWrapper: {
    minHeight: "100px",
  },
  popover: {
    textAlign: "center",
    padding: "22px",
    maxWidth: "750px",
  },
  requiredText: {
    fontSize: "14px",
  },
  popupText: {
    marginBottom: 0,
  },
  buttonRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  trashButton: {
    fontSize: "42px",
  },
  enabledCheckbox: {
    width: "100%",
    marginTop: "16px",
  },
  checkboxLabel: {
    fontFamily: '"Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif',
    fontWeight: 600,
    fontSize: "0.875rem",
    color: "black",
  },
  checkbox: {
    "& svg": {
      fontSize: "32px",
    },
  },
});

const initialState = {
  name: "",
  active: true,
  application: null as AppI,
  roleType: { value: 0, label: "Traveler", id: 0 },
};

interface RolesModalI {
  close: () => void;
  handleDelete: (id: string) => void;
  isCreating: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  isOpen: boolean;
  isRequestingDelete: boolean;
  isRequestingSave: boolean;
  saveRole: (
    params: {
      active: boolean;
      application?: number;
      roleTypeID: number;
      name: string;
    },
    role?: RoleI,
    isEditing?: boolean
  ) => void;
  selectedRole?: RoleI;
  setModalToDeleteMode: () => void;
  classes?: Partial<
    Record<
      | "container"
      | "dropdownWrapper"
      | "popover"
      | "requiredText"
      | "popupText"
      | "buttonRow"
      | "trashButton"
      | "enabledCheckbox"
      | "checkboxLabel"
      | "checkbox",
      string
    >
  >;
}

type Params = {
  name: string;
  active: boolean;
  application?: AppI;
  roleType?: RoleTypeI;
};

function RolesModal(props: RolesModalI) {
  const {
    close,
    handleDelete,
    isCreating,
    isDeleting,
    isEditing,
    isOpen,
    isRequestingDelete,
    isRequestingSave,
    saveRole,
    selectedRole,
    setModalToDeleteMode,
  } = props;
  const classes = withStyles.getClasses(props);
  const [{ name, active, application, roleType }, safeSetState] =
    useSafeSetState(initialState);

  const roleTypes = useRoleTypes();
  const apps = useApps();

  const getRoleTypes = useCallback(() => {
    if (roleTypes.data?.length) {
      return roleTypes.data.find((rt) => rt.id === selectedRole?.roleTypeID);
    }
  }, [selectedRole?.roleTypeID, roleTypes.data]);

  useEffect(() => {
    if (selectedRole) {
      const params: Params = {
        name: selectedRole.name,
        active: selectedRole.active,
      };

      params.roleType = getRoleTypes();

      if (selectedRole.applicationID && apps.data && apps.data.length) {
        params.application = apps.data.find(
          (a) => a.id === selectedRole.applicationID
        );
      }
      safeSetState(params);
    } else {
      safeSetState(initialState);
    }
  }, [getRoleTypes, selectedRole, apps.data, safeSetState]);

  useEffect(() => {
    if (selectedRole && roleTypes.data?.length) {
      const foundRoleType = roleTypes.data.find(
        (rt) => rt.id == selectedRole.roleTypeID
      );
      safeSetState({ roleType: foundRoleType });
    }
  }, [roleTypes.data, safeSetState, selectedRole]);

  useEffect(() => {
    if (!isOpen) safeSetState(initialState);
  }, [isOpen, safeSetState]);

  function canSave() {
    return name && name.length;
  }

  function handleCheckboxChange(event, active) {
    safeSetState({ active });
  }

  function handleApplicationDropDownChange(app) {
    if (app === application) safeSetState({ application: null });
    else safeSetState({ application: app });
  }

  function handleRoleTypeDropDownChange(roleType) {
    safeSetState({ roleType });
  }

  function handleInputChange(name) {
    safeSetState({ name });
  }

  function handleSave() {
    if (canSave())
      saveRole({
        active,
        application: application ? application.id : null,
        roleTypeID: roleType.id,
        name,
      });
  }

  function renderBody() {
    if (isDeleting) return renderConfirmation();
    else return renderForm();
  }

  function renderConfirmation() {
    return (
      <Confirmation
        handleConfirmation={() => handleDelete(selectedRole.id)}
        handleClose={close}
        header={`Are you sure you want to delete the role: ${selectedRole.name}?`}
        requestingConfirmation={isRequestingDelete}
      />
    );
  }

  function renderHeader() {
    if (isCreating) return "Add a role";
    else if (isEditing) return "Edit role";
    else return null;
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleSave();
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <Input label={`Name *`} value={name} onChange={handleInputChange} />
        <div className={classes.dropdownWrapper}>
          <Dropdown
            label="Role Type *"
            onChange={handleRoleTypeDropDownChange}
            selectedItems={roleType}
            items={roleTypes.data || []}
            disabled={roleTypes.isLoading || roleTypes.isError}
          />
        </div>

        <div className={classes.dropdownWrapper}>
          <Dropdown
            label="Application"
            onChange={handleApplicationDropDownChange}
            selectedItems={application}
            items={apps.data || []}
            disabled={apps.isLoading || apps.isError}
          />
        </div>

        <FormControlLabel
          className={classes.enabledCheckbox}
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              classes={{ root: classes.checkbox }}
              checked={active}
              color="primary"
              onChange={handleCheckboxChange}
              value="ssoDisabled"
              inputProps={{ "aria-label": "Enable Role" }}
            />
          }
          label="Enable Role"
        />
        <div className={classes.buttonRow}>
          <span>
            <Button
              onClick={handleSave}
              label="Save"
              loading={isRequestingSave}
              disabled={isRequestingSave || !canSave()}
            />
            <Button onClick={close} label="Cancel" isTextButton />
          </span>
          {!isCreating && (
            <Tooltip title="Delete Role" placement={"top"} enterDelay={150}>
              <IconButton onClick={setModalToDeleteMode} size="large">
                <DeleteForever className={classes.trashButton} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </form>
    );
  }

  return (
    <Modal
      close={isDeleting ? null : close}
      hasRequiredFields
      isOpen={isOpen}
      header={renderHeader()}
    >
      {renderBody()}
    </Modal>
  );
}

export default withStyles(RolesModal, styles);
