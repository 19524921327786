import {
  ellipsisOverflow,
  grayColor,
  flexVertAligned,
} from "assets/jss/globalStyle";
import { ThemeI } from "types/Theme";

const tableCell = {
  padding: "10px",
  minWidth: "150px",
  maxWidth: "200px",
  paddingRight: 0,
};

// eslint-disable-next-line
const companyRoleFeatureStyle = (theme: ThemeI, props) => ({
  propertyCell: {
    minWidth: "240px",
    maxWidth: "240px",
    fontWeight: "normal",
    paddingRight: 0,
    ...ellipsisOverflow,
    position: "sticky",
    zIndex: 1,
    backgroundColor: "white",
    left: 0,
    cursor: "pointer",
  },
  cellContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  error: {
    marginLeft: "-5px",
    color: "red",
  },
  featureRow: {
    "&:hover > td": {
      backgroundColor: "#F5F5F5",
    },
  },
  tableCell: {
    ...tableCell,
    "&:nth-child(7)": {
      borderLeft: "solid 1px rgba(224, 224, 224, 1)",
    },
  },
  tableCellApprove: {
    ...tableCell,
    "&:nth-child(8)": {
      borderLeft: "solid 1px rgba(224, 224, 224, 1)",
    },
  },
  flexVertAligned,
  popupIcon: {
    marginLeft: "6px",
    fontSize: "18px",
    color: grayColor,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  popoverText: {
    fontSize: "12px",
  },
});

export default companyRoleFeatureStyle;
