import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import IESplashPage from "layouts/IESplashPage";
import { getIEVersion } from "common/helpers";

const rootContainer = document.getElementById("root");
const root = createRoot(rootContainer);
root.render(getIEVersion() ? <IESplashPage /> : <App />);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
