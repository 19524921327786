import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  setUserStateFailed,
  setUserStateSuccess,
} from "redux/actions/userState";
import { RootState } from "App";

import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/Loading/Loading";
import { useCallback, useEffect } from "react";
import { ACCESS_TOKEN, BYPASS } from "services/authConstants";
import jwt_decode from "jwt-decode";
import { UserStateI } from "types/User";
import { setCompany } from "redux/actions/selectedCompanyID";

/** 
Role Based Access: Towers is designed to be used by users with different tiers of roles. Depending on the user's role, they will have limited
or full access to functionality within Towers. Roles are assigned with the user management on Towers. When a user logs into towers, the JWT we
get from Auth0 has info on what roles are assigned to the user. Since a user can have multiple roles, we give them the access level of the highest tier role
that they have. 

Role Breakdown (each role also have the limitations of the roles above it)

ADMINISTRATOR - A - AccessLevel: 100
 - Has full permissions within the application.
 - Can reinstate soft deleted users.

TOWERS ADMINISTRATOR - TADMIN - AccessLevel: 80
  - Cannot assign other users to ADMIN or TOWERS ADMIN role group or see who is in each group.
  - Cannot see CTM Approve UI

TOWERS SUPPORT 2 - TS2 - AccessLevel: 60
  - Cannot see or do anything with application roles/permissions, but has full access outside of that.

TOWERS SUPPORT 1 - TS1 - AccessLevel: 50
  - Can Send Welcome Email, or Password Reset Email on user details page. Can't change user details/password.
  - No Onboarding tab.
  - No Roles tab.
  - No Features tab
  - Account Settings is read only
  - No Global Settings Tab
  - Cannot make configuration changes

APPROVAL ADMIN - APAD - AccessLevel: 40
  - Has access to approve and can select different companies to configure their approve. 
  - No other access.

APPROVE/CLIENT SUPPORT HYBRID - CS and AS - AccessLevel: 30
  - Matrix of both of the two roles below

CLIENT SUPPORT - CS - AccessLevel: 20
  - Client Role, automatically puts you into your companies context and doesn't let you switch to other companies
  - Can't see Features/Roles
  - Can see users trigger password and welcome emails.
  - Can't see itineraries
  - Logos can be changed
  - Company Details Page is name and logo only
  - No Top TABS, can only see within company of logged in user

APPROVE SUPPORT - AS - AccessLevel: 10
  - Client Role, automatically puts you into your companies context and doesn't let you switch to other companies
  - Gives Access to Approve and nothing else
  - On Approve Config page, do not show Queue Management settings

*/

interface AuthenticationPropsI {
  location: string;
  setUserStateFailed: (er: Error) => void;
  setUserStateSuccess: (val: UserStateI) => void;
  setCompany: (id: string) => void;
  userState: UserStateI | Error;
}

const mapDispatchToProps = (dispatch) => ({
  setUserStateSuccess: (data: UserStateI) =>
    dispatch(setUserStateSuccess(data)),
  setUserStateFailed: (er) => dispatch(setUserStateFailed(er)),
  setCompany: (id) => dispatch(setCompany(id)),
});

const ProtectedRoute = (WrappedComponent: React.FunctionComponent<any>) => {
  const Authentication = (props: AuthenticationPropsI) => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const storedAccessToken = localStorage.getItem(ACCESS_TOKEN);
    const useStoredAccessToken =
      Boolean(localStorage.getItem(BYPASS)) && Boolean(storedAccessToken);
    const shouldRegisterUser = useStoredAccessToken || isAuthenticated;
    const registerUser = useCallback(() => {
      const { setUserStateSuccess, userState, setCompany } = props;
      if (!userState) {
        let token = null;
        try {
          if (useStoredAccessToken) token = jwt_decode(storedAccessToken);
        } catch (e) {
          token = null;
        } finally {
          const uData = token || user;
          const roles = uData["https://www.ctmsmart.com/roles"];
          const companyID = uData["https://www.ctmsmart.com/defaultCompanyID"];
          const fullName = uData["https://www.ctmsmart.com/fullName"];
          const givenName =
            fullName && typeof fullName === "string"
              ? fullName.split(" ")[0]
              : "";
          let accessLevel = 0;
          if (Array.isArray(roles)) {
            accessLevel = roles.reduce((a, c) => {
              if (c.code === "A") return 100;
              if (c.code === "TADMIN" && a < 80) return 80;
              if (c.code === "TS2" && a < 60) return 60;
              if (c.code === "TS1" && a < 50) return 50;
              if (c.code === "APAD" && a < 40) return 40;
              if (c.code === "CS" && a < 20) {
                return a === 10 ? 30 : 20;
              }
              if (c.code === "AS" && a <= 20) {
                return a === 20 ? 30 : 10;
              }
              return a;
            }, accessLevel);
          }
          const userData = {
            accessLevel,
            roles,
            givenName,
            companyID,
          };
          if (accessLevel <= 30) {
            setCompany(companyID);
          }
          setUserStateSuccess(userData);
        }
      }
    }, [props, user, useStoredAccessToken, storedAccessToken]);

    useEffect(() => {
      if (shouldRegisterUser) registerUser();
    }, [shouldRegisterUser, registerUser]);

    const { userState, ...rest } = props;
    if (isLoading)
      return <Loading active loadingText="Loading..." addContainer />;
    if (shouldRegisterUser) {
      return userState ? (
        <WrappedComponent {...rest} />
      ) : (
        <Loading active loadingText="Loading..." addContainer />
      );
    } else {
      return (
        <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
      );
    }
  };

  return connect(
    (state: RootState) => ({
      userState: state.userState.payload,
    }),
    mapDispatchToProps
  )(Authentication);
};

export default ProtectedRoute;
