import { Fragment } from "react";
import { withStyles } from "tss-react/mui";
import { useSafeSetState } from "hooks/useState";
import { Loading, MultiToggle, ServerEventTrigger } from "components";
import { sendCompanyWelcomeEmails } from "services/selectedCompany";
import emailTriggerStyle from "components/EmailTriggers/emailTriggerStyle";
import { NotificationObjI } from "types";
import { CompanyI } from "types/Company";
import { useEditCompanyEmailTrigger } from "hooks/companies";

const initialState = {
  welcomeEmailSent: false,
  welcomeEmailSending: false,
  welcomeEmailError: null,
};

interface EmailTriggersI {
  addNotification: (notification: NotificationObjI) => void;
  companySelected: CompanyI;
  selectedCompanyID: string;
  classes?: Partial<
    Record<
      | "root"
      | "header"
      | "loadingContainer"
      | "settingsContainer"
      | "settingsSection"
      | "settingsHeader"
      | "warningText"
      | "descriptionText",
      string
    >
  >;
}

const EmailTriggers = (props: EmailTriggersI) => {
  const { addNotification, companySelected, selectedCompanyID } = props;
  const classes = withStyles.getClasses(props);

  const autoTriggerProperties = [{ name: "On" }, { name: "Off" }];

  const toggleAutoTrigger = useEditCompanyEmailTrigger(selectedCompanyID);

  const [
    { welcomeEmailSent, welcomeEmailSending, welcomeEmailError },
    safeSetState,
  ] = useSafeSetState(initialState);

  const autoTriggerClick = (val) => {
    const { welcomeEmailAutotriggered } = companySelected;
    if (val === 0 && !welcomeEmailAutotriggered) {
      toggleAutoTrigger.mutate({
        ...companySelected,
        welcomeEmailAutotriggered: true,
      });
    } else if (val === 1 && welcomeEmailAutotriggered) {
      toggleAutoTrigger.mutate({
        ...companySelected,
        welcomeEmailAutotriggered: false,
      });
    }
  };

  const welcomeEmailEventTrigger = () => {
    safeSetState({
      welcomeEmailSending: true,
      welcomeEmailSent: false,
      welcomeEmailError: null,
    });
    sendCompanyWelcomeEmails(companySelected.id)
      .then(() =>
        safeSetState({
          welcomeEmailSending: false,
          welcomeEmailError: null,
          welcomeEmailSent: true,
        })
      )
      .catch((er) => {
        safeSetState({
          welcomeEmailSending: false,
          welcomeEmailError: "There was an error triggering emails.",
          welcomeEmailSent: false,
        });
        addNotification({
          text: `Error triggering emails.`,
          errors: er.customErrors,
          type: "error",
        });
      });
  };

  if (!companySelected)
    return (
      <div className={classes.loadingContainer}>
        <Loading active={true} loadingText="Loading Company" />
      </div>
    );
  return (
    <Fragment>
      <h2 className={classes.header}>WELCOME EMAIL TRIGGERS</h2>
      <p className={classes.warningText}>
        Note: Email triggers will not function for Companies that have been
        configured to login via SSO.
      </p>
      <div className={classes.settingsContainer}>
        <div className={classes.settingsSection}>
          <h3 className={classes.settingsHeader}>Auto Trigger</h3>
          <MultiToggle
            value={companySelected.welcomeEmailAutotriggered ? 0 : 1}
            properties={autoTriggerProperties}
            handleClick={autoTriggerClick}
            label="Auto Trigger"
          />
          <p className={classes.descriptionText}>
            'Welcome Email' will be automatically sent to new users when they
            are added.
          </p>
        </div>
        <div className={classes.settingsSection}>
          <h6 className={classes.settingsHeader}>Manual Trigger</h6>
          <ServerEventTrigger
            text="Send 'Welcome email' to new users."
            handleClick={welcomeEmailEventTrigger}
            loading={welcomeEmailSending}
            success={welcomeEmailSent}
            error={welcomeEmailError}
            data-aut="CompanySendWelcomeEmail"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default withStyles(EmailTriggers, emailTriggerStyle);
