import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { CITIES, CITYSEARCH } from "common/constants";
import { handleErrors } from "common/helpers";

export const fetchCities = (countryCode) => {
  const url = getConfig(CITIES).url + countryCode;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${CITIES} url`));
  }
};

export const searchCities = (searchTerm) => {
  const url = getConfig(CITYSEARCH).url;
  if (url) {
    return get(url, { searchTerm })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${CITYSEARCH} url`));
  }
};
