import { MESSAGE_LOGS } from "common/constants";

export const MESSAGE_LOGS_SET_SERVER_QUERY = `${MESSAGE_LOGS}_SET_SERVER_QUERY`;

export const setMessageLogsServerQuery = () => ({
  type: MESSAGE_LOGS_SET_SERVER_QUERY,
});

export const MESSAGE_LOGS_SET_QUERY = `${MESSAGE_LOGS}_SET_QUERY`;

export const setMessageLogsQuery = (query) => ({
  type: MESSAGE_LOGS_SET_QUERY,
  query,
});

export const MESSAGE_LOGS_SET_COMPANY = `${MESSAGE_LOGS}_SET_COMPANY`;

export const setMessageLogsCompany = (company) => ({
  type: MESSAGE_LOGS_SET_COMPANY,
  company,
});

export const MESSAGE_LOGS_SET_DEBTOR = `${MESSAGE_LOGS}_SET_DEBTOR`;

export const setMessageLogsDebtor = (debtor) => ({
  type: MESSAGE_LOGS_SET_DEBTOR,
  debtor,
});

export const MESSAGE_LOGS_SET_TEMPLATES = `${MESSAGE_LOGS}_SET_TEMPLATES`;

export const setMessageLogsTemplate = (template) => ({
  type: MESSAGE_LOGS_SET_TEMPLATES,
  template,
});

export const MESSAGE_LOGS_SET_ORDER = `${MESSAGE_LOGS}_SET_ORDER`;

export const setMessageLogsOrder = (order, orderBy) => ({
  type: MESSAGE_LOGS_SET_ORDER,
  order,
  orderBy,
});

export const MESSAGE_LOGS_SET_PAGE = `${MESSAGE_LOGS}_SET_PAGE`;

export const setMessageLogsPage = (page) => ({
  type: MESSAGE_LOGS_SET_PAGE,
  page,
});

export const MESSAGE_LOGS_SET_ROWS_PER_PAGE = `${MESSAGE_LOGS}_SET_ROWS_PER_PAGE`;

export const setMessageLogsRowsPerPage = (rows) => ({
  type: MESSAGE_LOGS_SET_ROWS_PER_PAGE,
  rows,
});

export const MESSAGE_LOGS_SET_START_DATE = `${MESSAGE_LOGS}_SET_START_DATE`;

export const setMessageLogsStartDate = (startDate) => ({
  type: MESSAGE_LOGS_SET_START_DATE,
  startDate,
});

export const MESSAGE_LOGS_SET_END_DATE = `${MESSAGE_LOGS}_SET_END_DATE`;

export const setMessageLogsEndDate = (endDate) => ({
  type: MESSAGE_LOGS_SET_END_DATE,
  endDate,
});
