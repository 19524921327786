// ##############################
// // // Sidebar styles
// #############################

import { blueBackgroundColor } from "assets/jss/globalStyle";

const routeLink = {
  width: "100%",
  textDecoration: "none",
  color: "white",
  display: "flex",
  alignItems: "center",
  padding: "12px 16px",
  "&:hover": {
    backgroundColor: "#1E2E3E",
    color: "white",
  },
  "&:focus": {
    color: "white",
  },
};

// eslint-disable-next-line
const sidebarStyle = (theme, props) => ({
  root: {
    height: "auto",
    width: "300px",
    backgroundColor: blueBackgroundColor,
    color: "white",
    paddingTop: "50px",
    marginTop: "8px",
  },
  company: {
    paddingLeft: "20px",
    fontWeight: 600,
    fontSize: "16px",
    wordWrap: "break-word",
  },
  routes: {
    marginTop: "30px",
    listStyle: "none",
    padding: 0,
  },
  routeListHeader: {
    color: "white",
    fontWeight: 600,
    fontSize: "16px",
    backgroundColor: blueBackgroundColor,
  },
  routeListItem: {
    width: "100%",
    padding: 0,
  },
  routeLink,
  subRouteLink: {
    ...routeLink,
    padding: "8px 8px 8px 16px",
    fontSize: "12px",
  },
  dashboardLink: {
    color: "white",
    marginTop: "30px",
  },
  routeLinkActive: {
    backgroundColor: "#1E2E3E",
  },
  routeText: {
    marginLeft: "8px",
  },
  subRouteText: {
    fontSize: "14px",
    marginLeft: "32px",
  },
});

export default sidebarStyle;
