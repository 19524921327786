import { useQuery } from "@tanstack/react-query";
import { fetchRoleTypes } from "services/roleTypes";
import { RoleTypeI } from "types/Role";
import { QUERYKEY_ROLE_TYPES } from "./queryKeys";

function mapRoleTypes(data): RoleTypeI[] {
  return data.map((rt) => {
    return {
      ...rt,
      value: rt.id,
      label: rt.name,
    };
  });
}

export function useRoleTypes() {
  return useQuery({
    queryKey: [QUERYKEY_ROLE_TYPES],
    queryFn: fetchRoleTypes,
    select: mapRoleTypes,
  });
}
