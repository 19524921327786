import React from "react";
import cx from "classnames";
import Button from "@mui/material/Button";
import { withStyles } from "tss-react/mui";
import toggleButtonStyle from "components/ToggleButton/toggleButtonStyle";

interface ToggleButtonI {
  "data-aut"?: string;
  label: string;
  selected: boolean;
  onClick: () => void;
  classes?: Partial<
    Record<"buttonStyle" | "unSelected" | "selected" | "text", string>
  >;
}

const ToggleButton = (props: ToggleButtonI) => {
  const { label, selected, onClick } = props;
  const classes = withStyles.getClasses(props);
  const dataAut = props["data-aut"];
  const buttonClass = cx(
    classes.buttonStyle,
    selected ? classes.selected : classes.unSelected
  );
  return (
    <Button className={buttonClass} onClick={onClick} data-aut={dataAut}>
      <span className={classes.text}>{label}</span>
    </Button>
  );
};

const ToggleButtonStyled = withStyles(ToggleButton, toggleButtonStyle);

export default ToggleButtonStyled;
