import React from "react";
import { withStyles } from "tss-react/mui";
import { Button } from "components";
import { ellipsisOverflow } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (props, theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  buttonWrapper: {
    flexShrink: 0,
  },
  input: {
    display: "none",
  },
  selectedValue: {
    marginTop: "20px",
    marginLeft: "10px",
    color: "black",
    ...ellipsisOverflow,
  },
});

interface UploadInputI {
  onChange: (file: File) => void;
  label: string;
  value: string | { name: string };
  classes?: Partial<
    Record<"wrapper" | "buttonWrapper" | "input" | "selectedValue", string>
  >;
}

function UploadInput(props: UploadInputI) {
  const { onChange, label, value } = props;
  const classes = withStyles.getClasses(props);
  const handleChange = (e) => {
    onChange(e.target.files[0]);
  };

  function trimUrl(url) {
    // eslint-disable-next-line
    return url.replace(/^.*[\\\/]/, "");
  }

  function renderValue() {
    if (!value) return;
    else if (typeof value === "object" && value.name) return value.name;
    else if (typeof value === "string")
      return (
        <a target="_blank" href={value}>
          {trimUrl(value)}
        </a>
      );
  }

  return (
    <div className={classes.wrapper}>
      <section className={classes.buttonWrapper}>
        <input
          className={classes.input}
          type="file"
          id={label}
          onChange={handleChange}
        />
        <label htmlFor={label} className={classes.wrapper}>
          <Button
            label={label}
            isBlue
            disabled={false}
            loading={false}
            component="span"
            data-aut={`${label}|FileUpload`}
          />
        </label>
      </section>
      <section className={classes.selectedValue}>{renderValue()}</section>
    </div>
  );
}

const UploadInputStyled = withStyles(UploadInput, styles);

export default UploadInputStyled;
