import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { ROLE_TYPES } from "common/constants";
import { handleErrors } from "common/helpers";
import { RoleTypeI } from "types/Role";

export const fetchRoleTypes = (): Promise<RoleTypeI[]> => {
  const url = getConfig(ROLE_TYPES).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLE_TYPES} url or id`));
  }
};
