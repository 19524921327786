import {
  COMPANY_SEARCH_COMPANIES_FILTER,
  COMPANY_SEARCH_COMPANIES_ORDER,
  COMPANY_SEARCH_COMPANIES_ORDER_BY,
  COMPANY_SEARCH_COMPANIES_PAGE,
  COMPANY_SEARCH_COMPANIES_ROWS_PER_PAGE,
} from "redux/actions/companySearch";

export interface CompanySearchStateI {
  companiesFilter: string;
  companiesOrder: {
    companiesOrder: "asc" | "desc";
    companiesOrderBy: string;
  };
  companiesPage: number;
  companiesRowsPerPage: number;
}

const initialState: CompanySearchStateI = {
  companiesFilter: "",
  companiesOrder: { companiesOrder: "desc", companiesOrderBy: "title" },
  companiesPage: 0,
  companiesRowsPerPage: 10,
};

const companySearch = (
  state: CompanySearchStateI = initialState,
  action
): CompanySearchStateI => {
  switch (action.type) {
    case COMPANY_SEARCH_COMPANIES_FILTER:
      return {
        ...state,
        companiesFilter: action.companiesFilter,
        companiesPage: 0,
      };
    case COMPANY_SEARCH_COMPANIES_ORDER:
      return {
        ...state,
        companiesOrder: action.companiesOrder,
      };
    case COMPANY_SEARCH_COMPANIES_ORDER_BY:
      return {
        ...state,
        companiesOrder: action.payload.companiesOrder,
      };
    case COMPANY_SEARCH_COMPANIES_PAGE:
      return {
        ...state,
        companiesPage: action.companiesPage,
      };
    case COMPANY_SEARCH_COMPANIES_ROWS_PER_PAGE:
      return {
        ...state,
        companiesRowsPerPage: action.companiesRowsPerPage,
      };
    default:
      return state;
  }
};

export default companySearch;
