import { Fragment } from "react";
import { withStyles } from "tss-react/mui";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { Loading, SortableTableHead } from "components";
import {
  setCompaniesFilter,
  setCompaniesOrder,
  setCompaniesOrderBy,
  setCompaniesPage,
  setCompaniesRowsPerPage,
} from "redux/actions/companySearch";
import { itineraryResetTravelers } from "redux/actions/itinerarySearch";
import { isUserNotSelectingText, smartSort } from "common/helpers";
import companyTableStyle from "components/CompanyTable/companyTableStyle";
import { CompanyI } from "types/Company";
import { DataAutDisplayI, SortOrder } from "types";
import { TableColumnI } from "types/Table";
import { useCompanies } from "hooks/companies";

const columns: TableColumnI[] = [
  {
    id: "title",
    align: "left",
    label: "Name",
    width: "35%",
  },
  {
    id: "active",
    align: "right",
    label: "Active",
    width: "15%",
  },
  {
    id: "identifier",
    align: "right",
    label: "Identifier",
    width: "35%",
  },
  {
    id: "modifiedOn",
    align: "right",
    label: "Modified On",
    width: "15%",
  },
];

interface CompanyTableI {
  companiesFilter: string;
  companiesOrder: SortOrder;
  companiesOrderBy: string;
  companiesPage: 0;
  companiesRowsPerPage: number;
  handleCompanySet: (company: CompanyI) => void;
  setCompaniesFilter: (filter: string) => void;
  setCompaniesOrder: (data: {
    companiesOrder: SortOrder;
    companiesOrderBy: string;
  }) => void;
  setCompaniesPage: (page: number) => void;
  setCompaniesRowsPerPage: (rows: number) => void;
  classes?: Partial<
    Record<
      | "toolBarTitle"
      | "textField"
      | "toolBar"
      | "table"
      | "tableWrapper"
      | "paginationIcon"
      | "tableRow"
      | "loadingContainer",
      string
    >
  >;
}

const CompanyTable = (props: CompanyTableI) => {
  const {
    companiesFilter,
    companiesOrder,
    companiesOrderBy,
    companiesPage,
    companiesRowsPerPage,
    handleCompanySet,
    setCompaniesFilter,
    setCompaniesOrder,
    setCompaniesPage,
    setCompaniesRowsPerPage,
  } = props;
  const classes = withStyles.getClasses(props);

  const companies = useCompanies();
  const companySet = (company) => {
    isUserNotSelectingText() && handleCompanySet(company);
  };

  const handleRequestSort = (event, property) => {
    const newCompaniesOrderBy = property;
    let newCompaniesOrder = "desc" as SortOrder;

    if (companiesOrderBy === property && companiesOrder === "desc") {
      newCompaniesOrder = "asc";
    }

    setCompaniesOrder({
      companiesOrder: newCompaniesOrder,
      companiesOrderBy: newCompaniesOrderBy,
    });
  };

  const handleChangePage = (event, page) => {
    setCompaniesPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setCompaniesRowsPerPage(event.target.value);
  };

  const filterData = () => {
    // If we have a companies filter, make it lowercase
    const companiesFilterLower =
      companiesFilter && companiesFilter.toLowerCase();
    // If we have a companies filter, filter out anything that doesn't match it. If we have no filter, make a copy of the data since we will be sorting it. (Don't mutate state)
    const data =
      companiesFilterLower && companiesFilterLower.length
        ? companies?.data?.filter(
            (company) =>
              // Check to see if the company title has the search string
              (typeof company.title === "string" &&
                company.title.toLowerCase().indexOf(companiesFilterLower) !==
                  -1) ||
              (Array.isArray(company.debtors) &&
                company.debtors.some(
                  (d) =>
                    // Check to see if any of the company debtors has a name with the search string
                    d.name.toLowerCase().indexOf(companiesFilterLower) !== -1 ||
                    // Also check the company debtors identifier
                    d.identifier.toLowerCase().indexOf(companiesFilterLower) !==
                      -1
                ))
          )
        : [...companies.data];
    return data;
  };

  const sortData = (
    a: CompanyI,
    b: CompanyI,
    forceOrderBy?: string,
    forceDesc?: boolean
  ) => {
    const evaluated = smartSort(
      a[forceOrderBy || companiesOrderBy],
      b[forceOrderBy || companiesOrderBy]
    );
    if (forceDesc || companiesOrder === "desc") return evaluated;
    else return -evaluated;
  };

  const renderDebtors = (debtors) => {
    let result = "";
    if (Array.isArray(debtors)) {
      for (let i = 0; i < debtors.length; i++) {
        result += debtors[i].identifier;
        if (i !== debtors.length - 1) result += ", ";
      }
    }
    return result;
  };

  if (companies.isLoading || companies.isError) {
    return (
      <div className={classes.loadingContainer}>
        <Loading
          active={companies.isLoading}
          loadingText="Loading Companies"
          error={companies.isError}
        />
      </div>
    );
  }

  const filteredData: CompanyI[] = filterData();
  let sortedData = [];
  // Perform a double sort if we are sorting by isActive
  // That way we can have our companies in alphabetical order after they are grouped into active/inactive
  if (companiesOrderBy === "active") {
    sortedData = filteredData.sort((a, b) => sortData(a, b, "title", true));
    sortedData = sortedData.sort(sortData);
  } else {
    sortedData = filteredData.sort(sortData);
  }

  return (
    <Fragment>
      <div className={classes.toolBar}>
        <h2 className={classes.toolBarTitle}>SELECT AN ACCOUNT TO EDIT</h2>
        <TextField
          id="standard-with-placeholder"
          label="Filter by Name or Id"
          InputProps={{
            inputProps: {
              "aria-label": "Filter by Name or Id",
              autoComplete: "off",
              "data-aut": "accounts-filter",
            },
          }}
          placeholder=""
          className={classes.textField}
          margin="normal"
          value={companiesFilter}
          onChange={(e) => setCompaniesFilter(e.target.value)}
          variant="standard"
        />
      </div>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <SortableTableHead
            order={companiesOrder}
            orderBy={companiesOrderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            name="Companies"
          />
          <TableBody>
            {sortedData
              .slice(
                companiesPage * companiesRowsPerPage,
                companiesPage * companiesRowsPerPage + companiesRowsPerPage
              )
              .map((n, i) => {
                return (
                  <TableRow
                    hover
                    onClick={() => companySet(n)}
                    role="checkbox"
                    tabIndex={-1}
                    key={n.id}
                    className={classes.tableRow}
                    data-aut={`Companies|Company${i + 1}`}
                  >
                    <TableCell
                      data-aut={`Companies|Company${i + 1}|Title`}
                      component="th"
                      scope="row"
                      padding="normal"
                      align="left"
                      style={{ width: "35%" }}
                    >
                      {n.title}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ width: "15%" }}
                      data-aut={`Companies|Company${i + 1}|Active`}
                    >
                      {n.active ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ width: "35%" }}
                      data-aut={`Companies|Company${i + 1}|Identifier`}
                    >
                      {renderDebtors(n.debtors)}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ width: "15%" }}
                      data-aut={`Companies|Company${i + 1}|ModifiedOn`}
                    >
                      {n.modifiedOn.format("YYYY/MM/DD")}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        classes={{
          selectIcon: classes.paginationIcon,
        }}
        component="div"
        count={sortedData.length}
        rowsPerPage={companiesRowsPerPage}
        page={companiesPage}
        backIconButtonProps={{
          "aria-label": "Previous Page",
          color: "primary",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
          color: "primary",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          SelectDisplayProps: {
            "data-aut": "Companies|RowsPerPage",
          } as DataAutDisplayI,
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  companiesFilter: state.companySearch.companiesFilter,
  companiesOrder: state.companySearch.companiesOrder.companiesOrder,
  companiesOrderBy: state.companySearch.companiesOrder.companiesOrderBy,
  companiesPage: state.companySearch.companiesPage,
  companiesRowsPerPage: state.companySearch.companiesRowsPerPage,
  travelers: state.itinerarySearch.selectedTravelers,
});
const mapDispatchToProps = (dispatch) => ({
  itineraryResetTravelers: () => dispatch(itineraryResetTravelers()),
  setCompaniesFilter: (data) => dispatch(setCompaniesFilter(data)),
  setCompaniesOrder: (data) => dispatch(setCompaniesOrder(data)),
  setCompaniesOrderBy: (data) => dispatch(setCompaniesOrderBy(data)),
  setCompaniesPage: (data) => dispatch(setCompaniesPage(data)),
  setCompaniesRowsPerPage: (data) => dispatch(setCompaniesRowsPerPage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(CompanyTable, companyTableStyle));
