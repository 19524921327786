import moment from "moment";
import {
  ITINERARIES_SELECT_COUNTRY,
  ITINERARIES_SELECT_COMPANY,
  ITINERARIES_SELECT_DEBTOR,
  ITINERARIES_ADD_TRAVELER,
  ITINERARIES_REMOVE_TRAVELER,
  ITINERARIES_RESET_TRAVELERS,
  ITINERARIES_ADD_CITY,
  ITINERARIES_REMOVE_CITY,
  ITINERARIES_ADD_PCC,
  ITINERARIES_REMOVE_PCC,
  ITINERARIES_SET_PNRS,
  ITINERARIES_ADD_SUPPLIER,
  ITINERARIES_REMOVE_SUPPLIER,
  ITINERARIES_SET_SUPPLIER_QUERY,
  ITINERARIES_SELECT,
  ITINERARIES_SET_CITY_QUERY,
  ITINERARIES_SET_TRAVEL_TYPE,
  ITINERARIES_SET_DATES,
  ITINERARIES_SET_SEARCH_PARAMS,
  ITINERARIES_SET_SEARCH_TYPE,
  ITINERARIES_SEARCH_EXPAND,
  ITINERARIES_ORDER,
  ITINERARIES_ORDER_BY,
  ITINERARIES_PAGE,
  ITINERARIES_ROWS_PER_PAGE,
} from "redux/actions/itinerarySearch";
import { parsePNRs } from "services/pnrs";
import { CompanyI } from "types/Company";

import { ItinerarySearchStateI } from "types/ItinerarySearch";

interface ParamsI {
  maxResults: 1000;
}

interface PNRSearchParamsI extends ParamsI {
  SabrePnrs: any[];
}
export interface AdvancedSearchParamsI extends ParamsI {
  EndDate?: any;
  StartDate?: any;
  IncludeAir?: boolean;
  IncludeHotels?: boolean;
  IncludeCars?: boolean;
  travelerEmails?: string[];
  CompanyIds?: string[];
  Debtors?: [string];
  Countries?: [string];
  Cities?: string[];
  SabrePCCs?: string[];
  Vendors?: string[];
}

interface BasicSearchI {
  searchType: string;
}

interface PNRSearchI extends BasicSearchI {
  selectedPNRs: any[];
}

export interface AdvancedItinerarySearchI extends BasicSearchI {
  endDate: any;
  startDate: any;
  travelType: {
    flight: boolean;
    hotel: boolean;
    car: boolean;
  };
  selectedTravelers?: any[];
  selectedCompany?: CompanyI;
  selectedDebtor?: any;
  selectedCountry?: any;
  selectedCities?: any[];
  selectedPCCs?: any[];
  selectedSuppliers?: any[];
  selectedPNRS: "";
}

function isPNRSearch(itin: unknown): itin is PNRSearchI {
  return (itin as PNRSearchI).selectedPNRs.length > 0;
}

function createSearchParams(itinerarySearch): AdvancedSearchParamsI {
  const basicParams: ParamsI = {
    maxResults: 1000,
  };
  if (isPNRSearch(itinerarySearch)) {
    const result: PNRSearchParamsI = {
      ...basicParams,
      SabrePnrs: parsePNRs(itinerarySearch.selectedPNRs),
    };
    return result;
  } else {
    const result: AdvancedSearchParamsI = {
      ...basicParams,
      EndDate: itinerarySearch.endDate.format("MM/DD/YYYY"),
      StartDate: itinerarySearch.startDate.format("MM/DD/YYYY"),
      IncludeAir: itinerarySearch.travelType.flight,
      IncludeHotels: itinerarySearch.travelType.hotel,
      IncludeCars: itinerarySearch.travelType.car,
    };

    if (
      itinerarySearch.selectedTravelers &&
      itinerarySearch.selectedTravelers.length
    ) {
      result.travelerEmails = itinerarySearch.selectedTravelers.map(
        (t) => t.email
      );
    } else if (
      itinerarySearch.searchType === "Companies" &&
      itinerarySearch.selectedCompany
    ) {
      result.CompanyIds = [itinerarySearch.selectedCompany.id];
    } else if (
      itinerarySearch.searchType === "Debtors" &&
      itinerarySearch.selectedDebtor
    ) {
      result.Debtors = [itinerarySearch.selectedDebtor.identifier];
    }
    if (
      itinerarySearch.selectedCountry &&
      !itinerarySearch.selectedCities.length
    )
      result.Countries = [itinerarySearch.selectedCountry.countryCode];
    if (itinerarySearch.selectedCities && itinerarySearch.selectedCities.length)
      result.Cities = itinerarySearch.selectedCities.map((c) => c.code);
    if (itinerarySearch.selectedPCCs && itinerarySearch.selectedPCCs.length)
      result.SabrePCCs = itinerarySearch.selectedPCCs.map((p) => p.code);
    if (
      itinerarySearch.selectedSuppliers &&
      itinerarySearch.selectedSuppliers.length
    )
      result.Vendors = itinerarySearch.selectedSuppliers.map((s) => s.code);

    return result;
  }
}

export const initialState: ItinerarySearchStateI = {
  cityQuery: "",
  expanded: false,
  endDate: moment().add(1, "month"),
  itinerariesOrder: "desc",
  itinerariesOrderBy: "PNR",
  itinerariesPage: 0,
  itinerariesRowsPerPage: 10,
  searchParams: null,
  searchType: "Companies",
  selectedCountry: null,
  selectedCompany: null,
  selectedDebtor: null,
  selectedCities: [],
  selectedItinerary: null,
  selectedPCCs: [],
  selectedPNRs: "",
  selectedSuppliers: [],
  selectedTravelers: [],
  startDate: moment(),
  suppliersQuery: "",
  travelType: {
    flight: true,
    hotel: true,
    car: true,
  },
};

export const pnrMapper = (pnr) => ({ label: pnr });

const itinerarySearch = (
  state = initialState,
  action
): ItinerarySearchStateI => {
  switch (action.type) {
    case ITINERARIES_SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: createSearchParams(state),
      };
    case ITINERARIES_SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload,
      };
    case ITINERARIES_SET_PNRS:
      return {
        ...state,
        selectedPNRs: action.payload,
      };
    case ITINERARIES_SEARCH_EXPAND:
      return {
        ...state,
        expanded: !state.expanded,
      };
    case ITINERARIES_SELECT:
      return {
        ...state,
        selectedItinerary: action.selectedItinerary,
      };
    case ITINERARIES_SELECT_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
        cityQuery: "",
        selectedCities: [],
      };
    case ITINERARIES_SET_CITY_QUERY:
      return {
        ...state,
        cityQuery: action.query,
      };

    case ITINERARIES_SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
        selectedTravelers: [],
      };
    case ITINERARIES_SELECT_DEBTOR:
      return {
        ...state,
        selectedDebtor: action.payload,
        selectedTravelers: [],
      };
    case ITINERARIES_ADD_TRAVELER: {
      const traveler = action.payload;
      const travelers = [...state.selectedTravelers];
      if (travelers.every((t) => t.id !== traveler.id))
        travelers.push(traveler);
      return {
        ...state,
        selectedTravelers: travelers,
      };
    }
    case ITINERARIES_REMOVE_TRAVELER:
      return {
        ...state,
        selectedTravelers: state.selectedTravelers.filter(
          (t) => t.email !== action.payload.email
        ),
      };
    case ITINERARIES_RESET_TRAVELERS:
      return {
        ...state,
        selectedTravelers: [],
      };
    case ITINERARIES_ADD_CITY: {
      const city = action.payload;
      const cities = [...state.selectedCities];
      if (cities.every((c) => c.code !== city.code)) cities.push(city);
      return {
        ...state,
        selectedCities: cities,
      };
    }
    case ITINERARIES_REMOVE_CITY:
      return {
        ...state,
        selectedCities: state.selectedCities.filter(
          (c) => c.code !== action.payload.code
        ),
      };
    case ITINERARIES_SET_DATES: {
      const { endDate, startDate } = action.payload;
      return {
        ...state,
        endDate,
        startDate,
      };
    }
    case ITINERARIES_SET_TRAVEL_TYPE:
      return {
        ...state,
        travelType: action.payload,
      };
    case ITINERARIES_SET_SUPPLIER_QUERY:
      return {
        ...state,
        suppliersQuery: action.query,
      };
    case ITINERARIES_ADD_SUPPLIER: {
      const supplier = action.payload;
      const suppliers = [...state.selectedSuppliers];
      if (suppliers.every((s) => s.name !== supplier.name))
        suppliers.push(supplier);
      return {
        ...state,
        selectedSuppliers: suppliers,
      };
    }
    case ITINERARIES_REMOVE_SUPPLIER:
      return {
        ...state,
        selectedSuppliers: state.selectedSuppliers.filter(
          (s) => s.name !== action.payload.name
        ),
      };
    case ITINERARIES_ADD_PCC: {
      const selectedPCCs = [...state.selectedPCCs];
      if (selectedPCCs.every((pcc) => pcc !== action.payload))
        selectedPCCs.push(action.payload);
      return {
        ...state,
        selectedPCCs,
      };
    }
    case ITINERARIES_REMOVE_PCC:
      return {
        ...state,
        selectedPCCs: state.selectedPCCs.filter(
          (pcc) => pcc !== action.payload
        ),
      };
    case ITINERARIES_ORDER:
      return {
        ...state,
        itinerariesOrder: action.payload,
        selectedItinerary: null,
      };
    case ITINERARIES_ORDER_BY:
      return {
        ...state,
        itinerariesOrder: action.payload.itinerariesOrder,
        itinerariesOrderBy: action.payload.itinerariesOrderBy,
        selectedItinerary: null,
      };
    case ITINERARIES_PAGE:
      return {
        ...state,
        itinerariesPage: action.payload,
        selectedItinerary: null,
      };
    case ITINERARIES_ROWS_PER_PAGE:
      return {
        ...state,
        itinerariesRowsPerPage: action.payload,
        selectedItinerary: null,
      };
    default:
      return state;
  }
};

export default itinerarySearch;
