import { deleteRequest, get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { COMPANIES } from "common/constants";
import { handleErrors, wrapCSVRowWithQuotes } from "common/helpers";
import { CompanyI } from "types/Company";

const csvColumnHeaderMap = {
  accountName: "Account Name",
  status: "Status",
  towersAccountId: "Towers Account ID",
  enableImpersonation: "Enable Impersonation",
  logo: "Logo",
  enableSSO: "Enable SSO",
  hardLogout: "Hard Logout",
  authenticationMethod: `"Authentication Method (Email, EID)"`,
  auth0Connection: "Auth0 Connection",
  ssoConfig: "SSO Config",
  debtors: "Debtor(s)",
  debtorNames: "Debtor Names",
  features: "Features",
  featureNames: "Feature Names",
  roleNames: "Role Names",
  onboardingAutoTrigger: "Onboarding Auto Trigger",
};

const url = getConfig(COMPANIES).url;

export const fetchCompanies = (): Promise<CompanyI[]> => {
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COMPANIES} url`));
  }
};

export const createCompany = (params) => {
  const formData = mapSaveDataToForm(params);
  const contentType = "multipart/form-data";
  if (url) {
    return post(url, formData, null, contentType)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COMPANIES} url`));
  }
};

export const deleteCompany = (id: string) => {
  if (!id) return Promise.reject(new Error("Missing company id"));
  if (url) {
    const fullUrl = `${url}/${id}`;
    return deleteRequest(fullUrl).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${COMPANIES} url`));
  }
};

export const editCompany = (params) => {
  const formData = mapSaveDataToForm(params, true);
  const contentType = "multipart/form-data";
  if (!params.id) return Promise.reject(new Error("Missing company id"));
  if (url) {
    const fullUrl = `${url}/${params.id}`;
    return put(fullUrl, formData, null, contentType)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COMPANIES} url`));
  }
};

export const getCompanyConfig = (id) => {
  if (url && id) {
    const finalUrl = `${url}/CompanyConfig`;
    return get(finalUrl, { id })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => formatJSONToCSV(json));
  } else {
    return Promise.reject(new Error(`Missing ${COMPANIES} url or company id`));
  }
};

export const getActiveApproveDebtors = (id) => {
  if (url && id) {
    const finalUrl = `${url}/${id}/Debtor/WithActivedApproveFeature`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COMPANIES} url`));
  }
};

const formatJSONToCSV = (json) => {
  const formattedJSON = { ...json };
  // wrap comma separated string in double quotes so csv doesn't separate into individual cells
  formattedJSON.debtorNames = wrapCSVRowWithQuotes(
    formattedJSON.debtorNames.split(",").join(", ")
  );
  formattedJSON.featureNames = wrapCSVRowWithQuotes(
    formattedJSON.featureNames.split(",").join(", ")
  );
  formattedJSON.roleNames = wrapCSVRowWithQuotes(
    formattedJSON.roleNames.split(",").join(", ")
  );

  const columnHeaderRow = [];
  const csvDataRowArrays = [[]];
  const csvColumns = Object.keys(formattedJSON);
  // map through each column and populate the data
  csvColumns.map((key, index) => {
    columnHeaderRow.push(csvColumnHeaderMap[key]);
    const colData = formattedJSON[key];
    const firstRow = csvDataRowArrays[0];
    if (!Array.isArray(colData)) {
      // regular string field, just display in first row
      firstRow.push(colData);
    } else {
      // nested data array, map through and show by individual rows
      colData.map((item, rowIndex) => {
        let output = "";
        const objKeys = Object.keys(item);
        objKeys.forEach((k, index) => {
          // Loop through object and turn into comma separated string of data
          if (index === 0) output += `"`;
          output += `${k}: ${item[k]}, `;
          if (objKeys.length - 1 === index) output += `"`;
        });
        if (csvDataRowArrays[rowIndex]) {
          // row already exists with data
          csvDataRowArrays[rowIndex][index] = output;
        } else {
          // row does not exist yet, create an empty row and populate particular column
          csvDataRowArrays[rowIndex] = new Array(csvColumns.length);
          csvDataRowArrays[rowIndex][index] = output;
        }
      });
    }
  });

  return [columnHeaderRow, ...csvDataRowArrays];
};

const mapSaveDataToForm = (state, isEditCompany = false) => {
  const {
    active,
    biDebtorCode,
    clientSsoCode,
    externalAuth0EnterpriseConnection,
    isSso,
    logoFile,
    logoUrl,
    selectedCompanyClone,
    sso_AuthenticateByEmail,
    sso_AuthenticateByEmployeeID,
    sso_HardLogout,
    sso_LogoutUrl,
    title,
    welcomeEmailAutotriggered,
    canImpersonate,
  } = state;

  const company: CompanyI = {
    active,
    logoUrl,
    title,
  };

  if (selectedCompanyClone) {
    company.IsClone = true;
    company.CloneCompanyID = selectedCompanyClone.id;
  } else {
    const possibleParams = {
      biDebtorCode,
      clientSsoCode,
      externalAuth0EnterpriseConnection,
      isSso,
      canImpersonate,
      sso_AuthenticateByEmail,
      sso_AuthenticateByEmployeeID,
      sso_HardLogout,
      sso_LogoutUrl,
      welcomeEmailAutotriggered,
    };

    Object.keys(possibleParams).forEach((p) => {
      if (possibleParams[p] != null) company[p] = possibleParams[p];
    });

    if (company.isSso) company.welcomeEmailAutotriggered = false;
    if (isEditCompany) company.defaultDebtorID = state.defaultDebtorID;
  }

  const formData = new window.FormData();
  formData.append("data", JSON.stringify(company));

  if (logoFile instanceof File) formData.append("file", logoFile);
  return formData;
};
