import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createPCC, fetchPCCs, updatePCC } from "services/pccs";
import { Pcc } from "types/Pcc";
import { QUERYKEY_PCCS } from "./queryKeys";

const mapAndFilterPCCData = (data: Pcc[]) => {
  const mappedData = data.map((pcc) => {
    return { ...pcc, label: pcc.code };
  });
  const filtered = mappedData.filter((pcc) => pcc.active);
  return {
    PCCs: mappedData,
    activePCCs: filtered,
  };
};

export function usePCCs() {
  return useQuery({
    queryKey: [QUERYKEY_PCCS],
    queryFn: () => fetchPCCs(),
    select: mapAndFilterPCCData,
  });
}

export function useCreatePCC() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPCC,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_PCCS] }),
  });
}

export function useEditPCC() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePCC,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_PCCS] }),
  });
}
