import React, { Suspense } from "react";
import { Loading } from "components";

const Itineraries = React.lazy(() => import("./Itineraries"));

function ItinerariesView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Itineraries" />}>
      <Itineraries {...props} />
    </Suspense>
  );
}

export default ItinerariesView;
