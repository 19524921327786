import React, { Component } from "react";
import { connect } from "react-redux";
import { Loading } from "components";
import { setPageError } from "redux/actions/application";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    // If we are error handling for the page view, we set the error in redux so it can be reset on route change
    if (this.props.pageView) this.props.setPageError();
    // Otherwise we just set the local error state
    else this.setState({ hasError: true });
  }

  render() {
    // Check for local error or page level error
    if (this.state.hasError || (this.props.pageView && this.props.pageError)) {
      // Our Loading/Error component fills its parent's div. In some cases we need to provide a full size container for it to go in, or provide it a card to render in.
      if (this.props.addContainer)
        return (
          <div
            className={this.props.addLayoutClass ? "layout" : ""}
            style={{ width: "100%", height: "100%", minHeight: "500px" }}
          >
            <Loading active={true} error={true} />
          </div>
        );
      else return <Loading active={true} error={true} />;
    }
    // No error, render children
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    pageError: state.application.pageError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPageError: () => dispatch(setPageError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
