import { Fragment } from "react";
import { withStyles } from "tss-react/mui";

// eslint-disable-next-line
const styles = (theme, props) => ({
  input: {
    display: "none",
  },
});

interface FileUploadI {
  accept: ".csv";
  inputButton: JSX.Element;
  label?: string;
  onChange: (file: File) => void;
  uploadRef: React.Ref<HTMLInputElement>;
  classes?: Partial<Record<"input", string>>;
}

function FileUpload(props: FileUploadI) {
  const {
    accept,
    inputButton,
    label = "file-upload",
    onChange,
    uploadRef,
  } = props;
  const classes = withStyles.getClasses(props);

  function handleChange(e) {
    if (e && e.target && e.target.files && e.target.files.length) {
      onChange(e.target.files[0]);
    }
  }

  return (
    <Fragment>
      <input
        className={classes.input}
        type="file"
        accept={accept}
        id={label}
        onChange={handleChange}
        ref={uploadRef}
      />
      <label htmlFor={label}>{inputButton}</label>
    </Fragment>
  );
}

export default withStyles(FileUpload, styles);
