import { useState } from "react";
import { withStyles } from "tss-react/mui";
import { connect } from "react-redux";
import moment from "moment";
import { saveFile } from "common/helpers";

import {
  AutoComplete,
  Button,
  Dropdown,
  Input,
  SingleDatePicker,
} from "components";

import {
  setMessageLogsServerQuery,
  setMessageLogsCompany,
  setMessageLogsEndDate,
  setMessageLogsPage,
  setMessageLogsQuery,
  setMessageLogsStartDate,
  setMessageLogsTemplate,
  setMessageLogsDebtor,
} from "redux/actions/messageLogs";
import { fetchMessageLogsCSV } from "services/messageLogs";

import { label } from "assets/jss/globalStyle";
import { NotificationObjI } from "types";
import { CompanySelectionI, DebtorSelectionI } from "types/Company";
import { MessageLogTemplateCodeI } from "types/MessageLogTemplateCode";
import { useMessageLogTemplateCodes } from "hooks/messageLogTemplateCodes";
import { useMessageLogs } from "hooks/messageLogs";
import { useHistory } from "react-router-dom";
import { useCompanies } from "hooks/companies";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useCompanyDebtors, useDebtors } from "hooks/debtors";

const splitRow = {
  display: "flex",
  justifyContent: "space-between",
};

// eslint-disable-next-line
const styles = (theme, props) => ({
  formContainer: {
    padding: "22px",
  },
  row: {
    height: "100px",
  },
  splitRow,
  topRow: {
    ...splitRow,
    height: "100px",
  },
  companyFilter: {
    width: "38%",
    marginRight: "22px",
  },
  datePicker: {
    width: "110px",
  },
  label: {
    ...label,
    display: "block",
  },
});

const today = moment();

interface MessageLogsFormI {
  addNotification: (not: NotificationObjI) => void;
  endDate: moment.Moment;
  globalSelectedCompanyID?: string;
  query: string;
  selectedCompany?: CompanySelectionI;
  selectedDebtor?: DebtorSelectionI;
  selectedTemplate?: MessageLogTemplateCodeI;
  setMessageLogsServerQuery: () => void;
  setMessageLogsCompany: (company: string) => void;
  setMessageLogsDebtor: (debtor: string) => void;
  setMessageLogsEndDate: (date: moment.Moment) => void;
  setMessageLogsQuery: (query: string) => void;
  setMessageLogsStartDate: (date: moment.Moment) => void;
  setMessageLogsTemplate: (template: MessageLogTemplateCodeI) => void;
  startDate: moment.Moment;
  classes?: Partial<
    Record<
      | "formContainer"
      | "row"
      | "splitRow"
      | "topRow"
      | "companyFilter"
      | "datePicker"
      | "label",
      string
    >
  >;
}

function MessageLogsForm(props: MessageLogsFormI) {
  const {
    addNotification,
    endDate,
    globalSelectedCompanyID,
    query,
    selectedCompany,
    selectedDebtor,
    selectedTemplate,
    setMessageLogsServerQuery,
    setMessageLogsCompany,
    setMessageLogsDebtor,
    setMessageLogsEndDate,
    setMessageLogsQuery,
    setMessageLogsStartDate,
    setMessageLogsTemplate,
    startDate,
  } = props;
  const classes = withStyles.getClasses(props);

  const [fetchingCSV, setCSVFetching] = useState(false);
  const templateCodes = useMessageLogTemplateCodes();
  const messageLogs = useMessageLogs();
  const history = useHistory();
  const companies = useCompanies();
  const debtors = useDebtors();
  const globalSelectedCompany = useSelectedCompany(
    globalSelectedCompanyID
  )?.data;
  const companyDebtors = useCompanyDebtors(
    globalSelectedCompanyID || selectedCompany?.id
  );
  const autocompleteDebtors =
    globalSelectedCompany || selectedCompany ? companyDebtors : debtors;

  function handleCSVRequest() {
    const company = globalSelectedCompany || selectedCompany;
    setCSVFetching(true);
    fetchMessageLogsCSV({
      company,
      startDate,
      endDate,
      query,
      selectedTemplate,
    })
      .then((csvdata) => {
        saveFile(csvdata, "message-logs.csv");
      })
      .catch((e) => {
        addNotification({
          text: `There was an error downloading the csv.`,
          errors: e.customErrors,
          type: "error",
        });
      })
      .finally(() => {
        setCSVFetching(false);
      });
  }

  function setEndDateRange() {
    return (date) => date.isBefore(startDate) || date.isAfter(today);
  }

  function setStartDateRange() {
    return (date) => date.isAfter(endDate) || date.isAfter(today);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (history.location.pathname !== "/messageLogs") {
          history.push("/messageLogs");
        }
        setMessageLogsServerQuery();
      }}
      className={classes.formContainer}
    >
      <div className={classes.topRow}>
        <span className={classes.companyFilter}>
          <AutoComplete
            label="Filter By Company"
            disabled={Boolean(globalSelectedCompany)}
            errorMessage={companies.isError ? "Error Fetching Companies" : ""}
            isLoading={companies.isLoading}
            handleDelete={() => setMessageLogsCompany(null)}
            onChange={(company) => {
              setMessageLogsDebtor(null);
              setMessageLogsCompany(company);
            }}
            selectedItem={globalSelectedCompany || selectedCompany}
            suggestions={companies.data}
          />
        </span>
        <span className={classes.companyFilter}>
          <AutoComplete
            label="Filter By Debtor"
            disabled={Boolean(globalSelectedCompany)}
            errorMessage={
              autocompleteDebtors.isError ? "Error Fetching Debtors" : ""
            }
            isLoading={autocompleteDebtors.isLoading}
            handleDelete={() => setMessageLogsDebtor(null)}
            onChange={setMessageLogsDebtor}
            selectedItem={selectedDebtor}
            suggestions={autocompleteDebtors.data}
          />
        </span>
        <span className={classes.datePicker} style={{ marginRight: "22px" }}>
          <label className={classes.label}>Start Date</label>
          <SingleDatePicker
            date={startDate}
            setDate={setMessageLogsStartDate}
            isOutsideDateRange={setStartDateRange()}
            id={"messageLogsStartDate"}
          />
        </span>
        <span className={classes.datePicker}>
          <label className={classes.label}>End Date</label>
          <SingleDatePicker
            date={endDate}
            setDate={setMessageLogsEndDate}
            isOutsideDateRange={setEndDateRange()}
            id={"messageLogsEndDate"}
          />
        </span>
      </div>
      <div className={classes.row}>
        <Dropdown
          label="Filter By Template"
          onChange={setMessageLogsTemplate}
          selectedItems={selectedTemplate || undefined}
          items={templateCodes.data}
          disabled={templateCodes.isLoading || templateCodes.isError}
        />
      </div>
      <div className={classes.row}>
        <Input
          label="Search Query"
          isSearch
          placeholder="Search by text query"
          value={query}
          onChange={setMessageLogsQuery}
          data-aut="MessageLogsForm|SearchQuery"
        />
      </div>
      <div className={classes.splitRow}>
        <Button
          label="Search"
          type="submit"
          data-aut="MessageLogs|SearchButton"
        />

        {messageLogs?.data?.length ? (
          <Button
            onClick={handleCSVRequest}
            label="Download CSV"
            isTextButton
            loading={fetchingCSV}
            data-aut="MessageLogs|CSVButton"
          />
        ) : null}
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  endDate: state.messageLogs.endDate,
  query: state.messageLogs.query,
  globalSelectedCompanyID: state.selectedCompanyID,
  selectedCompany: state.messageLogs.selectedCompany,
  selectedDebtor: state.messageLogs.selectedDebtor,
  startDate: state.messageLogs.startDate,
  selectedTemplate: state.messageLogs.messageLogTemplate,
});

const mapDispatchToProps = (dispatch) => ({
  setMessageLogsServerQuery: () => dispatch(setMessageLogsServerQuery()),
  setMessageLogsCompany: (company) => dispatch(setMessageLogsCompany(company)),
  setMessageLogsDebtor: (debtor) => dispatch(setMessageLogsDebtor(debtor)),
  setMessageLogsEndDate: (endDate) => dispatch(setMessageLogsEndDate(endDate)),
  setMessageLogsPage: (page) => dispatch(setMessageLogsPage(page)),
  setMessageLogsQuery: (query) => dispatch(setMessageLogsQuery(query)),
  setMessageLogsStartDate: (startDate) =>
    dispatch(setMessageLogsStartDate(startDate)),
  setMessageLogsTemplate: (template) =>
    dispatch(setMessageLogsTemplate(template)),
});

const MessageLogsFormStyled = withStyles(MessageLogsForm, styles);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageLogsFormStyled);
