import { PixelCrop } from "react-image-crop";

// From react-image-crop example on code sandbox
export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  // ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // Move the origin to the center of the original position
  ctx.translate(centerX, centerY);

  // Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export function getCroppedImg(
  image: HTMLImageElement,
  pixelCrop: PixelCrop
): Promise<File> {
  const canvas = document.createElement("canvas");

  canvasPreview(image, canvas, pixelCrop);

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        const file = new File([blob], "croppedImage.jpg", { type: blob.type });
        resolve(file);
      },
      "image/jpeg",
      0.8
    );
  });
}

// export function getImageBlob(src, callback) {
//   const img = new Image();
//   const c = document.createElement("canvas");
//   const ctx = c.getContext("2d");

//   img.onload = function () {
//     c.width = img.naturalWidth; // update canvas size to match image
//     c.height = img.naturalHeight;
//     ctx.drawImage(img, 0, 0); // draw in image
//     c.toBlob(
//       function (blob) {
//         callback(URL.createObjectURL(blob));
//       },
//       "image/png",
//       1
//     );
//   };
//   img.crossOrigin = ""; // if from different origin
//   img.src = src;
// }
