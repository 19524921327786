import QueryStringHandler from "components/Common/QueryStringHandler";
import Companies from "views/Companies";
import AccountSettings from "views/AccountSettings";
import Approve from "views/Approve";
import Features from "views/Features";
import Itineraries from "views/Itineraries";
import GlobalSearch from "views/GlobalSearch";
import GlobalSettings from "views/GlobalSettings";
import Onboarding from "views/Onboarding";
import ImpersonationLogs from "views/ImpersonationLogs";
import MessageLogs from "views/MessageLogs";
import Roles from "views/Roles";
import UserSearch from "views/UserSearch";

import {
  Announcement,
  Email,
  Settings,
  LibraryBooks,
  People,
  Apps,
  AssignmentInd,
  ThumbUp,
  Storage,
} from "@mui/icons-material";

function dashboardRoutes({ accessLevel, companyID }, approveEnabled) {
  const routes = [
    {
      path: "/dashboard",
      sidebarName: "Account Settings",
      dataAut: "AccountSettings",
      icon: Settings,
      RouteComponent: QueryStringHandler({
        WrappedComponent: AccountSettings,
        shouldRedirect: true,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 20 && al !== 40;
      },
    },
    {
      path: "/features",
      sidebarName: "Features",
      icon: Apps,
      RouteComponent: QueryStringHandler({
        WrappedComponent: Features,
        shouldRedirect: true,
      }),
      isAccessible: (al) => {
        return al >= 60;
      },
    },
    {
      path: "/roles",
      sidebarName: "Roles",
      icon: AssignmentInd,
      RouteComponent: QueryStringHandler({
        WrappedComponent: Roles,
        shouldRedirect: true,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 60;
      },
    },
    {
      path: "/users",
      sidebarName: "Users",
      dataAut: "Users",
      icon: People,
      RouteComponent: QueryStringHandler({
        WrappedComponent: UserSearch,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 20 && al !== 40;
      },
    },
    {
      path: "/onboarding",
      sidebarName: "Onboarding",
      dataAut: "Onboarding",
      icon: Email,
      RouteComponent: QueryStringHandler({
        WrappedComponent: Onboarding,
        shouldRedirect: true,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 60;
      },
    },
    {
      path: "/itineraries",
      sidebarName: "Itineraries",
      dataAut: "Itineraries",
      icon: LibraryBooks,
      RouteComponent: QueryStringHandler({
        WrappedComponent: Itineraries,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 50;
      },
    },

    {
      path: "/messagelogs",
      sidebarName: "Message Logs",
      dataAut: "Message Logs",
      icon: Announcement,
      RouteComponent: QueryStringHandler({
        WrappedComponent: MessageLogs,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 50;
      },
    },
    {
      path: "/impersonationlogs",
      sidebarName: "Impersonation Logs",
      dataAut: "Impersonation Logs",
      icon: Storage,
      RouteComponent: QueryStringHandler({
        WrappedComponent: ImpersonationLogs,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 50;
      },
    },
    {
      path: "/approve",
      sidebarName: "Approve",
      dataAut: "Approve",
      icon: ThumbUp,
      RouteComponent: QueryStringHandler({
        WrappedComponent: Approve,
        companyID,
        accessLevel,
        shouldRedirect: true,
      }),
      isAccessible: (al) => {
        return (
          (al === 10 || al === 30 || al === 40 || al === 100) && approveEnabled
        );
      },
      subRoutes: [
        {
          path: "/approve/settings",
          sidebarName: "Settings",
          dataAut: "ApproveSettings",
        },
        {
          path: "/approve/emailconfig",
          sidebarName: "Email Config",
          dataAut: "EmailConfig",
        },
        {
          path: "/approve/travelclasses",
          sidebarName: "Travel Classes",
          dataAut: "TravelClasses",
        },
        {
          path: "/approve/approvalgroups",
          sidebarName: "Approval Groups",
          dataAut: "ApproverGroups",
        },
        {
          path: "/approve/travelpolicies",
          sidebarName: "Travel Policies",
          dataAut: "TravelPolicies",
        },
      ],
    },

    {
      path: "/search",
      RouteComponent: QueryStringHandler({
        WrappedComponent: GlobalSearch,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 50;
      },
    },
    {
      path: "/settings",
      RouteComponent: QueryStringHandler({
        WrappedComponent: GlobalSettings,
        companyID,
        accessLevel,
      }),
      isAccessible: (al) => {
        return al >= 50;
      },
    },
    // Default base path
    {
      path: "/",
      RouteComponent:
        accessLevel < 40
          ? QueryStringHandler({
              WrappedComponent: AccountSettings,
              shouldRedirect: true,
              companyID,
              accessLevel,
            })
          : Companies,
      isAccessible: (al) => {
        return al >= 20;
      },
    },
    // Base path for CTM Approve users which have only one specific use case
    {
      path: "/",
      RouteComponent: QueryStringHandler({
        WrappedComponent: Approve,
        shouldRedirect: true,
        companyID,
        accessLevel,
      }),

      isAccessible: (al) => {
        return al === 10;
      },
    },
  ];

  function filterRoute(route) {
    return route.isAccessible(accessLevel);
  }

  return routes.filter(filterRoute);
}

export default dashboardRoutes;
