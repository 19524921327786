import { deleteRequest, get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { FEATURES } from "common/constants";
import { handleErrors, validateEmail, validateURL } from "common/helpers";
import { FeatureI } from "types/Feature";

export const getFeatures = (companyID): Promise<FeatureI[]> => {
  let url = getConfig(FEATURES).url;
  if (url && companyID) {
    url = `${url}${companyID}/Permissions`;
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .then((features) => {
        features.forEach(sortEnabledRoles);
        return features.sort((a, b) => {
          if (a.featureName === b.featureName) {
            if (!a.featureNameOverride) return -1;
            else if (!b.featureNameOverride) return 1;
            return a.featureNameOverride < b.featureNameOverride ? -1 : 1;
          }
          return a.featureName < b.featureName ? -1 : 1;
        });
      });
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url`));
  }
};

export const fetchFeature = (companyFeatureId) => {
  let url = getConfig(FEATURES).url;
  if (url && companyFeatureId) {
    url = `${url}${companyFeatureId}`;
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url`));
  }
};

const globalRoles = {
  Administrator: true,
  "Client Manager": true,
  "Travel Arranger": true,
  Traveler: true,
};

function sortEnabledRoles(feature) {
  feature.roles.sort((a, b) => {
    if (globalRoles[a.name] && !globalRoles[b.name]) return -1;
    else if (!globalRoles[a.name] && globalRoles[b.name]) return 1;
    else if (a.name < b.name) return -1;
    else return 1;
  });
}

// Removes any null attributes from an object
function stripValues(params, selectedParams) {
  const newParams = { ...params };
  Object.keys(newParams).forEach((k) => {
    if (
      selectedParams[k] == null &&
      (newParams[k] == null || newParams[k].length === 0)
    )
      delete newParams[k];
    else if (selectedParams[k] && newParams[k] && newParams[k].length === 0)
      newParams[k] = null;
  });
  return newParams;
}

export function saveFeature(params, selectedFeature, template) {
  const selectedParams =
    selectedFeature && selectedFeature.params ? selectedFeature.params : {};
  if (!params.nameOverride || !params.nameOverride.length) {
    // If we have no name override and it's not on the selected feature, don't include it in the payload
    if (!selectedFeature || !selectedFeature.nameOverride)
      delete params.nameOverride;
    // Otherwise, make it null so we can overwrite the previous value
    else params.nameOverride = null;
  }
  params.parameters = stripValues(params.parameters, selectedParams);
  template.parameters.forEach((p) => {
    if (p.typeName === "Html" && params.parameters[p.key])
      params.parameters[p.key] = params.parameters[p.key].replace(
        /[\n\r]/g,
        ""
      );
  });

  const hasFileUpload =
    template.feature &&
    template.feature.requirements &&
    template.feature.requirements.hasFileUpload;

  return handleFileUploadFeature(
    params,
    selectedFeature,
    template,
    hasFileUpload
  );
}

export function handleFileUploadFeature(
  params,
  selectedFeature,
  template,
  hasFileUpload
) {
  const parameters = params.parameters || {};
  const formData = new FormData();
  if (hasFileUpload) {
    const fileKey = template.parameters.find((p) => p.typeName === "File").key;
    let file;
    if (parameters[fileKey] instanceof File) {
      file = parameters[fileKey];
      delete params.parameters[fileKey];
    }
    formData.append("file", file);
  }
  formData.append("data", JSON.stringify(params));
  const contentType = "multipart/form-data";
  if (selectedFeature) return editFeature(formData, params.id, contentType);
  else return newFeature(formData, contentType);
}

function newFeature(params, contentType) {
  const url = getConfig(FEATURES).url;
  if (url) {
    return post(url, params, null, contentType)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url`));
  }
}

function editFeature(params, id, contentType) {
  const url = getConfig(FEATURES).url;
  if (url && id) {
    const finalURL = `${url}${id}`;
    return put(finalURL, params, null, contentType)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url or featureID`));
  }
}

export const deleteFeature = (id) => {
  const url = getConfig(FEATURES).url;
  if (url && id) {
    const finalURL = `${url}${id}`;
    return deleteRequest(finalURL).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url or featureID`));
  }
};

interface ToggleFeatureRolePropertyI {
  enabled: boolean;
  roleID: string;
  featureID: string;
  roleIndex: number;
  featureIndex: number;
}

export const toggleFeatureRoleProperty = ({
  enabled,
  roleID,
  featureID,
}: ToggleFeatureRolePropertyI) => {
  const url = getConfig(FEATURES).url;
  if (url) {
    const finalUrl = `${url}${featureID}/${roleID}/${enabled}`;
    return put(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url`));
  }
};

export const toggleFeatureProperty = ({ companyFeatureID, enabled }) => {
  const url = getConfig(FEATURES).url;
  if (url) {
    const finalUrl = `${url}/${companyFeatureID}/${enabled}`;
    return put(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${FEATURES} url`));
  }
};

export function mapParams({
  isCreating,
  params,
  template,
  selectedCompany,
  selectedFeature,
  addNotification,
}) {
  let paramsAreValid = true;
  const companyID = selectedCompany.id;
  const newParams = { ...params, companyID };
  if (!isCreating) {
    newParams.id = selectedFeature.id;
    if (selectedFeature.parameters && selectedFeature.parameters.Position) {
      if (!newParams.parameters) newParams.parameters = {};
      newParams.parameters.Position = selectedFeature.parameters.Position;
    }
  }
  // We need to type check all data,
  // and if we have any data that is populated via autocompletes, we need to strip that array data down to only values
  if (newParams.parameters) {
    const keys = Object.keys(newParams.parameters);
    keys.forEach((k) => {
      const item = newParams.parameters[k];
      const templateItem = template.parameters.find((p) => p.key === k);
      if (item) {
        const hasOptions =
          k === "Timezones" ||
          (templateItem && templateItem.options && templateItem.options.length);
        // Items with options do not need to be typeChecked, we assume the options are valid and our code works
        if (hasOptions) {
          if (Array.isArray(item)) {
            newParams.parameters[k] = newParams.parameters[k].map(
              (it) => it.value
            );
          } else {
            newParams.parameters[k] = newParams.parameters[k].value;
          }
        } else {
          if (templateItem.allowMultiples) {
            item.forEach((i) => {
              paramsAreValid = validateParam(i, templateItem, addNotification);
            });
          } else {
            paramsAreValid = validateParam(item, templateItem, addNotification);
          }
        }
      }
    });
  }

  if (!paramsAreValid) return false;
  else return newParams;
}

function validateParam(item, templateItem, addNotification) {
  const type = templateItem.typeName;
  try {
    switch (type) {
      case "Number":
        item = Number(item);
        if (typeof item !== "number" || Number.isNaN(item))
          throw new Error(
            `Invalid input for ${templateItem.label} field. Non numeric value detected.`
          );
        break;
      case "Email":
        if (!validateEmail(item))
          throw new Error(
            `Invalid input for ${templateItem.label} field. Invalid email syntax detected.`
          );
        break;
      case "Url":
        if (!validateURL(item))
          throw new Error(
            `Invalid input for ${templateItem.label} field. Invalid url syntax detected.`
          );
        break;
      case "Boolean":
        item = Boolean(item);
        break;
      default:
        item = item.toString();
    }
    return true;
  } catch (err) {
    addNotification({
      text:
        err.message ||
        `Error with input data. Please try again with sanitized data.`,
      type: "error",
    });
    return false;
  }
}
