import { cardHeader, paddingSize, redColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  root: {
    margin: "16px 0",
    padding: paddingSize,
  },
  header: {
    ...cardHeader,
    margin: "0",
  },
  loadingContainer: {
    height: "218px",
  },
  settingsContainer: {
    display: "flex",
    width: "100%",
  },
  settingsSection: {
    borderLeft: "solid 1px rgba(0, 0, 0, 0.1)",
    width: "50%",
    padding: `0 ${paddingSize}`,
  },
  settingsHeader: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: 0,
    marginBottom: "22px",
    textTransform: "none",
  },
  warningText: {
    fontSize: "12px",
    color: redColor,
  },
  descriptionText: {
    fontSize: "14px",
  },
});

export default styles;
