import { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "tss-react/mui";
import TablePagination from "@mui/material/TablePagination";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ArrowDownward } from "@mui/icons-material";
import { Loading } from "components";

import {
  itineraryResetTravelers,
  setItinerariesOrder,
  setItinerariesOrderBy,
  setItinerariesPage,
  setItinerariesRowsPerPage,
  itinerarySelect,
} from "redux/actions/itinerarySearch";

import { isUserNotSelectingText, smartSort } from "common/helpers";
import { ItineraryCard } from "..";
import itineraryTableStyle from "components/ItineraryTable/itineraryTableStyle";
import { SortOrder } from "types";
import { useItineraries } from "hooks/itineraries";

interface ItineraryTableI {
  itinerariesOrder: SortOrder;
  itinerariesOrderBy: "PNR" | "Name" | "Debtor" | "PCC" | "Date";
  itinerariesPage: number;
  itinerariesRowsPerPage: number;
  itinerarySelect: (itin: any) => void;
  selectedItinerary?: any;
  setItinerariesOrder: (order: SortOrder) => void;
  setItinerariesOrderBy: (params: {
    itinerariesOrder: SortOrder;
    itinerariesOrderBy: "PNR" | "Name" | "Debtor" | "PCC" | "Date";
  }) => void;
  setItinerariesPage: (page: number) => void;
  setItinerariesRowsPerPage: (rows: number) => void;
  classes?: Partial<
    Record<
      | "errorText"
      | "expandIcon"
      | "loadingRow"
      | "toolBarTitle"
      | "textField"
      | "toolBar"
      | "table"
      | "tableWrapper"
      | "paginationRoot"
      | "paginationCaption"
      | "paginationIcon"
      | "paginationContainer"
      | "sortIcon"
      | "innerRow"
      | "tableRow"
      | "tableBody"
      | "tableCellSmall"
      | "tableCell"
      | "tableCellMiddle"
      | "cellItem"
      | "pnrWrapper"
      | "expansionPanel"
      | "expansionPanelContent"
      | "expansionPanelDetails"
      | "endDate",
      string
    >
  >;
}

const ItineraryTable = (props: ItineraryTableI) => {
  const {
    itinerariesOrder,
    itinerariesOrderBy,
    itinerariesPage,
    itinerariesRowsPerPage,
    itinerarySelect,
    selectedItinerary,
    setItinerariesOrderBy,
    setItinerariesPage,
    setItinerariesRowsPerPage,
  } = props;
  const classes = withStyles.getClasses(props);
  const itineraries = useItineraries();
  const handleRequestSort = (property) => {
    const itinerariesOrderBy = property;
    let itinerariesOrder = "desc" as SortOrder;

    if (itinerariesOrderBy === property && itinerariesOrder === "desc") {
      itinerariesOrder = "asc";
    }
    setItinerariesOrderBy({ itinerariesOrder, itinerariesOrderBy });
  };

  const handleChangePage = (event, page) => {
    setItinerariesPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setItinerariesRowsPerPage(event.target.value);
  };

  const handleExpansionClick = (itin) => {
    if (selectedItinerary === null && itin === null) return;
    else if (selectedItinerary === itin) itinerarySelect(null);
    else itinerarySelect(itin);
  };

  const sortData = (a, b) => {
    let mappedValues;
    const order = itinerariesOrderBy;
    if (order === "PNR") {
      mappedValues = mapPNRs(a, b);
    } else if (order === "Name") {
      mappedValues = mapNames(a, b);
    } else if (order === "Debtor") {
      mappedValues = mapDebtors(a, b);
    } else if (order === "PCC") {
      mappedValues = mapPCCs(a, b);
    } else if (order === "Date") {
      mappedValues = mapDates(a, b);
    }
    const { mappedA, mappedB } = mappedValues;
    const evaluated = smartSort(mappedA, mappedB);
    if (itinerariesOrder === "desc") return evaluated;
    else return -evaluated;
  };

  // Mapping functions map to specific values on our nested itinerary object to sort off of
  const mapPNRs = (a, b) => {
    return { mappedA: a.source.pnr, mappedB: b.source.pnr };
  };

  const mapNames = (a, b) => {
    return {
      mappedA: a.travelers[0].firstName,
      mappedB: b.travelers[0].firstName,
    };
  };

  const mapDebtors = (a, b) => {
    return { mappedA: a.debtorIdentifier, mappedB: b.debtorIdentifier };
  };

  const mapPCCs = (a, b) => {
    return { mappedA: a.source.pcc, mappedB: b.source.pcc };
  };

  const mapDates = (a, b) => {
    return { mappedA: moment(a.startTime), mappedB: moment(b.startTime) };
  };

  const renderDates = (itinerary, index) => {
    const firstDate = moment(itinerary.startTime).format("MM/DD/YY");
    const endDate = moment(itinerary.endTime).format("MM/DD/YY");
    return (
      <div>
        <span data-aut={`Itineraries|Itinerary${index}|FirstDate`}>
          {firstDate}
        </span>
        <span
          className={classes.endDate}
          data-aut={`Itineraries|Itinerary${index}|EndDate`}
        >
          {endDate}
        </span>
      </div>
    );
  };

  const renderError = () => {
    return (
      <p className={classes.errorText}>
        There was a server error fetching itineraries.
      </p>
    );
  };

  const renderLoading = () => {
    return (
      <div className={classes.loadingRow}>
        <Loading active={true} loadingText="Searching Itineraries" />
      </div>
    );
  };

  const renderTableBody = () => {
    if (itineraries.isFetching) return renderLoading();
    else if (itineraries.isError) return renderError();
    else return renderItineraries();
  };

  const renderItineraryCards = (itinerary, index) => {
    // First we group all of the various cards into an array from their buckets
    const cards = [];
    itinerary.hotels.forEach((hotel) => {
      cards.push({
        type: "hotel",
        name: hotel.company.name,
        code: hotel.company.code,
        startTime: hotel.start.dateLocal,
        endTime: hotel.end.dateLocal,
        cityCode: hotel.cityCodes,
        confirmation: hotel.referenceCode,
        price: hotel.rate.amount,
      });
    });

    itinerary.cars.forEach((car) => {
      cards.push({
        type: "car",
        name: car.agency.name,
        code: car.agency.code,
        startTime: car.start.dateLocal,
        endTime: car.end.dateLocal,
        cityCode: car.cityCodes,
        confirmation: car.referenceCode,
        price: car.totalCost && car.totalCost.amount,
      });
    });

    itinerary.air.forEach((flight) => {
      cards.push({
        type: "flight",
        departureAirport: flight.start.airport,
        startTime: flight.start.dateLocal,
        arrivalAirport: flight.end.airport,
        endTime: flight.end.dateLocal,
        flightNumber: flight.flightNumber,
        code: flight.airline.code,
        price: flight.totalCost,
      });
    });

    return cards.map((card, i) => (
      <div key={i} className={classes.innerRow}>
        <ItineraryCard {...card} itineraryIndex={index} cardIndex={i + 1} />
      </div>
    ));
  };

  const renderItinerarySummary = (itinerary, index) => {
    const expanded = selectedItinerary === index;
    const displayIndex = index + 1;
    return (
      <Accordion
        key={index}
        className={classes.expansionPanel}
        expanded={expanded}
      >
        <AccordionSummary
          className={classes.expansionPanelContent}
          onClick={() => {
            isUserNotSelectingText() && handleExpansionClick(index);
          }}
          data-aut={`Itineraries|Itinerary${displayIndex}|Summary`}
        >
          <div className={classes.innerRow}>
            <span className={classes.tableCellSmall}>
              <div
                className={classes.pnrWrapper}
                data-aut={`Itineraries|Itinerary${displayIndex}|PNR`}
              >
                <i
                  style={expanded ? { transform: "rotate(90deg)" } : {}}
                  className={`icon-minimize-arrows ${classes.expandIcon}`}
                ></i>
                {itinerary.source.pnr}
              </div>
            </span>
            <span
              className={classes.tableCellMiddle}
              data-aut={`Itineraries|Itinerary${displayIndex}|Travelers`}
            >
              {itinerary.travelers.map((t, ind) => (
                <p
                  key={`${t.firstName}${t.lastName}`}
                  className={classes.cellItem}
                  data-aut={`Itineraries|Itinerary${displayIndex}|Traveler${
                    ind + 1
                  }`}
                >{`${t.firstName} ${t.lastName}`}</p>
              ))}
            </span>
            <span className={classes.tableCellMiddle}>
              <p
                className={classes.cellItem}
                data-aut={`Itineraries|Itinerary${displayIndex}|DebtorIdentifier`}
              >
                {itinerary.debtorIdentifier}
              </p>
            </span>
            <span
              className={classes.tableCellSmall}
              data-aut={`Itineraries|Itinerary${displayIndex}|PCC`}
            >
              {itinerary.source.pcc}
            </span>
            <span className={classes.tableCell}>
              {renderDates(itinerary, displayIndex)}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelContent}>
          <div
            className={classes.expansionPanelDetails}
            data-aut={`Itineraries|Itinerary${displayIndex}|Details`}
          >
            {renderItineraryCards(itinerary, displayIndex)}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderItineraries = () => {
    return itineraries.data
      .sort(sortData)
      .slice(
        itinerariesPage * itinerariesRowsPerPage,
        itinerariesPage * itinerariesRowsPerPage + itinerariesRowsPerPage
      )
      .map(renderItinerarySummary);
  };

  return (
    <Fragment>
      <div className={classes.tableWrapper}>
        <div className={classes.tableRow}>
          <span
            className={classes.tableCellSmall}
            onClick={() => handleRequestSort("PNR")}
            data-aut="Itineraries|PNRFilter"
          >
            PNR{" "}
            {itinerariesOrderBy === "PNR" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={
                  itinerariesOrder === "asc"
                    ? { transform: "rotate(-180deg)" }
                    : {}
                }
              />
            )}
          </span>
          <span
            className={classes.tableCellMiddle}
            onClick={() => handleRequestSort("Name")}
            data-aut="Itineraries|NameFilter"
          >
            Traveler Names{" "}
            {itinerariesOrderBy === "Name" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={
                  itinerariesOrder === "asc"
                    ? { transform: "rotate(-180deg)" }
                    : {}
                }
              />
            )}
          </span>
          <span
            className={classes.tableCellMiddle}
            style={{ display: "flex" }}
            onClick={() => handleRequestSort("Debtor")}
            data-aut="Itineraries|DebtorFilter"
          >
            Debtor Id{" "}
            {itinerariesOrderBy === "Debtor" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={
                  itinerariesOrder === "asc"
                    ? { transform: "rotate(-180deg)" }
                    : {}
                }
              />
            )}
          </span>
          <span
            className={classes.tableCellSmall}
            onClick={() => handleRequestSort("PCC")}
            data-aut="Itineraries|PCCFilter"
          >
            PCC{" "}
            {itinerariesOrderBy === "PCC" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={
                  itinerariesOrder === "asc"
                    ? { transform: "rotate(-180deg)" }
                    : {}
                }
              />
            )}
          </span>
          <span
            className={classes.tableCell}
            onClick={() => handleRequestSort("Date")}
            data-aut="Itineraries|DateFilter"
          >
            Travel Dates{" "}
            {itinerariesOrderBy === "Date" && (
              <ArrowDownward
                className={classes.sortIcon}
                style={
                  itinerariesOrder === "asc"
                    ? { transform: "rotate(-180deg)" }
                    : {}
                }
              />
            )}
          </span>
        </div>
        <div className={classes.tableBody}>{renderTableBody()}</div>
      </div>
      <div className={classes.paginationContainer}>
        <TablePagination
          classes={{
            selectIcon: classes.paginationIcon,
          }}
          component="div"
          count={itineraries.data.length}
          labelDisplayedRows={({ from, to, count }) => (
            <span data-aut="Itineraries|DisplayedRowsCount">{`${from}-${
              to === -1 ? count : to
            } of ${count}`}</span>
          )}
          rowsPerPage={itinerariesRowsPerPage}
          page={itinerariesPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            color: "primary",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            color: "primary",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  itinerariesOrder: state.itinerarySearch.itinerariesOrder,
  itinerariesOrderBy: state.itinerarySearch.itinerariesOrderBy,
  itinerariesPage: state.itinerarySearch.itinerariesPage,
  itinerariesRowsPerPage: state.itinerarySearch.itinerariesRowsPerPage,
  selectedItinerary: state.itinerarySearch.selectedItinerary,
});

const mapDispatchToProps = (dispatch) => ({
  itineraryResetTravelers: () => dispatch(itineraryResetTravelers()),
  itinerarySelect: (it) => dispatch(itinerarySelect(it)),
  setItinerariesOrder: (order) => dispatch(setItinerariesOrder(order)),
  setItinerariesOrderBy: (orderby) => dispatch(setItinerariesOrderBy(orderby)),
  setItinerariesPage: (page) => dispatch(setItinerariesPage(page)),
  setItinerariesRowsPerPage: (rows) =>
    dispatch(setItinerariesRowsPerPage(rows)),
});

const ItineraryTableStyled = withStyles(ItineraryTable, itineraryTableStyle);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItineraryTableStyled);
