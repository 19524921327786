// ##############################
// // // Header styles
// #############################

import { container, contentWidth, defaultFont } from "assets/jss/globalStyle";

// eslint-disable-next-line
const headerStyle = (theme, props) => ({
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: 1029,
    color: "#555555",
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    display: "block",
  },
  container: {
    ...container,
    minHeight: "50px !important",
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    maxWidth: contentWidth,
  },
  img: {
    width: "150px",
  },
  imgContainer: {
    flexGrow: 1,
  },
  greeting: {
    fontSize: "18px",
    fontWeight: 600,
    backgroundColor: "#fff",
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: 1000,
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: 400,
    lineHeight: "1.42857143",
    color: "#333",
    whiteSpace: "nowrap",
    "&:hover": {
      fontWeight: "600",
    },
  },
  profilePic: {
    width: "40px",
    borderRadius: "50%",
  },
});

export default headerStyle;
