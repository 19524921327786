import {
  blueColor,
  ellipsisOverflow,
  whiteColor,
} from "assets/jss/globalStyle";

// eslint-disable-next-line
const toggleButtonStyle = (props, theme) => ({
  buttonStyle: {
    fontWeight: 600,
    textTransform: "none",
    border: "1px solid",
    transition: "all 0.5s ease",
    width: "100%",
    height: "45px",
    "&:hover": {
      background: "#33ADFF",
      borderColor: "#33ADFF",
    },
    margin: "3px 0",
  },
  unSelected: {
    color: blueColor,
    borderColor: blueColor,
    "&:hover": {
      color: whiteColor,
      borderColor: "#33ADFF",
    },
  },
  selected: {
    color: whiteColor,
    background: blueColor,
    borderColor: "#007AFF",
  },
  text: {
    ...ellipsisOverflow,
  },
});

export default toggleButtonStyle;
