import * as constants from "common/constants";
/**
 * Base Url of API server.  Important to append ending slash.
 */
export const AUDIENCE = process.env.REACT_APP_API_AUDIENCE;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
/**
 * Api configuration for each resource, add a types for auto generated action and reducer in redux
 */
export const config = {
  [constants.AIRPORT_CODES]: {
    url: `${BASE_URL}Locations/airportcodes`,
  },
  [constants.APPLICATION_MESSAGES]: {
    url: `${BASE_URL}ApplicationMessages`,
  },
  [constants.APPROVAL_CONFIG]: {
    url: `${BASE_URL}Approve/Configuration`,
  },
  [constants.APPROVAL_EMAIL]: {
    url: `${BASE_URL}Approve/EmailConfiguration`,
  },
  [constants.APPROVAL_GROUPS]: {
    url: `${BASE_URL}Approve/ApproverGroup`,
  },
  [constants.APPS]: {
    url: `${BASE_URL}Applications`,
  },
  [constants.CITIES]: {
    url: `${BASE_URL}Locations/cities/`,
  },
  [constants.CITYSEARCH]: {
    url: `${BASE_URL}External/Sabre/Search/Cities`,
  },
  [constants.COUNTRIES]: {
    url: `${BASE_URL}Locations/countries`,
  },

  [constants.COMPANY_DEBTORS]: {
    url: `${BASE_URL}Debtors/Company`,
  },
  [constants.COMPANY]: {
    url: `${BASE_URL}companies/`,
  },
  [constants.COMPANIES]: {
    url: `${BASE_URL}companies`,
  },
  [constants.CURRENCY_SYMBOLS]: {
    url: `${BASE_URL}Currency/symbols`,
  },
  [constants.FEATURES]: {
    url: `${BASE_URL}Features/Company/`,
  },
  [constants.COMPANY_SEND_WELCOME_EMAILS]: {
    url: `${BASE_URL}Onboarding/WelcomeEmail/Send/`,
  },
  [constants.COMPANY_FEATURE_TEMPLATES]: {
    url: `${BASE_URL}Features/Company`,
  },
  [constants.DATE_FORMATS]: {
    url: `${BASE_URL}DateFormats`,
  },
  [constants.DEBTORS]: {
    url: `${BASE_URL}Debtors`,
  },
  [constants.EMAIL_TEMPLATES]: {
    url: `${BASE_URL}TemplateEmail`,
  },
  [constants.FILE_UPLOAD]: {
    url: `${BASE_URL}Upload/`,
  },
  [constants.HOTEL_CITIES]: {
    url: `${BASE_URL}Locations/cities/search`,
  },
  [constants.IMPERSONATION_LOGS]: {
    url: `${BASE_URL}Impersonation`,
  },
  [constants.ITINERARIES]: {
    url: `${BASE_URL}Itineraries/Search`,
  },
  [constants.PCCS]: {
    url: `${BASE_URL}External/Sabre/Pccs`,
  },
  [constants.MESSAGE_LOGS]: {
    url: `${BASE_URL}MessagingLogs`,
  },
  [constants.ROLES]: {
    url: `${BASE_URL}Roles`,
  },
  [constants.ROLE_TYPES]: {
    url: `${BASE_URL}Roles/Types`,
  },
  [constants.ROLES_ADMIN]: {
    url: `${BASE_URL}Roles/Admin`,
  },
  [constants.COMPANY_TRAVELERS]: {
    url: `${BASE_URL}travelers/company/`,
  },
  [constants.DEBTOR_TRAVELERS]: {
    url: `${BASE_URL}travelers/debtor/`,
  },
  [constants.TIMEZONES]: {
    url: `${BASE_URL}Timezones`,
  },
  [constants.TRAVEL_GROUPS]: {
    url: `${BASE_URL}Approve/TravelerGroup`,
  },
  [constants.TRAVEL_POLICIES]: {
    url: `${BASE_URL}Approve/TravelPolicies`,
  },
  [constants.VENDORS]: {
    url: `${BASE_URL}External/Sabre/Search/Vendors`,
  },
  [constants.USER_SEARCH]: {
    url: `${BASE_URL}Users/Search`,
  },
  [constants.USERS]: {
    url: `${BASE_URL}Users`,
  },
  [constants.USER_PASSWORD_RESET_EMAIL_TRIGGER]: {
    url: `${BASE_URL}Authentication/User/`,
  },
  [constants.USER_WELCOME_EMAIL_TRIGGER]: {
    url: `${BASE_URL}Onboarding/user/`,
  },
  [constants.MESSAGE_LOG_TEMPLATE_CODES]: {
    url: `${BASE_URL}MessagingLogs/TemplateCodes`,
  },
  [constants.SLATES]: {
    url: `${BASE_URL}Carousel`,
  },
};

export const getConfig = (type) => {
  return config[type] || {}; // return empty so we don't crash
};
