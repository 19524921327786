import React, { useEffect, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AUTH_CONFIG,
  ACCESS_TOKEN,
  EXPIRES_AT,
  ID_TOKEN,
  SCOPES,
} from "services/authConstants";

const Logout = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(SCOPES);
    logout({ logoutParams: { returnTo: AUTH_CONFIG.loginUrl } });
  }, [logout]);

  return <Fragment></Fragment>;
};

export default Logout;
