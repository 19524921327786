import React, { useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Button } from "components";
import { NotificationObjI, Errors } from "types";

import { blueColor, label, redColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  input: {
    display: "none",
  },
  buttonWrapper: {
    marginBottom: "7px",
  },
  image: {
    cursor: "pointer",
  },
  labelWrapper: {
    display: "block",
  },
  label: {
    ...label,
  },
  labelAction: {
    color: blueColor,
    marginLeft: "12px",
    fontWeight: 900,
    fontSize: "12px",
    cursor: "pointer",
  },
  error: {
    margin: 0,
    color: redColor,
    fontSize: "10px",
    marginBottom: "-5px",
  },
});

interface ValidationObjI {
  maxSize?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  minAspectRatio?: number | string;
  maxAspectRatio?: number | string;
}

interface ImageUploadI {
  addNotification: (payload: NotificationObjI) => void;
  alt?: string;
  disabled?: boolean;
  handleOpenImageCrop?: (url: string, isFile?: boolean) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imageFile?: File;
  imageFileObjectURL?: string;
  imageUrl?: string;
  imageHeight?: string;
  buttonLabel: string;
  formLabel?: string;
  validation?: ValidationObjI;
  classes?: Partial<
    Record<
      | "input"
      | "buttonWrapper"
      | "image"
      | "labelWrapper"
      | "label"
      | "labelAction"
      | "error",
      string
    >
  >;
}

function ImageUpload(props: ImageUploadI) {
  const {
    addNotification,
    alt,
    disabled,
    handleOpenImageCrop,
    onChange,
    imageFileObjectURL,
    imageUrl,
    imageHeight = "45px",
    buttonLabel,
    formLabel,
    validation = {},
  } = props;
  const classes = withStyles.getClasses(props);
  const [hasError, setError] = useState(false);
  const inputRef = useRef(null);

  function validateImage(image, imageFile) {
    const megs = imageFile.size / 1024000;
    const errors: Errors = {};
    if (validation.maxSize && Number(validation.maxSize) < megs) {
      errors.maxSize = [
        `Image is too big at ${megs.toFixed(
          2
        )}mb. Please upload an image smaller than ${validation.maxSize}mb.`,
      ];
    }

    if (validation.minHeight && validation.minHeight > image.height) {
      errors.minHeight = [
        `Image is too small. Please upload an image at least ${validation.minHeight}px tall.`,
      ];
    }

    if (validation.maxHeight && validation.maxHeight < image.height) {
      errors.maxHeight = [
        `Image is too big. Please upload an image lower than ${validation.maxHeight}px tall.`,
      ];
    }

    if (
      validation.minAspectRatio &&
      Number(validation.minAspectRatio) > image.width / image.height
    ) {
      errors.minAspectRatio = [
        `Please upload an image with at least ${validation.minAspectRatio} aspect ratio.`,
      ];
    }

    if (
      validation.maxAspectRatio &&
      Number(validation.maxAspectRatio) < image.width / image.height
    ) {
      errors.maxAspectRatio = [
        `Please upload an image lower than ${validation.maxAspectRatio} aspect ratio.`,
      ];
    }

    if (Object.keys(errors).length) {
      addNotification({
        text: `There was an error with the image upload.`,
        errors,
        type: "error",
      });
    } else {
      setError(false);
      onChange(imageFile);
    }
  }

  function verifyImage(imageFile) {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result as string;
      img.onload = function () {
        validateImage(this, imageFile);
      };
    };
  }

  function handleChange(e) {
    if (e && e.target && e.target.files && e.target.files.length) {
      verifyImage(e.target.files[0]);
    }
  }

  function handleClear() {
    inputRef.current.value = null;
    onChange(null);
  }

  function renderBody() {
    if (hasError || (!imageFileObjectURL && !imageUrl)) return renderButton();
    else
      return (
        <img
          src={imageFileObjectURL || imageUrl}
          className={classes.image}
          style={{ height: imageHeight }}
          alt={alt || formLabel || buttonLabel || "Image Upload"}
          onError={() => setError(true)}
        />
      );
  }

  function renderButton() {
    return (
      <div className={classes.buttonWrapper}>
        {hasError ? (
          <p className={classes.error}>
            There was an error loading the saved image.
          </p>
        ) : null}
        <Button
          label={buttonLabel}
          isBlue
          loading={false}
          component="span"
          data-aut={`${formLabel}|ImageUpload`}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <section>
      {(imageFileObjectURL || imageUrl) && !hasError ? (
        <span className={classes.labelWrapper}>
          <label className={classes.label}>{formLabel}</label>
          {!disabled && (
            <label htmlFor={buttonLabel} className={classes.labelAction}>
              EDIT
            </label>
          )}
          {!disabled && handleOpenImageCrop && imageFileObjectURL && (
            <label
              className={classes.labelAction}
              onClick={() => {
                let url, isFile;
                if (imageFileObjectURL) {
                  url = imageFileObjectURL;
                  isFile = true;
                } else {
                  url = imageUrl;
                }
                handleOpenImageCrop(url, isFile);
              }}
            >
              CROP
            </label>
          )}
          {!disabled && (
            <label className={classes.labelAction} onClick={handleClear}>
              CLEAR
            </label>
          )}
        </span>
      ) : null}
      <input
        className={classes.input}
        type="file"
        accept=".png, .jpg, .jpeg"
        id={buttonLabel}
        onChange={handleChange}
        ref={inputRef}
        disabled={disabled}
        data-aut="ImageUploadInput"
      />
      <label htmlFor={buttonLabel}>{renderBody()}</label>
    </section>
  );
}

export default withStyles(ImageUpload, styles);
