import cx from "classnames";
import Button from "@mui/material/Button";
import { withStyles } from "tss-react/mui";
import FormGroup from "@mui/material/FormGroup";
import { Error } from "@mui/icons-material";
import multiToggleStyle from "components/MultiToggle/multiToggleStyle";
import Tooltip from "@mui/material/Tooltip";

type Property = {
  name: string;
};

interface ToggleProps {
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  label: string;
  properties: Property[];
  value: 0 | 1;
  handleClick: (val: string | number) => void;
  offButton?: boolean;
  tooltip?: string;
  classes?: Partial<
    Record<
      | "rowContainer"
      | "buttonStyle"
      | "button"
      | "buttonLeft"
      | "buttonRight"
      | "active"
      | "error"
      | "errorMessage",
      string
    >
  >;
}

const setFirstPropertyClass = (offButton, i, properties, classes, value) => {
  if (!offButton && i === 0) {
    return cx(
      classes.buttonStyle,
      classes.buttonLeft,
      value === i ? classes.active : ""
    );
  } else {
    return i === properties.length - 1
      ? cx(
          classes.buttonStyle,
          classes.buttonRight,
          value === i ? classes.active : ""
        )
      : cx(
          classes.buttonStyle,
          classes.button,
          value === i ? classes.active : ""
        );
  }
};

const MultiToggle = (props: ToggleProps) => {
  const {
    disabled = false,
    error,
    errorMessage,
    label,
    properties,
    value,
    handleClick,
    offButton,
    tooltip,
  } = props;
  const classes = withStyles.getClasses(props);
  function renderBody() {
    return (
      <div className={classes.rowContainer}>
        {offButton && (
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            className={cx(
              classes.buttonStyle,
              classes.buttonLeft,
              !Number.isInteger(value) ? classes.active : ""
            )}
            onClick={() => {
              handleClick("off");
            }}
            data-aut={`${label}|Off`}
            disabled={disabled}
          >
            Off
          </Button>
        )}
        {properties.map((property, i) => (
          <Button
            key={property.name}
            size="medium"
            variant="outlined"
            color="primary"
            onClick={() => {
              handleClick(i);
            }}
            className={setFirstPropertyClass(
              offButton,
              i,
              properties,
              classes,
              value
            )}
            data-aut={`${label}|${property.name}`}
            disabled={disabled}
          >
            {property.name}
          </Button>
        ))}
        {error && <Error className={classes.error} />}
        {error && errorMessage && (
          <span className={classes.errorMessage}>{errorMessage}</span>
        )}
      </div>
    );
  }

  function renderWithorWithoutTooltip() {
    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement={"top"} enterDelay={150}>
          {renderBody()}
        </Tooltip>
      );
    } else {
      return renderBody();
    }
  }

  return <FormGroup row>{renderWithorWithoutTooltip()}</FormGroup>;
};

export default withStyles(MultiToggle, multiToggleStyle);
