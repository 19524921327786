import { REDIRECT_TO_LOGOUT, SET_PAGE_ERROR } from "redux/actions/application";

export interface ApplicationStateI {
  redirectToLogout: boolean;
  pageError: boolean;
}

export const initialState: ApplicationStateI = {
  redirectToLogout: false,
  pageError: false,
};

const application = (
  state: ApplicationStateI = initialState,
  action
): ApplicationStateI => {
  switch (action.type) {
    case SET_PAGE_ERROR:
      return {
        ...state,
        pageError: true,
      };
    case REDIRECT_TO_LOGOUT:
      return {
        ...state,
        redirectToLogout: true,
      };
    default:
      return state;
  }
};

export default application;
