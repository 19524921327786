import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { fetchMessageLogs } from "services/messageLogs";
import { MessageLogsStateI } from "types/MessageLog";
import { QUERYKEY_MESSAGE_LOGS } from "./queryKeys";

export function useMessageLogs() {
  const reduxState = useSelector(
    ({
      messageLogs,
      selectedCompanyID,
    }: {
      messageLogs: MessageLogsStateI;
      selectedCompanyID: string;
    }) => ({ messageLogs, selectedCompanyID })
  );
  const company = reduxState.selectedCompanyID
    ? { id: reduxState.selectedCompanyID }
    : reduxState.messageLogs?.selectedCompany;
  const debtor = reduxState.messageLogs?.selectedDebtor;

  return useQuery({
    queryKey: [QUERYKEY_MESSAGE_LOGS, reduxState.messageLogs.serverQuery],
    queryFn: () => {
      return fetchMessageLogs({
        ...reduxState.messageLogs.serverQuery,
        company,
        debtor,
      });
    },
    enabled: Boolean(reduxState?.messageLogs?.serverQuery),
  });
}
