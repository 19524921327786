import { ellipsisOverflow, label, silverColor } from "assets/jss/globalStyle";
import { ThemeI } from "types/Theme";

// eslint-disable-next-line
const styles = (theme: ThemeI, props) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  disabled: {
    background: silverColor,
  },
  label,
  dropdown: {
    width: "100%",
    "& .MuiSelect-select": {
      minHeight: "35px !important",
      padding: "5px",
    },
    "& .Mui-disabled": {
      background: silverColor,
    },
  },
  textContainer: {
    height: "35px",
    paddingLeft: "5px",
    display: "flex",
    alignItems: "center",
  },
  placeholder: {
    color: "gray",
  },
  textContent: ellipsisOverflow,
});

export default styles;
