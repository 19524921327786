import React from "react";
import { useSafeSetState } from "hooks/useState";
import { withStyles } from "tss-react/mui";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { AddCircleOutline } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Button, Input } from "components";
import { searchUsers } from "services/users";

import { ellipsisOverflow, primaryColor } from "assets/jss/globalStyle";
import { NotificationObjI } from "types";
import { CompanyI } from "types/Company";
import { UserI } from "types/User";

const initialState = {
  searchQuery: "",
  results: [],
  page: 0,
  maxResults: 10,
  count: 0,
  searching: false,
};

// eslint-disable-next-line
const styles = (props, theme) => ({
  tableRow: {
    width: "100%",
  },
  userSearch: {
    padding: "0 24px",
    marginBottom: "24px",
  },
  cell: {
    padding: "0 16px",
    ...ellipsisOverflow,
  },
  ellipsisOverflow,
  table: {
    tableLayout: "fixed",
  },
});

interface UserTablePropsI {
  addNotification: (notification: NotificationObjI) => void;
  selectedCompany?: CompanyI;
  handleAddUserRequest: (user: UserI) => void;
  classes?: Partial<
    Record<
      "tableRow" | "userSearch" | "cell" | "ellipsisOverflow" | "table",
      string
    >
  >;
}

function UserTable(props: UserTablePropsI) {
  const {
    addNotification,
    selectedCompany = undefined,
    handleAddUserRequest,
  } = props;
  const classes = withStyles.getClasses(props);
  const [{ searching, searchQuery, results, page, count }, safeSetState] =
    useSafeSetState(initialState);

  function handleClick() {
    safeSetState({ page: 0, count: 0, results: [], searching: true });
    const params = {
      includeCount: true,
      query: searchQuery,
      selectedCompanies: selectedCompany && [selectedCompany],
      usersRowsPerPage: 10,
      usersPage: 0,
      usersOrder: "desc",
    };
    searchUsers(params)
      .then((r) => {
        safeSetState({
          count: r.totalCount,
          results: r.data,
          searching: false,
        });
      })
      .catch((er) => {
        addNotification({
          text: `There was an error searching users.`,
          errors: er.customErrors,
          type: "error",
        });
        safeSetState({ searching: false });
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleClick();
  }

  function handlePageChange(event, page) {
    safeSetState({ page });
    const params = {
      includeCount: false,
      query: searchQuery,
      selectedCompanies: selectedCompany && [selectedCompany],
      usersRowsPerPage: 10,
      usersPage: page,
      usersOrder: "desc",
    };
    searchUsers(params).then((r) => {
      safeSetState({ results: r.data });
    });
  }

  function mapUser(user) {
    return (
      <TableRow tabIndex={-1} key={`${user.id}`} className={classes.tableRow}>
        <TableCell
          className={classes.cell}
          component="th"
          scope="row"
          padding="normal"
          align="left"
          style={{ width: "35%" }}
        >
          {user.nameFormatted}
        </TableCell>
        <TableCell
          className={classes.cell}
          align="left"
          style={{ width: "65%" }}
        >
          {user.email}
        </TableCell>
        <TableCell
          className={classes.cell}
          align="right"
          style={{ width: "10%", paddingRight: "10px" }}
        >
          <Tooltip title="Add user" placement={"top"} enterDelay={150}>
            <IconButton
              onClick={() => handleAddUserRequest(user)}
              aria-label={`Add user: ${user.email}`}
              data-aut={`Add user: ${user.email}`}
              size="large"
            >
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <form className={classes.userSearch} onSubmit={handleSubmit}>
        <Input
          label="Search for a User"
          value={searchQuery}
          onChange={(searchQuery) => safeSetState({ searchQuery })}
          data-aut="UserTable|FilterInput"
        />
        <Button
          onClick={handleClick}
          label="Search User"
          disabled={searching}
          loading={searching}
          aria-label="Search User"
          data-aut="UserTable|SearchButton"
        />
      </form>
      <Table className={classes.table}>
        <TableHead>
          <TableRow tabIndex={-1} className={classes.tableRow}>
            <TableCell
              className={classes.cell}
              component="th"
              scope="row"
              padding="normal"
              align="left"
              style={{ width: "35%", fontWeight: 600 }}
            >
              Name
            </TableCell>
            <TableCell
              className={classes.cell}
              align="left"
              style={{ width: "65%", fontWeight: 600 }}
            >
              Email
            </TableCell>
            <TableCell
              className={classes.cell}
              align="right"
              style={{ width: "10%", paddingRight: "10px" }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{results.map(mapUser)}</TableBody>
      </Table>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={10}
        page={page}
        rowsPerPageOptions={[10]}
        onPageChange={handlePageChange}
        sx={{
          "& .MuiTablePagination-actions > button": {
            color: primaryColor,
          },
        }}
      />
    </div>
  );
}

export default withStyles(UserTable, styles);

// export default UserTableStyled;
