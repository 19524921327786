import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { addNotification } from "redux/actions/notifications";
import { NotificationObjI } from "types";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useSafeSetState } from "hooks/useState";

interface QueryStringHandlerPropsI {
  accessLevel?: number;
  companyID?: string;
  shouldRedirect?: boolean;
  WrappedComponent: React.FunctionComponent<any>;
  selectedCompanyID?: string;
}

interface HandlerProps {
  addNotification: (notification: NotificationObjI) => void;
  location: Location;
  selectedCompanyID?: string;
}

const initialState = {
  companyError: false,
  redirect: false,
};

function CompanyError() {
  return (
    <p style={{ textAlign: "center" }}>
      There was an error fetching your company.
    </p>
  );
}

const QueryStringHandler = ({
  WrappedComponent,
  accessLevel,
  shouldRedirect = false,
}: QueryStringHandlerPropsI) => {
  const Handler = (props: HandlerProps) => {
    const { addNotification, selectedCompanyID } = props;
    const [{ companyError, redirect }, safeSetState] =
      useSafeSetState(initialState);
    const selectedCompany = useSelectedCompany(selectedCompanyID);
    const isClientLogin = accessLevel < 40;
    // On update, check if there is no company selected, and companies are not loading.
    // In that case, we want to try to update the company selected based on query string if possible.
    if (selectedCompany.isError && !redirect && !companyError) {
      if (isClientLogin) {
        safeSetState({ companyError: true });
        addNotification({
          text: "There was an error retrieving the selected company.",
          type: "error",
        });
        return <CompanyError />;
      } else if (selectedCompany.error && shouldRedirect) {
        safeSetState({ redirect: true });
        addNotification({
          text: "There was an error retrieving the selected company.",
          type: "error",
        });
        return <Redirect to="/" />;
      }
    }

    if (selectedCompanyID && selectedCompany.isLoading) return <></>;

    if (companyError) return <CompanyError />;
    else if (redirect) return <Redirect to="/" />;
    else {
      return <WrappedComponent {...props} />;
    }
  };

  const mapStateToProps = (state) => ({
    selectedCompanyID: state.selectedCompanyID,
    userState: state.userState.payload,
  });

  const mapDispatchToProps = (dispatch) => ({
    addNotification: (notification) => dispatch(addNotification(notification)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Handler);
};

export default QueryStringHandler;
