import { ellipsisOverflow, label, defaultFont } from "assets/jss/globalStyle";

const secondaryText = {
  margin: "0 0 0 5px",
  fontSize: "11px",
  maxHeight: "36px",
  ...ellipsisOverflow,
};

const row = {
  margin: "0",
  minHeight: "100px",
};

// eslint-disable-next-line
const styles = (theme, props) => ({
  row: {
    width: "100%",
    margin: "10px 0",
  },
  toolTip: {
    maxWidth: "600px",
    wordWrap: "break-word",
  },
  icon: {
    padding: "5px",
  },
  secondaryText,
  defaultAuto: {
    ...secondaryText,
    marginTop: "-5px",
  },
  checkbox: {
    "& svg": {
      fontSize: "32px",
    },
    marginLeft: "-11px",
  },
  label: {
    ...defaultFont,
    ...label,
  },
  partialWidth: {
    ...row,
    width: "650px",
  },
  fullWidth: {
    ...row,
    width: "800px",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  blackText: {
    color: "black",
  },
});

export default styles;
