import { useState } from "react";
import { withStyles } from "tss-react/mui";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { KeyboardArrowDown } from "@mui/icons-material";
import expandableCardStyle from "components/ExpandableCard/expandableCardStyle";

interface CardProps {
  children: JSX.Element | JSX.Element[];
  rightSideHeaderContent?: React.ReactNode;
  expanded?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  hasRequiredFields?: boolean;
  header: string | React.ReactNode;
  ariaLabel?: string;
  dataAut?: string;
  noPadding?: boolean;
  overflowX?: boolean;
  classes?: Partial<
    Record<
      | "root"
      | "bottomPadding"
      | "withPadding"
      | "noPadding"
      | "summaryContainer"
      | "summaryContent"
      | "header"
      | "headerWrapper"
      | "requiredFields"
      | "expandIcon",
      string
    >
  >;
}

const ExpandableCard = (props: CardProps) => {
  const {
    children,
    rightSideHeaderContent,
    expanded,
    handleClick,
    hasRequiredFields,
    header,
    ariaLabel,
    dataAut,
    noPadding,
    overflowX,
  } = props;
  const classes = withStyles.getClasses(props);

  const [localExpanded, setExpanded] = useState(false);
  const useLocalState = expanded == null;
  const isExpanded = useLocalState ? localExpanded : expanded;
  const onClick = (e) => {
    if (useLocalState) {
      setExpanded(!localExpanded);
    } else {
      handleClick(e);
    }
  };
  return (
    <Accordion className={classes.root} expanded={isExpanded}>
      <AccordionSummary
        className={classes.withPadding}
        classes={{
          content: classes.summaryContent,
          expanded: classes.summaryContent,
        }}
        onClick={onClick}
        aria-label={ariaLabel}
        data-aut={dataAut}
      >
        <div className={classes.summaryContainer}>
          <div className={classes.headerWrapper}>
            <span>
              <h2 className={classes.header}>{header}</h2>
              {hasRequiredFields && (
                <span className={classes.requiredFields}>
                  * Required Fields
                </span>
              )}
            </span>
            {rightSideHeaderContent && <span>{rightSideHeaderContent}</span>}
          </div>
          <KeyboardArrowDown
            style={isExpanded ? { transform: "rotate(180deg)" } : {}}
            className={classes.expandIcon}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={noPadding ? classes.noPadding : classes.bottomPadding}
        style={overflowX ? { overflowX: "auto" } : {}}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(ExpandableCard, expandableCardStyle);
