import { deleteRequest, get, put, post } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { COMPANY_DEBTORS, DEBTORS } from "common/constants";
import { handleErrors } from "common/helpers";
import { DebtorI, DebtorCompactI, DebtorSelectionI } from "types/Company";

const url: string | null = getConfig(DEBTORS).url;

export const fetchDebtorsWithApprove = (): Promise<DebtorCompactI[]> => {
  if (url) {
    // We use the compact version of the api for reduced payloads
    const finalURL = `${url}/Compact`;
    return get(finalURL, { filterApproveFeature: true })
      .then(handleErrors)
      .then((res) => res.json())
      .then((debtors) => debtors.map((d) => ({ ...d, label: d.name })));
  } else {
    return Promise.reject(new Error(`Missing ${DEBTORS} url`));
  }
};

export const fetchDebtors = (): Promise<DebtorI[]> => {
  if (url) {
    // Full debtor payload
    const finalURL = `${url}/Company`;
    return get(finalURL)
      .then(handleErrors)
      .then((res) => res.json())
      .then((debtors) => debtors.map((d) => ({ ...d, label: d.name })));
  } else {
    return Promise.reject(new Error(`Missing ${DEBTORS} url`));
  }
};

export const fetchDebtor = (id: string): Promise<DebtorI> => {
  if (!id) return Promise.reject(new Error("Missing debtor id."));
  if (!url) return Promise.reject(new Error(`Missing ${DEBTORS} url`));

  const finalURL = `${url}/${id}`;
  return get(finalURL)
    .then(handleErrors)
    .then((res) => res.json());
};

export const saveDebtor = (debtor: DebtorI) => {
  if (!debtor) return Promise.reject(new Error("Missing debtor."));
  if (!url) return Promise.reject(new Error(`Missing ${DEBTORS} url`));
  const finalURL = `${url}/${debtor.id}`;
  return put(finalURL, debtor).then(handleErrors);
};

const companyDebtorURL = getConfig(COMPANY_DEBTORS).url;

export const createDebtor = ({
  debtor,
  companyID,
}: {
  debtor: DebtorI;
  companyID: string;
}) => {
  if (companyDebtorURL) {
    const finalURL = `${companyDebtorURL}/${companyID}/Create`;
    return post(finalURL, debtor).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${COMPANY_DEBTORS} url`));
  }
};

export const fetchCompanyDebtors = (id): Promise<DebtorI[]> => {
  if (!id) return Promise.reject(new Error("Missing company id."));
  if (!companyDebtorURL)
    return Promise.reject(new Error(`Missing ${COMPANY_DEBTORS} url`));
  const finalURL = `${companyDebtorURL}/${id}`;
  return get(finalURL)
    .then(handleErrors)
    .then((res) => res.json())
    .then((debtors) => debtors.map((d) => ({ ...d, label: d.name })));
};

export const fetchUnusedDebtors = (): Promise<DebtorSelectionI[]> => {
  if (!companyDebtorURL)
    return Promise.reject(new Error(`Missing ${COMPANY_DEBTORS} url`));
  return get(companyDebtorURL)
    .then(handleErrors)
    .then((res) => res.json())
    .then((ud) => ud.map((d) => ({ ...d, label: d.name })));
};

export const addDebtorToCompany = ({
  debtorId,
  companyId,
}: {
  debtorId: string;
  companyId: string;
}) => {
  if (!debtorId) return Promise.reject(new Error("Missing debtor id."));
  if (!companyId) return Promise.reject(new Error("Missing company id."));
  if (!companyDebtorURL)
    return Promise.reject(new Error(`Missing ${COMPANY_DEBTORS} url`));

  const finalURL = `${companyDebtorURL}/${companyId}/Debtor/${debtorId}`;

  return put(finalURL).then(handleErrors);
};

export const removeDebtorFromCompany = ({
  debtorId,
  companyId,
}: {
  debtorId: string;
  companyId: string;
}) => {
  if (!debtorId) return Promise.reject(new Error("Missing debtor id."));
  if (!companyId) return Promise.reject(new Error("Missing company id."));
  if (!companyDebtorURL)
    return Promise.reject(new Error(`Missing ${COMPANY_DEBTORS} url`));

  const finalURL = `${companyDebtorURL}/${companyId}/Debtor/${debtorId}`;

  return deleteRequest(finalURL).then(handleErrors);
};

export const makeDebtorDefault = ({
  debtorId,
  companyId,
}: {
  debtorId: string;
  companyId: string;
}) => {
  if (!debtorId) return Promise.reject(new Error("Missing debtor id."));
  if (!companyId) return Promise.reject(new Error("Missing company id."));
  if (!companyDebtorURL)
    return Promise.reject(new Error(`Missing ${COMPANY_DEBTORS} url`));

  const finalURL = `${companyDebtorURL}/${companyId}/Debtor/${debtorId}/Default`;

  return put(finalURL).then(handleErrors);
};
