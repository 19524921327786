import { NOTIFICATIONS } from "common/constants";

export const NOTIFICATIONS_ADD_MESSAGE = `${NOTIFICATIONS}_ADD_MESSAGE`;
export const NOTIFICATIONS_CLOSE_MESSAGE = `${NOTIFICATIONS}_CLOSE_MESSAGE`;
export const NOTIFICATIONS_PROCESS_QUEUE = `${NOTIFICATIONS}_PROCESS_QUEUE`;

export const addNotification = (payload) => ({
  type: NOTIFICATIONS_ADD_MESSAGE,
  payload,
});
export const closeNotification = (payload) => ({
  type: NOTIFICATIONS_CLOSE_MESSAGE,
  payload,
});
export const processNotificationQueue = () => ({
  type: NOTIFICATIONS_PROCESS_QUEUE,
});
