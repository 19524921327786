import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from "tss-react/mui";
import checkboxSpinnerStyle from "components/CheckboxSpinner/checkboxSpinnerStyle";

interface CheckboxSpinnerI {
  enabled: boolean;
  disabled?: boolean;
  fixedWidth?: string;
  loading?: boolean;
  onChange: () => void;
  checkboxClass?: string;
  value: string | null;
  classes?: Partial<
    Record<
      | "checkBoxWrapper"
      | "checkboxBlue"
      | "checkboxPink"
      | "checkboxGreen"
      | "checkboxPurple"
      | "buttonProgress",
      string
    >
  >;
}

const CheckboxSpinner = (props: CheckboxSpinnerI) => {
  const {
    enabled,
    disabled,
    fixedWidth,
    loading,
    onChange,
    checkboxClass,
    value,
  } = props;
  const classes = withStyles.getClasses(props);
  return (
    <div
      className={classes.checkBoxWrapper}
      aria-busy={disabled}
      style={fixedWidth ? { width: fixedWidth } : {}}
    >
      <Checkbox
        checked={enabled}
        disabled={disabled}
        onChange={onChange}
        value={value || ""}
        sx={{ "&.Mui-checked": classes[checkboxClass] }}
        inputProps={{ "aria-label": value || "" }}
      />
      {loading && (
        <CircularProgress
          size={32}
          className={classes.buttonProgress}
          data-aut="checkbox-spinner"
        />
      )}
    </div>
  );
};

const CheckboxSpinnerStyled = withStyles(CheckboxSpinner, checkboxSpinnerStyle);

export default CheckboxSpinnerStyled;
