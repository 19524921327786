import { ReactElement } from "react";
import { withStyles } from "tss-react/mui";
import { Loading } from "components";
import { UserStateStateI } from "types/User";
import { ThemeI } from "types/Theme";
import { useHistory } from "react-router-dom";

// eslint-disable-next-line
const styles = (theme: ThemeI, props) => ({
  loadingContainer: {
    height: theme.layout.dashboard.height,
  },
});

export const isUserAuthenticated = (userState: UserStateStateI) => {
  return (
    userState.payload &&
    userState.payload.accessLevel &&
    userState.payload.accessLevel > 0
  );
};

export const textGenerator = () => {
  return "There was an error retrieving your user information. Logging out in 10 seconds.";
};

interface RoleAuthenticationI {
  children: ReactElement<any, any>;
  userState: UserStateStateI;
  classes?: Partial<Record<"loadingContainer", string>>;
}

const RoleAuthentication = (props: RoleAuthenticationI) => {
  const { children, userState } = props;
  const classes = withStyles.getClasses(props);
  const userAuthenticated = isUserAuthenticated(userState);
  const history = useHistory();

  if (userAuthenticated) return children;
  else {
    setTimeout(() => {
      history.push("/logout");
    }, 10000);
    return (
      <div className={classes.loadingContainer}>
        <Loading active={true} loadingText={textGenerator()} />
      </div>
    );
  }
};

export default withStyles(RoleAuthentication, styles);
