import { Fragment } from "react";
import { withStyles } from "tss-react/mui";
import { connect } from "react-redux";
import moment from "moment";

import { Loading, SortableTableHead } from "components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  setMessageLogsOrder,
  setMessageLogsPage,
  setMessageLogsRowsPerPage,
} from "redux/actions/messageLogs";
import { isUserNotSelectingText, smartSort } from "common/helpers";

import { TableColumnI } from "types/Table";
import { SortOrder } from "types";
import { ellipsisOverflow, primaryColor } from "assets/jss/globalStyle";
import { MessageLogI } from "types/MessageLog";
import { useMessageLogs } from "hooks/messageLogs";

const columns: TableColumnI[] = [
  {
    id: "dateSent",
    align: "left",
    label: "Date",
    width: "25%",
  },
  {
    id: "to",
    align: "left",
    label: "Recipient",
    width: "50%",
  },
  {
    id: "templateCode",
    align: "left",
    label: "Template Code",
    width: "25%",
  },
];

// eslint-disable-next-line
const styles = (theme, props) => ({
  tableRow: {
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.hoverBackground,
    },
  },
  userSearch: {
    padding: "0 22px",
    marginBottom: "22px",
  },

  headerCell: {
    padding: "0 16px",
    ...ellipsisOverflow,
  },
  leftCell: {
    padding: "16px",
    width: "25%",
  },
  middleCell: {
    padding: "16px",
    width: "50%",
  },
  rightCell: {
    padding: "16px",
    width: "25%",
  },
  ellipsisOverflow,
  table: {
    tableLayout: "fixed",
    margin: "0 0 32px 0",
  },
  loadingContainer: {
    position: "relative",
    width: "100%",
    height: "450px",
  },
});

interface MessageLogsTableI {
  error?: boolean;
  loading?: boolean;
  order: SortOrder;
  orderBy: string;
  rowsPerPage: number;
  selectMessage: (message: MessageLogI) => void;
  setMessageLogsOrder: (order: SortOrder, orderBy: string) => void;
  setMessageLogsPage: (page: number) => void;
  setMessageLogsRowsPerPage: (rowsPerPage: number) => void;
  page: number;
  classes?: Partial<
    Record<
      | "tableRow"
      | "userSearch"
      | "headerCell"
      | "leftCell"
      | "middleCell"
      | "rightCell"
      | "ellipsisOverflow"
      | "table"
      | "loadingContainer",
      string
    >
  >;
}

function MessageLogsTable(props: MessageLogsTableI) {
  const {
    order,
    orderBy,
    rowsPerPage,
    selectMessage,
    setMessageLogsOrder,
    setMessageLogsPage,
    setMessageLogsRowsPerPage,
    page,
  } = props;
  const classes = withStyles.getClasses(props);
  const messageLogs = useMessageLogs();

  function handleSortChange(event, newOrderBy) {
    let newOrder = "desc" as SortOrder;
    if (order === "desc" && orderBy === newOrderBy) newOrder = "asc";
    setMessageLogsOrder(newOrder, newOrderBy);
  }

  function handlePageChange(event, page) {
    setMessageLogsPage(page);
  }

  function handleRowsPerPageChange(event) {
    setMessageLogsRowsPerPage(event.target.value);
  }

  function sortAlerts(a, b) {
    const evaluated = smartSort(a[orderBy], b[orderBy]);
    if (order === "desc") return evaluated;
    else return -evaluated;
  }

  function renderLoading() {
    if (!messageLogs.isError && !messageLogs.isFetching) return <></>;
    return (
      <div className={classes.loadingContainer}>
        <Loading
          active={true}
          loadingText="Searching Message Logs"
          error={messageLogs.isError}
        />
      </div>
    );
  }

  function renderMessage(ra, index) {
    return (
      <TableRow
        className={classes.tableRow}
        key={ra.id}
        onClick={() => isUserNotSelectingText() && selectMessage(ra.id)}
        data-aut={`MessageLogsTable|Row-${index}`}
      >
        <TableCell
          className={classes.leftCell}
          component="th"
          scope="row"
          padding="normal"
          align="left"
        >
          {moment(ra.dateSent).format("MM/DD/YYYY")}
        </TableCell>
        <TableCell className={classes.middleCell} align="left">
          {ra.to}
        </TableCell>
        <TableCell className={classes.rightCell} align="left">
          {ra.templateCode}
        </TableCell>
      </TableRow>
    );
  }

  function renderTable() {
    const sortedAlerts = messageLogs.data.sort(sortAlerts);
    return (
      <Fragment>
        <Table className={classes.table}>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleSortChange}
            columns={columns}
          />
          <TableBody>
            {sortedAlerts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(renderMessage)}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={sortedAlerts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiTablePagination-actions > button": { color: primaryColor },
          }}
        />
      </Fragment>
    );
  }
  if (messageLogs.isFetching || messageLogs.isLoading || messageLogs.isError) {
    return renderLoading();
  } else return renderTable();
}

const mapStateToProps = (state) => ({
  order: state.messageLogs.order,
  orderBy: state.messageLogs.orderBy,
  page: state.messageLogs.page,
  rowsPerPage: state.messageLogs.rowsPerPage,
});

const mapDispatchToProps = (dispatch) => ({
  setMessageLogsOrder: (order, orderBy) =>
    dispatch(setMessageLogsOrder(order, orderBy)),
  setMessageLogsPage: (page) => dispatch(setMessageLogsPage(page)),
  setMessageLogsRowsPerPage: (rows) =>
    dispatch(setMessageLogsRowsPerPage(rows)),
});

const MessageLogsTableStyled = withStyles(MessageLogsTable, styles);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageLogsTableStyled);
