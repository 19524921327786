import { useDispatch } from "react-redux";
import { NOTIFICATIONS_ADD_MESSAGE } from "redux/actions/notifications";

export const useAddNotification = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch({
      type: NOTIFICATIONS_ADD_MESSAGE,
      payload,
    });
  };
};
