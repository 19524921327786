import { pagination } from "assets/jss/components/tableStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  paginationIcon: {
    ...pagination.paginationIcon,
  },
  toolBarTitle: {
    width: "100%",
    margin: 0,
    fontSize: "18px",
    fontWeight: 600,
  },
  textField: {
    marginRight: "50px",
    width: "300px",
  },
  toolBar: {
    width: "100%",
    padding: theme.layout.paddingSize,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableRow: {
    cursor: "pointer",
  },
  loadingContainer: {
    height: "488px",
  },
});

export default styles;
