import React, { Fragment } from "react";
import { withStyles } from "tss-react/mui";
import { Button } from "components";
import IconButton from "@mui/material/IconButton";
import { Clear } from "@mui/icons-material";
import { flexRight, redColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  flexRight,
  deleteContainer: {
    textAlign: "center",
  },
  deleteHeader: {
    fontSize: "20px",
    fontWeight: 600,
  },
  deleteWarning: {
    fontSize: "14px",
    color: redColor,
  },
});

interface ConfirmationProps {
  buttonLabel?: string;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirmation: React.MouseEventHandler<HTMLButtonElement>;
  header: string;
  mainText?: string;
  requestingConfirmation?: boolean;
  usePrimaryColor?: boolean;
  classes?: Partial<
    Record<
      "flexRight" | "deleteContainer" | "deleteHeader" | "deleteWarning",
      string
    >
  >;
}

const Confirmation = (props: ConfirmationProps) => {
  const {
    buttonLabel = "Delete",
    handleClose,
    handleConfirmation,
    header,
    mainText,
    requestingConfirmation,
    usePrimaryColor,
  } = props;
  const classes = withStyles.getClasses(props);

  return (
    <Fragment>
      <div className={classes.flexRight}>
        <IconButton onClick={handleClose} size="large">
          <Clear fontSize="large" />
        </IconButton>
      </div>
      <div className={classes.deleteContainer}>
        <h5 className={classes.deleteHeader}>{header}</h5>
        {mainText && <p className={classes.deleteWarning}>{mainText}</p>}
        <Button
          label={buttonLabel}
          isBlue={usePrimaryColor}
          secondary={!usePrimaryColor}
          onClick={handleConfirmation}
          disabled={requestingConfirmation}
          loading={requestingConfirmation}
          data-aut="DeleteModal|DeleteButton"
        />
        <Button
          label="Cancel"
          isTextButton
          onClick={handleClose}
          data-aut="DeleteModal|CancelButton"
        />
      </div>
    </Fragment>
  );
};
export default withStyles(Confirmation, styles);
