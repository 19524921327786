export const QUERYKEY_APPS = "apps";
export const QUERYKEY_APPROVER_GROUPS = "approverGroups";
export const QUERYKEY_CITIES = "cities";
export const QUERYKEY_COMPANIES = "companies";
export const QUERYKEY_COMPANY_DEBTORS = "companyDebtors";
export const QUERYKEY_COMPANY_APPROVE_DEBTORS = "companyApproveDebtors";
export const QUERYKEY_COMPANY_TRAVELERS = "companyTravelers";
export const QUERYKEY_COUNTRIES = "countries";
export const QUERYKEY_COUNTRY_CITIES = "countryCities";
export const QUERYKEY_CUSTOM_UDIDS = "customUDIDs";
export const QUERYKEY_COMPANY_APPROVE_EMAIL = "companyApproveEmail";
export const QUERYKEY_CURRENCY_SYMBOLS = "currencySymbols";
export const QUERYKEY_DEBTOR_APPROVE_EMAIL = "debtorApproveEmail";
export const QUERYKEY_DATE_FORMATS = "dateFormats";
export const QUERYKEY_DEBTORS = "debtors";
export const QUERYKEY_DEBTORS_COMPACT = "debtorsCompact";
export const QUERYKEY_DEBTOR_TRAVELERS = "debtorTravelers";
export const QUERYKEY_FEATURES = "features";
export const QUERYKEY_FEATURE_TEMPLATES = "featureTemplates";
export const QUERYKEY_IMPERSONATION_LOGS = "impersonationLogs";
export const QUERYKEY_IMPERSONATION_LOG_DETAILS = "impersonationLogDetails";
export const QUERYKEY_ITINERARIES = "itineraries";
export const QUERYKEY_MESSAGE_LOGS = "messageLogs";
export const QUERYKEY_MESSAGE_LOG_TEMPLATE_CODES = "messageLogTemplateCodes";
export const QUERYKEY_PCCS = "pccs";
export const QUERYKEY_PNRS = "pnrs";
export const QUERYKEY_ROLES = "roles";
export const QUERYKEY_ROLE_TYPES = "roleTypes";
export const QUERYKEY_SELECTED_COMPANY = "selectedCompany";
export const QUERYKEY_TIMEZONES = "timezones";
export const QUERYKEY_TRAVEL_GROUPS = "travelGroups";
export const QUERYKEY_TRAVEL_POLICIES = "travelPolicies";
export const QUERYKEY_TRAVEL_POLICY_TYPES = "travelPolicyTypes";
export const QUERYKEY_UNUSED_DEBTORS = "unusedDebtors";
export const QUERYKEY_USERS = "searchUsers";
export const QUERYKEY_VENDORS = "vendors";
