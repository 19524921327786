import { USER_SEARCH } from "common/constants";

export const USER_SEARCH_CHANGE_QUERY = `${USER_SEARCH}_CHANGE_QUERY`;
export const userSearchChangeQuery = (payload) => ({
  type: USER_SEARCH_CHANGE_QUERY,
  payload,
});

export const USER_SEARCH_SELECT_COMPANY = `${USER_SEARCH}_SELECT_COMPANY`;
export const userSearchSelectCompany = (payload) => ({
  type: USER_SEARCH_SELECT_COMPANY,
  payload,
});

export const USER_SEARCH_CHANGE_ROLES = `${USER_SEARCH}_CHANGE_ROLES`;
export const userSearchChangeRoles = (payload) => ({
  type: USER_SEARCH_CHANGE_ROLES,
  payload,
});
export const USER_SEARCH_REMOVE_ROLE = `${USER_SEARCH}_REMOVE_ROLE`;
export const userSearchRemoveRole = (payload) => ({
  type: USER_SEARCH_REMOVE_ROLE,
  payload,
});
export const USER_SEARCH_SET_FORM_PARAMS = `${USER_SEARCH}_SET_FORM_PARAMS`;
export const userSearchSetFormParams = (payload) => ({
  type: USER_SEARCH_SET_FORM_PARAMS,
  payload,
});

// Pagination
export const USER_SEARCH_ORDER = `${USER_SEARCH}_ORDER`;
export const USER_SEARCH_ORDER_BY = `${USER_SEARCH}_ORDER_BY`;
export const USER_SEARCH_PAGE = `${USER_SEARCH}_PAGE`;
export const USER_SEARCH_ROWS_PER_PAGE = `${USER_SEARCH}_ROWS_PER_PAGE`;
export const USER_SEARCH_SHOW_DELETED_USERS = `${USER_SEARCH}_SHOW_DELETED_USERS`;

export const setUsersOrderBy = (payload) => ({
  type: USER_SEARCH_ORDER_BY,
  payload,
});
export const setUsersPage = (payload) => ({ type: USER_SEARCH_PAGE, payload });
export const setUsersRowsPerPage = (payload) => ({
  type: USER_SEARCH_ROWS_PER_PAGE,
  payload,
});

export const setShowDeletedUsers = (payload) => {
  return {
    type: USER_SEARCH_SHOW_DELETED_USERS,
    payload,
  };
};
