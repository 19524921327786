// ##############################
// // // App styles
// #############################

import {
  backgroundColor,
  contentWidth,
  transition,
  greenColor,
  redColor,
} from "assets/jss/globalStyle";

// eslint-disable-next-line
const appStyle = (theme, props) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    minHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    backgroundColor: "white",
  },
  content: {
    marginTop: "120px",
    padding: 0,
    minHeight: "calc(100vh - 200px)",
    background: backgroundColor,
    display: "flex",
  },
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    maxWidth: contentWidth,
    padding: "30px",
    position: "relative",
  },
  selectedCompanyContainer: {
    maxWidth: "calc(100vw - 300px)",
  },
  map: {
    marginTop: "70px",
  },
  success: {
    backgroundColor: greenColor,
  },
  error: {
    backgroundColor: redColor,
  },
  snackbarContent: {
    maxWidth: "700px",
    fontFamily: "Open Sans",
  },
});

export default appStyle;
