import { useQuery } from "@tanstack/react-query";
import { searchVendors } from "services/vendors";
import { QUERYKEY_VENDORS } from "./queryKeys";

interface VendorI {
  code: string;
  name: string;
  label: string;
}

function mapVendors(data): VendorI[] {
  return data.map((s) => ({ ...s, label: s.name }));
}

export function useVendors(query) {
  return useQuery({
    queryKey: [QUERYKEY_VENDORS, query],
    queryFn: () => searchVendors(query),
    select: mapVendors,
    enabled: Boolean(query),
  });
}
