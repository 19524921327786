import { Fragment, useCallback, useEffect, useRef } from "react";
import { withStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import { formatDate, validateEmail } from "common/helpers";
import { Clear, DeleteForever, Restore } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AutoComplete,
  Button,
  Confirmation,
  Input,
  Loading,
  MultiToggle,
  ServerEventTrigger,
  ToggleButton,
} from "components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  userWelcomeEmailTrigger,
  userPasswordResetEmailTrigger,
} from "services/users";
import userModalStyle from "components/UserModal/userModalStyle";
import { fetchRoles } from "services/roles";
import { useSafeSetState } from "hooks/useState";
import { NotificationObjI } from "types";
import { UserI, UserStateI } from "types/User";
import { useCompanies } from "hooks/companies";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useCreateUser, useEditUser } from "hooks/users";
import { useQueryClient } from "@tanstack/react-query";
import { QUERYKEY_USERS } from "hooks/queryKeys";

interface UserModalI {
  addNotification: (not: NotificationObjI) => void;
  clear: () => void;
  close: (val: boolean) => void;
  deleteUser: () => void;
  deletingUser?: UserI;
  error: boolean;
  isOpen: boolean;
  loading: boolean;
  requestingUserDelete: boolean;
  selectedCompanyID?: string;
  selectedUser?: UserI;
  setDeleteUser: (
    // e: MouseEvent<HTMLButtonElement, MouseEvent>,
    e: any,
    selectedUser: UserI
  ) => void;
  userState: UserStateI;
  setUserToReinstate: (user: UserI) => void;
  classes?: Partial<
    Record<
      | "container"
      | "checkbox"
      | "ssoLabel"
      | "label"
      | "header"
      | "loadingContainer"
      | "errorButton"
      | "main"
      | "isSSOUser"
      | "inputs"
      | "toggles"
      | "rightColumn"
      | "roles"
      | "roleMessage"
      | "rolesLoading"
      | "flexRight"
      | "userActionIcon"
      | "input"
      | "date"
      | "flexVertAligned"
      | "requiredFields",
      string
    >
  >;
}

const UserModal = (props: UserModalI) => {
  const {
    addNotification,
    clear,
    close,
    deleteUser,
    deletingUser,
    error,
    isOpen,
    loading,
    requestingUserDelete,
    selectedCompanyID,
    selectedUser,
    setDeleteUser,
    userState,
    setUserToReinstate,
  } = props;
  const classes = withStyles.getClasses(props);
  const companySelected = useSelectedCompany(selectedCompanyID)?.data;
  const initialState = {
    company: companySelected || null,
    companyEmployeeID: "",
    companyRoles: [],
    companyRolesLoading: false,
    companyRolesError: false,
    createdOn: null,
    editPassword: false,
    email: "",
    id: null,
    invalidEmail: false,
    isActive: true,
    lastLogin: null,
    nameFirst: "",
    nameLast: "",
    password: "",
    passwordEmailError: null,
    passwordEmailSending: false,
    passwordEmailSent: false,
    username: "",
    roles: [] as string[],
    saving: false,
    ssoDisabled: false,
    welcomeEmailSending: false,
    welcomeEmailError: null,
    welcomeEmailSent: false,
    deleted: false,
  };

  const [state, safeSetState] = useSafeSetState(initialState);
  const companies = useCompanies();
  const companiesRef = useRef(companies.data);
  const getAdmin = true;
  const createUser = useCreateUser();
  const editUser = useEditUser();
  const queryClient = useQueryClient();

  const userRef = useRef(selectedUser);

  const statusProperties = [
    { name: "Active", value: state.isActive },
    { name: "Inactive", value: !state.isActive },
  ];

  const setCompanyRoles = useCallback(
    (id) => {
      fetchRoles(id, getAdmin)
        .then((companyRoles) =>
          safeSetState({
            companyRoles,
            companyRolesLoading: false,
            companyRolesError: false,
          })
        )
        .catch(() => {
          safeSetState({ companyRolesError: true });
        });
    },
    [safeSetState, getAdmin]
  );

  const setCompany = useCallback(
    (company) => {
      if (company && company.id) {
        if (state.company) {
          if (state.company.id === company.id) return;
          // If are switching to a different company we need to wipe any selected/saved roles
          safeSetState({ roles: [], company, companyRolesLoading: true });
        } else {
          safeSetState({ company, companyRolesLoading: true });
        }
        setCompanyRoles(company.id);
      } else {
        safeSetState({ company: null, companyRoles: [], roles: [] });
      }
    },
    [safeSetState, setCompanyRoles, state.company]
  );

  useEffect(() => {
    if (companySelected) {
      setCompanyRoles(companySelected.id);
    }
  }, [companySelected, setCompanyRoles]);

  useEffect(() => {
    function findCompany() {
      return companies.data?.find(
        (c) => c.id === selectedUser?.defaultCompanyID
      );
    }
    if (selectedUser && userRef.current !== selectedUser) {
      // Set internal state to the users properties, but do not respect the welcomeEmailSent
      // flag because we want to be able to trigger it multiple times (only once per modal session though)
      const newUser: any = { ...selectedUser };
      const newRoles = selectedUser.roles
        ? selectedUser.roles.map((r) => r.id)
        : [];
      newUser.roles = newRoles;
      safeSetState({ ...newUser, welcomeEmailSent: false });
      userRef.current = selectedUser;
      if (companies?.data?.length) {
        setCompany(findCompany());
      }
    }

    if (!companiesRef.current?.length && companies.data?.length) {
      companiesRef.current = companies.data;
      setCompany(findCompany());
    }
  }, [companies.data, safeSetState, selectedUser, setCompany]);

  function canSave() {
    const { company, nameFirst, nameLast, username, email, roles } = state;
    if (!company) return false;
    if (!nameFirst.length) return false;
    if (!nameLast.length) return false;
    if (!username.length) return false;
    if (!validateEmail(email)) return false;
    if (!roles.length) return false;
    return true;
  }

  function handleClear() {
    clear();
    safeSetState({ ...initialState });
  }

  function handleSubmit() {
    const {
      username,
      email,
      nameFirst,
      nameLast,
      isActive,
      roles,
      company,
      ssoDisabled,
      password,
      companyEmployeeID,
      id,
    } = state;
    const user: any = {
      UserName: username,
      Email: email,
      FirstName: nameFirst,
      LastName: nameLast,
      IsActive: isActive,
      Roles: roles,
    };
    if (password) user.password = password;
    if (company) user.defaultCompanyID = company.id;
    if (companyEmployeeID) user.companyEmployeeID = companyEmployeeID;
    if (company && company.isSso) user.ssoDisabled = ssoDisabled;
    if (selectedUser) {
      user.id = id;
      saveUser(editUser, user);
    } else {
      saveUser(createUser, user);
    }
  }

  function saveUser(callback, user) {
    safeSetState({ saving: true });
    callback.mutateAsync(user).then(saveSuccess).catch(saveError);
  }

  function saveSuccess() {
    // Give backend an extra second for cosmos db to pick up change so that subsequent searches pick it up
    setTimeout(() => {
      safeSetState({ saving: false });
      addNotification({
        text: `User successfully saved.`,
        type: "success",
      });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_USERS] });
      close(true);
    }, 1000);
  }

  function saveError(er) {
    safeSetState({ saving: false });
    addNotification({
      text: `Error saving user.`,
      errors: er.customErrors,
      type: "error",
    });
  }

  function setEmployeeID(companyEmployeeID) {
    safeSetState({ companyEmployeeID });
  }

  function setRole(role) {
    const { roles } = state;
    let newRoles;
    if (roles.includes(role)) {
      newRoles = roles.filter((r) => r !== role);
    } else {
      newRoles = [...roles];
      newRoles.push(role);
    }
    safeSetState({ roles: newRoles });
  }

  function setSSO() {
    safeSetState({ ssoDisabled: !state.ssoDisabled });
  }

  function setEmail(email) {
    safeSetState({ email, invalidEmail: false });
  }

  function setFirstName(nameFirst) {
    safeSetState({ nameFirst });
  }

  function setLastName(nameLast) {
    safeSetState({ nameLast });
  }

  function setPasswordEditable() {
    safeSetState({ editPassword: !state.editPassword });
  }

  function setPassword(password) {
    safeSetState({ password });
  }

  function setUserName(username) {
    safeSetState({ username });
  }

  function statusClick(i) {
    if (i === 0 && !state.isActive) {
      safeSetState({ isActive: true });
    } else if (i === 1 && state.isActive) {
      safeSetState({ isActive: false });
    }
  }

  function triggerPasswordResetEmail(e) {
    e.stopPropagation();
    safeSetState({
      passwordEmailSending: true,
      passwordEmailSent: false,
      passwordEmailError: null,
    });
    userPasswordResetEmailTrigger(state.id)
      .then(() => {
        safeSetState({ passwordEmailSending: false, passwordEmailSent: true });
      })
      .catch(() => {
        safeSetState({
          passwordEmailSending: false,
          passwordEmailError: true,
        });
        addNotification({
          text: `Error sending password reset email.`,
          type: "error",
        });
      });
  }

  function triggerWelcomeEmail(e) {
    e.stopPropagation();
    safeSetState({ welcomeEmailSending: true });
    userWelcomeEmailTrigger(state.id)
      .then(() => {
        safeSetState({ welcomeEmailSending: false, welcomeEmailSent: true });
      })
      .catch(() => {
        safeSetState({ welcomeEmailSending: false, welcomeEmailError: true });
        addNotification({
          text: `Error sending welcome email.`,
          type: "error",
        });
      });
  }

  function handleInputBlur() {
    if (state.email && state.email.length) {
      const validated = validateEmail(state.email);
      if (!validated) safeSetState({ invalidEmail: true });
    }
  }

  function renderCompanyRoles() {
    const { company, companyRoles, companyRolesError, companyRolesLoading } =
      state;
    if (companyRolesError) {
      return (
        <p className={classes.roleMessage}>
          There was an error fetching roles.
        </p>
      );
    } else if (companyRolesLoading) {
      return (
        <div className={classes.rolesLoading}>
          <CircularProgress size={32} />
        </div>
      );
    } else if (!company) {
      return (
        <p className={classes.roleMessage}>Select a company to see roles.</p>
      );
    } else {
      return companyRoles.map(renderRole);
    }
  }

  function renderConfirmation() {
    return (
      <Confirmation
        handleClose={() => close(false)}
        handleConfirmation={deleteUser}
        header={`Are you sure you want to delete ${deletingUser.nameFormatted}?`}
        mainText="Note: Deleting users is permanent and cannot be undone. Consider turning the user to inactive if their record is still important."
        requestingConfirmation={requestingUserDelete}
      />
    );
  }

  function renderLoading() {
    return (
      <div className={classes.loadingContainer}>
        <div className={classes.header}>
          {!loading && (
            <IconButton
              onClick={() => close(false)}
              className={classes.errorButton}
              size="large"
            >
              <Clear fontSize="large" />
            </IconButton>
          )}
        </div>
        <div className={classes.main}>
          <Loading active={loading} error={error} loadingText="Loading User" />
        </div>
      </div>
    );
  }

  function renderRole(role) {
    return (
      <ToggleButton
        key={role.id}
        label={role.name}
        selected={Boolean(state.roles.find((r) => r === role.id))}
        onClick={() => setRole(role.id)}
        data-aut={`User|Roles|${role.name}`}
      />
    );
  }

  function renderUserModal() {
    const {
      company,
      companyEmployeeID,
      nameFirst,
      invalidEmail,
      nameLast,
      username,
      createdOn,
      email,
      isActive,
      lastLogin,
      saving,
      ssoDisabled,
      password,
      welcomeEmailSending,
      welcomeEmailError,
      welcomeEmailSent,
      passwordEmailError,
      passwordEmailSending,
      passwordEmailSent,
      deleted,
    } = state;

    const hasLimitedAccess = userState.accessLevel < 60;
    const showReinstate = userState.accessLevel === 100 && deleted;
    const showDelete = userState.accessLevel >= 80;

    return (
      <Fragment>
        <div className={classes.header}>
          <div className={classes.flexVertAligned}>
            {selectedUser ? (
              <h2>{`${nameFirst} ${nameLast}`}</h2>
            ) : (
              <h2>Add a new user</h2>
            )}
            <span className={classes.requiredFields}>* Required Fields</span>
          </div>
          <IconButton
            onClick={() => close(false)}
            data-aut="User|CloseButton"
            size="large"
          >
            <Clear fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.main}>
          <div className={classes.inputs}>
            <div className={classes.input}>
              <Input
                data-aut="UserModal|FirstName"
                label="First Name *"
                value={nameFirst}
                onChange={setFirstName}
                disabled={hasLimitedAccess}
              />
            </div>
            <div className={classes.input}>
              <Input
                data-aut="UserModal|LastName"
                label="Last Name *"
                value={nameLast}
                onChange={setLastName}
                disabled={hasLimitedAccess}
              />
            </div>
            <div className={classes.input}>
              <Input
                data-aut="UserModal|UserName"
                label="Username *"
                value={username}
                onChange={setUserName}
                disabled={hasLimitedAccess}
              />
            </div>
            <div className={classes.input}>
              <Input
                data-aut="UserModal|EmailAddress"
                label="Email Address *"
                value={email}
                onChange={setEmail}
                onBlur={handleInputBlur}
                error={invalidEmail && "Please input a valid email"}
                disabled={hasLimitedAccess}
              />
            </div>
            {!hasLimitedAccess &&
              ((company && !company.isSso) || ssoDisabled) && (
                <div className={classes.input}>
                  <Input
                    data-aut="UserModal|Password"
                    label="Set Password"
                    checked={state.editPassword}
                    onCheck={setPasswordEditable}
                    value={password}
                    onChange={setPassword}
                    disabled={!state.editPassword}
                  />
                </div>
              )}
            {!hasLimitedAccess && (
              <Fragment>
                <Button
                  onClick={handleSubmit}
                  label="Save"
                  disabled={saving || !canSave()}
                  loading={saving}
                  data-aut="User|SaveButton"
                />
                <Button
                  onClick={() => close(false)}
                  label="Cancel"
                  isTextButton
                  data-aut="User|CancelButton"
                />
              </Fragment>
            )}
          </div>
          <div className={classes.toggles}>
            <div>
              <div className={classes.input}>
                <label className={classes.label}>Status</label>
                <MultiToggle
                  value={isActive ? 0 : 1}
                  properties={statusProperties}
                  handleClick={statusClick}
                  error={company && company.active === false}
                  errorMessage="Company is Inactive"
                  label="Status"
                  disabled={hasLimitedAccess}
                />
              </div>
              <div className={classes.input}>
                <AutoComplete
                  dataAut="UserModal|Company"
                  label="Company *"
                  errorMessage={
                    companies.isError ? "Error Fetching Companies" : ""
                  }
                  isLoading={companies.isLoading}
                  handleDelete={() => {
                    setCompany(null);
                  }}
                  onChange={setCompany}
                  selectedItem={company}
                  suggestions={companies.data}
                  disabled={hasLimitedAccess}
                />
              </div>
              <div className={classes.input}>
                <Input
                  data-aut="UserModal|EmployeeID"
                  label="Employee Id"
                  value={companyEmployeeID}
                  onChange={setEmployeeID}
                  disabled={hasLimitedAccess}
                />
              </div>
              {company && company.isSso && (
                <FormControlLabel
                  className={classes.isSSOUser}
                  classes={{ label: classes.ssoLabel }}
                  control={
                    <Checkbox
                      classes={{ root: classes.checkbox }}
                      checked={ssoDisabled}
                      color="primary"
                      onChange={setSSO}
                      value="ssoDisabled"
                      disabled={hasLimitedAccess}
                    />
                  }
                  label="Disable SSO"
                />
              )}
              {selectedUser &&
                ((company && !company.isSso) || ssoDisabled) &&
                company &&
                company.active &&
                isActive && (
                  <Fragment>
                    <ServerEventTrigger
                      text={
                        welcomeEmailSent
                          ? "Welcome email sent."
                          : "Send 'Welcome email'"
                      }
                      loading={welcomeEmailSending}
                      error={
                        welcomeEmailError && "Error sending Welcome email."
                      }
                      success={welcomeEmailSent}
                      handleClick={!welcomeEmailSent && triggerWelcomeEmail}
                      data-aut="UserSendWelcomeEmail"
                    />
                    <ServerEventTrigger
                      text={
                        passwordEmailSent
                          ? "Password Reset email sent."
                          : "Send 'Password Reset email'"
                      }
                      loading={passwordEmailSending}
                      error={
                        passwordEmailError &&
                        "Error sending Password Reset email."
                      }
                      success={passwordEmailSent}
                      handleClick={triggerPasswordResetEmail}
                      data-aut="UserSendPasswordResetEmail"
                    />
                  </Fragment>
                )}
              {selectedUser && (
                <Fragment>
                  <p className={classes.date} data-aut="User|CreatedOn">
                    <span>Created On: </span>
                    {formatDate(createdOn)}
                  </p>
                  <p className={classes.date} data-aut="User|LastLogin">
                    <span>Last Login: </span>
                    {formatDate(lastLogin)}
                  </p>
                </Fragment>
              )}
            </div>
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.roles}>
              {!hasLimitedAccess && (
                <Fragment>
                  <label className={classes.label}>Client Role(s) *</label>
                  {renderCompanyRoles()}
                </Fragment>
              )}
            </div>
            {selectedUser && (
              <div className={classes.flexRight}>
                {showReinstate && (
                  <Tooltip
                    title="Reinstate User"
                    placement={"top"}
                    enterDelay={150}
                  >
                    <div>
                      <IconButton
                        onClick={() => setUserToReinstate(selectedUser)}
                        size="large"
                      >
                        <Restore className={classes.userActionIcon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
                {showDelete && (
                  <Tooltip
                    title="Delete User"
                    placement={"top"}
                    enterDelay={150}
                  >
                    <IconButton
                      onClick={(e) => setDeleteUser(e, selectedUser)}
                      data-aut="User|DeleteButton"
                      size="large"
                    >
                      <DeleteForever className={classes.userActionIcon} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      TransitionProps={{ onExited: handleClear }}
    >
      {loading || error ? (
        renderLoading()
      ) : (
        <div className={classes.container}>
          {deletingUser ? renderConfirmation() : renderUserModal()}
        </div>
      )}
    </Dialog>
  );
};

const UserModalStyled = withStyles(UserModal, userModalStyle);

export default UserModalStyled;
