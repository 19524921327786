import { Fragment } from "react";
import { withStyles } from "tss-react/mui";
import { capitalizeFirstLetter } from "common/helpers";

// eslint-disable-next-line
const styles = (theme, props) => ({
  errorHeader: {
    fontWeight: 600,
  },
  categoryList: {
    listStyle: "none",
    padding: 0,
  },
  category: {
    fontWeight: 600,
    textDecoraction: "underline",
  },
  error: {
    fontSize: "13px",
    margin: "8px 0",
  },
  errorList: {
    paddingLeft: "8px",
    listStyle: "none",
  },
});

function formatError(
  errors: string[],
  classes: Partial<
    Record<
      "errorHeader" | "categoryList" | "category" | "error" | "errorList",
      string
    >
  >
) {
  return Object.keys(errors).map((k, i) => (
    <li
      key={`${i}${k}`}
      className={classes.error}
      data-aut={`Snackbar|Error|Category${i + 1}`}
    >
      <span className={classes.category}>
        {capitalizeFirstLetter(k && k.length ? k : "General")}
      </span>
      <ul className={classes.errorList}>
        {errors[k].map((e, index) => (
          <li
            key={`${index}${k}`}
            data-aut={`Snackbar|Error|Category${i + 1}|Error{${index + 1}}`}
          >
            {e}
          </li>
        ))}
      </ul>
    </li>
  ));
}

interface SnackbarMessageI {
  errors: string[];
  message: string;
  classes?: Partial<
    Record<
      "errorHeader" | "categoryList" | "category" | "error" | "errorList",
      string
    >
  >;
}

const SnackbarMessage = (props: SnackbarMessageI) => {
  const { errors, message } = props;
  const classes = withStyles.getClasses(props);
  if (!errors)
    return <span data-aut="Snackbar|Success|Message">{message}</span>;
  return (
    <Fragment>
      <span className={classes.errorHeader} data-aut="Snackbar|Error|Message">
        {message}
      </span>
      <ul
        className={classes.categoryList}
        data-aut="Snackbar|Error|CategoryList"
      >
        {formatError(errors, classes)}
      </ul>
    </Fragment>
  );
};

const SnackbarMessageStyled = withStyles(SnackbarMessage, styles);

export default SnackbarMessageStyled;
