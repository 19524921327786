import React, { useRef } from "react";
import { useSafeSetState } from "hooks/useState";
import IconButton from "@mui/material/IconButton";
import { Delete, Close } from "@mui/icons-material";
import { Button, Input, ImageUpload } from "components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { isValidHttpUrl } from "common/helpers";
import { withStyles } from "tss-react/mui";
import { errorMessage } from "assets/jss/globalStyle";
import { NotificationObjI } from "types";
import ImageCropper from "components/ImageCropper/ImageCropper";

type Slate = {
  slateId?: string;
  title: string;
  description: string;
  links: Link[];
  file?: any;
  imageURL?: string;
  active: boolean;
  deleted: boolean;
};

type Link = {
  linkName: string;
  linkUrl: string;
  active: boolean;
  deleted: boolean;
  error: boolean;
};

const newSlate: Slate = {
  title: "",
  description: "",
  links: [],
  active: true,
  deleted: false,
  imageURL: null,
};
const newLink: Link = {
  linkName: "",
  linkUrl: "",
  active: true,
  deleted: false,
  error: false,
};

// eslint-disable-next-line
const styles = (theme, props) => ({
  buttonRow: {
    width: "100%",
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: "8px 0",
  },
  slate: {
    height: "96px",
    width: "240px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 10px 10px 0",
    cursor: "pointer",
    position: "relative",
    userSelect: "none",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      backgroundColor: "rgba(0,0,0,0.25)",
    },
    "&:hover button": {
      display: "block",
    },
  },
  draggableSlate: {
    zIndex: 99999,
  },
  closeSlate: {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "#ffffff",
    display: "none",
  },
  slateName: {
    position: "relative",
    color: "#ffffff",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "3em",
    paddingLeft: "8px",
    margin: "0",
  },
  links: {
    display: "flex",
  },
  linkName: {
    marginRight: "16px",
  },
  removeLink: {
    top: "10px",
  },
  addLink: {
    marginTop: "10px",
  },
  errorMessage,
});

type SlateUpdate = {
  action: "create" | "edit" | "delete";
  slate: Slate;
};

interface GalleryProps {
  handleOpenCreateEditSlate: (slate: Slate) => void;
  trackPendingSlate: (update: SlateUpdate) => void;
  setSlates: (slates: Slate[]) => void;
  slates: Slate[];
  classes?: Partial<
    Record<
      | "buttonRow"
      | "container"
      | "slate"
      | "draggableSlate"
      | "closeSlate"
      | "slateName"
      | "heading"
      | "subHeading"
      | "links"
      | "linkName"
      | "removeLink"
      | "addLink"
      | "errorMessage",
      string
    >
  >;
}

const SlatesGalleryComponent = (props: GalleryProps) => {
  const { handleOpenCreateEditSlate, trackPendingSlate, setSlates, slates } =
    props;
  const classes = withStyles.getClasses(props);
  const containerRef = useRef(null);
  const handleRemoveSlate = (e, slateId) => {
    e.stopPropagation();
    const newSlates = [...slates];
    const index = slates.findIndex((slate) => slate.slateId === slateId);
    const removedSlate = newSlates.splice(index, 1)[0];
    setSlates(newSlates);
    trackPendingSlate({ action: "delete", slate: removedSlate });
  };
  const SortableItem = ({ slate }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: slate.slateId });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    return (
      <div
        ref={setNodeRef}
        className={classes.slate}
        style={{
          backgroundImage: `url(${slate.imageURL})`,
          backgroundSize: "cover",
          ...style,
        }}
        onClick={() => handleOpenCreateEditSlate(slate)}
        data-aut={`SortableSlate|${slate.title}`}
        {...attributes}
        {...listeners}
      >
        <IconButton
          className={classes.closeSlate}
          onClick={(e) => handleRemoveSlate(e, slate.slateId)}
          data-aut={`RemoveSlateButton|${slate.title}`}
          size="large"
        >
          <Close />
        </IconButton>
        <span className={classes.slateName} title={slate.title}>
          {slate.title}
        </span>
      </div>
    );
  };
  const SortableContainer = ({ children }) => {
    const items = slates.map((slate) => slate.slateId);
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 25,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        const oldIndex = slates.findIndex(
          (slate) => slate.slateId === active.id
        );
        const newIndex = slates.findIndex(
          (slate) => slate.slateId === over?.id || ""
        );
        const newValues = arrayMove(slates, oldIndex, newIndex);
        setSlates(newValues);
      }
    };

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items}>{children}</SortableContext>
      </DndContext>
    );
  };
  return (
    <div ref={containerRef}>
      <div className={classes.container}>
        {slates && (
          <SortableContainer>
            {slates.map((slate, index) => (
              <div data-aut={`SlatePosition|${index}`} key={`Slate-${index}`}>
                <SortableItem slate={slate} key={slate.slateId} />
              </div>
            ))}
          </SortableContainer>
        )}
      </div>
      <div>
        <Button
          onClick={() => handleOpenCreateEditSlate(newSlate)}
          label="Add Slate"
          data-aut="AddSlateButton"
          isBlue
          noMargin
        />
      </div>
    </div>
  );
};

interface CreateEditProps {
  handleCloseCreateEditSlate: () => void;
  trackPendingSlate: (update: SlateUpdate) => void;
  selectedSlate: Slate;
  addNotification: (payload: NotificationObjI) => void;
  classes?: Partial<
    Record<
      | "buttonRow"
      | "container"
      | "slate"
      | "draggableSlate"
      | "closeSlate"
      | "slateName"
      | "heading"
      | "subHeading"
      | "links"
      | "linkName"
      | "removeLink"
      | "addLink"
      | "errorMessage",
      string
    >
  >;
}

interface CreateEditStateI {
  isCroppingImage: boolean;
  cropImageFile: File | null;
  cropImageUrl: string | null;
  slateData: Slate;
  imageFileObjectURL: string | null;
  imageFile: File | null;
  errors: {
    links: {};
    title?: boolean | string;
    description?: boolean | string;
    imageURL?: boolean | string;
  };
}

const CreateEditSlateViewComponent = (props: CreateEditProps) => {
  const {
    handleCloseCreateEditSlate,
    trackPendingSlate,
    selectedSlate,
    addNotification,
  } = props;
  const classes = withStyles.getClasses(props);
  const isEdit = Boolean(selectedSlate.slateId);
  const initialState: CreateEditStateI = {
    slateData: selectedSlate ? { ...selectedSlate } : newSlate,
    cropImageFile: null,
    cropImageUrl: null,
    imageFileObjectURL: null,
    imageFile: null,
    isCroppingImage: false,
    errors: { links: {} },
  };

  const [
    { isCroppingImage, imageFileObjectURL, imageFile, slateData, errors },
    safeSetState,
  ] = useSafeSetState(initialState);

  const toggleImageCrop = () => {
    safeSetState({ isCroppingImage: !isCroppingImage });
  };

  function handleImageUpdate(imgURL, imgFile) {
    const newSlate: Slate = { ...slateData };
    newSlate.file = imgFile;
    newSlate.imageURL = imgURL;

    safeSetState({
      imageFile: imgFile,
      imageFileObjectURL: imgURL,
      isCroppingImage: false,
      slateData: newSlate,
    });
  }

  const handleUpdateSlateName = (title) => {
    const updatedSlate = { ...slateData, title };
    const errs = { ...errors };
    errs.title = false;
    safeSetState({ slateData: updatedSlate, errors: errs });
  };
  const handleUpdateDescription = (description) => {
    const updatedSlate = { ...slateData, description };
    const errs = { ...errors };
    errs.description = false;
    safeSetState({ slateData: updatedSlate, errors: errs });
  };
  const handleUpdateLinkName = (name, linkIdx) => {
    const links = [...slateData.links];
    const errs = { ...errors };
    errs.links[linkIdx] = { ...errs.links[linkIdx], linkName: "" };
    links[linkIdx].linkName = name;
    const updatedSlate = { ...slateData, links };
    safeSetState({ slateData: updatedSlate, errors: errs });
  };
  const handleUpdateLinkUrl = (url, linkIdx) => {
    const links = [...slateData.links];
    const errs = { ...errors };
    errs.links[linkIdx] = { ...errs.links[linkIdx], linkUrl: "" };
    links[linkIdx].linkUrl = url;
    const updatedSlate = { ...slateData, links };
    safeSetState({ slateData: updatedSlate, errors: errs });
  };
  const handleRemoveLink = (e, linkIdx) => {
    e.stopPropagation();
    const links = [...slateData.links];
    const errs = { ...errors };
    links.splice(linkIdx, 1);
    const updatedSlate = { ...slateData, links };
    delete errs.links[linkIdx];
    safeSetState({ slateData: updatedSlate, errors: errs });
  };
  const handleAddLink = () => {
    const links = [...slateData.links];
    const link = { ...newLink };
    links.push(link);
    const updatedSlate = { ...slateData, links };
    safeSetState({ slateData: updatedSlate });
  };
  const setImageFile = (imageFile) => {
    const errs = { ...errors, imageURL: false };
    if (imageFile) {
      const imageFileObjectURL = URL.createObjectURL(imageFile);
      const updatedSlate = {
        ...slateData,
        file: imageFile,
        imageURL: imageFileObjectURL,
      };
      safeSetState({
        slateData: updatedSlate,
        imageFile,
        imageFileObjectURL,
        errors: errs,
      });
    } else {
      const updatedSlate = { ...slateData, file: null, imageURL: null };
      safeSetState({
        slateData: updatedSlate,
        imageFile: null,
        errors: errs,
        imageFileObjectURL: null,
      });
    }
  };
  const handleSaveButtonClick = () => {
    const hasErrors = validateSlateData();
    if (hasErrors) return;
    if (isEdit) {
      trackPendingSlate({ action: "edit", slate: slateData });
    } else {
      trackPendingSlate({ action: "create", slate: slateData });
    }
    handleCloseCreateEditSlate();
  };
  const validateSlateData = () => {
    const errs = { ...errors };
    let hasErrs = false;
    Object.keys(slateData).forEach((key) => {
      switch (key) {
        case "title":
          if (!slateData[key]) {
            errs[key] = "Please enter a slate name.";
            hasErrs = true;
          }
          if (slateData[key].length > 50) {
            errs[key] = "Name must be 50 characters or less.";
            hasErrs = true;
          }
          break;
        case "description":
          if (slateData[key]?.length > 50) {
            errs[key] = "Description must be 50 characters or less.";
            hasErrs = true;
          }
          break;
        case "links":
          slateData[key].forEach((link, linkIdx) => {
            errs[key][linkIdx] = {};
            if (!link.linkName) {
              errs[key][linkIdx].linkName = "Please enter a link name.";
              hasErrs = true;
            }
            if (!isValidHttpUrl(link.linkUrl)) {
              errs[key][linkIdx].linkUrl =
                "Please enter a valid url. (https://example.com)";
              hasErrs = true;
            }
          });
          break;
        case "imageURL":
          if (!slateData[key]) {
            errs[key] = "Please upload an image for this slate.";
            hasErrs = true;
          }
          break;
        default:
          break;
      }
    });
    safeSetState({ errors: errs });
    return hasErrs;
  };

  if (isCroppingImage) {
    return (
      <ImageCropper
        close={toggleImageCrop}
        name={imageFile?.name}
        src={slateData.imageURL}
        updateImage={handleImageUpdate}
        type={imageFile ? "file" : "url"}
      />
    );
  }
  return (
    <div>
      <div>
        <Input
          label={`Custom Name *`}
          value={slateData.title}
          onChange={(val) => handleUpdateSlateName(val)}
          error={errors.title}
          data-aut="SlateNameInput"
        />
      </div>
      <div>
        <Input
          label={`Custom Description`}
          value={slateData.description}
          onChange={(val) => handleUpdateDescription(val)}
          error={errors.description}
          data-aut="SlateDescriptionInput"
        />
      </div>

      <div>
        <h4 className={classes.subHeading}>Links</h4>
      </div>
      {slateData.links &&
        slateData.links.map((link, idx) => (
          <React.Fragment key={`links-${idx}`}>
            <div className={classes.heading}></div>
            <div className={classes.links}>
              <div className={classes.linkName}>
                <Input
                  label={`Custom Name *`}
                  value={link.linkName}
                  onChange={(val) => handleUpdateLinkName(val, idx)}
                  error={errors.links[idx] && errors.links[idx].linkName}
                  data-aut={`Link|${idx}|Name`}
                />
              </div>
              <div>
                <Input
                  label={`Url *`}
                  value={link.linkUrl}
                  onChange={(val) => handleUpdateLinkUrl(val, idx)}
                  error={errors.links[idx] && errors.links[idx].linkUrl}
                  data-aut={`Link|${idx}|Url`}
                />
              </div>
              <IconButton
                className={classes.removeLink}
                onClick={(e) => handleRemoveLink(e, idx)}
                data-aut={`Link|${idx}|RemoveButton`}
                size="large"
              >
                <Delete />
              </IconButton>
            </div>
          </React.Fragment>
        ))}
      <div className={classes.addLink}>
        <Button
          onClick={() => handleAddLink()}
          label="Add Link"
          data-aut="AddLinkButton"
          isBlue
          noMargin
        />
      </div>
      <ImageUpload
        addNotification={addNotification}
        handleOpenImageCrop={toggleImageCrop}
        imageFileObjectURL={imageFileObjectURL}
        imageUrl={slateData.imageURL}
        buttonLabel="Add Slate Image"
        formLabel="Slate Image"
        alt="Slate Image"
        imageHeight="150px"
        onChange={setImageFile}
        validation={{
          maxSize: 5,
          minHeight: 150,
          minAspectRatio: 0.5,
          maxAspectRatio: 3,
        }}
      />
      {errors.imageURL && (
        <p className={classes.errorMessage}>{errors.imageURL}</p>
      )}
      <div className={classes.buttonRow}>
        <Button
          onClick={handleSaveButtonClick}
          label="Save"
          data-aut="Slate|SaveButton"
        />
        <Button
          onClick={handleCloseCreateEditSlate}
          label="Cancel"
          isTextButton
          data-aut="Slate|CancelButton"
        />
      </div>
    </div>
  );
};

export const SlatesGallery = withStyles(SlatesGalleryComponent, styles);
export const CreateEditSlateView = withStyles(
  CreateEditSlateViewComponent,
  styles
);
