import React, { Fragment, KeyboardEvent, MouseEventHandler } from "react";
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Search from "@mui/icons-material/Search";
import inputStyle from "components/Input/inputStyle";

const getInputClasses = (hasMaxRows, multiline, classes) => {
  if (hasMaxRows) return null;
  return multiline ? classes.inputMultiline : classes.input;
};

const getInputProps = ({
  isMultiple,
  isSearch,
  hasMaxRows,
  multiline,
  classes,
  label,
  dataAut,
  selectedItems,
  setValues,
  min,
  max,
  step,
}) => {
  interface PropsResult {
    classes: {
      input?: typeof classes.inputMultiline | typeof classes.input;
      disabled: typeof classes.disabled;
      root?: typeof classes.textField;
    };
    inputProps: {
      autoComplete: "off";
      "aria-label"?: string;
      "data-aut"?: string;
      min?: string;
      max?: string;
      step?: string;
      size?: string;
    };
    startAdornment?: JSX.Element;
  }

  const result: PropsResult = {
    classes: {
      input: getInputClasses(hasMaxRows, multiline, classes),
      disabled: classes.disabled,
    },
    inputProps: { autoComplete: "off" },
  };
  if (label) result.inputProps["aria-label"] = label;
  if (dataAut) result.inputProps["data-aut"] = dataAut;
  if (min) result.inputProps.min = min;
  if (max) result.inputProps.max = max;
  if (step) result.inputProps.step = step;
  if (multiline && !hasMaxRows) {
    result.classes.root = classes.textField;
  }
  if (isSearch)
    result.startAdornment = (
      <InputAdornment position="start" className={classes.inputAdornment}>
        <Search />
      </InputAdornment>
    );
  else if (isMultiple) {
    result.inputProps.size = "1";
    result.classes.root = classes.root;
    const items = Array.isArray(selectedItems) ? selectedItems : [];
    result.startAdornment = (
      <Fragment>
        {items.map((item, index) => (
          <Chip
            style={index === items.length - 1 ? { marginRight: "8px" } : {}}
            key={`${item}${index}`}
            tabIndex={-1}
            label={item}
            className={classes.chip}
            onDelete={() => setValues(items.filter((i) => i !== item))}
            data-aut={`${label}|MultiInput|Item`}
          />
        ))}
      </Fragment>
    );
  }
  return result;
};

const renderLabel = (props) => {
  const { checked, classes, label, onCheck, id } = props;
  if (label) {
    if (onCheck) {
      return (
        <span className={classes.flex}>
          <Checkbox
            classes={{ root: classes.checkbox }}
            checked={checked || false}
            color="primary"
            onChange={onCheck}
            value={label}
            inputProps={{
              "aria-checked": checked || false,
              role: "checkbox",
            }}
          />
          <label htmlFor={id || label} className={classes.label}>
            {label}
          </label>
        </span>
      );
    } else {
      return (
        <label htmlFor={id || label} className={classes.label}>
          {label}
        </label>
      );
    }
  }
};

export const handleChange = (
  val: string,
  onChange: (val: string, name?: string) => void,
  name?: string,
  maxLength?: number
) => {
  const maxInputLength = maxLength || 254;
  // Limit input values to 254 to prevent invalid data being sent to databases with 255 character limit
  if (val.length <= maxInputLength) {
    onChange(val, name);
  }
};

interface InputPropsI {
  "data-aut"?: string;
  checked?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  helperText?: string;
  label?: string;
  isSearch?: boolean;
  isMultiple?: boolean;
  min?: number;
  max?: number;
  step?: number;
  multiline?: boolean;
  name?: string;
  noMinHeight?: boolean;
  onBlur?: (e?: Event) => void;
  onChange?: (val: string, name?: string) => void;
  onCheck?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onMouseOut?: MouseEventHandler<HTMLDivElement>;
  placeholder?: string;
  setValues?: (val: string | string[], isValid?: boolean) => void;
  selectedItems?: string[];
  style?: any;
  type?: string;
  value: string;
  id?: string;
  maxRows?: number | string;
  maxLength?: number;
  classes?: Partial<
    Record<
      | "wrapper"
      | "errorLabel"
      | "flex"
      | "errorMessage"
      | "chip"
      | "label"
      | "input"
      | "inputMultiline"
      | "textField"
      | "root"
      | "inputAdornment"
      | "disabled"
      | "checkbox",
      string
    >
  >;
}

const Input = (props: InputPropsI) => {
  const {
    disabled,
    error,
    helperText,
    label,
    isMultiple,
    isSearch,
    min,
    max,
    step,
    multiline,
    name,
    noMinHeight,
    onBlur,
    onChange,
    onKeyDown,
    onMouseOut,
    placeholder,
    selectedItems,
    setValues,
    style = {},
    type,
    value,
    id = null,
    maxRows = null,
    maxLength = null,
  } = props;
  const classes = withStyles.getClasses(props);

  function handleBlur(e) {
    if (isMultiple) pushValue(e);
    if (onBlur) onBlur();
  }

  function pushValue(e) {
    if (!value || !value.length || !Array.isArray(selectedItems)) return;
    const newItems = [...selectedItems];
    newItems.push(value);
    const isValid = e.target.checkValidity();
    setValues(newItems, isValid);
    if (isValid) handleChange("", onChange);
  }

  function getValue(val) {
    if (!val && val !== 0) return "";
    return val;
  }

  const dataAut = props["data-aut"];
  const inputProps = getInputProps({
    isMultiple,
    isSearch,
    hasMaxRows: Boolean(maxRows),
    classes,
    label,
    dataAut,
    multiline,
    selectedItems,
    setValues,
    min,
    max,
    step,
  });

  return (
    <div
      style={label && !noMinHeight ? { minHeight: "100px", ...style } : style}
      className={classes.wrapper}
    >
      {renderLabel(props)}
      <TextField
        disabled={disabled}
        id={id || label}
        error={Boolean(error)}
        helperText={helperText}
        fullWidth
        multiline={multiline}
        name={name}
        type={type}
        variant="outlined"
        placeholder={placeholder}
        InputProps={inputProps}
        value={getValue(value)}
        maxRows={maxRows}
        onKeyDown={(e) => {
          const isEnter = e.key === "Enter";
          const isDelete = e.key === "Backspace";

          if (Array.isArray(selectedItems)) {
            if (isEnter) {
              pushValue(e);
            } else if (
              isDelete &&
              selectedItems.length &&
              (!value || !value.length)
            ) {
              const newItems = [...selectedItems];
              newItems.pop();
              setValues(newItems);
            }
          }
          if (onKeyDown && isEnter) onKeyDown(e);
        }}
        onChange={(e) => {
          handleChange(e.target.value, onChange, e.target.name, maxLength);
        }}
        onBlur={handleBlur}
        onMouseOut={onMouseOut}
      />
      {error && <p className={classes.errorMessage}>{error}</p>}
    </div>
  );
};

export default withStyles(Input, inputStyle);
