import { Fragment, useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown } from "@mui/icons-material";
import Select from "@mui/material/Select";
import { CustomChip } from "components";
import dropdownStyle from "components/Dropdown/dropdownStyle";
import CircularProgress from "@mui/material/CircularProgress";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { blueColor } from "assets/jss/globalStyle";

const iconStyles = {
  color: blueColor,
};
const loadingIconStyles = {
  width: "20px !important",
  height: "20px !important",
  marginRight: "8px",
};

const sortableItemsMapper = (item, index) => `item-${item.label}${index}`;

const SortableContainer = ({ children, selected, updateSelected, classes }) => {
  const items = selected.map(sortableItemsMapper);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 25,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over?.id || "");
      const newValues = arrayMove(selected, oldIndex, newIndex);
      updateSelected(newValues);
    }
  };

  return (
    <div className={classes.chips}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items}>{children}</SortableContext>
      </DndContext>
    </div>
  );
};

const SortableItem = ({ item, index, disabled, handleDelete }) => {
  const mappedItem = sortableItemsMapper(item, index);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: mappedItem, transition: null });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { onPointerDown } = listeners;
  const handlePointerDown = (e) => {
    if (e.target.ariaLabel === "Remove Item Icon") return;
    onPointerDown(e);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onPointerDown={handlePointerDown}
    >
      <CustomChip
        label={item.label}
        onDelete={() => {
          if (!disabled) handleDelete(item);
        }}
      />
    </div>
  );
};

const DropdownSelections = (props) => {
  const { classes, disabled, handleDelete, handleEvent, selected } = props;
  return (
    <div className={classes.chips} onClick={handleEvent}>
      {selected?.map((item, index) => (
        <CustomChip
          key={`${item}${index}`}
          label={item.label}
          onDelete={() => {
            if (!disabled) handleDelete(item);
          }}
        />
      ))}
    </div>
  );
};

export interface DropdownItem {
  label: string;
  value?: string | number;
}

interface DropdownProps {
  addNone?: boolean;
  disabled?: boolean;
  displayEmpty?: boolean;
  items: DropdownItem[] | string[];
  label?: string;
  loading?: boolean;
  multiple?: boolean;
  numberOfDropdownItemsToRender?: number;
  placeholder?: string;
  selectedItems: DropdownItem[] | DropdownItem;
  sortable?: boolean;
  width?: string;
  onChange: (items: DropdownItem[] | DropdownItem) => void;
  handleDelete?: (item: DropdownItem) => void;
  dataAut?: string;
  classes?: Partial<
    Record<
      | "root"
      | "chips"
      | "chip"
      | "disabled"
      | "label"
      | "dropdown"
      | "select"
      | "icon"
      | "loadingIcon"
      | "textContainer"
      | "placeholder"
      | "textContent",
      string
    >
  >;
}

const Dropdown = (props: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const {
    addNone,
    disabled,
    displayEmpty,
    items,
    label,
    loading,
    multiple,
    placeholder,
    selectedItems,
    sortable,
    width,
    dataAut,
  } = props;
  const classes = withStyles.getClasses(props);

  const getStyles = (item, items) => {
    return {
      fontWeight: items.includes(item) ? 600 : 500,
    };
  };

  const setMenuProps = () => {
    return {
      PaperProps: {
        style: {
          width: width,
        },
      },
    };
  };

  const onChange = (val) => {
    props.onChange(val);
    setOpen(false);
  };

  const renderValues = (selected) => {
    if ((!selected || (multiple && !selected.length)) && placeholder) {
      return (
        <div className={classes.textContainer}>
          <span className={classes.placeholder}>{placeholder}</span>
        </div>
      );
    }

    if (multiple) {
      if (sortable) {
        return (
          <SortableContainer
            updateSelected={onChange}
            selected={selected}
            classes={classes}
          >
            {selected.map((item, index) => (
              <SortableItem
                key={index}
                item={item}
                index={index}
                disabled={disabled}
                handleDelete={props.handleDelete}
              />
            ))}
          </SortableContainer>
        );
      }
      return <DropdownSelections {...props} selected={selected} />;
    } else
      return (
        <div className={classes.textContainer}>
          <span className={classes.textContent}>
            {selected && selected.label}
          </span>
        </div>
      );
  };
  const MenuProps = setMenuProps();
  return (
    <Fragment>
      {label && (
        <label htmlFor={label} className={classes.label}>
          {label}
        </label>
      )}
      <Select
        className={classes.dropdown}
        sx={{
          ".MuiSelect-icon": loading ? loadingIconStyles : iconStyles,
        }}
        disabled={disabled}
        displayEmpty={displayEmpty}
        multiple={multiple}
        value={selectedItems || ""}
        open={open}
        onChange={(e) => onChange(e.target.value)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        placeholder={placeholder}
        IconComponent={loading ? CircularProgress : KeyboardArrowDown}
        input={
          <OutlinedInput
            data-aut={dataAut || "input"}
            name={label}
            // labelWidth={0}
            id={label}
          />
        }
        renderValue={renderValues}
        MenuProps={MenuProps}
        ref={dropdownRef}
      >
        {addNone && (
          <MenuItem value="" data-aut={`Dropdown|None`}>
            <em>None</em>
          </MenuItem>
        )}
        {items?.map((item, i) => (
          <MenuItem
            key={`${item.label}${i}`}
            value={item}
            style={getStyles(item.label, items)}
            data-aut={`Dropdown|Selection|${item.label}`}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Fragment>
  );
};

export default withStyles(Dropdown, dropdownStyle);
