import { CircularProgress } from "@mui/material";

import { withStyles } from "tss-react/mui";

// eslint-disable-next-line
const styles = (theme, props) => ({
  container: {
    width: "100%",
    minHeight: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
});

interface CircularLoadingI {
  loadingText?: string;
  classes?: Partial<Record<"container", string>>;
}

function CircularLoading(props: CircularLoadingI) {
  const { loadingText = "Loading..." } = props;
  const classes = withStyles.getClasses(props);

  return (
    <div className={classes.container}>
      <CircularProgress />
      <p>{loadingText}</p>
    </div>
  );
}

export default withStyles(CircularLoading, styles);
