import { COMPANY_SEARCH } from "common/constants";

export const COMPANY_SEARCH_COMPANIES_FILTER = `${COMPANY_SEARCH}_COMPANIES_FILTER`;
export const COMPANY_SEARCH_COMPANIES_ORDER = `${COMPANY_SEARCH}_COMPANIES_ORDER`;
export const COMPANY_SEARCH_COMPANIES_ORDER_BY = `${COMPANY_SEARCH}_COMPANIES_ORDER_BY`;
export const COMPANY_SEARCH_COMPANIES_PAGE = `${COMPANY_SEARCH}_COMPANIES_PAGE`;
export const COMPANY_SEARCH_COMPANIES_ROWS_PER_PAGE = `${COMPANY_SEARCH}_COMPANIES_ROWS_PER_PAGE`;

export const setCompaniesFilter = (companiesFilter) => ({
  type: COMPANY_SEARCH_COMPANIES_FILTER,
  companiesFilter,
});
export const setCompaniesOrder = (companiesOrder) => ({
  type: COMPANY_SEARCH_COMPANIES_ORDER,
  companiesOrder,
});
export const setCompaniesOrderBy = (payload) => ({
  type: COMPANY_SEARCH_COMPANIES_ORDER_BY,
  payload,
});
export const setCompaniesPage = (companiesPage) => ({
  type: COMPANY_SEARCH_COMPANIES_PAGE,
  companiesPage,
});
export const setCompaniesRowsPerPage = (companiesRowsPerPage) => ({
  type: COMPANY_SEARCH_COMPANIES_ROWS_PER_PAGE,
  companiesRowsPerPage,
});
