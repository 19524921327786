import { blueBackgroundColor, unstyledButton } from "assets/jss/globalStyle";

// eslint-disable-next-line
const tabsStyle = (props, theme) => ({
  container: {
    width: "100%",
    height: "50px",
    position: "absolute",
    top: "76.25px",
    display: "flex",
  },
  tab: {
    ...unstyledButton,
    backgroundColor: blueBackgroundColor,
    flex: "2 1 33.33%",
    color: "white",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRight: "solid 1px gray",
    fontSize: "16px",
    fontWeight: 300,
    "&:last-of-type": {
      borderRight: "none",
    },
    "& > svg": {
      marginRight: "5px",
    },
    "&:hover, &:focus": {
      color: "white",
    },
  },
  activeTab: {
    backgroundColor: "#0F2336",
    fontWeight: 600,
  },
});

export default tabsStyle;
