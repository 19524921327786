import React from "react";
import { createStore, applyMiddleware } from "redux";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createRootReducer from "./redux/reducers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";
import "./assets/css/react_dates_overrides.css";
import "./assets/css/material-dashboard-react.css";
import "./assets/css/icomoon.css";

import indexRoutes from "./routes/index";

import themeCreator from "assets/jss/themeCreator";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";

import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH_CONFIG } from "services/authConstants";
import { AUDIENCE } from "services/apiConfig";
import { StrictMode } from "react";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const theme = themeCreator();

const history = createBrowserHistory();
const routerHistoryMiddleware = routerMiddleware(history);
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const rootReducer = createRootReducer(history);

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(routerHistoryMiddleware))
);

export const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();

const auth0Domain = AUTH_CONFIG.domain;
const auth0ClientId = AUTH_CONFIG.clientID;
const auth0CallBackUrl = AUTH_CONFIG.redirectUri;

const App = () => (
  <StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientId}
                authorizationParams={{
                  redirect_uri: auth0CallBackUrl,
                  audience: AUDIENCE,
                }}
                useRefreshTokens={true}
                cacheLocation="localstorage"
              >
                <ConnectedRouter history={history}>
                  <Switch>
                    {indexRoutes.map((prop, key) => (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    ))}
                  </Switch>
                </ConnectedRouter>
              </Auth0Provider>
            </Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </AppInsightsContext.Provider>
  </StrictMode>
);

export default App;
