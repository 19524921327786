import React, { Suspense } from "react";
import { Loading } from "components";

const AccountSettings = React.lazy(() => import("./AccountSettings"));

function AccountSettingsView(props) {
  return (
    <Suspense
      fallback={<Loading active loadingText="Loading Account Settings" />}
    >
      <AccountSettings {...props} />
    </Suspense>
  );
}

export default AccountSettingsView;
