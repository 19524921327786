import { withStyles } from "tss-react/mui";
import cx from "classnames";
import { Domain, Search, Settings } from "@mui/icons-material";
import tabsStyle from "components/Tabs/tabsStyle";
import { UserStateI } from "types/User";
import { RouteComponentProps } from "react-router-dom";

interface TabsI {
  onClick: (val: string) => void;
  userState: UserStateI;
  history: RouteComponentProps["history"];
  classes?: Partial<Record<"container" | "tab" | "activeTab", string>>;
}

function getClass(loc: string, props: TabsI) {
  const { classes, history } = props;
  return cx(
    classes.tab,
    history.location.pathname === loc ? classes.activeTab : ""
  );
}

const Tabs = (props: TabsI) => {
  const { onClick, userState = {} as UserStateI } = props;
  const classes = withStyles.getClasses(props);
  return (
    <div className={classes.container}>
      <button
        onClick={() => onClick("/")}
        className={getClass("/", props)}
        data-aut="HeaderNav|AllAccounts"
      >
        <Domain />
        All Accounts
      </button>
      {userState.accessLevel && userState.accessLevel >= 50 && (
        <button
          onClick={() => onClick("/search")}
          className={getClass("/search", props)}
          data-aut="HeaderNav|GlobalSearch"
        >
          <Search />
          Global Search
        </button>
      )}
      {userState.accessLevel && userState.accessLevel >= 60 && (
        <button
          onClick={() => onClick("/settings")}
          className={getClass("/settings", props)}
          data-aut="HeaderNav|GlobalSettings"
        >
          <Settings />
          Global Settings
        </button>
      )}
    </div>
  );
};

const TabsStyled = withStyles(Tabs, tabsStyle);

export default TabsStyled;
