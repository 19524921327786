import { Checkbox, FormControlLabel } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { convertUrlToFileUrl } from "common/helpers";
import { getCroppedImg } from "common/helpers/image";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { useState, useRef, useEffect } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// eslint-disable-next-line
const styles = (theme, props) => ({
  cancelButton: {
    marginLeft: "8px",
  },
  container: {
    maxWidth: "500px",
    maxHeight: "900px",
    margin: "auto",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  checkboxLabel: {
    color: "black",
  },
});

interface ImageCropperProps {
  close: () => void;
  name: string;
  src: string;
  updateImage: (imgUrl: string, imgFile: File) => void;
  type: "url" | "file";
  classes?: Partial<
    Record<
      "cancelButton" | "container" | "flexCenter" | "checkboxLabel",
      string
    >
  >;
}

function ImageCropper(props: ImageCropperProps) {
  const { close, src, updateImage, type } = props;
  const [imgSrc, setSrc] = useState<string>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const canCrop = useRef<boolean>(false);
  const [crop, setCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<null | string>(null);
  const classes = withStyles.getClasses(props);

  useEffect(() => {
    if (type === "url") {
      convertUrlToFileUrl(src)
        .then((fileUrl) => {
          setSrc(fileUrl);
        })
        .catch(() => {
          console.warn(
            "Image fetch failed. CORS settings likely to be changed."
          );
        });
    } else {
      setSrc(src);
    }
  }, [src, type]);

  function handleUpdate(c: PixelCrop) {
    if (c.width === 0 && c.height === 0) {
      canCrop.current = false;
    } else {
      canCrop.current = true;
    }
    setCrop(c);
  }

  async function handleCropImage(curImage) {
    const img: File = await getCroppedImg(curImage, crop);
    const imgURL = URL.createObjectURL(img);
    updateImage(imgURL, img);
  }

  function handleSaveImage() {
    handleCropImage(imgRef.current);
  }

  function toggleScale() {
    if (aspect) setAspect(null);
    else {
      canCrop.current = false;
      setCrop(null);
      setAspect("1");
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.flexCenter}>
        {src && (
          <ReactCrop
            crop={crop}
            onChange={handleUpdate}
            aspect={Number(aspect)}
          >
            <img src={imgSrc} ref={imgRef} />
          </ReactCrop>
        )}
      </div>
      <div className={classes.flexCenter} style={{ marginTop: "16px" }}>
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox
              checked={typeof aspect === "number"}
              color="primary"
              onChange={toggleScale}
              value="ToggleScale"
              inputProps={{ "aria-label": "Aspect Ratio" }}
            />
          }
          label="Aspect Ratio"
        />

        <Input
          style={{ width: "80px" }}
          value={aspect}
          onChange={(e) => {
            canCrop.current = false;
            setCrop(null);
            setAspect(e);
          }}
          disabled={typeof aspect !== "number"}
          type="number"
          step={0.05}
        />
      </div>
      <div className={classes.flexCenter} style={{ marginBottom: "16px" }}>
        <Button
          onClick={handleSaveImage}
          label="Crop"
          disabled={canCrop.current === false}
          data-aut="CropImage"
        />
        <Button isBlue onClick={close} label="Cancel" data-aut="CancelCrop" />
      </div>
    </div>
  );
}

export default withStyles(ImageCropper, styles);
