// eslint-disable-next-line
const loadingStyles = (theme, props) => ({
  loadingContainer: {
    position: "absolute",
    display: "block",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  loadingCenteredDiv: {
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  loadingImage: {
    maxHeight: "250px",
  },
  loadingErrorHeader: {
    fontSize: "26px",
    fontWeight: "bold",
    marginTop: "0px",
  },
  loadingErrorText: {
    display: "block",
    lineHeight: "1.8em",
    fontSize: "16px",
  },
});

export default loadingStyles;
