import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { CURRENCY_SYMBOLS } from "common/constants";
import { handleErrors } from "common/helpers";

export const fetchCurrencySymbols = () => {
  const url = getConfig(CURRENCY_SYMBOLS).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${CURRENCY_SYMBOLS} url`));
  }
};
