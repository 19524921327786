import { connect } from "react-redux";
import { useSafeSetState } from "hooks/useState";
import { withStyles } from "tss-react/mui";
import { AutoComplete, Button, Dropdown, Input } from "components";
import {
  userSearchSelectCompany,
  userSearchSetFormParams,
} from "redux/actions/userSearch";
import userSearchFormStyle from "components/UserSearchForm/userSearchFormStyle";
import { UserSearchStateI } from "types/User";
import { useCompanies } from "hooks/companies";
import { useSelectedCompany } from "hooks/selectedCompany";
import { useMultiCompanyRoles } from "hooks/roles";
import { UseQueryResult } from "@tanstack/react-query";
import { RoleI } from "types/Role";
import { useEffect } from "react";

const defaultRoles = [
  { label: "Administrator", value: "016AC211316E1A6C2A9138F14B6FC369" },
  { label: "Traveler", value: "016AC211343428BC7C64B3B181BF0090" },
  { label: "Travel Arranger", value: "016AC2113924D15A483E575C15FCF29B" },
  { label: "Client Manager", value: "016AC2113A0445FEAB1A05793DE27B3C" },
];

interface UserSearchFormI {
  selectedCompanyID?: string;
  userSearch: UserSearchStateI;
  handleSearch?: () => void;
  userSearchSetFormParams: (params) => void;
  userSearchSelectCompany: (params) => void;
  classes?: Partial<Record<"container" | "half" | "searchQuery", string>>;
}

const UserSearchForm = (props: UserSearchFormI) => {
  const {
    selectedCompanyID,
    userSearch,
    userSearchSetFormParams,
    handleSearch,
    userSearchSelectCompany,
  } = props;
  const classes = withStyles.getClasses(props);
  const { selectedRoles, query, selectedCompanies } = userSearch;
  const globalSelectedCompany = useSelectedCompany(selectedCompanyID);
  useEffect(() => {
    if (globalSelectedCompany.data) {
      userSearchSelectCompany([globalSelectedCompany.data]);
    }
  }, [globalSelectedCompany.data, userSearchSelectCompany]);

  const initialState = {
    query: query,
    selectedCompanies: globalSelectedCompany?.data
      ? [globalSelectedCompany.data]
      : selectedCompanies,
    selectedRoles: selectedRoles,
  };

  const [state, safeSetState] = useSafeSetState(initialState);
  const companies = useCompanies();

  const selectedCompanyIds = state.selectedCompanies.map(
    (company) => company.id
  );

  // Get roles for each company and combine into unique list
  const multiCompanyRolesQueries = useMultiCompanyRoles(selectedCompanyIds);
  const allRolesReturned = multiCompanyRolesQueries.every(
    (query) => query.isSuccess
  );
  let combinedRoles = null;
  if (allRolesReturned) {
    multiCompanyRolesQueries.forEach(
      (roleQuery: UseQueryResult<RoleI[]>, index: number) => {
        if (index === 0) combinedRoles = [...roleQuery.data];
        else {
          roleQuery?.data?.forEach((role) => {
            if (!combinedRoles.some((cr) => cr.id === role.id))
              combinedRoles.push(role);
          });
        }
      }
    );
  }
  const roles = combinedRoles || (defaultRoles as any[]);

  defaultRoles.forEach((defRole) => {
    const hasRole = roles.find((r) => {
      return r.label === defRole.label;
    });
    if (!hasRole) roles.unshift(defRole);
  });

  const updateQuery = (query) => {
    safeSetState({ query });
  };
  const updateSelectedCompanies = (selectedCompany) => {
    const newCompanies = [...state.selectedCompanies, selectedCompany];
    safeSetState({ selectedCompanies: newCompanies });
  };
  const removeSelectedCompany = (selectedCompany) => {
    const selectedCompanies = state.selectedCompanies.filter(
      (company) => company.id !== selectedCompany.id
    );
    safeSetState({ selectedCompanies });
  };
  const updateRoles = (selectedRoles) => {
    safeSetState({ selectedRoles });
  };
  const removeRole = (role) => {
    const selectedRoles = state.selectedRoles.filter(
      (r) => r.value !== role.value
    );
    safeSetState({ selectedRoles });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...state };
    if (selectedCompanyID) {
      delete params.selectedCompanies;
    }
    userSearchSetFormParams(state);
    if (handleSearch) handleSearch();
  };

  // Selected company is specific to userSearch, companySelected is the global company selection
  return (
    <form className={classes.container} onSubmit={handleSubmit}>
      <div className={classes.searchQuery}>
        <Input
          label="Search Query"
          isSearch
          placeholder="Search by name, username, email address, or employee ID"
          value={state.query}
          onChange={updateQuery}
          data-aut="UserSearch|Query"
        />
      </div>
      {!globalSelectedCompany?.data && (
        <div className={classes.half}>
          <AutoComplete
            label="Filter By Company"
            errorMessage={companies.isError ? "Error Fetching Companies" : ""}
            isLoading={companies.isLoading}
            handleDelete={removeSelectedCompany}
            onChange={updateSelectedCompanies}
            selectedItem={state.selectedCompanies}
            suggestions={companies?.data}
            isMulti
          />
        </div>
      )}
      <div className={classes.half}>
        <Dropdown
          multiple
          handleDelete={removeRole}
          label="Filter By Role"
          onChange={updateRoles}
          selectedItems={state.selectedRoles}
          items={roles}
          disabled={!allRolesReturned}
        />
      </div>
      <Button
        label="Search"
        type="submit"
        data-aut="UserSearchForm|SearchButton"
      />
    </form>
  );
};

export const mapStateToProps = (state) => ({
  selectedCompanyID: state.selectedCompanyID,
  userSearch: state.userSearch,
});

export const mapDispatchToProps = (dispatch) => ({
  userSearchSelectCompany: (params) =>
    dispatch(userSearchSelectCompany(params)),
  userSearchSetFormParams: (params) =>
    dispatch(userSearchSetFormParams(params)),
});

const UserSearchFormStyled = withStyles(UserSearchForm, userSearchFormStyle);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearchFormStyled);
