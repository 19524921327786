import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import application from "./application";
import companySearch from "./companySearch";
import itinerarySearch from "./itinerarySearch";
import notifications from "./notifications";
import impersonationLogs from "./impersonationLogs";
import messageLogs from "./messageLogs";
import selectedCompanyID from "./selectedCompanyID";
import userState from "./userState";
import userSearch from "./userSearch";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    application,
    companySearch,
    impersonationLogs,
    itinerarySearch,
    notifications,
    messageLogs,
    selectedCompanyID,
    userState,
    userSearch,
  });

export default createRootReducer;
