import moment from "moment";
import { withStyles } from "tss-react/mui";
import itineraryCardStyle from "components/ItineraryCard/itineraryCardStyle";

type CardType = "flight" | "car" | "hotel";

const renderDateTime = (date: string | null, type: CardType) => {
  if (!date || !date.length) return "";
  const momentDate = moment.utc(date);
  if (type === "hotel") return momentDate.format("MM/DD/YY");
  else return momentDate.format("MM/DD/YY hh:mm A");
};

const renderItemDetails = ({
  type,
  departureAirport,
  arrivalAirport,
  name,
}) => {
  if (type === "flight")
    return `${departureAirport.code} - ${arrivalAirport.code}`;
  else return name;
};

const renderStartText = (type: CardType) => {
  if (type === "flight") return "Departure";
  if (type === "hotel") return "Check In";
  if (type === "car") return "Pick Up";
  return "";
};

const renderEndText = (type: CardType) => {
  if (type === "flight") return "Arrival";
  if (type === "hotel") return "Check Out";
  if (type === "car") return "Return";
  return "";
};

const renderCodeText = (type: CardType) => {
  if (type === "flight") return "Carrier Code";
  if (type === "hotel") return "Hotel Chain Code";
  if (type === "car") return "Type Code";
  return "";
};

type Airport = {
  code: string;
};

interface ItineraryCardI {
  arrivalAirport?: Airport;
  cardIndex: number;
  cityCode?: string;
  code?: string;
  confirmation?: string;
  departureAirport?: Airport;
  endTime: string;
  flightNumber?: string;
  itineraryIndex: string;
  name?: string;
  price?: number;
  startTime: string;
  type: CardType;
  classes?: Partial<
    Record<
      "cardWrapper" | "topRow" | "middleRow" | "bottomRow" | "bottomItem",
      string
    >
  >;
}

const ItineraryCard = (props: ItineraryCardI) => {
  const {
    arrivalAirport,
    cardIndex,
    cityCode,
    code,
    confirmation,
    departureAirport,
    endTime,
    flightNumber,
    itineraryIndex,
    startTime,
    type,
  } = props;
  const classes = withStyles.getClasses(props);

  return (
    <div
      className={classes.cardWrapper}
      data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}`}
    >
      <div className={classes.topRow}>
        <span
          data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|ItemDetails`}
        >{`${type}: ${renderItemDetails({
          type,
          departureAirport,
          arrivalAirport,
          name,
        })}`}</span>
        {/* STOW-444 - Temporarily not showing pricing here until correct data is returned in the future. Users will go to my bookings in Lightning for pricing data */}
        {/* <span data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|Price`}>{price ? `$${price}` : 'N/A'}</span> */}
      </div>
      {confirmation && (
        <div className={classes.middleRow}>
          <p>Confirmation Number</p>
          <p
            data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|Confirmation`}
          >
            {confirmation}
          </p>
        </div>
      )}
      <div className={classes.bottomRow}>
        <div className={classes.bottomItem}>
          <p>{renderStartText(type)}</p>
          <p
            data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|StartTime`}
          >
            {renderDateTime(startTime, type)}
          </p>
        </div>
        <div className={classes.bottomItem}>
          <p>{renderEndText(type)}</p>
          <p
            data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|EndTime`}
          >
            {renderDateTime(endTime, type)}
          </p>
        </div>
        <div className={classes.bottomItem}>
          <p>{type === "flight" ? "Flight Number" : "City Code"}</p>
          <p
            data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|ItemNumber`}
          >
            {type === "flight" ? flightNumber : cityCode}
          </p>
        </div>
        <div className={classes.bottomItem}>
          <p>{renderCodeText(type)}</p>
          <p
            data-aut={`Itineraries|Itinerary${itineraryIndex}|Card${cardIndex}|ItemCode`}
          >
            {code}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withStyles(ItineraryCard, itineraryCardStyle);
