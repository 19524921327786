import { spacing, textColor } from "../globalStyle";

export const authenticationStyle = {
  root: {
    flexGrow: 1,
    height: "300px",
  },
  paper: {
    padding: spacing.unit * 2,
    height: "100%",
    color: textColor,
  },
  gridItem: {
    textAlign: "center" as const,
  },
};

export default authenticationStyle;
