// eslint-disable-next-line
const styles = (theme, props) => ({
  root: {
    margin: "2px",
    color: "rgba(0, 0, 0, 0.87)",
    height: "32px",
    cursor: "default",
    border: "none",
    display: "inline-flex",
    outline: "none",
    padding: 0,
    fontSize: "13px",
    transition:
      "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxSizing: "border-box",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    alignItems: "center",
    whiteSpace: "nowrap",
    borderRadius: "16px",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "#e0e0e0",
  },
  label: {
    cursor: "inherit",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    whiteSpace: "nowrap",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  icon: {
    padding: "0",
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    height: "auto",
    margin: "0 4px 0 -8px",
    fill: "currentColor",
    width: "1em",
    display: "inline-block",
    fontSize: "24px",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    userSelect: "none",
    flexShrink: 0,
    "&:hover": {
      color: "rgba(0, 0, 0, 0.4)",
    },
  },
});

export default styles;
