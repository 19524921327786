import { useQuery } from "@tanstack/react-query";
import { fetchCountries } from "services/countries";
import { QUERYKEY_COUNTRIES } from "./queryKeys";
import { Country } from "types/Locations";

function mapCountries(data): Country[] {
  return data.map((c) => ({
    label: c.country,
    countryCode: c.countryCode,
  }));
}

export function useCountries() {
  return useQuery({
    queryKey: [QUERYKEY_COUNTRIES],
    queryFn: fetchCountries,
    select: mapCountries,
    staleTime: Infinity,
  });
}
