import React, { useEffect, Fragment } from "react";
import moment from "moment";
import { withStyles } from "tss-react/mui";
import { useSafeSetState } from "hooks/useState";
import { Loading, Input, Modal, Button } from "components";
import { fetchMessageLog } from "services/messageLogs";
import { MessageLogI } from "types/MessageLog";
import { saveFile } from "common/helpers";

// eslint-disable-next-line
const styles = (theme, props) => ({
  row: {
    display: "flex",
    width: "100%",
    height: "100px",
    "& > div": {
      flexGrow: 1,
    },
  },
  half: {
    width: "50%",
    "&:first-child": {
      marginRight: "8px",
    },
    "&:last-child": {
      marginLeft: "8px",
    },
  },
  messageContent: {
    width: "100%",
    maxHeight: "300px",
    overflowY: "auto",
    border: `1px solid ${theme.palette.silverColor}`,
    borderRadius: "5px",
    padding: "4px",
  },
});

const initialState = {
  loading: false,
  emailType: "",
  error: false,
  messageLogID: null,
  companyName: "",
  contentHtml: "",
  dateSent: null,
  subject: "",
  to: "",
};

interface MessageLogsModalI {
  close: () => void;
  isOpen: boolean;
  selectedMessage: MessageLogI;
  classes?: Partial<Record<"row" | "half" | "messageContent", string>>;
}

function MessageLogsModal(props: MessageLogsModalI) {
  const { close, isOpen, selectedMessage } = props;
  const classes = withStyles.getClasses(props);
  const [
    {
      companyName,
      contentHtml,
      dateSent,
      emailType,
      error,
      loading,
      messageLogID,
      subject,
      to,
    },
    safeSetState,
  ] = useSafeSetState(initialState);

  useEffect(() => {
    if (!messageLogID && selectedMessage) {
      safeSetState({
        messageLogID: selectedMessage,
        error: null,
        loading: true,
      });
      fetchMessageLog(selectedMessage)
        .then((r) => {
          safeSetState({
            companyName: r.companyName,
            contentHtml: r.contentHtml,
            dateSent: r.dateSent,
            subject: r.subject,
            emailType: r.emailType,
            to: r.to,
            loading: false,
          });
        })
        .catch((e) => {
          safeSetState({ error: e, loading: false });
        });
    }
  }, [messageLogID, safeSetState, selectedMessage]);

  function handleClose() {
    close();
    safeSetState(initialState);
  }

  function renderBody() {
    if (error) return renderError();
    if (loading) return renderLoading();
    if (messageLogID) return renderMessageLog();
  }

  function renderError() {
    return <Loading active={true} addContainer error={error} />;
  }

  function renderLoading() {
    return (
      <Loading active={true} addContainer loadingText="Loading Message Log" />
    );
  }

  function renderMarkup() {
    return { __html: contentHtml };
  }

  function renderMessageLog() {
    return (
      <Fragment>
        <div className={classes.row}>
          <div className={classes.half}>
            <Input
              label="Date Sent"
              value={moment(dateSent).format("MM/DD/YYYY")}
              disabled={true}
            />
          </div>
          <div className={classes.half}>
            <Input label="To" value={to} disabled={true} />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.half}>
            <Input label="Company Name" value={companyName} disabled={true} />
          </div>
          <div className={classes.half}>
            <Input label="Template" value={emailType} disabled={true} />
          </div>
        </div>
        <div className={classes.row}>
          <Input label="Subject" value={subject} disabled={true} />
        </div>
        <Button
          onClick={() =>
            saveFile(contentHtml, `${subject || "messageLog"}.html`)
          }
          label="Download Email"
          isTextButton
          noMargin
          data-aut="MessageLogs|DownloadEmail"
        />
        <div
          className={classes.messageContent}
          dangerouslySetInnerHTML={renderMarkup()}
        />
      </Fragment>
    );
  }

  return (
    <Modal close={handleClose} isOpen={isOpen} header="Message Log">
      {renderBody()}
    </Modal>
  );
}
export default withStyles(MessageLogsModal, styles);
