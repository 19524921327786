import { blueColor, whiteColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const useMultiToggleStyle = (theme, props) => ({
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonStyle: {
    color: blueColor,
    fontWeight: 600,
    textTransform: "none" as "none",
    borderColor: blueColor,
    transition: "all 0.5s ease",
    height: "45px",
    "&:hover": {
      background: "#33ADFF",
      color: whiteColor,
      border: "1px solid #33ADFF",
    },
  },
  button: {
    borderRadius: "0px",
    borderLeft: "0px solid black",
    "&:hover": {
      borderLeft: "0px solid black",
    },
  },
  buttonLeft: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  buttonRight: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderLeft: "0px solid black",
    "&:hover": {
      borderLeft: "0px solid black",
    },
  },
  active: {
    background: blueColor,
    color: "white",
  },
  error: {
    color: "red",
  },
  errorMessage: {
    fontSize: "11px",
  },
});

export default useMultiToggleStyle;
