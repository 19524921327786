import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "tss-react/mui";

import style from "assets/jss/components/authenticationStyle";

const Login = (props) => {
  const classes = withStyles.getClasses(props);
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item className={classes.gridItem}>
        <CircularProgress />
        <p>Please Wait...</p>
      </Grid>
    </Grid>
  );
};

const LoginStyled = withStyles(Login, style);

export default LoginStyled;
