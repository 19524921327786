import { useQuery } from "@tanstack/react-query";
import { MessageLogTemplateCodeI } from "types/MessageLogTemplateCode";
import { fetchMessageLogTemplateCodes } from "services/messageLogTemplateCodes";
import { QUERYKEY_MESSAGE_LOG_TEMPLATE_CODES } from "./queryKeys";

function templateCodesMapper(tcodes): MessageLogTemplateCodeI[] {
  return tcodes.map((tc) => {
    return {
      ...tc,
      label: tc.name,
      value: tc.code,
    };
  });
}

export function useMessageLogTemplateCodes() {
  return useQuery({
    queryKey: [QUERYKEY_MESSAGE_LOG_TEMPLATE_CODES],
    queryFn: fetchMessageLogTemplateCodes,
    select: templateCodesMapper,
    staleTime: Infinity,
  });
}
