import { withStyles } from "tss-react/mui";
import { RotateLeft } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { label } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  label,
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  iconButton: {
    marginLeft: "4px",
    padding: "8px",
  },
  pickerInput: {
    "&::-webkit-color-swatch": {
      border: "none",
    },
    "&::-moz-color-swatch": {
      border: "none",
    },
    "&:focus, &:active": {
      borderColor: theme.palette.primaryColor,
      borderWidth: "2px",
    },
    background: "white",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    display: "inline-block",
    height: "45px",
    borderRadius: "4px",
    minWidth: "45px",
    cursor: "pointer",
  },
  hexInputContainer: {
    display: "flex",
    width: "100%",
    paddingRight: "18px",
  },
  input: {
    height: "45px",
    paddingTop: 0,
    paddingBottom: 0,
    width: "auto",
    flex: "100 1 auto",
    minWidth: "50px",
  },
  inputRoot: {
    marginRight: "10px",
  },
});

interface ColorPickerI {
  defaultValue?: string;
  onChange: (val: string) => void;
  value?: string;
  hideReset?: boolean;
  withHexInput?: boolean;
  inputLabel: string;
  dataAut?: string;
  classes?: Partial<
    Record<
      | "label"
      | "container"
      | "iconButton"
      | "pickerInput"
      | "hexInputContainer"
      | "input"
      | "inputRoot",
      string
    >
  >;
}

function ColorPicker(props: ColorPickerI) {
  const {
    defaultValue,
    onChange,
    value,
    hideReset,
    withHexInput,
    inputLabel,
    dataAut,
  } = props;
  const classes = withStyles.getClasses(props);

  return (
    <div className={classes.container}>
      {inputLabel && <label className={classes.label}>{inputLabel}</label>}
      <div className={classes.hexInputContainer}>
        {withHexInput && (
          <TextField
            className={classes.inputRoot}
            type="text"
            value={value || defaultValue}
            onChange={(e) => onChange(e.target.value)}
            variant="outlined"
            InputProps={{
              classes: { input: classes.input },
              "aria-label": `${inputLabel}-textfield`,
            }}
            inputProps={{
              "data-aut": dataAut,
            }}
          />
        )}
        <input
          value={value || defaultValue}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          type="color"
          className={classes.pickerInput}
          style={withHexInput ? { margin: "0" } : {}}
          aria-label={inputLabel}
        />
        {!hideReset && value && (
          <IconButton
            className={classes.iconButton}
            aria-label="Clear Color"
            onClick={() => {
              onChange(null);
            }}
            size="large"
          >
            <RotateLeft />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default withStyles(ColorPicker, styles);
