import { useQuery } from "@tanstack/react-query";
import { fetchTimezones } from "services/timezones";
import { QUERYKEY_TIMEZONES } from "./queryKeys";

export type Timezone = {
  code: string;
  name: string;
  label: string;
  value: string;
};

function mapResponse(res): Timezone[] {
  return res.map((r) => ({ ...r, label: r.name, value: r.code }));
}

export function useTimezones() {
  return useQuery({
    queryKey: [QUERYKEY_TIMEZONES],
    queryFn: fetchTimezones,
    select: mapResponse,
  });
}
