import { deleteRequest, get, post, put } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { USERS, ROLES, ROLES_ADMIN } from "common/constants";
import { handleErrors } from "common/helpers";
import { RoleI } from "types/Role";

const url = getConfig(ROLES).url;

const usersUrl = getConfig(USERS).url;

export function fetchAdminRoles() {
  const adminURL = getConfig(ROLES_ADMIN).url;
  if (adminURL) {
    return get(adminURL)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLES_ADMIN} url`));
  }
}

export function fetchAdminRoleUsers(id) {
  if (url) {
    return get(`${url}/${id}/Users`)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLES} url`));
  }
}

export function fetchGlobalRoles() {
  if (url) {
    return get(`${url}`)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLES} url`));
  }
}

export function fetchRoles(id, getAdmin = false): Promise<RoleI[]> {
  if (url) {
    return get(`${url}/Company/${id}/${getAdmin}`)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLES} url`));
  }
}

export function createRole({
  active,
  companyID,
  applicationID,
  name,
  roleTypeID,
}) {
  if (url) {
    const params = { active, companyID, name, applicationID, roleTypeID };
    return post(url, params)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLES} url`));
  }
}

export function editRole({
  active,
  deleted,
  id,
  companyID,
  applicationID,
  name,
  roleTypeID,
}) {
  if (url) {
    const params = {
      active,
      deleted,
      id,
      companyID,
      name,
      applicationID,
      roleTypeID,
    };
    return put(url, params)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ROLES} url`));
  }
}

export function deleteRole(id) {
  if (!id) return Promise.reject(new Error("Missing company id"));
  if (url) {
    const fullUrl = `${url}/${id}`;
    return deleteRequest(fullUrl).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${ROLES} url`));
  }
}

export function addUserToAdminRole(id, roleId) {
  if (!id) return Promise.reject(new Error("Missing user id"));
  const fullUrl = `${usersUrl}/${id}/Roles/Admin/${roleId}`;
  return put(fullUrl).then(handleErrors);
}

export function removeUserFromAdminRole(id, roleId) {
  if (!id) return Promise.reject(new Error("Missing user id"));
  const fullUrl = `${usersUrl}/${id}/Roles/Admin/${roleId}`;
  return deleteRequest(fullUrl).then(handleErrors);
}
