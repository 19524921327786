import React, { Suspense } from "react";
import { Loading } from "components";

const UserSearch = React.lazy(() => import("./UserSearch"));

function UserSearchView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Users" />}>
      <UserSearch {...props} />
    </Suspense>
  );
}

export default UserSearchView;
