import {
  cardHeader,
  grayColor,
  flexVertAligned,
  paddingSize,
  requiredFields,
} from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  root: {
    margin: "16px 0",
    borderRadius: "4px",
  },
  bottomPadding: {
    padding: "0 22px 22px 22px",
  },
  withPadding: {
    padding: paddingSize,
  },
  noPadding: {
    padding: 0,
  },
  summaryContainer: {
    width: "100%",
    position: "relative",
  },
  summaryContent: {
    margin: "0px !important",
  },
  header: {
    ...cardHeader,
    margin: 0,
  },
  headerWrapper: {
    ...flexVertAligned,
    justifyContent: "space-between",
    paddingRight: "50px",
  },
  requiredFields,
  expandIcon: {
    top: 0,
    right: "5px",
    position: "absolute",
    transition: "all 0.25s ease",
    fontSize: "32px",
    color: grayColor,
  },
});

export default styles;
