import React, { Suspense } from "react";
import { Loading } from "components";

const ImpersonationLogs = React.lazy(() => import("./ImpersonationLogs"));

function ImpersonationLogsView(props) {
  return (
    <Suspense
      fallback={<Loading active loadingText="Loading Impersonation Logs" />}
    >
      <ImpersonationLogs {...props} />
    </Suspense>
  );
}

export default ImpersonationLogsView;
