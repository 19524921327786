import Paper from "@mui/material/Paper";
import { ErrorBoundary } from "components";
import { withStyles } from "tss-react/mui";
import { paddingSize } from "assets/jss/globalStyle";

// eslint-disable-next-line
const styles = (theme, props) => ({
  paperWrapper: {
    padding: paddingSize,
    margin: "16px 0",
    position: "relative",
    width: "100%",
    overflowX: "auto",
  },
});

interface CardProps {
  allowOverflow?: boolean;
  children: React.ReactNode;
  noPadding?: boolean;
  noMargin?: boolean;
  classes?: Partial<Record<"paperWrapper", string>>;
}

const Card = (props: CardProps) => {
  const { allowOverflow, children, noPadding, noMargin } = props;
  const classes = withStyles.getClasses(props);

  const style: React.CSSProperties = {};
  if (noPadding) style.padding = 0;
  if (noMargin) style.margin = 0;
  if (allowOverflow) style.overflow = "visible";
  return (
    <Paper className={classes.paperWrapper} style={style}>
      <ErrorBoundary addContainer>{children}</ErrorBoundary>
    </Paper>
  );
};

export default withStyles(Card, styles);
