import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { COMPANY_FEATURE_TEMPLATES } from "common/constants";
import { handleErrors } from "common/helpers";

const url = getConfig(COMPANY_FEATURE_TEMPLATES).url;

export const fetchCompanyFeatureTemplates = (id) => {
  if (url) {
    const finalUrl = `${url}/${id}/Templates`;
    return get(finalUrl)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(
      new Error(`Missing ${COMPANY_FEATURE_TEMPLATES} url`)
    );
  }
};
