import { SET_SELECTED_COMPANY } from "redux/actions/selectedCompanyID";

const selectedCompanyID = (state = null, action): string | null => {
  switch (action.type) {
    case SET_SELECTED_COMPANY: {
      return action.id;
    }
    default:
      return state;
  }
};

export default selectedCompanyID;
