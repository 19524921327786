import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { APPS } from "common/constants";
import { handleErrors } from "common/helpers";

const url = getConfig(APPS).url;

export const fetchApps = () => {
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPS} url`));
  }
};
