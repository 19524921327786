import { useQuery } from "@tanstack/react-query";
import { fetchCompanyFeatureTemplates } from "services/featureTemplates";
import { FeatureTemplateI } from "types/Feature";
import { QUERYKEY_FEATURE_TEMPLATES } from "./queryKeys";

function templatesMapper(templates): FeatureTemplateI[] {
  return templates
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      else return +1;
    })
    .map((t) => ({ ...t, label: t.name }));
}

export function useCompanyFeatureTemplates(id) {
  return useQuery({
    queryKey: [QUERYKEY_FEATURE_TEMPLATES, id],
    queryFn: () => fetchCompanyFeatureTemplates(id),
    select: templatesMapper,
    enabled: Boolean(id),
    staleTime: Infinity,
  });
}
