import {
  cardHeader,
  ellipsisOverflow,
  tableHeadFont,
} from "assets/jss/globalStyle";

const roleCell = {
  ...tableHeadFont,
  ...ellipsisOverflow,
  fontSize: "12px",
  minWidth: "150px",
  maxWidth: "200px",
  paddingRight: 0,
};

// eslint-disable-next-line
const companyRoleFeaturesStyle = (theme, props) => ({
  root: {
    margin: "16px 0",
  },
  header: {
    margin: "0 0 0 22px",
    ...cardHeader,
  },
  propertyHeader: {
    marginTop: "0px",
  },
  paperWrapper: {
    paddingTop: "16px",
  },
  table: {
    minWidth: 700,
  },

  textField: {
    width: "400px",
    margin: "0 0 16px 16px",
    position: "sticky",
    left: 16,
  },
  propertyCell: {
    ...tableHeadFont,
    width: "235px",
    color: "black",
    paddingRight: 0,
    position: "sticky",
    zIndex: 1,
    backgroundColor: "white",
    left: 0,
  },
  roleCell: {
    ...roleCell,
    "&:nth-child(7)": {
      borderLeft: "solid 1px rgba(224, 224, 224, 1)",
    },
  },
  roleCellApprove: {
    ...roleCell,
    "&:nth-child(8)": {
      borderLeft: "solid 1px rgba(224, 224, 224, 1)",
    },
  },
});

export default companyRoleFeaturesStyle;
