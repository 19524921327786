import { blueColor, redColor } from "assets/jss/globalStyle";

// eslint-disable-next-line
const serverEventTriggerStyle = (theme, props) => ({
  button: {
    fontSize: "14px",
    fontWeight: 600,
    transition: "all 0.2s linear",
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  active: {
    color: blueColor,
    cursor: "pointer",
    "&:hover": {
      wordSpacing: "1px",
      fontWeight: 700,
    },
  },
  icon: {
    marginRight: "5px",
  },
  error: {
    color: redColor,
  },
});

export default serverEventTriggerStyle;
