import moment from "moment";

import {
  MESSAGE_LOGS_SET_COMPANY,
  MESSAGE_LOGS_SET_END_DATE,
  MESSAGE_LOGS_SET_ORDER,
  MESSAGE_LOGS_SET_PAGE,
  MESSAGE_LOGS_SET_ROWS_PER_PAGE,
  MESSAGE_LOGS_SET_QUERY,
  MESSAGE_LOGS_SET_START_DATE,
  MESSAGE_LOGS_SET_TEMPLATES,
  MESSAGE_LOGS_SET_SERVER_QUERY,
  MESSAGE_LOGS_SET_DEBTOR,
} from "redux/actions/messageLogs";
import { MessageLogsStateI } from "types/MessageLog";

export const initialState: MessageLogsStateI = {
  endDate: moment(),
  error: null,
  loading: false,
  messageLogs: [],
  messageLogTemplate: null,
  order: "desc",
  orderBy: "dateSent",
  page: 0,
  rowsPerPage: 10,
  query: "",
  serverQuery: null,
  selectedCompany: null,
  selectedDebtor: null,
  startDate: moment().subtract(2, "weeks"),
};

const messageLogs = (state = initialState, action): MessageLogsStateI => {
  switch (action.type) {
    case MESSAGE_LOGS_SET_COMPANY:
      return {
        ...state,
        selectedCompany: action.company,
      };
    case MESSAGE_LOGS_SET_DEBTOR:
      return {
        ...state,
        selectedDebtor: action.debtor,
      };
    case MESSAGE_LOGS_SET_END_DATE: {
      return {
        ...state,
        endDate: action.endDate,
      };
    }
    case MESSAGE_LOGS_SET_START_DATE: {
      return {
        ...state,
        startDate: action.startDate,
      };
    }
    case MESSAGE_LOGS_SET_ORDER:
      return {
        ...state,
        order: action.order,
        orderBy: action.orderBy,
      };
    case MESSAGE_LOGS_SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case MESSAGE_LOGS_SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rows,
      };
    case MESSAGE_LOGS_SET_SERVER_QUERY:
      return {
        ...state,
        // When we set a new query, we are kicking off a new search and want to go back to page 1 for results
        page: 0,
        serverQuery: {
          endDate: state.endDate,
          startDate: state.startDate,
          selectedCompany: state.selectedCompany,
          selectedDebtor: state.selectedDebtor,
          messageLogTemplate: state.messageLogTemplate,
          query: state.query,
        },
      };
    case MESSAGE_LOGS_SET_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case MESSAGE_LOGS_SET_TEMPLATES:
      return {
        ...state,
        messageLogTemplate: action.template,
      };
    default:
      return state;
  }
};

export default messageLogs;
