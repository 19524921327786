import {
  grayColor,
  blueColor,
  pinkColor,
  greenColor,
  purpleColor,
} from "assets/jss/globalStyle";

// eslint-disable-next-line
const checkboxSpinnerStyle = (theme, props) => ({
  checkBoxWrapper: {
    position: "relative",
    width: "48px",
  },
  checkboxBlue: {
    color: blueColor,
  },
  checkboxPink: {
    color: pinkColor,
  },
  checkboxGreen: {
    color: greenColor,
  },
  checkboxPurple: {
    color: purpleColor,
  },
  buttonProgress: {
    color: grayColor,
    position: "absolute",
    top: "5px",
    left: "5px",
  },
});

export default checkboxSpinnerStyle;
