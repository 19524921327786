import { Dispatch, useCallback, useEffect, useReducer, useRef } from "react";

export function useSetState<T>(initialState: T): [T, Dispatch<Partial<T>>] {
  const [state, setState]: [T, Dispatch<T>] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  return [state, setState];
}

export function useSafeSetState<T>(initialState: T): [T, Dispatch<Partial<T>>] {
  const [state, setState] = useSetState(initialState);
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const safeSetState = useCallback(
    (newState: Partial<T>) => mountedRef.current && setState(newState),
    [setState]
  );
  return [state, safeSetState];
}
