import { withStyles } from "tss-react/mui";
import cx from "classnames";
import { Done, Error } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import serverEventTriggerStyle from "components/ServerEventTrigger/serverEventTriggerStyle";

interface ServerEventTriggerI {
  "data-aut"?: string;
  loading: boolean;
  error?: string;
  handleClick: (e: any) => void;
  success?: boolean;
  text: string;
  classes?: Partial<Record<"button" | "active" | "icon" | "error", string>>;
}

const ServerEventTrigger = (props: ServerEventTriggerI) => {
  const { loading, error, handleClick, success, text } = props;
  const classes = withStyles.getClasses(props);
  const dataAut = props["data-aut"];
  let active = true;
  if (loading || error || success) active = false;
  const customClass = cx(
    classes.button,
    active ? classes.active : "",
    error ? classes.error : ""
  );
  return (
    <div className={customClass} onClick={handleClick} data-aut={dataAut}>
      {success && <Done className={classes.icon} />}
      {loading && <CircularProgress size={16} className={classes.icon} />}
      {error && <Error className={cx(classes.error, classes.icon)} />}
      <span>{error || text}</span>
    </div>
  );
};

export default withStyles(ServerEventTrigger, serverEventTriggerStyle);
