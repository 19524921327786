import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { COUNTRIES } from "common/constants";
import { handleErrors } from "common/helpers";

export const fetchCountries = () => {
  const url = getConfig(COUNTRIES).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${COUNTRIES} url`));
  }
};
