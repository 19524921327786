import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { searchItineraries } from "services/itineraries";
import { QUERYKEY_ITINERARIES } from "./queryKeys";
import { ItinerarySearchStateI } from "types/ItinerarySearch";

export function useItineraries() {
  const reduxState = useSelector(
    ({ itinerarySearch }: { itinerarySearch: ItinerarySearchStateI }) => ({
      itinerarySearch,
    })
  );
  return useQuery({
    queryKey: [QUERYKEY_ITINERARIES, reduxState.itinerarySearch?.searchParams],
    queryFn: () => {
      return searchItineraries(reduxState.itinerarySearch?.searchParams);
    },
    enabled: Boolean(reduxState.itinerarySearch?.searchParams),
    initialData: [],
  });
}
