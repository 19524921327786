import { DATE_FORMATS } from "common/constants";
import { handleErrors } from "common/helpers";
import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig";

const url: string | null = getConfig(DATE_FORMATS).url;

export const fetchDateFormats = () => {
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => res.map((r) => ({ ...r, label: r.name, value: r.code })));
  } else {
    return Promise.reject(new Error(`Missing ${DATE_FORMATS} url.`));
  }
};
