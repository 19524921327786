import Button from "@mui/material/Button";
import {
  blueColor,
  greenColor,
  lightGrayColor,
  grayColor,
  redColor,
} from "assets/jss/globalStyle";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import buttonStyle from "components/Button/buttonStyle";
import { withStyles } from "tss-react/mui";

interface ButtonI extends React.HTMLProps<HTMLButtonElement> {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  loading?: boolean;
  isTextButton?: boolean;
  tooltip?: string;
  secondary?: boolean;
  isBlue?: boolean;
  isGray?: boolean;
  noMargin?: boolean;
  component?: React.ElementType;
  classes?: Partial<Record<"button" | "spinner", string>>;
}

const CustomButton = (props: ButtonI) => {
  const {
    component,
    disabled,
    onClick,
    label,
    loading,
    isTextButton,
    tooltip,
    secondary,
    isBlue,
    isGray,
    noMargin,
    type,
  } = props;
  const classes = withStyles.getClasses(props);

  function renderButton() {
    const ariaLabel = props["aria-label"];
    const dataAut = props["data-aut"];
    // Using basic classes does not override certain aspects of material ui's button
    // so we override via inline styles
    const style: React.CSSProperties = {};

    if (isTextButton) style.backgroundColor = "transparent";
    else if (secondary) style.backgroundColor = redColor;
    else if (isBlue) style.backgroundColor = blueColor;
    else if (isGray) {
      style.backgroundColor = lightGrayColor;
      style.color = "black";
    } else style.backgroundColor = greenColor;

    if (isTextButton) {
      style.color = disabled ? grayColor : blueColor;
    }
    if (noMargin) style.margin = 0;
    return (
      <Button
        aria-label={ariaLabel}
        data-aut={dataAut}
        style={style}
        onClick={onClick}
        className={classes.button}
        disabled={disabled ? true : false}
        type={type}
        component={component ? component : undefined}
      >
        {label}
        {loading && (
          <CircularProgress
            size={40}
            className={classes.spinner}
            style={isTextButton ? { color: blueColor } : {}}
          />
        )}
      </Button>
    );
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={"top"} enterDelay={150}>
        {/* Add a span wrapper for the button so the tooltip works even if the button is disabled */}
        <span>{renderButton()}</span>
      </Tooltip>
    );
  } else {
    return renderButton();
  }
};

export default withStyles(CustomButton, buttonStyle);
