import { useQuery } from "@tanstack/react-query";
import { QUERYKEY_CURRENCY_SYMBOLS } from "./queryKeys";
import { fetchCurrencySymbols } from "services/currencySymbols";
import { CurrencySymbolI } from "types/CurrencySymbol";

function mapCurrencySymbols(data): CurrencySymbolI[] {
  return Object.keys(data).map((key) => ({
    label: data[key],
    value: key,
    code: key,
  }));
}

export function useCurrencySymbols() {
  return useQuery({
    queryKey: [QUERYKEY_CURRENCY_SYMBOLS],
    queryFn: fetchCurrencySymbols,
    select: mapCurrencySymbols,
    staleTime: Infinity,
  });
}
