import React, { useState } from "react";
import { Input } from "components";
import { ClassNameMap } from "@mui/styles/withStyles";

interface MultiInputPropsI {
  classes?: ClassNameMap;
  disabled?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  value: string[];
  setValue?: (val: string | string[], isValid?: boolean) => void;
  noMinHeight?: boolean;
  dataAut?: string;
}

function MultiInput({
  classes,
  disabled,
  name,
  label,
  placeholder,
  type,
  value,
  setValue,
  noMinHeight = false,
  dataAut,
}: MultiInputPropsI) {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  function updateList(val, isValid) {
    // If our input validation has failed, we don't want to push the value to our list.
    // Instead we want to set an error state.
    if (isValid !== false) {
      // If our input is valid then we pass it to the setValue callback
      setValue(val);
      if (error) setError(false);
    } else setError(true);
  }

  function updateValue(val) {
    // We only want to show errors when people are attempting to enter invalid values to the list
    if (error) setError(false);
    setInputValue(val);
  }

  return (
    <Input
      classes={classes}
      disabled={disabled}
      error={error}
      helperText={error ? `Invalid value. Must be a ${type}.` : null}
      label={label}
      name={name}
      placeholder={value && value.length ? undefined : placeholder}
      type={type}
      value={inputValue}
      onChange={updateValue}
      selectedItems={value}
      setValues={updateList}
      noMinHeight={noMinHeight}
      data-aut={dataAut}
      isMultiple
    />
  );
}

export default MultiInput;
