import { paddingSize } from "assets/jss/globalStyle";

// eslint-disable-next-line
const userSearchFormStyle = (theme, props) => ({
  container: {
    width: "100%",
    padding: paddingSize,
  },
  half: {
    width: "50%",
    marginBottom: ".5em",
  },
  searchQuery: {
    width: "66%",
    marginBottom: "1em",
  },
});

export default userSearchFormStyle;
