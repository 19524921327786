import React from "react";
import { SingleDatePicker } from "react-dates";
import { withStyles } from "tss-react/mui";
import datepickerStyle from "assets/jss/components/datepickerStyle";
import { useSafeSetState } from "hooks/useState";
import { Moment } from "moment";

const initialState = {
  focused: null,
};

interface DayPickerPropsI {
  date: Moment;
  disabled?: boolean;
  isOutsideDateRange?: boolean | ((date: Moment) => boolean);
  numberOfMonths?: number;
  setDate: (date: Moment) => void;
  withPortal?: boolean;
  openDirection?: "down" | "up";
  id: string;
  showClearDate?: boolean;
}

function DayPicker(props: DayPickerPropsI) {
  const {
    date,
    disabled = undefined,
    isOutsideDateRange = undefined,
    numberOfMonths = undefined,
    setDate,
    withPortal = true,
    openDirection = undefined,
    id,
    showClearDate = undefined,
  } = props;
  function sanitizeDate(date) {
    if (date === null || date.isValid()) setDate(date);
  }

  const [{ focused }, safeSetState] = useSafeSetState(initialState);

  return (
    <SingleDatePicker
      date={date}
      disabled={disabled}
      onDateChange={sanitizeDate}
      focused={focused}
      onFocusChange={({ focused }) => safeSetState({ focused })}
      id={id}
      daySize={50} // Controls the size of days on the calendar
      placeholder="Enter Date"
      numberOfMonths={numberOfMonths}
      isOutsideRange={isOutsideDateRange}
      withPortal={withPortal}
      openDirection={openDirection}
      showClearDate={showClearDate}
    />
  );
}

export default withStyles(DayPicker, datepickerStyle);
