import { get } from "services/apiClient";
import { getConfig } from "services/apiConfig.js";
import { ITINERARIES } from "common/constants";
import { handleErrors } from "common/helpers";
import { ItineraryI } from "types/Intinerary";

export const searchItineraries = (query = {}): Promise<ItineraryI[]> => {
  const url = getConfig(ITINERARIES).url;
  if (url) {
    return get(url, query)
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ITINERARIES} url`));
  }
};
