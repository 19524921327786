import { blueColor, label } from "assets/jss/globalStyle";

// eslint-disable-next-line
const itinerarySearchStyles = (theme, props) => ({
  root: {
    padding: "16px",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  searchButtonRow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  leftHalf: {
    width: "calc(50% - 8px)",
    marginRight: "8px",
  },
  rightHalf: {
    width: "calc(50% - 8px)",
    marginLeft: "8px",
  },
  leftThird: {
    width: "calc(33% - 12px)",
    marginRight: "8px",
  },
  middleThird: {
    width: "calc(33% - 12px)",
    marginLeft: "8px",
    marginRight: "8px",
  },
  rightThird: {
    width: "calc(33% - 12px)",
    marginLeft: "8px",
  },
  checkboxRoot: {
    padding: "8px",
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
    height: "46px",
    fontSize: "14px",
    fontWeight: 600,
    border: 0,
    "& label": {
      color: "black",
      fontWeight: 600,
    },
  },
  checkboxIcon: {
    color: blueColor,
    fontSize: 32,
  },
  label,
  legend: {
    ...label,
    marginBottom: "25px",
    paddingLeft: 0,
  },
  expansionPanel: {
    boxShadow: "none",
    width: "100%",
    padding: "0",
    margin: 0,
    "&:before": {
      height: "0px",
    },
  },
  panelContent: {
    margin: "12px 0 !important",
  },
  panelSummary: {
    padding: "0",
    color: blueColor,
    fontSize: "14px",
    margin: 0,
  },
  panelDetails: {
    padding: "0",
    flexWrap: "wrap",
  },
  expandIcon: {
    transform: "rotate(-90deg)",
    top: "20px",
    position: "absolute",
    transition: "all 0.25s ease",
  },
  expandText: {
    marginLeft: "30px",
  },
  radioButton: {
    color: blueColor,
    padding: "8px",
    "&$checked": {
      color: blueColor,
    },
  },
  checked: {},
  searchText: {
    marginRight: "8px",
  },
  searchCategory: {
    marginRight: "8px",
    fontSize: "14px",
    fontWeight: 600,
    color: "black",
  },
  travelDates: {
    display: "inline-block",
    width: "270px",
    marginRight: "16px",
  },
  travelTypes: {
    display: "inline-block",
  },
});

export default itinerarySearchStyles;
