import { IMPERSONATION_LOGS } from "common/constants";

export const IMPERSONATION_LOGS_SET_SERVER_QUERY = `${IMPERSONATION_LOGS}_SET_SERVER_QUERY`;

export const setImpersonationLogsServerQuery = () => ({
  type: IMPERSONATION_LOGS_SET_SERVER_QUERY,
});

export const IMPERSONATION_LOGS_SET_QUERY = `${IMPERSONATION_LOGS}_SET_QUERY`;

export const setImpersonationLogsQuery = (query) => ({
  type: IMPERSONATION_LOGS_SET_QUERY,
  query,
});

export const IMPERSONATION_LOGS_SET_COMPANY = `${IMPERSONATION_LOGS}_SET_COMPANY`;

export const setImpersonationLogsCompany = (company) => ({
  type: IMPERSONATION_LOGS_SET_COMPANY,
  company,
});

export const IMPERSONATION_LOGS_SET_ORDER = `${IMPERSONATION_LOGS}_SET_ORDER`;

export const setImpersonationLogsOrder = (order, orderBy) => ({
  type: IMPERSONATION_LOGS_SET_ORDER,
  order,
  orderBy,
});

export const IMPERSONATION_LOGS_SET_PAGE = `${IMPERSONATION_LOGS}_SET_PAGE`;

export const setImpersonationLogsPage = (page) => ({
  type: IMPERSONATION_LOGS_SET_PAGE,
  page,
});

export const IMPERSONATION_LOGS_SET_ROWS_PER_PAGE = `${IMPERSONATION_LOGS}_SET_ROWS_PER_PAGE`;

export const setImpersonationLogsRowsPerPage = (rows) => ({
  type: IMPERSONATION_LOGS_SET_ROWS_PER_PAGE,
  rows,
});

export const IMPERSONATION_LOGS_SET_START_DATE = `${IMPERSONATION_LOGS}_SET_START_DATE`;

export const setImpersonationLogsStartDate = (startDate) => ({
  type: IMPERSONATION_LOGS_SET_START_DATE,
  startDate,
});

export const IMPERSONATION_LOGS_SET_END_DATE = `${IMPERSONATION_LOGS}_SET_END_DATE`;

export const setImpersonationLogsEndDate = (endDate) => ({
  type: IMPERSONATION_LOGS_SET_END_DATE,
  endDate,
});
