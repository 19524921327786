import { pagination } from "assets/jss/components/tableStyle";
import { ExtendedTheme } from "types/MUIExtensions";

const tableCell = {
  fontSize: "13px",
  fontWeight: 600,
  cursor: "pointer",
  userSelect: "text" as const,
};

// eslint-disable-next-line
const itineraryTableStyle = (theme: ExtendedTheme, props) => ({
  errorText: {
    color: theme.palette.redColor,
    marginLeft: "20px",
  },
  expandIcon: {
    transform: "rotate(-90deg)",
    top: "5px",
    left: "0px",
    position: "absolute",
    transition: "all 0.25s ease",
  },
  loadingRow: {
    position: "relative",
    width: "100%",
    height: "200px",
  },
  toolBarTitle: {
    width: "100%",
    margin: 0,
  },
  textField: {
    marginRight: "50px",
    width: "300px",
  },
  toolBar: {
    width: "100%",
    padding: "16px",
  },
  table: {
    minWidth: 1020,
    position: "relative",
  },
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
  },
  ...pagination,
  paginationContainer: {
    width: "100%",
  },
  sortIcon: {
    marginLeft: "20px",
    transition: "all 0.25s ease",
  },
  innerRow: {
    width: "100%",
    display: "flex",
    padding: "10px 20px !important",
    alignItems: "center",
  },
  tableRow: {
    width: "100%",
    display: "flex",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    padding: "10px 20px",
    alignItems: "center",
  },
  tableBody: {
    position: "relative",
    minHeight: "200px",
  },
  tableCellSmall: {
    ...tableCell,
    width: "110px",
    display: "flex",
  },
  tableCell: {
    ...tableCell,
    width: "175px",
    display: "flex",
  },
  tableCellMiddle: {
    ...tableCell,
    width: "25%",
    flexGrow: 1,
  },
  cellItem: {
    margin: "5px 0",
  },
  pnrWrapper: {
    position: "relative",
    paddingLeft: 20,
    // color: theme.palette.primary.main,
  },
  expansionPanel: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    boxShadow: "none",
  },
  expansionPanelContent: {
    padding: 0,
  },
  expansionPanelDetails: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  endDate: {
    marginLeft: "30px",
  },
});

export default itineraryTableStyle;
