import React, { Suspense } from "react";
import { Loading } from "components";

const GlobalSettings = React.lazy(() => import("./GlobalSettings"));

function GlobalSettingsView(props) {
  return (
    <Suspense
      fallback={<Loading active loadingText="Loading Global Settings" />}
    >
      <GlobalSettings {...props} />
    </Suspense>
  );
}

export default GlobalSettingsView;
