import {
  IMPERSONATION_LOGS_SET_COMPANY,
  IMPERSONATION_LOGS_SET_END_DATE,
  IMPERSONATION_LOGS_SET_ORDER,
  IMPERSONATION_LOGS_SET_PAGE,
  IMPERSONATION_LOGS_SET_ROWS_PER_PAGE,
  IMPERSONATION_LOGS_SET_QUERY,
  IMPERSONATION_LOGS_SET_START_DATE,
  IMPERSONATION_LOGS_SET_SERVER_QUERY,
} from "redux/actions/impersonationLogs";
import { ImpersonationLogsSearchState } from "types/ImpersonationLog";

export const initialState: ImpersonationLogsSearchState = {
  query: "",
  company: null,
  endDate: null,
  startDate: null,
  order: "desc",
  orderBy: "date",
  page: 0,
  rowsPerPage: 10,
};

const impersonationLogs = (
  state = initialState,
  action
): ImpersonationLogsSearchState => {
  switch (action.type) {
    case IMPERSONATION_LOGS_SET_COMPANY:
      return {
        ...state,
        company: action.company,
      };

    case IMPERSONATION_LOGS_SET_END_DATE: {
      const endDate = action.endDate?.endOf("day");
      return {
        ...state,
        endDate,
      };
    }
    case IMPERSONATION_LOGS_SET_START_DATE: {
      return {
        ...state,
        startDate: action.startDate,
      };
    }
    case IMPERSONATION_LOGS_SET_ORDER:
      return {
        ...state,
        order: action.order,
        orderBy: action.orderBy,
      };
    case IMPERSONATION_LOGS_SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case IMPERSONATION_LOGS_SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rows,
      };
    case IMPERSONATION_LOGS_SET_SERVER_QUERY:
      return {
        ...state,
        // When we set a new query, we are kicking off a new search and want to go back to page 1 for results
        page: 0,
        serverQuery: {
          endDate: state.endDate,
          startDate: state.startDate,
          company: state.company,
          query: state.query,
        },
      };
    case IMPERSONATION_LOGS_SET_QUERY:
      return {
        ...state,
        query: action.query,
      };
    default:
      return state;
  }
};

export default impersonationLogs;
