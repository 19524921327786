import { useQuery } from "@tanstack/react-query";
import { fetchCities, searchCities } from "services/cities";
import {
  QUERYKEY_CITIES,
  QUERYKEY_COUNTRY_CITIES,
  QUERYKEY_ITINERARIES,
} from "./queryKeys";
import { City } from "types/Locations";

function mapCountryCities(cities): City[] {
  return cities.map((c) => {
    let secondaryLabel = "";
    if (c.cityCode) {
      secondaryLabel += c.cityCode.toUpperCase();
      if (c.country) secondaryLabel += ", ";
    }
    if (c.country) secondaryLabel += c.country;
    if (secondaryLabel.length)
      return { ...c, label: c.city, secondaryLabel, code: c.cityCode };
    else return { ...c, label: c.city, code: c.cityCode };
  });
}

export function useItinerarySelectedCountryCities(countryCode: string) {
  return useQuery({
    queryKey: [QUERYKEY_ITINERARIES, QUERYKEY_COUNTRY_CITIES, countryCode],
    queryFn: () => fetchCities(countryCode),
    select: mapCountryCities,
    enabled: Boolean(countryCode),
  });
}

function mapCities(cities): City[] {
  return cities.map((c) => {
    let secondaryLabel = "";

    if (c.code) {
      secondaryLabel += c.code.toUpperCase();
      if (c.stateCode || c.countryName) secondaryLabel += ", ";
    }

    if (c.stateCode) {
      secondaryLabel += c.stateCode;
      if (c.countryName) secondaryLabel += ", ";
    }

    if (c.countryName) secondaryLabel += c.countryName;

    if (secondaryLabel.length) return { ...c, secondaryLabel, label: c.name };
    else return { ...c, label: c.name };
  });
}

export function useItinerarySearchCities(cityQuery: string) {
  return useQuery({
    queryKey: [QUERYKEY_ITINERARIES, QUERYKEY_CITIES, cityQuery],
    queryFn: () => searchCities(cityQuery),
    select: mapCities,
    enabled: Boolean(cityQuery),
  });
}
