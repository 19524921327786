import React, { Suspense } from "react";
import { Loading } from "components";

const MessageLogs = React.lazy(() => import("./MessageLogs"));

function MessageLogsView(props) {
  return (
    <Suspense fallback={<Loading active loadingText="Loading Message Logs" />}>
      <MessageLogs {...props} />
    </Suspense>
  );
}

export default MessageLogsView;
