import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import Button from "@mui/material/Button";
import moment, { Moment } from "moment";
import { withStyles } from "tss-react/mui";
import { blueColor, whiteColor } from "assets/jss/globalStyle";
import datepickerStyle from "assets/jss/components/datepickerStyle";

interface DatePickerI {
  endDate: Moment;
  endDateId: string;
  onDatesChange: (params: { startDate: Moment; endDate: Moment }) => void;
  startDate: Moment;
  startDateId: string;
  classes?: Partial<Record<"button" | "buttonWrapper", string>>;
}

const DatePicker = (props: DatePickerI) => {
  const { endDate, endDateId, onDatesChange, startDate, startDateId } = props;
  const classes = withStyles.getClasses(props);

  const [focusedInput, setFocus] = useState(null);
  const today = moment();
  const nextMonth = moment().add(1, "month");
  const nextThreeMonths = moment().add(3, "months");
  const nextYear = moment().add(1, "year");
  const previousMonth = moment().subtract(1, "month");
  const previousThreeMonths = moment().subtract(3, "months");
  const previousYear = moment().subtract(1, "year");

  const presets = [
    {
      text: "Next Month",
      start: today,
      end: nextMonth,
      dataAut: "DatePicker|NextMonth",
    },
    {
      text: "Next 3 Months",
      start: today,
      end: nextThreeMonths,
      dataAut: "DatePicker|Next3Months",
    },
    {
      text: "Next Year",
      start: today,
      end: nextYear,
      dataAut: "DatePicker|NextYear",
    },
    {
      text: "Past Month",
      start: previousMonth,
      end: today,
      dataAut: "DatePicker|PastMonth",
    },
    {
      text: "Past 3 Months",
      start: previousThreeMonths,
      end: today,
      dataAut: "DatePicker|Past3Months",
    },
    {
      text: "Past Year",
      start: previousYear,
      end: today,
      dataAut: "DatePicker|PastYear",
    },
  ];

  const renderDatePresets = () => {
    return (
      <div className={classes.buttonWrapper}>
        {presets.map(({ text, start, dataAut, end }) => {
          const isSame = isSameDates(start, end);
          return (
            <Button
              className={classes.button}
              data-aut={dataAut}
              disabled={isSame}
              key={text}
              onClick={() => onDatesChange({ startDate: start, endDate: end })}
              style={
                isSame
                  ? {
                      color: whiteColor,
                      background: blueColor,
                      cursor: "initial",
                    }
                  : {}
              }
            >
              {text}
            </Button>
          );
        })}
      </div>
    );
  };

  const isSameDates = (start, end) => {
    return start.isSame(startDate, "day") && end.isSame(endDate, "day");
  };

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId={startDateId}
      endDate={endDate}
      endDateId={endDateId}
      onDatesChange={({ startDate, endDate }) =>
        onDatesChange({ startDate, endDate })
      }
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocus(focusedInput)}
      inputIconPosition="after"
      block
      withPortal
      customInputIcon={
        <i className="icon-date" style={{ color: blueColor }}></i>
      }
      renderCalendarInfo={renderDatePresets}
      daySize={50} // Controls the size of days on the calendar
      isOutsideRange={() => false} // Allows past dates to be selectable
      readOnly
    />
  );
};

DatePicker.defaultProps = {
  endDateId: "endDateId",
  startDateId: "startDateId",
};

export default withStyles(DatePicker, datepickerStyle);
