import React, { useEffect, useRef } from "react";
import { useSafeSetState } from "hooks/useState";

import Downshift from "downshift";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getInternalInputProps,
  itemToString,
  renderInput,
  getSuggestions,
  renderSuggestions,
  AutoCompleteSharedI,
} from "./AutoComplete";

const initialState = {
  inputValue: "",
  isOpen: false,
  useInputAutoWidth: false,
};

export interface AutoCompleteMultipleI extends AutoCompleteSharedI {
  isMulti: true;
  maxChar?: number;
  selectedItem: { label: string }[];
}

const DownshiftMultiple = (props: AutoCompleteMultipleI) => {
  const {
    autoRenderSuggestions,
    classes,
    disabled,
    fixedPositionWidth,
    handleDelete,
    handleQuery,
    isAsync,
    label,
    isLoading,
    maxResults = 100,
    minChar = 3,
    maxChar = Infinity,
    placeholder = "",
    onChange,
    selectedItem,
    suggestions = [],
  } = props;

  const rootRef = useRef(null);
  const inputRef = useRef(null);

  const [{ inputValue, isOpen, useInputAutoWidth }, safeSetState] =
    useSafeSetState(initialState);

  useEffect(() => {
    if (disabled && inputValue && inputValue.length) {
      safeSetState({ inputValue: "" });
    }
  }, [disabled, inputValue, safeSetState]);

  const handleKeyDown = (event) => {
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      handleDelete(selectedItem[selectedItem.length - 1]);
    }
  };

  const handleInputChange = (event) => {
    const val = event.target.value;
    if (val.length > maxChar) return;
    safeSetState({ inputValue: val });
    if (isAsync && val.length >= minChar) handleQuery(val);
  };

  const handleChange = (item) => {
    inputRef.current.blur();
    safeSetState({
      inputValue: "",
      isOpen: false,
    });
    if (selectedItem.every((i) => i !== item)) onChange(item);
  };

  const dataAut = props["data-aut"];

  return (
    <div
      className={classes.wrapper}
      style={!label ? { minHeight: "45px", height: "auto" } : {}}
    >
      {label && (
        <label htmlFor={label} className={classes.label}>
          {label}
        </label>
      )}
      <div className={classes.root} ref={rootRef}>
        <Downshift
          inputValue={inputValue}
          defaultHighlightedIndex={0}
          itemToString={itemToString}
          onChange={handleChange}
          selectedItem={selectedItem}
          isOpen={isOpen}
          onOuterClick={() => safeSetState({ isOpen: false })}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
          }) => (
            <div className={classes.container}>
              {renderInput({
                useInputAutoWidth: useInputAutoWidth,
                noLeftPadding: !selectedItem || !selectedItem.length,
                fullWidth: true,
                classes,
                disabled,
                ref: inputRef,
                InputProps: getInputProps({
                  inputProps: getInternalInputProps(label, dataAut),
                  id: label,
                  endAdornment: isLoading ? (
                    <CircularProgress size={20} />
                  ) : undefined,
                  startAdornment: selectedItem.map((item) => (
                    <Chip
                      key={item.label}
                      tabIndex={-1}
                      label={item.label}
                      className={classes.chip}
                      onDelete={() => {
                        if (!disabled) handleDelete(item);
                      }}
                    />
                  )),
                  onChange: handleInputChange,
                  onFocus: () => safeSetState({ isOpen: true }),
                  onKeyDown: handleKeyDown,
                  placeholder: placeholder || "",
                }),
              })}
              {isOpen ? (
                <div
                  style={
                    fixedPositionWidth
                      ? {
                          position: "fixed",
                          width: fixedPositionWidth,
                          zIndex: 2,
                        }
                      : {}
                  }
                >
                  <Paper className={classes.paper} square>
                    {renderSuggestions(
                      getSuggestions(
                        inputValue,
                        suggestions,
                        minChar,
                        autoRenderSuggestions,
                        maxResults,
                        selectedItem,
                        isAsync
                      ),
                      selectedItem,
                      getItemProps,
                      highlightedIndex,
                      inputValue,
                      classes,
                      isLoading,
                      minChar
                    )}
                  </Paper>
                </div>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    </div>
  );
};

export default DownshiftMultiple;
